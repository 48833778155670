import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  Select,
  Image,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import axios from "axios";
import { svg, svg_1 } from "../../assets/objectSVG";
import { MdImageNotSupported, MdImage } from "react-icons/md";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { BiCollapse } from "react-icons/bi";
import { Divider } from "../../components/node/ui/Divider";
import { useDispatch, useSelector } from "react-redux";
import { TimeZone, Countries, TimeZones } from "./timeZones";
import Langs from "./langs";
import { FiSave } from "react-icons/fi";
import AuthTokenService from "../../utils/AuthTokenService";
import {
  getProfile,
  updatePassword,
  updateProfile,
} from "../../store/thunk/accountsThunk";
import { NodeLoader } from "../../components/loader";
import { RiProfileLine } from "react-icons/ri";
import { SubscriptionContainer } from "./Subscription";
import { DashButton } from "../../components/styled";
import langs from "./langs";
import _ from "lodash";
import { TeamContainer } from "./Team";
import { ontoggleExtra, ontoggleProfile } from "../../store/profileSlice";
import { AgencyContainer } from "./Agency";
import { ResellerContainer } from "./Reseller";

const extendedProfileData = [
  {
    label: "Time Zone",
    select: true,
    key: "timezone",
  },
  {
    label: "Choose Language",
    select: true,
    key: "preferred_language",
  },
  {
    label: "Company's Name",
    key: "company_name",
  },
  {
    label: "Current Position",
    key: "current_position",
  },

  {
    label: "Address",
    key: "address_line1",
  },
  {
    label: "City",
    key: "city",
  },
  {
    label: "State",
    key: "state",
  },
  {
    label: "Country",
    select: true,
    key: "country",
  },
  {
    label: "Zip Code",
    key: "zip_code",
  },
  {
    label: "Tax ID",
    key: "tax_id",
  },
];

export const AccountContainer = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profileData);
  const profileLoading = useSelector((state) => state.profile.profileLoading);
  const [profile, setProfile] = useState({
    email: "",
    first_name: "",
    last_name: "",
    is_admin: false,
    image_url: "",
    company_name: "",
    timezone: "",
    preferred_language: "",
    current_plan: "",
    country: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
    tax_id: "",
    current_position: "",
  });
  const [expandPassword, setExpandPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });
  const { toggleProfile, toggleExtra } = useSelector((state) => state.profile);
  const loading = useSelector((state) => state.profile.loading);
  useEffect(() => {
    dispatch(getProfile()).then((res) => setProfile(res.payload));
  }, []);
  const fileRef = useRef(null)
  const fileUpload = e => {

    const formData = new FormData()

    formData.append("image", event.target.files[0], event.target.files[0].name)
    uploadImg(formData)
  }

  const removeImage = () => {
    const formData = new FormData()
    formData.append("image", null)
    uploadImg(formData)
  }

  const uploadImg = data => {
    axios
      .post(process.env.API_BASE_URL + "api/v1/users/profile", data, {
        headers: { "content-type": "multipart/form-data", Authorization: `Bearer ${AuthTokenService.get()}` },
      })
      .then(() => {
        dispatch(getProfile()).then((res) => setProfile(res.payload));
      })
  }
  return (
    <Flex width="100%" height="100%" sx={{ paddingY: "20px", gap: "20px" }}>
      {loading ? (
        <Flex
          width="100%"
          sx={{
            bgColor: "#fff",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            position: "relative",
            gap: "20px",
            flexDir: "row",
            position: "relative",
          }}
        >
          <NodeLoader loading={loading} bgcolor="#fff" />
        </Flex>
      ) : (
        <Flex
          width="100%"
          sx={{
            gap: "20px",
            flexDir: "row",
            position: "relative",
          //  mb:loading&&profileLoading?"0%":"8%"
          }}
        >
          <Flex flexDir="column" width="50%" height="100%" gap="20px">
            <Flex
              width="100%"
              height={
                profile.current_plan !== "Team" &&
                profile.current_plan !== "Agency" &&
                profile.current_plan !== "Reseller"
                  ? "100%"
                  : toggleExtra
                  ? ""
                  : toggleProfile
                  ? "100%"
                  : "50%"
              }
              sx={{
                bgColor: "#fff",
                boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
                transition: "height 0.20s",
                borderRadius: "4px",
                p: "20px",
                gap: "20px",
                position: "relative",
                flexDir: "column",
              }}
            >
              {" "}
              {profileLoading ? (
                <NodeLoader bgcolor="#fff" loading={profileLoading} />
              ) : (
                <>
                  {" "}
                  <Flex
                    gap="10px"
                    alignItems="center"
                    pb="5px"
                    borderBottom="2px solid #152D73"
                    justifyContent="space-between"
                  >
                    <Flex gap="10px">
                      <Icon
                        as={RiProfileLine}
                        boxSize="1.7em"
                        sx={{
                          transition: "transform 0.15s",
                        }}
                      />
                      <Flex
                        sx={{
                          fontSize: [16, 16, 18, 20, 22, 24],
                          fontWeight: 500,
                          m: "auto 0px",
                        }}
                      >
                        Profile
                      </Flex>
                    </Flex>{" "}
                    {profileData.current_plan !== "Pro Plan" &&
                      profileData.current_plan !== "Free" && (
                        <Tooltip
                          hasArrow
                          label={
                            toggleProfile
                              ? "Collapse Profile"
                              : "Expand Profile"
                          }
                        >
                          <Flex>
                            <Icon
                              as={
                                toggleProfile
                                  ? BiCollapse
                                  : HiOutlineArrowsExpand
                              }
                              onClick={() => {
                                dispatch(ontoggleProfile());
                                if (toggleExtra) {
                                  dispatch(ontoggleExtra());
                                }
                              }}
                              boxSize="1.3em"
                              color="#162c73"
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  transform: "scale(1.2)",
                                },
                                transition: "transform 0.20s",
                              }}
                            />{" "}
                          </Flex>
                        </Tooltip>
                      )}
                  </Flex>
                  <Flex
                    height="100%"
                    width="100%"
                    sx={{
                      display: toggleExtra ? "none" : "flex",
                      position: "relative",
                      flexDir: "column",
                    }}
                  >
                    <Flex
                      flexWrap="wrap"
                      width="100%"
                      height="100%"
                      sx={{
                        position: "absolute",
                        // p: "20px",
                        rowGap: "20px",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <Flex
                        gap="20px"
                        flexDir="column"
                        justifyContent="space-between"
                        alignItems="center"
                        width="28%"
                        p="5px"
                      >
                        <Flex
                          sx={{
                            borderRadius: "50%",
                            border: "1px solid lightgray",
                            outline: "2px solid lightgray",
                            outlineOffset: 2,
                          }}
                        >
                          <Image
                            src={
                              profile.image_url &&
                              profile.image_url.startsWith("http")
                                ? profile.image_url
                                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            }
                            sx={{
                              borderRadius: "50%",
                              width: "160px",
                              height: "160px",
                            }}
                          />
                        </Flex>
                        <Flex
                          justifyContent="space-between"
                          gap="10px"
                          width="100%"
                        >
                          <Tooltip label="Upload" hasArrow>
                            <Button
                              width="100%"
                              onClick={() => fileRef.current.click()}
                            >
                              <Icon as={MdImage} boxSize="1.3em" />
                              <input
                                accept="image/*"
                                ref={fileRef}
                                type="file"
                                name="image"
                                style={{ display: "none" }}
                                onChange={fileUpload}
                              />
                            </Button>
                          </Tooltip>
                          <Tooltip label="Delete" hasArrow>
                            <Button       onClick={removeImage} width="100%">
                              <Icon as={MdImageNotSupported} boxSize="1.3em" />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Flex>
                      <Flex
                        width="72%"
                        px="20px"
                        flexDir="column"
                        justifyContent="space-between"
                        alignItems="end"
                      >
                        <DashButton
                          onClick={() => {
                            if (
                              expandPassword &&
                              passwordData.password.length > 4 &&
                              passwordData.password ===
                                passwordData.password_confirmation
                            ) {
                              dispatch(updatePassword(passwordData));
                            }
                            if (!_.isEqual(profile, profileData)) {
                              dispatch(updateProfile(profile));
                            }
                          }}
                          sx={{
                            gap: "5px",
                            width: "50%",
                            "&:hover": {
                              "& #icon": {
                                fill: "rgb(21,46,115)",
                              },
                            },
                          }}
                        >
                          <Icon
                            as={FiSave}
                            boxSize="1.3em"
                            sx={{
                              transition: "transform 0.15s",
                            }}
                          />
                          Save
                        </DashButton>
                        <Flex flexDir="column" gap="15px">
                          <Flex width="100%" gap="10px">
                            <Flex flexDir="column" gap="5px" width="100%">
                              <Flex sx={{ fontSize: 14 }}>First Name</Flex>
                              <Input
                                value={profile.first_name}
                                onChange={(e) =>
                                  setProfile((profile) => ({
                                    ...profile,
                                    first_name: e.target.value,
                                  }))
                                }
                              />
                            </Flex>
                            <Flex flexDir="column" gap="5px" width="100%">
                              <Flex sx={{ fontSize: 14 }}>Last Name</Flex>
                              <Input
                                value={profile.last_name}
                                onChange={(e) =>
                                  setProfile((profile) => ({
                                    ...profile,
                                    last_name: e.target.value,
                                  }))
                                }
                              />
                            </Flex>
                          </Flex>
                          <Flex flexDir="column" gap="5px" width="100%">
                            <Flex sx={{ fontSize: 14 }}>Email Address</Flex>
                            <Input
                              value={profile.email}
                              isDisabled={true}
                              onChange={(e) =>
                                setProfile((profile) => ({
                                  ...profile,
                                  email: e.target.value,
                                }))
                              }
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex width="100%" flexDir="column" position="relative">
                        <Flex
                          sx={{
                            pb: "3px",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                        >
                          <Checkbox
                            onChange={(e) =>
                              setExpandPassword(e.target.checked)
                            }
                          >
                            Change Password
                          </Checkbox>
                        </Flex>
                        <Flex
                          display={expandPassword ? "flex" : "none"}
                          flexDir="column"
                          gap="10px"
                          mb="10px"
                        >
                          <Flex flexDir="column" gap="5px" width="100%">
                            <Flex sx={{ fontSize: 14 }}>Current Password</Flex>
                            <Input
                              type={"password"}
                              onChange={(e) =>
                                setPasswordData((c) => ({
                                  ...c,
                                  current_password: e.target.value,
                                }))
                              }
                            />
                          </Flex>
                          <Flex width="100%" gap="10px">
                            <Flex flexDir="column" gap="5px" width="100%">
                              <Flex sx={{ fontSize: 14 }}>New Password</Flex>
                              <Input
                                type={"password"}
                                onChange={(e) =>
                                  setPasswordData((c) => ({
                                    ...c,
                                    password: e.target.value,
                                  }))
                                }
                              />
                            </Flex>
                            <Flex flexDir="column" gap="5px" width="100%">
                              <Flex sx={{ fontSize: 14 }}>
                                Confirm Password
                              </Flex>
                              <Input
                                type={"password"}
                                onChange={(e) =>
                                  setPasswordData((c) => ({
                                    ...c,
                                    password_confirmation: e.target.value,
                                  }))
                                }
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                        <Divider visibility={expandPassword ? true : false} />
                        <Flex position="relative" width="100%">
                          <Flex
                            width="100%"
                            flexWrap="wrap"
                            rowGap="10px"
                            pr="10px"
                            mt="10px"
                          >
                            {extendedProfileData.map((data, i) => {
                              return (
                                <Flex
                                  key={i}
                                  flexDir="column"
                                  gap="5px"
                                  width="50%"
                                  pl={i % 2 == 0 ? "none" : "5px"}
                                  pr={i % 2 == 0 ? "5px" : "none"}
                                >
                                  {data.select ? (
                                    <>
                                      <Flex sx={{ fontSize: 14 }}>
                                        {data.label}
                                      </Flex>
                                      <Select
                                        onChange={(e) => {
                                          if (
                                            data.key === "preferred_language"
                                          ) {
                                            setProfile((profile) => ({
                                              ...profile,
                                              preferred_language:
                                                e.target.value,
                                            }));
                                          } else if(data.key === "timezone" ){
                                            setProfile((profile) => ({
                                              
                                              ...profile,
                                              timezone: e.target.value,
                                            }));
                                          }else{
                                            setProfile((profile) => ({
                                              ...profile,
                                              country: e.target.value,
                                            }));
                                          }
                                           
                                        }}
                                        value={
                                          data.key === "country"?profile.country:
                                          data.key === "preferred_language"
                                            ? profile.preferred_language 
                                            : profile.timezone !== null
                                            ? profile.timezone
                                            : ""
                                        }
                                      >
                                        {data.key === "preferred_language"
                                          ? langs.map((lang, i) => {
                                              return (
                                                <option
                                                  key={i}
                                                  value={lang.value}
                                                >
                                                  {lang.label}
                                                </option>
                                              );
                                            })
                                            :data.key === "timezone" ? TimeZones.map((zone, i) => {
                                              return (
                                                <option
                                                  key={i}
                                                  value={zone.value}
                                                >
                                                  {zone.label}
                                                </option>
                                              );
                                            }):Countries.map((zone, i) => {
                                              return (
                                                <option
                                                  key={i}
                                                  value={zone.value}
                                                >
                                                  {zone.label}
                                                </option>
                                              );
                                            })}
                                      </Select>
                                    </>
                                  ) : (
                                    <>
                                      <Flex sx={{ fontSize: 14 }}>
                                        {data.label}
                                      </Flex>
                                      <Input
                                        value={profile[data.key]}
                                        onChange={(e) => {
                                          setProfile((profile) => ({
                                            ...profile,
                                            [data.key]: e.target.value,
                                          }));
                                        }}
                                      />
                                    </>
                                  )}
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
            {profileData?.current_plan !== "Free" &&
              profileData?.current_plan !== "Pro Plan" && (
                <Flex
                  width="100%"
                  height={
                    toggleExtra
                      ? "100%"
                      : toggleProfile
                      ? ""
                      : toggleExtra && toggleProfile
                      ? ""
                      : "50%"
                  }
                  sx={{
                    bgColor: "#fff",
                    transition: "height 0.20s",
                    boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
                    borderRadius: "4px",
                    p: "20px",
                    gap: "20px",
                    position: "relative",
                    flexDir: "column",
                  }}
                >
                  {profileData.current_plan === "Team" && <TeamContainer />}
                  {profileData.current_plan === "Agency" && <AgencyContainer />}
                  {profileData.current_plan === "Reseller" && (
                    <ResellerContainer />
                  )}
                </Flex>
              )}
          </Flex>
         {profileData && <SubscriptionContainer id = {profileData.paddle_customer_id} />}
        </Flex>
      )}
    </Flex>
  );
};
