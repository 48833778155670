import React, { useRef, useState, useCallback } from "react";
import { CaptureButton, EventWrapper, NodeOuter } from "../../styled";
import { Flex, Input } from "@chakra-ui/react";
import { NodeLoader } from "../../loader";
import { useTranslation } from "react-i18next";
import { AppInfo } from "../ui/AppInfo";
import { Selector, selectStyle } from "../ui/Selector";
import { AdditionalResponse } from "../ui/AdditionalResponse";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import {
  onLinkDelete,
  setSchedulerData,
} from "../../../store/slice/canvasSlice";
import { useDispatch } from "react-redux";
import { ModalContainer } from "../../modal/index";
import ReactSelect from "react-select";
import { OnTestandReview, saveKonnect } from "../../../store/thunk/rightHandAppsThunk";

export const Scheduler = React.memo(({ data, selected }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const daysArray = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];
  let datesArr = [];
  for (let i = 1; i < 32; i++) {
    datesArr.push({ label: i, value: i });
  }
  const intervalArr = [
    { label: "Minutes", value: "Minutes" },
    { label: "Hours", value: "Hours" },
    { label: "Days", value: "Days" },
    { label: "Weeks", value: "Weeks" },
  ];
  const nodeOuter = useRef();
  const [expand, setExpand] = useState(false);
  const [expandHeight, setExpandHeight] = useState(false);
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  const helper = {
    onHelp: useCallback(() => {
      setModalState({ display: true, type: "help" });
    }, []),
    onExpand: useCallback(() => setExpand((c) => !c), []),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    setSchData: (e, type, label) => {
      dispatch(
        setSchedulerData({
          nodeId: data.nodeId,
          value: type === "input" ? e.target.value : e,
          type,
          label,
        })
      );
    },
    onCaptureResponse: () => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      dispatch(OnTestandReview(data.nodeId)).then((res) => {
        dispatch(saveKonnect({ publish: false }));
      });
    },
  };

  return (
    <NodeOuter
      expandheight={expandHeight ? "true" : "false"}
      selected={selected}
      ref={nodeOuter}
      id="drag"
      expand={expand ? "true" : "false"}
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalState={setModalState}
        nodeIdx={data.nodeIdx}
      />
      <EventWrapper visibility={true}>
        <Flex sx={{ fontWeight: 500 }}>{t("konnect.sidebar.choose_trigger") }</Flex>
        <Selector
          parentnode={nodeOuter}
          options={data.appEvents}
          nodeId={data.nodeId}
          selectType={"Trigger"}
          value={data.selectedEvent ? data.selectedEvent : null}
        />
      </EventWrapper>
      {data.selectedEvent?.name === "Once" ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.date_time")}
          </Flex>
          <Input
            sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
            placeholder="Select Date & Time"
            size="md"
            type="datetime-local"
            value={data.selectedValue?.localData?.date || ""}
            onChange={(e) => helper.setSchData(e, "input")}
          />
        </EventWrapper>
      ) : data.selectedEvent?.name === "Every day" ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.time")}
          </Flex>
          <Input
            sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
            placeholder="Select Time"
            size="md"
            type="time"
            value={data.selectedValue?.localData?.time || ""}
            onChange={(e) => helper.setSchData(e, "input")}
          />
        </EventWrapper>
      ) : data.selectedEvent?.name === "Days of the week" ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.days")}
          </Flex>
          <Flex
            onMouseEnter={() => {
              nodeOuter.current.id = "";
              nodeOuter?.current.classList.add("nowheel");
            }}
            onMouseLeave={() => {
              nodeOuter.current.id = "drag";
              nodeOuter?.current.classList.remove("nowheel");
            }}
          >
            <ReactSelect
              closeMenuOnSelect={false}
              value={data.selectedValue?.localData?.days || []}
              placeholder="Select Day"
              isMulti
              onChange={(e) => helper.setSchData(e, "select")}
              options={daysArray}
              styles={selectStyle}
            />
          </Flex>

          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.time")}
          </Flex>
          <Input
            sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
            placeholder="Select Time"
            size="md"
            type="time"
            value={data.selectedValue?.localData?.time || ""}
            onChange={(e) => helper.setSchData(e, "input")}
          />
        </EventWrapper>
      ) : data.selectedEvent?.name === "Days of the month" ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.dates")}
          </Flex>
          <Flex
            onMouseEnter={() => {
              nodeOuter.current.id = "";
              nodeOuter?.current.classList.add("nowheel");
            }}
            onMouseLeave={() => {
              nodeOuter.current.id = "drag";
              nodeOuter?.current.classList.remove("nowheel");
            }}
          >
            <ReactSelect
              closeMenuOnSelect={false}
              value={data.selectedValue?.localData?.dates || null}
              placeholder="Select Date"
              isMulti
              onChange={(e) => helper.setSchData(e, "select")}
              options={datesArr}
              styles={selectStyle}
            />
          </Flex>

          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.time")}
          </Flex>
          <Input
            sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
            placeholder="Select Time"
            size="md"
            type="time"
            value={data.selectedValue?.localData?.time || ""}
            onChange={(e) => helper.setSchData(e, "input")}
          />
        </EventWrapper>
      ) : data.selectedEvent?.name === "At regular intervals" ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>
            {t("konnect.sidebar.scheduler.timespan")}
          </Flex>
          <Flex
            onMouseEnter={() => {
              nodeOuter.current.id = "";
              nodeOuter?.current.classList.add("nowheel");
            }}
            onMouseLeave={() => {
              nodeOuter.current.id = "drag";
              nodeOuter?.current.classList.remove("nowheel");
            }}
          >
            <ReactSelect
              value={data.selectedValue?.localData?.timespan || null}
              placeholder="Choose Time Span"
              onChange={(e) => helper.setSchData(e, "select", "timespan")}
              options={intervalArr}
              styles={selectStyle}
            />
          </Flex>
          {data?.selectedValue?.time_span === "Minutes" ? (
            <>
              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.every")}
              </Flex>
              <Input
                sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
                placeholder="Any value between 1 to 59"
                size="md"
                type="number"
                value={data.selectedValue?.localData?.every || ""}
                onChange={(e) => helper.setSchData(e, "input", "minute")}
              />
            </>
          ) : data?.selectedValue?.time_span === "Hours" ? (
            <>
              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.every")}
              </Flex>
              <Input
                sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
                placeholder="Any value between 1 to 24"
                size="md"
                type="number"
                value={data.selectedValue?.localData?.every || ""}
                onChange={(e) => helper.setSchData(e, "input", "hour")}
              />
            </>
          ) : data?.selectedValue?.time_span === "Days" ? (
            <>
              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.every")}
              </Flex>

              <Input
                sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
                placeholder="Between number of days"
                size="md"
                type="number"
                value={data.selectedValue?.localData?.every || ""}
                onChange={(e) => helper.setSchData(e, "input", "days")}
              />
              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.time")}
              </Flex>
              <Input
                sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
                placeholder="Select Time"
                size="md"
                type="time"
                value={data.selectedValue?.localData?.time || ""}
                onChange={(e) => helper.setSchData(e, "input", "time")}
              />
            </>
          ) : data?.selectedValue?.time_span === "Weeks" ? (
            <>
              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.every")}
              </Flex>
              <Flex
                onMouseEnter={() => {
                  nodeOuter.current.id = "";
                  nodeOuter?.current.classList.add("nowheel");
                }}
                onMouseLeave={() => {
                  nodeOuter.current.id = "drag";
                  nodeOuter?.current.classList.remove("nowheel");
                }}
              >
                <ReactSelect
                  closeMenuOnSelect={false}
                  value={data.selectedValue?.localData?.days || []}
                  placeholder="Select Day"
                  isMulti
                  onBlur={() => (nodeOuter.current.id = "drag")}
                  onChange={(e) => helper.setSchData(e, "select", "week")}
                  options={daysArray}
                  styles={selectStyle}
                />
              </Flex>

              <Flex sx={{ fontWeight: 500 }}>
                {t("konnect.sidebar.scheduler.time")}
              </Flex>
              <Input
                sx={{ bgColor: "#f3f7ff", border: "1px solid #b7c9ff" }}
                placeholder="Select Time"
                size="md"
                type="time"
                value={data.selectedValue?.localData?.time || ""}
                onChange={(e) => helper.setSchData(e, "input", "time")}
              />
            </>
          ) : null}
        </EventWrapper>
      ) : null}
      <EventWrapper visibility={true}>
        <CaptureButton onClick={helper.onCaptureResponse}>
          {t("konnect.sidebar.scheduler.test_review")}
        </CaptureButton>
      </EventWrapper>

      <Divider visibility={data?.additionalResponses} />
      <EventWrapper visibility={data?.additionalResponses} last="true">
        <ResultContainer response={data.configResponses} additional="true" />
      </EventWrapper>
      {data?.additionalResponses > 3 && !modalState.display ? (
        <AdditionalResponse
          visibility={data?.additionalResponses > 3 ? true : false}
          display={modalState.display}
          onClick={helper.additionalResponse}
        />
      ) : null}

      {modalState.display && (
        <ModalContainer
          nodeOuter={nodeOuter}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
          setExpand={setExpand}
          setExpandHeight={setExpandHeight}
        />
      )}
    </NodeOuter>
  );
});
