import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLinkedAccount,
  getOauthDataPost,
  getaddonapps,
  getapps,
  saveAuthLabelPost,
  saveOauthDataPost,
  saveTwitterCredPost,
  updateAppStatusPost,
} from "../../api-client";

export const getAddonApps = createAsyncThunk(
  "getaddonapps",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getaddonapps(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOauthData = createAsyncThunk(
  "getOauthData",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getOauthDataPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveTwitterCred = createAsyncThunk(
  "saveTwitterCred",
  async (body, { rejectWithValue }) => {
    try {
      const response = await saveTwitterCredPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveOauthData = createAsyncThunk(
  "saveOauthData",
  async (body, { rejectWithValue }) => {
    try {
      const response = await saveOauthDataPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchApp = createAsyncThunk(
  "searchApp",
  async (searchQuery, thunkAPI) => {
    try {
      const response = await getapps(searchQuery);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getApps = createAsyncThunk(
  "getapps",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getapps(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getLinkedAccountsinModal = createAsyncThunk(
  "getLinkedAccountsinModal",
  async (appId, { rejectWithValue }) => {
    try {
      const response = await getLinkedAccount(appId.id);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editAuthLabelPost = createAsyncThunk(
  "editAuthLabelPost",
  async (body, { rejectWithValue }) => {
    try {
      const response = await saveAuthLabelPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const editAuthLabelPostIApps = createAsyncThunk(
  "editAuthLabelPostIApps",
  async (body, { rejectWithValue }) => {
    try {
      const response = await saveAuthLabelPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateAppAccountStatus = createAsyncThunk(
  "updateAppAccountStatus",
  async (body, { rejectWithValue }) => {
    try {
      const response = await updateAppStatusPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateAppAccountStatusInApps = createAsyncThunk(
  "updateAppAccountStatusInApps",
  async (body, { rejectWithValue }) => {
    try {
      const response = await updateAppStatusPost(body);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
