import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { captureWebResponse } from "../../api-client";
import { apiClient } from "../../api-client/axios";

export const captureWebHookResponse = createAsyncThunk(
  "captureWebHookResponse",
  async (_, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const webhookNode = canvas.editorState.nodes[0];
    if (webhookNode.data.refreshed) {
      return { message: "Refreshed" };
    } else {
      try {
        const response = await captureWebResponse(webhookNode);
        return response?.data;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  }
);
