import React, { useCallback, useState } from "react";
import { Flex, Input, Textarea, Box } from "@chakra-ui/react";
import { Handle } from "reactflow";
import { useDispatch } from "react-redux";
import { onInputTextType ,onLinkDelete} from "../../../store/slice/canvasSlice";

export const ResponseContainer = React.memo(
  ({ result = [], nodeId, parentnode }) => {
    const configResult = result.filter(
      (c) =>
        c.checked === true && !c.additional && c.config_key_type !== "custom"
    );
    const dispatch = useDispatch();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "7px",
        }}
      >
        {configResult.map((data, i) => {
          let Link1;
          let infoToShow;
          if (data.label?.includes("https")) {
            Link1 = data.label?.slice(
              data.label.indexOf("<a"),
              data.label.indexOf("/a>") + 3
            );
            infoToShow = data.label
              .slice(data.label?.indexOf("[") + 1, data.label?.indexOf("]"))
              .split(",");
          }
          return (
            <Flex key={i} sx={{ flexDirection: "column", gap: "7px" }}>
              <Flex sx={{ color: "#8c8c8c", fontSize: 12 }}>
                {data.label?.includes("https")
                  ? data.label?.slice(0, data.label?.indexOf("["))
                  : data.label}
                {data.config_key_required ? (
                  <span style={{ color: "#F74545" }}>*</span>
                ) : (
                  ""
                )}
                {infoToShow ? (
                  <span
                    style={{
                      color: "#666699",
                      fontSize: "11px",
                      paddingBottom: "5px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    {infoToShow[0]}{" "}
                  </span>
                ) : null}{" "}
                {<a dangerouslySetInnerHTML={{ __html: Link1 }}></a> ? (
                  <span style={{ color: "#152d73", fontSize: "11px" }}>
                    {<a dangerouslySetInnerHTML={{ __html: Link1 }}></a>}
                  </span>
                ) : null}
              </Flex>
              <Flex>
                <Flex
                  width="6%"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Handle
                    style={{
                      height: "10px",
                      background: "#152f73",
                      width: "10px",
                      borderRadius: "50%",
                      outline: "2px solid #152f73",
                    }}
                    type="target"
                    position="left"
                    isConnectable="true"
                    id={data.port.target}
                  />
                </Flex>
                {data?.value?.length > 27 ? (
                  <Textarea
                    bgColor="#f3f7ff"
                    name="config_value"
                    onFocus={() => (parentnode.current.id = "")}
                    onBlur={() => (parentnode.current.id = "drag")}
                    placeholder={data.label}
                    isDisabled={data.linked ? true : data.target_linked ? true : false}
                    minH="65px"
                    width="88%"
                    onChange={(e) => {
                      dispatch(
                        onLinkDelete({
                          nodeId,
                          additionalOnly: true,
                          selectedConfig: data,
                        })
                      );
                      dispatch(
                        onInputTextType({
                          nodeId: nodeId,
                          input: e.target.value,
                          portInfo: data,
                        })
                      );
                    }}
                    sx={{
                      px: "10px",
                      border: data.dirty
                        ? "1px solid red"
                        : "1px solid #b7c9ff",
                      "&:disabled": {
                        opacity: 1,
                      },
                      "&:hover": {
                        border: "1px solid #b7c9ff",
                      },
                    }}
                    value={data.value || ""}
                  />
                ) : (
                  <Input
                    name="config_value"
                    bgColor="#f3f7ff"
                    width="88%"
                    onFocus={() => (parentnode.current.id = "")}
                    onBlur={() => (parentnode.current.id = "drag")}
                    isDisabled={data.target_linked ? true : false}
                    type={data.id == "coupon_length" ? "number" : "text"}
                    placeholder={
                      data.label?.includes("https")
                        ? data.label.slice(0, data.label.indexOf("["))
                        : data.label
                    }
                    onChange={(e) => {
                      dispatch(
                        onLinkDelete({
                          nodeId,
                          additionalOnly: true,
                          selectedConfig: data,
                        })
                      );
                      dispatch(
                        onInputTextType({
                          nodeId: nodeId,
                          input: e.target.value,
                          portInfo: data,
                        })
                      );
                    }}
                    sx={{
                      border: data.dirty
                        ? "1px solid red"
                        : "1px solid #b7c9ff",
                      "&:disabled": {
                        opacity: 1,
                      },
                      "&:hover": {
                        border: "1px solid #b7c9ff",
                      },
                    }}
                    value={data.value || ""}
                  />
                )}
                <Flex
                  width="6%"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Handle
                    style={{
                      height: "10px",
                      background: "#152f73",
                      width: "10px",
                      borderRadius: "50%",
                      outline: "2px solid #152f73",
                    }}
                    id={data.port.source}
                    type="source"
                    position="right"
                    isConnectable="true"
                  />
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    );
  }
);

export const CondtionsContainer = ({
  data,
  appSide = "left",
  parentNode,
  onChange,
  visibility,
  appType,
  conditions,
}) => {
  function findSubstringWithTenAlphabets(text) {
    const pattern = /[A-Za-z]+/g; // Regular expression to match consecutive alphabetic characters
    const matches = text.match(pattern); // Find all matches of consecutive alphabetic characters

    if (text) {
      for (let i = 0; i < text.length; i++) {
        if (text[i].length > 35) {
          return text[i]; // Found a substring with more than 10 alphabets, return it
        }
      }
    }

    return null; // No substring with more than 10 alphabets found
  }
  return (
    <Box
      sx={{
        display: visibility ? "flex" : "none",
      }}
    >
      {data.configResponses
        .filter((res) => res.config_key === "conditions_setup")
        .map((x) => {
          return (
            <>
              <Flex sx={{ position: "relative", mr: "4%" }}>
                <Handle
                  style={{
                    height: "8px",
                    background: "#152f73",
                    "&.connecting": {
                      background: "#ff0072",
                    },
                    width: "8px",
                    borderRadius: "50%",
                    outline: "2px solid #152f73",
                  }}
                  type="target"
                  position="left"
                  isConnectable="true"
                  id={x.port.target}
                />{" "}
              </Flex>
              <Flex
                sx={{
                  width: "95%",
                  gap: "7px",
                  minHeight: "70px",
                  width: "100%",
                  flexDirection: "column",
                  backgroundColor: "#f3f7ff",
                  borderRadius: "5px",
                }}
              >
                {conditions?.map((condition, i) => {
                  let queryOperatorText =
                    condition.queryOperator === undefined
                      ? ""
                      : condition.queryOperator.toUpperCase();
                  // Handling empty entries in the condition
                  if (condition.field === "" || condition.operator === "") {
                    return "";
                  }
                  // Remove the query operator AND or OR if this is the last item
                  if (i === conditions.length - 1) {
                    queryOperatorText = "";
                  }
                  let res = condition.field.label;
                  let res1 = condition.value.name
                    ? condition.value.name
                    : condition.value;
                  const string =
                    (condition && res?.length > 35) ||
                    findSubstringWithTenAlphabets(res);
                  const string1 =
                    (condition && res1?.length > 35) ||
                    findSubstringWithTenAlphabets(res1);
                  return (
                    <Flex sx={{ flexDirection: "column", paddingLeft: "5px" }}>
                      <span
                        // data-tip={string?condition.field.label:null}
                        style={{
                          color: "#152F73",
                          fontSize: "18px",

                          // overflow: string && string ? "hidden" : null,
                          // textOverflow: string ? "ellipsis" : "none",
                          // whiteSpace: string ? "nowrap" : "none",
                        }}
                      >
                        {condition?.field?.label}
                      </span>
                      <span style={{ color: "#909395", fontSize: "18px" }}>
                        {condition?.operator}
                      </span>

                      <span
                        // data-tip={string1?condition.value.name ? condition.value.name : condition.value:null}
                        style={{
                          color: "#152F73",
                          fontSize: "18px",
                          // overflow: string1 ? "hidden" : null,
                          // textOverflow: string1 ? "ellipsis" : "none",
                          // whiteSpace: string1 ? "nowrap" : "none",
                        }}
                      >
                        {" " +
                          ` ${
                            condition?.value.name
                              ? condition?.value.name
                              : condition?.value
                          }` +
                          " " +
                          queryOperatorText}{" "}
                      </span>

                    
                    </Flex>
                  );
                })}
              </Flex>
            </>
          );
        })}
    </Box>
  );
};
