import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  userExist,
  userForgotPassword,
  userResetPassword,
  userSignup,
  userUpdate,
  userVerify,
} from "./thunk/authThunk";

const initialState = {
  user: null,
  forgotApiError: false,
  loading: false,
  verifyLoading: true,
  twitter_cred: {
    client_key: "UzlINVRsdTZMZHRRMHhlNEstbDU6MTpjaQ",
    client_secret: "9xjqcBOOY5bf3ry4-mT8ZH8j0ueX4aRMEvHIjWpmplaLGMVJW3"
  },
  /// agar cred h to apne ko phle jsa rkhna h(omni), nahi to step 1 token + step2 omniauth
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    store_twitter_cred: (state, action) => {
      state.twitter_cred = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload ? action.payload.response : {};
        state.loading = false;
      })
      .addCase(login.pending, (state, action) => {
        state.user = null;
        state.loading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.loading = false;
      })
      .addCase(userSignup.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(userSignup.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userSignup.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(userExist.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(userExist.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userExist.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(userVerify.fulfilled, (state, action) => {
        state.verifyLoading = false;
      })
      .addCase(userVerify.rejected, (state, action) => {
        state.verifyLoading = false;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.user = null;
      })
      .addCase(userForgotPassword.fulfilled, (state, action) => {
        state.forgotApiError = false;
        state.loading = false;
      })
      .addCase(userForgotPassword.pending, (state, action) => {
        state.forgotApiError = false;
        state.loading = true;
      })
      .addCase(userForgotPassword.rejected, (state, action) => {
        state.forgotApiError = true;
        state.loading = false;
      })
      .addCase(userResetPassword.fulfilled, (state, action) => {})
      .addCase(userResetPassword.rejected, (state, action) => {});
  },
});

export const { store_twitter_cred } = authSlice.actions;

export default authSlice.reducer;
