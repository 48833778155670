import React, { useRef, useState } from 'react';
import {
  Flex,
  Box,
  RadioGroup,
  Stack,
  Radio,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { svg } from '../../../assets/objectSVG';
import { Selector } from '../../node/ui/Selector';
import { deleteCondition, updateCondition } from "../../../store/slice/canvasSlice"
import { useDispatch } from 'react-redux';
export const ConditionItem = ({
  parentNode,
  showQueryOperators,
  itemIndex,
  condition,
  fromFields,
  operatorList, conditionsList, nodeId, data
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          gap: '10px',
          m: '',
          p: '2% 4%',
          flexDirection: 'column',
          border: '1px solid white',
          borderRadius: '3px',
          boxShadow: '1px 1px 3px 1px lightgray',
        }}
      >
        {t("konnect.conditions.conditionText")} {itemIndex + 1}
        <Flex sx={{ gap: '10px' }}>
          <Selector
            parentnode={parentNode}
            options={fromFields}
            selectType="conditionItem"
            name="first"
            nodeId={nodeId}
            data={data}
            condition={condition}
            value={condition.field}

          />
          <Selector
            parentnode={parentNode}
            options={operatorList}
            _condition={condition}
            name="second"
            selectType="conditionItem"
            nodeId={nodeId}
            data={data}
            condition={condition}
            value={condition.second}
          />
          <Selector
            parentnode={parentNode}
            options={fromFields}
            _condition={condition}
            isConditionDrop="true"
            selectType="conditionItem"
            name="third"
            nodeId={nodeId}
            condition={condition}
            value={condition.thirdValue}
            data={data}
          />
          <Tooltip label="Delete" hasArrow>
            <Box
              sx={{
                display: 'flex',
                marginTop: 'inherit',
                color: '#666666',
                cursor: 'pointer',
                alignItems: 'center',
              }}
              onClick={() => dispatch(deleteCondition({ key: condition.key, nodeId: nodeId }))}
            >
              {svg.delete}
            </Box>
          </Tooltip>
        </Flex>
      </Flex>
      {showQueryOperators && (
        <>
          <Flex sx={{ m: '10px 6px' }}>
            <RadioGroup
            >
              <Stack direction="row">
                <Radio
                  name={condition.key + 'operator'}
                  id="and"
                  value="and"
                  checked={'and' === condition.queryOperator}
                  onChange={(event) => {
                    const conditionObj = {
                      ...condition,
                      queryOperator: event.currentTarget.value,
                    };
                    dispatch(updateCondition({ conditionObj, nodeId, key: condition.key }));
                  }}
                >
                  {t("konnect.conditions.operatorAndLabel")}
                </Radio>
                <Radio
                  name={condition.key + 'operator'}
                  id="or"
                  value="or"
                  checked={condition.queryOperator === 'or'}
                  onChange={(event) => {
                    const conditionObj = {
                      ...condition,
                      queryOperator: event.currentTarget.value,
                    };
                    dispatch(updateCondition({ conditionObj, nodeId, key: condition.key }));

                  }}
                >
                  {t("konnect.conditions.operatorOrLabel")}
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </>
      )}
    </>
  );
};
