import React from "react";
import { Flex, Input, Textarea } from "@chakra-ui/react";
import { Handle } from "reactflow";

export const ResultContainer = React.memo(({ response = [], additional }) => {

  let filteredResponse = [];
  if (additional) {
    filteredResponse = response.filter(
      (c) => c.checked === true && c.additional
    );
  } else filteredResponse = response?.filter((c) => c.checked === true);


  return filteredResponse.filter((r) => !!r.value).map((data, i) => {
    function strToArray(text) {
      if (text.includes(".")) {
        let str = text
        let strArr = str.split("")
        strArr.map(letter => {
          if (letter === ".") {
            let index = strArr.indexOf(".")
            strArr.splice(index, 2, " >", "> ")
            let final = strArr.join("")
            strArr = final.split("")
          }
        })

        return strArr.join("")
      } else {
        return text
      }
    }
    let connector = data.parent && strToArray(data.parent)
    let label = data.label && strToArray(data.label)
    return (
      <Flex key={i} sx={{ flexDirection: "column", gap: "7px" }}>
        <Flex sx={{ color: "#8c8c8c", fontSize: 12,display:"inline" }}>{data.parent ? `${connector} >> ${label}` : data.label}</Flex>
        <Flex>
          {data?.value?.length > 27 ? (
            <Textarea
              bgColor="#e5eefd"
              disabled
              minH="65px"
              width="94%"
              sx={{
                px: "10px",
                border: "1px solid #b7c9ff",
                "&:disabled": {
                  opacity: 1,
                },
                "&:hover": {
                  border: "1px solid #b7c9ff",
                },
              }}
              value={data.value ?data.value:null }
            />
          ) : (
            <Input
            name="config_value"
              bgColor="#e5eefd"
              disabled
              width="94%"
              sx={{
                border: "1px solid #b7c9ff",
                "&:disabled": {
                  opacity: 1,
                },
                "&:hover": {
                  border: "1px solid #b7c9ff",
                },
              }}
              value={data.value}
            />
          )}
          <Flex
            width="6%"
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Handle
              style={{
                height: "10px",
                background: "#152f73",
                width: "10px",
                borderRadius: "50%",
                outline: "2px solid #152f73",
              }}
              id={data.port.source}
              type="source"
              position="right"
              isConnectable="true"
            />
          </Flex>
        </Flex>
      </Flex>
    );
  });
});
