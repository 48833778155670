import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const arabic = {
  translation: {
    common: {
      app: 'كونيكتز',
    },
    header: {
      topNavigations: {
        tipsAndTricks: 'نصائح وحيل',
        pricing: 'التسعير',
        blogs: 'المدونات',
        helpCenter: 'مركز المساعدة',
      },
      searchAppTextPlaceholder: 'البحث هنا...',
      createKonnectzBtnLabel: 'إنشاء كونيكتز',
      taskhistory: 'تاريخ المهمة',
      success: 'نجاح',
      error: 'خطأ',
      notask: 'لم يتم العثور على مهمة',
      tryagain: 'ضبط الفلتر الخاص بك وحاول مرة أخرى',
    },

    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        'تم إرسال تعليمات إعادة تعيين كلمة المرور عبر البريد الإلكتروني، يرجى التحقق من بريدك الإلكتروني.',
      SENT_RESET_INSTRUCTIONS_FAIL: 'طلب غير مصرح به!',
      VERIFY_EMAIL_FAIL: 'عذرًا، غير قادر على تأكيد بريدك الإلكتروني.',
      PASSWORD_NOT_UPDATED: 'عذرًا، فشل في تحديث حسابك.',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL:
        'فشل في إرسال تعليمات كلمة المرور. يرجى المحاولة بعد بعض الوقت!',
      AUTH_SIGN_UP_FAIL: 'حدث خطأ ما. يرجى المحاولة بعد بعض الوقت!',
      somethingWentWrong: 'حدث خطأ ما. يرجى المحاولة بعد بعض الوقت!',
    },

    successMessages: {
      PASSWORD_UPDATED:
        'تم تحديث كلمة المرور. يرجى تسجيل الدخول باستخدام كلمة المرور الجديدة!',
    },

    history: {
      task: {
        task: 'مهمة',
        tasks: 'مهام',
        taskdate: 'تاريخ المهمة: ',
        user: 'المستخدم: ',
        search_history: 'البحث في التاريخ',
      },
    },
    // Other history configurations...

    auth: {
      signup: {
        createPassword: {
          title: 'إنشاء كلمة مرور',
          subtitle: 'اكتبهم مرتين للتأكيد',
          passwordErrorMsg: 'كلمة مرور غير صالحة!',
          confirmPasswordErrorMsg: 'كلمة المرور غير صالحة!',
          passwordMatchingErrorMsg: 'كلمتا المرور غير متطابقتين!',
          continueBtnLabel: 'استمرار',
          createPasswordPlaceholder: 'إنشاء كلمة مرور',
          confirmPasswordPlaceholder: 'تأكيد كلمة المرور',
          passwordRule:
            'تلميح: يجب أن تكون كلمة المرور بين 8 و 15 حرفًا ويجب أن تحتوي على حرف كبير واحد وحرف صغير واحد ورقم واحد وحرف خاص واحد على الأقل.',
          passwordRule1: 'يجب أن تكون كلمة المرور بين 8 و 15 حرفًا.',
          passwordRule2:
            'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل.',
          passwordRule3: 'يجب أن تحتوي كلمة المرور على رقم واحد على الأقل.',
          passwordRule4: 'يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل.',
        },
        verificationSent: {
          title: 'التحقق',
          subtitle: 'تحقق من عنوان البريد الإلكتروني',
          verificationText1: 'لقد أرسلنا رابط التحقق على ',
          verificationText2:
            '، قم بفتح البريد الإلكتروني وتحقق من عنوان بريدك الإلكتروني.',
          didntReceiveEmailText: 'لم تتلقى أي بريد؟',
          resendText: 'إعادة الإرسال',
        },
        verifySuccess: {
          title: 'اكتمال التحقق',
          verifyFailedTitle: 'فشل التحقق',
        },
        signupSuccess: {
          firstName: 'الاسم الأول',
          firstNameErrorMsg: 'الاسم الأول مطلوب!',
          lastName: 'اسم العائلة',
          lastNameErrorMsg: 'اسم العائلة مطلوب!',
          verifyErrorMsg:
            'حدث خطأ ما. يرجى محاولة تسجيل الدخول إذا تم التحقق من حسابك بالفعل.',
        },
        title: 'أنشئ حسابك',
        subtitle: 'مرحبًا، مرحبًا',
        verifyingMessage: 'جارٍ التحقق منك',
        emailInvalid: 'البريد الإلكتروني غير صالح!',
        emailExists: 'عنوان البريد الإلكتروني الذي أدخلته مسجل بالفعل',
        teamSignup: 'التسجيل كفريق؟',
        continueBtnLabel: 'استمرار',
        alreadyHaveAccountText: 'هل لديك حساب؟',
        loginBtnLabel: 'تسجيل الدخول',
        emailPlaceholder: 'أدخل البريد الإلكتروني',
        features: {
          feature1: {
            title: 'أتمتة بسيطة',
            image: SimpleAutomation,
            description:
              'قم ببناء سير عمل معقد بسهولة في دقائق باستخدام منشئ الاتصال البصري لدينا',
          },
          feature2: {
            title: 'منصة للجميع',
            image: PlatformForEveryone,
            description:
              'ليست أداة أتمتة عادية فقط للمبرمجين، يمكن لأي شخص بناء أتمتة باستخدام KonnectzIT',
          },
          feature3: {
            title: 'تكامل التطبيقات السلس',
            image: SeamlessAppsIntegration,
            description:
              'قم بربط تطبيقات غير محدودة من مكتبتنا وقم بتوظيف المهام اليومية تلقائيًا',
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          'حدث خطأ ما. يرجى محاولة تسجيل الدخول إذا تم التحقق من حسابك بالفعل.',
      },

      login: {
        title: 'تسجيل الدخول في حسابك',
        subtitle: 'مرحبًا بك مرة أخرى!',
        emailPlaceholder: 'أدخل البريد الإلكتروني',
        emailInvalid: 'بريد إلكتروني خاطئ !',
        passwordRequired: 'كلمة المرور مطلوبة !',
        passwordPlaceholder: 'أدخل كلمة المرور',
        rememberMe: 'تذكرنى',
        loginBtnLabel: 'تسجيل الدخول الآن',
        forFirstTimeSignup: 'للمرة الأولى ؟ ',
        forgotPasswordLbl: 'هل نسيت كلمة المرور',
        alreadyHaveAccountText: 'ليس لديك حساب؟ ',
        clickingContinueText: 'بالنقر فوق متابعة ، فإنك توافق على ',
        termsAndConditionsText: 'البنود و الظروف.',
        signupNowText: 'سجل',
        features: {
          feature1: {
            title: 'خطوات غير محدودة',
            image: UnlimitedSteps,
            description:
              'قم بتوصيل عدة تطبيقات وإنشاء خطوات غير محدودة لأتمتة سلسة.',
          },
          feature2: {
            title: 'تصور كل شيء',
            image: VisualizeEverything,
            description:
              'قم ببناء عمليات سير العمل الخاصة بك وتخيل الأتمتة باستخدام أداة إنشاء الاتصال المرئي بسهولة.',
          },
          feature3: {
            title: 'مكتبة التكامل',
            image: IntegrationLibrary,
            description:
              'مجموعة من التطبيقات السحابية لدمج وإرسال البيانات بينها لتوفير وقتك.',
          },
        },
        loginFailMsg: 'اسم المستخدم او كلمة المرور غير صحيحة!',
        captchaErrorMsg: 'خطأ في كلمة التحقق',
      },
      forgot: {
        title: 'نسيت كلمة المرور',
        forgtoBtnLabel: 'احصل على رابط نسيت كلمة المرور',
        passwordLink: 'لقد أرسلنا رابط نسيت كلمة المرور على',
        successSubTitle: 'تم إرسال البريد الإلكتروني',
        features: {
          feature1: {
            title: 'أتمتة بسيطة',
            image: SimpleAutomation,
            description:
              'قم ببناء سير العمل المعقدة بسهولة في دقائق باستخدام منشئ الاتصال البصري لدينا',
          },
          feature2: {
            title: 'منصة للجميع',
            image: PlatformForEveryone,
            description:
              'ليست أداة أتمتة عادية فقط للمبرمجين، يمكن لأي شخص بناء أتمتة باستخدام KonnectzIT',
          },
          feature3: {
            title: 'تكامل التطبيقات السلس',
            image: SeamlessAppsIntegration,
            description:
              'قم بربط تطبيقات غير محدودة من مكتبتنا وأتمتة المهام اليومية',
          },
        },
        emailInvalid: 'البريد الإلكتروني غير صالح!',
      },
      reset: {
        title: 'إعادة تعيين كلمة المرور',
        subTitle: 'إنشاء كلمة مرور جديدة',
      },
    },
    konnect: {
      sidebar: {
        search_apps: 'البحث في التطبيقات',
        search_apps_endList: 'ياي! لقد رأيت كل شيء',
        add_on_apps: 'التطبيقات المضافة',
        integrated_apps: 'التطبيقات المتكاملة',
        choose_trigger: 'اختر المؤثر',
        choose_action: 'اختر الإجراء',
        textFormater: {
          formaterText: "أدخل '@' واختر القيم",
        },
        scheduler: {
          userTimeInput: 'أدخل القيمة بالدقائق أو الساعات أو الأيام',
          selectDate: 'اختر تاريخًا',
          select: 'اختر',
          test_review: 'اختبار ومراجعة',
          time: 'الوقت',
          dates: 'التواريخ',
          date: 'التاريخ',
          days: 'الأيام',
          everyday_time: 'الوقت اليومي',
        },
        responseModal: {
          searchByLable: 'البحث حسب التصنيف',
          no_labelFound: 'لم يتم العثور على تصنيف',
          nodeInner_title: 'اختر المزيد من القيم',
          labelText: 'التصنيف:',
        },
        coupon_generator: {
          numberPlaceholder: 'العدد',
        },
        choose_trigger_header: 'اختر حدث التطبيق',
        no_triggers_available: 'لا توجد مؤثرات متاحة',
        choose_format_header: 'خيار التنسيق',
        choose_config_header: 'اختر التكوين',
        select_trigger_action_dropdown_header: 'اختر',
        choose_option_header: 'تعريف الخيار',
        no_options_available: 'لا توجد خيارات متاحة',
        choose_linked_account: 'اختر الحساب',
        customize_form_header: 'ضبط النموذج',
        customize_form_partial: 'اختيار',
        configure_response: 'تخصيص الاستجابة',
        basic_auth: 'المصادقة الأساسية',
        api_key: 'مفتاح الواجهة',
        api_token: 'رمز API',
        webhook_url: 'عنوان URL للويب هوك',
        captureWebhookResponse: 'التقاط استجابة الويب هوك',
        hereText: 'هنا',
        webhook_instructions: 'تعليمات ويب هوك - انقر',
        webhook_url_toast: 'تم نسخ عنوان URL للويب هوك إلى الحافظة!',
        webhook_response: 'استجابة الويب هوك',
        choose_action_event: 'اختر الإجراء',
        endpoint_url: 'عنوان URL النهائي',
        payload_type: 'نوع الحمولة',
        wrap_request_array: 'لف الطلب في مصفوفة',
        set_params: 'تعيين المعلمات',
        add_header: 'إضافة رأس',
        delayLabel: 'تأخير',
        delayText: 'كم تريد التأخير',
        set_paramter: 'تعيين المعلمة',
        expiry_time: 'الجدول الزمني للانقضاء',
        expiry_val: 'الجدول الزمني',
        segmentIndex: 'فهرس القطعة',
        validate: 'التحقق',
        generate: 'إنشاء',
      },
      tobar: {
        cancelBtnLabel: 'إلغاء',
        inputLeftElm_placeholder:
          'البحث في أكثر من 1000 تطبيق متكامل أو تطبيق مضاف',
        redoBtnLabel: 'إعادة',
        undoBtnLabel: 'تراجع',
      },
      UI: {
        betaText: 'بيتا',
        webhookDuplicateToastMsg: 'تطبيق الويب هوك موجود بالفعل!',
      },
      header: {
        backBtnLabel: 'رجوع',
        saveBtnLabel: 'حفظ',
        publishBtnLabel: 'نشر',
        nameOfKonnectz: 'اسم',
      },
      testAndReview: {
        btnLabel: 'اختبار ومراجعة',
        taskAvailableLabel: 'المهمة متاحة',
        taskConsumbedLabel: 'المهمة تم استهلاكها',
        taskCnsumptionLabel: 'استهلاك المهمة',
      },
      proceed: {
        btnLabel: 'استمرار',
      },
      additionalConfigsModal: {
        cancelBtnLabel: 'إلغاء',
        okBtnLabel: 'موافق',
      },
      conditions: {
        conditionText: 'الشرط',
        addNewConditionBtnLabel: 'إضافة شرط جديد +',
        cancelBtnLabel: 'إلغاء',
        okBtnLabel: 'موافق',
        addBtnLabel: 'إضافة',
        operatorAndLabel: 'و',
        operatorOrLabel: 'أو',
        headerConditionText: 'الشرط',
        selectFieldHeaderTitle: 'اختر الحقل',
        selectConditionHeaderTitle: 'اختر الشرط',
        validateBtnLabel: 'التحقق',
        conditionsHeaderText: 'الشروط',
      },
      publishSuccessMsg: 'تم نشر Konnect بنجاح!',
      saveSuccessMsg: 'تم حفظ Konnect بنجاح!',
    },

    apps: {
      authenticatedAppsTitle: 'التطبيقات المصادق عليها',
      aunthenticateNowTitle: 'المصادقة الآن',
      noAppsToAunthenticate: 'لا توجد تطبيقات للمصادقة في الوقت الحالي.',
      authenticateNow: 'ليس لديك أي تطبيقات مصادق عليها. المصادقة الآن.',
      addAccountText: 'إضافة حساب',
      betaText: 'بيتا',
      integratedApps: 'التطبيقات المتكاملة',
      followInstrusctionText: 'يرجى اتباع التعليمات لمزيد من المرجعية',
      oAuthHandlerText: 'معالج KonnectzIT OAuth، يُرجى الانتظار...',
      cancelBtnText: 'إلغاء',
      newsLable: 'أخبار',

      yesContinueText: 'نعم، استمر',
      requiredText: '(مطلوب)',
      accessAPIModalHeaderText1: 'السماح لـ KonnectzIT بالوصول إلى حساب',
      accessAPIModalHeaderText2: '؟',
      accessAPIModalMainText1: ' ',

      accessAPIModalMainText2: ' متاح في صفحة التكامل',
      accessAPIModalMainText3: ' ',
      authorized: 'التطبيقات المُصرَّح بها',
    },
    konnectzList: {
      homeText: 'الصفحة الرئيسية',

      allText: 'الكل',
      searchText: 'بحث',
      createkonnectzLabel: 'إنشاء',

      noKonnectzInfo: 'لا توجد konnectz في الوقت الحالي!',
      deleteModalBodyText: 'هل أنت متأكد أنك تريد الحذف؟',
      deleteModalNoText: 'لا',
      deleteModalDeleteText: 'حذف',
      folders: {
        createFolderTitleText: 'اسم المجلد',
        enterFolderName: 'أدخل اسم المجلد',
        fodersLeftTitleTextL: 'المجلدات',
        createFolderInputPlaceholder: 'أدخل اسم المجلد',
        createFolderBtn: 'إنشاء',
        nameTitle: 'الاسم',
        noKonnectz: 'يبدو أن لديك لا',
        deleteFolderConfirmationText: 'هل أنت متأكد أنك تريد حذف هذا المجلد؟',
        cancelBtn: 'إلغاء',
        newFolder: 'مجلد جديد',
        deleteBtn: 'حذف',
        selectFolderInfo: 'حدد المجلد الذي تريد نقل ملفك إليه',
        moveFolderCancelBtn: 'إلغاء',
        moveBtn: 'نقل المجلد',
        renameSaveBtn: 'إعادة تسمية المجلد',
        renameFolder: 'إعادة تسمية',
        renameYourFolderText: 'إعادة تسمية مجلدك',
        createFolderBtn: 'إنشاء مجلد',
      },
    },

    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: 'أحدث التطبيقات',
        },
        totalKonnectz: {
          headerTitle: 'مجموع Konnectz',
          activeKonnectz: 'Konnectz نشطة',
          inactiveKonnectz: 'Konnectz غير نشطة',
        },
        latestKonnectz: {
          headerTitle: 'أحدث Konnectz',
          vieawAll: 'عرض الكل',
        },
        subscription: {
          subscriptionTitle: 'الاشتراك',
          contentText: 'قم بفتح المزيد من القوة عن طريق الترقية إلى Pro',
          applyNewCoupon: 'تطبيق قسيمة جديدة',
          applyBtnLabel: 'تطبيق',
          featuresIncludedLabel: 'الميزات المشمولة',
          upgradeBtnLabel: 'الترقية الآن',
          unlockMoreUpgradingText: 'افتح المزيد من القوة من خلال الترقية',
        },
        taskConsumption: {
          headerTitle: 'استهلاك المهمة',
        },
      },
    },

    account: {
      myProfileText: 'الملف الشخصي',
      billingtext: 'عنوان الفوترة',
      emailAddressLabel: 'عنوان البريد الإلكتروني',
      firstNameLabel: 'الاسم الأول',
      lastNameLabel: 'الاسم الأخير',
      subAccountsLabel: 'الحسابات الفرعية',
      firstNameRequiredText: 'الاسم الأول مطلوب!',
      lastNameRequiredText: 'الاسم الأخير مطلوب!',
      countryRequiredText: 'البلد مطلوب!',
      addressLine1RequiredText: 'العنوان (السطر الأول) مطلوب!',
      cityRequiredText: 'المدينة مطلوبة!',
      stateRequiredText: 'الولاية مطلوبة!',
      zipCodeRequiredText: 'الرمز البريدي مطلوب!',
      taxIdRequiredText: 'الرقم الضريبي مطلوب!',
      companyNameLabel: 'اسم الشركة',
      currentPositionLabel: 'الوظيفة الحالية',
      countryLabel: 'البلد',
      addressLabel: 'العنوان',
      cityLabel: 'المدينة',
      stateLabel: 'الولاية',
      zipCodeLabel: 'الرمز البريدي',
      taxIdLabel: 'الرقم الضريبي',
      timeZoneLabel: 'المنطقة الزمنية',
      changeLanguageLabel: 'اختيار اللغة',
      changePasswordHeaderText: 'تغيير كلمة المرور',
      currentPasswordLabel: 'كلمة المرور الحالية',
      currentPasswordRequiredText: 'كلمة المرور الحالية مطلوبة!',
      newPasswordLabel: 'كلمة المرور الجديدة',
      newPasswordRequiredText: 'كلمة المرور الجديدة مطلوبة!',
      confirmPasswordLabel: 'تأكيد كلمة المرور',
      confirmPasswordRequiredText: 'تأكيد كلمة المرور مطلوب!',
      updateBtnLabel: 'تحديث',
      editUpdateAccountSideBarText: 'تحرير / تحديث الحساب',
      changePasswordSideBarText: 'تغيير كلمة المرور',
      billingText: 'الفوترة',
      teamText: 'الفريق',
      agencyText: 'الوكالة',
      sideBarHeaderAccountText: 'الحساب',
      profileLable: 'الملف الشخصي',
      profileUpdatedSuccessfullyText: 'تم تحديث الملف الشخصي بنجاح!',
      addTeamMemberText: 'إضافة عضو إلى الفريق',
      addSubAccountsText: 'إضافة حسابات فرعية',

      billing: {
        couponCodeText: 'الكود',
        applyCodeBtnLbl: 'تطبيق الكود',
        tasksText: 'المهام',
        promotionalTasksText: 'المهام الإضافية',
        redeemedOnText: 'تم استرداده في',
        expiresOnText: 'تنتهي صلاحيته في',
        noCouponsAppliedText: 'لم يتم تطبيق ترخيص',
        plansAndFeaturesText: 'الخطط والميزات',
        currentPlanText: 'الخطة الحالية',
        upgradeText: 'الترقية',
        billingText: 'الفوترة',
        licenseerror: 'ترخيص غير صالح!',
        completionofprofile:
          'الرجاء إكمال ملفك الشخصي قبل استرداد الكود. هذا مطلوب لغرض GPDR/معالجة البيانات والفوترة',
      },
      team: {
        teamLabel: 'الفريق',
        name: 'الاسم',
        firstName: 'الاسم الأول',
        lastName: 'الاسم الأخير',
        access: 'الوصول',
        email: 'البريد الإلكتروني',
        addBtn: 'إضافة',
        cancelBtn: 'إلغاء',
        assignAccessLbl: 'تعيين الوصول',
        noUsersInfo: 'لا يوجد مستخدمون متاحون',
        collapseTeamLabel: 'طي الفريق',
        expandTeamLabel: 'توسيع الفريق',
        teamMembersHeaderText: 'أعضاء الفريق',
      },
      agency: {
        agencyLabel: 'الوكالة',
        name: 'اسم العميل:',
        firstName: 'الاسم الأول',
        lastName: 'الاسم الأخير',
        tasks: 'المهمات المخصصة:',
        consumed: 'المهمات المستهلكة',
        agencyConsumed: 'تم استهلاكها',
        remainingLabel: 'المتبقي',
        totalSubaccounts: 'مجموع الحسابات الفرعية:',
        email: 'البريد الإلكتروني:',
        active: 'نشط:',
        inActive: 'غير نشط:',
        addBtn: 'إضافة',
        addMember: 'إضافة عضو',
        deleteMember: 'حذف العضو',
        cancelBtn: 'إلغاء',
        editMemberLabel: 'تحرير العضو',
        noAgenciesInfo: 'لا توجد وكالات متاحة',
        agencyMembersHeaderText: 'الحسابات الفرعية',
        total: 'إجمالي المهام:',
        update: 'تحديث',
        saveChangeLabel: 'حفظ التغيير',
        updateTask: 'تحديث المهمة',
        delete: 'حذف',
        available: 'المهام المتاحة',
        deactivate: 'تفعيل',
        totalAssigned: 'إجمالي المهام المخصصة',
        activate: 'إلغاء التفعيل',
        detailsLabel: 'التفاصيل',
        subaccounterror: 'فشل تحديث تفاصيل الحساب الفرعي!',
      },
      reseller: {
        resellerLabel: 'تاجر جملة',
      },
    },

    sideNav: {
      homeLabel: 'الرئيسية',
      appsLabel: 'التطبيقات',
      konnectzLabel: 'Konnectz',
      historyLabel: 'التاريخ',
      historyCapsLabel: 'التاريخ',
      accountLabel: 'الحساب',
      notificationsLabel: 'الإشعارات',
      logoutLabel: 'تسجيل الخروج',
      getHelpLabel: 'تحتاج مساعدة؟',
      helpModalText: 'كن على اتصال. سنأتي قريبًا!',
      konnectzITLogoText: 'KonnectzIT',
    },
  },
};
