import React, { useState } from "react";
import { bigSVG, svg } from "../../../assets/objectSVG";
import { useFormik } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Flex,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useToast,
  Text,
  Toast,
} from "@chakra-ui/react";
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NodeLoader } from "../../../components/loader";
import signupFeatures from "../../../components/carousel/features/signup-features.json";
import { Carousel } from "../../../components/carousel";
import { userExist, userSignup } from "../../../store/thunk/authThunk";
import { t } from "i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { BsPersonCircle } from "react-icons/bs";

export const SignUpContainer = () => {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.auth);
  const [state, setState] = useState("sign-up");
  const [email, setEmail] = useState("xyz@gmail.com");

  return (
    <>
      <Flex
        width="65%"
        sx={{
          p: "3% 0px 3% 3%",
          position: "relative",
          zIndex: 0,
        }}
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {signupFeatures?.features.map((feature, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Carousel t={t} feature={feature} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
      <Flex
        width="35%"
        sx={{
          m: "3%",
          position: "relative",
          zIndex: 2,
          borderRadius: "8px",
          flexDirection: "column",
          gap: "10px",
          p: "3%",
          backgroundColor: "rgb(21,46,115)",
          boxShadow: "0px 0px 15px -1px rgb(21,46,115,0.3)",
        }}
      >
        <NodeLoader loading={loading} stroke="#fff" />
        {state === "sign-up" ? (
          <>
            <Flex sx={{ m: "0px auto", gap: "5px" }}>
              <Flex
                sx={{
                  m: "0px auto",
                  width: ["20px", "22px", "24px", "26px", "26px"],
                }}
              >
                {svg.invertedLogo}
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  fontSize: [18, 20, 22, 24, 26],
                  fontWeight: 500,
                }}
              >
                KonnectzIT
              </Flex>
            </Flex>
            <Flex
              sx={{
                m: "0px auto 15% auto",
                fontSize: [39, 44, 51, 55, 59],
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Sign Up
            </Flex>
            <UserExistCheck
              loading={loading}
              setState={setState}
              setEmail={setEmail}
            />
             
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.signup.alreadyHaveAccountText")}
                <NavLink
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  Sign In
                </NavLink>
              </Flex>
         
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}
                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                   {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </>
        ) : state === "create-password" ? (
          <Flex sx={{ flexDir: "column", gap: "20px" }}>
            <Flex sx={{ flexDir: "column", gap: "5px" }}>
              <Flex
                sx={{
                  color: "#fff",
                  fontSize: [16, 17, 17, 18, 20],
                  fontWeight: 500,
                  justifyContent: "center",
                }}
              >
                {t("auth.signup.createPassword.subtitle")}
  
              </Flex>
              <Flex
                height="2px"
                width="100%"
                sx={{ bgColor: "#fff", borderRadius: "50%" }}
              />{" "}
            </Flex>
            <Flex
              sx={{
                color: "#fff",
                fontSize: [20, 22, 24, 26, 28],
                fontWeight: 500,
                justifyContent: "center",
              }}
            >
              {t("auth.reset.subTitle")}
            </Flex>
            {/* <Flex sx={{ fill: "#fff", m: "0px auto 20px auto" }}>
            {/* <Flex sx={{ fill: "#fff", m: "0px auto 20px auto" }}>
              {bigSVG.createPassord}
            </Flex> */}
           
            <CreatePassword
              loading={loading}
              setState={setState}
              email={email}
            />
          </Flex>
        ) : (
          <Flex sx={{ flexDir: "column", gap: "20px" }}>
            <Flex sx={{ flexDir: "column", gap: "5px" }}>
              <Flex
                sx={{
                  color: "#fff",
                  fontSize: [16, 17, 17, 18, 20],
                  fontWeight: 500,
                  justifyContent: "center",
                }}
              >
                   {t("auth.signup.title")}
              </Flex>
              <Flex
                height="2px"
                width="100%"
                sx={{ bgColor: "#fff", borderRadius: "50%" }}
              />{" "}
            </Flex>
            <Flex
              sx={{
                color: "#fff",
                fontSize: [20, 22, 24, 26, 28],
                fontWeight: 500,
                justifyContent: "center",
              }}
            >
             {t("auth.signup.verifyingMessage")}
            </Flex>
            <Flex sx={{ m: "0px auto 20px auto" }}>
              {bigSVG.verificationSent}
            </Flex>
            <Text sx={{ color: "#fff", textAlign: "center" }}>

              {t("auth.signup.verificationSent.verificationText1")}
              <span style={{ fontWeight: 600, textDecoration: "underline" }}>
                {email}
              </span>
              
              {t("auth.signup.verificationSent.verificationText2")}
            </Text>
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.alreadyHaveAccountText")}
                <NavLink
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  Sign In
                </NavLink>
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}
                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                   {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

const UserExistCheck = ({ setState, setEmail }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation()
  const [captcha,setCaptcha] = useState(false)

  function displayError(e) {
    toast({
      position: 'top',
      status: 'error',
      variant: 'solid',
      title: t("auth.login.captchaErrorMsg"),
      duration: 2500,
      containerStyle: {
        fontWeight: 400,
      },
    });
 
}
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
    captcha?  handleSubmit():displayError()
    },
  });
  const handleSubmit = async () => {
    const payload = {
      email: formik.values.email,
    };
    dispatch(userExist(payload)).then((res) => {
      if (!res.error) {
        if (res.payload.key === "USER_EXISTS") {
          toast({
            position: "top",
            status: "error",
            variant: "solid",
            title: "E-mail address already in use!",
            duration: 2500,
            containerStyle: {
              fontWeight: "300 !important",
            },
          });
        } else if (res.payload.key === "NO_USER") {
          setEmail(payload.email);
          setState("create-password");
        }
      }
    });
  };
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="email"
              name="email"
              type="email"
              required
              placeholder= {t("auth.signup.emailPlaceholder")} 
              variant="outlined"
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </InputGroup>
        </FormControl>
        <ReCAPTCHA
    sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY} 
        onChange={(value) => {
         
            setCaptcha(value ? true : false)
          
        }}
      />
        <Button
          type="submit"
          width="full"
          variant="outline"
          size={{
            base: "md",
            sm: "md",
            md: "md",
            lg: "md",
            xl: "lg",
            "2xl": "lg",
          }}
          sx={{
            borderTop: "1px solid #f7c544",
            borderRight: "1px solid #f7c544",
            borderLeft: "1px solid #f7c544",
            borderBottom: "4px solid #f7c544",
            fontSize: [14, 15, 15, 16, 18],
            borderRadius: "4px",
            color: "#f7c544",
            "&:hover": {
              color: "rgb(21,46,115)",
              borderTop: "1px solid #fff",
              borderRight: "1px solid #fff",
              borderLeft: "1px solid #fff",
              borderBottom: "4px solid #fff",
            },
          }}
        >
          {t("auth.signup.createPassword.continueBtnLabel")}
        </Button>
      </form>
    </div>
  );
};

const CreatePassword = ({ setState, email }) => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    const payload = {
      email: email,
      first_name:formik.values.first_name,
      last_name:formik.values.last_name,
      password: formik.values.password,
      is_admin: false,
      password_confirmation: formik.values.confirmPassword,
    };
    if (payload.password === payload.password_confirmation) {
      dispatch(userSignup(payload)).then((res) => {
        if (!res.error) {
          if (res.payload.key === "SIGNUP_SUCCESS") {
            setState("verify-email");
          } else {
            toast({
              position: "top",
              status: "error",
              variant: "solid",
              title: "Something went wrong!",
              duration: 2500,
              containerStyle: {
                fontWeight: "300 !important",
              },
            });
          }
        }
      });
    } else
      toast({
        position: "top",
        status: "error",
        variant: "solid",
        title: "Password do not match. Try again!",
        duration: 2500,
        containerStyle: {
          fontWeight: "300 !important",
        },
      });
  };
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
           <FormControl>
         
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
                 <InputLeftElement pointerEvents="none">
              <BsPersonCircle color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="first_name"
              name="first_name"
              type={ "text" }
              placeholder= {"First Name"}
              variant="outlined"
              required
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.first_name}
            />
      
          </InputGroup>
        </FormControl>
        <FormControl>
          
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
               <InputLeftElement pointerEvents="none">
               <BsPersonCircle color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="last_name"
              name="last_name"
              type={ "text" }
              placeholder= {"Last Name"}
              variant="outlined"
              required
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.last_name}
            />
         
          </InputGroup>
        </FormControl>



        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <LockIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder= {t("auth.login.passwordPlaceholder")}
              variant="outlined"
              required
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <InputRightElement
              onClick={() => setShowPassword((c) => !c)}
              sx={{ cursor: "pointer" }}
            >
              {showPassword ? (
                <ViewOffIcon color="rgb(21,45,115)" boxSize="1.2em" />
              ) : (
                <ViewIcon color="rgb(21,45,115)" boxSize="1.2em" />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <LockIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirm ? "text" : "password"}
              placeholder= {t("account.confirmPasswordLabel")}
              variant="outlined"
              required
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
            />
            <InputRightElement
              onClick={() => setShowConfirm((c) => !c)}
              sx={{ cursor: "pointer" }}
            >
              {showConfirm ? (
                <ViewOffIcon color="rgb(21,45,115)" boxSize="1.2em" />
              ) : (
                <ViewIcon color="rgb(21,45,115)" boxSize="1.2em" />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex sx={{ color: "#fff", fontSize: [10, 11, 11, 12, 14] }}>
          {t("auth.signup.createPassword.passwordRule")}
          
        </Flex>
        <Button
          type="submit"
          width="full"
          variant="outline"
          size={{
            base: "md",
            sm: "md",
            md: "md",
            lg: "md",
            xl: "lg",
            "2xl": "lg",
          }}
          sx={{
            borderTop: "1px solid #f7c544",
            borderRight: "1px solid #f7c544",
            borderLeft: "1px solid #f7c544",
            borderBottom: "4px solid #f7c544",
            fontSize: [14, 15, 15, 16, 18],
            borderRadius: "4px",
            color: "#f7c544",
            "&:hover": {
              color: "rgb(21,46,115)",
              borderTop: "1px solid #fff",
              borderRight: "1px solid #fff",
              borderLeft: "1px solid #fff",
              borderBottom: "4px solid #fff",
            },
          }}
        >
           {t("auth.signup.createPassword.continueBtnLabel")}
        </Button>
      </form>
    </div>
  );
};
