import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { AppWrapper } from "./components/styled";
import { Button, ChakraProvider, Wrap } from "@chakra-ui/react";
import { theme } from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { RenderRoutes } from "./routes";
import i18n from "./utils/i18next";
import styled from "@emotion/styled";
const App = () => {
  const router = createBrowserRouter([{ path: "*", Component: RenderRoutes }]);

  return (
    <AppWrapper>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <RouterProvider router={router} />
          </I18nextProvider>
        </Provider>
      </ChakraProvider>
    </AppWrapper>
  );
};

export default App;
