import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { UpgradeNow } from "./upgradeNow/index";
import { TotalKonnectz } from "./totalKonnectz";
import { TaskConsumption } from "./taskConsumption";
import { PromotionalTask } from "./promotionalTask";
import { NewsLetter } from "./newsLetter";
import { LatestKonnectz } from "./latestKonnectz";
import { useSelector } from "react-redux";
import { NodeLoader } from "../../components/loader";
export const DashboardContainer = () => {
  const loading = useSelector((state) => state.dash.loading);
  return (
    <Flex
      width="100%"
      height="100%"
      sx={{
        paddingY: "20px",
        gap: "20px",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Flex
        width="100%"
        height="100%"
        position="absolute"
        sx={{
          top: "20px",
          left: "0px",
          right: "0px",
          bottom: "20px",
        }}
        display={loading ? "flex" : "none"}
      >
        <NodeLoader loading={loading} bgcolor="#fff" bottom="20px" />
      </Flex>
      <Flex width="100%" height="30%" sx={{ gap: "20px", maxH: "250px" }}>
        <TotalKonnectz />
        <TaskConsumption />
        <PromotionalTask />
        <UpgradeNow />
      </Flex>
      <Flex width="100%" height="100%" sx={{ gap: "20px" }}>
        <LatestKonnectz />
        <NewsLetter />
      </Flex>
    </Flex>
  );
};
