import React from "react";
import { onCanvasDrop } from "../../../store/slice/canvasSlice";
import { nanoid } from "@reduxjs/toolkit";
import {
  getAppEvent,
  getLinkedAccounts,
} from "../../../store/thunk/rightHandAppsThunk";
const couponInitialState = {
  expiryNum: "",
  duration: [
    { id: 1, name: "Days", label: "Days", selected: true, value: 1 },
    { id: 2, name: "Week", label: "Week", selected: false, value: 2 },
    { id: 3, name: "Months", label: "Months", selected: false, value: 3 },
    { id: 4, name: "Years", label: "Years", selected: false, value: 4 },
  ],
};
const textSplitterInitialState = {
  segmentIndex: [
    { id: 1, name: "First", label: "First", selected: true, value: 1 },
    { id: 2, name: "Second", label: "Second", selected: false, value: 2 },
    { id: 3, name: "Last", label: "Last", selected: false, value: 3 },
    {
      id: 4,
      name: "Second to Last",
      label: "Second to Last",
      selected: false,
      value: 4,
    },
    { id: 5, name: "All", label: "All", selected: false, value: 5 },
  ],
};
export const NodeType = ({ element, nodes }) => {
  let nodeType;
  switch (element.type.toLowerCase()) {
    case "webhook":
      nodeType = "WebHookInner";
      break;
    case "api":
      nodeType = "NodeInner";
      break;
    case "add_on":
      switch (element.provider.toLowerCase()) {
        case "textformatter":
          nodeType = "addOnInner";
          break;
        case "iterator":
          nodeType = "commonAddOnInner";
          break;
        case "converter":
          nodeType = "addOnInner";
          break;
        case "numberformatter":
          nodeType = "addOnInner";
          break;
        case "dateformatter":
          nodeType = "addOnInner";
          break;
        case "generator":
          nodeType = "coupan";
          break;
        case "conditioner":
          nodeType = "conditions";
          break;
        case "api":
          nodeType = "apiInner";
          break;
        case "scheduler":
          nodeType = "scheduler";
          break;
        case "textsplitter":
          nodeType = "textSplitter";
          break;
        default:
          nodeType = "commonAddOnInner";
      }
      break;
    case "webhook_api":
      if (nodes.length > 0) {
        nodeType = "NodeInner";
      } else nodeType = "WebHookInner";
      break;
  }
  return nodeType;
};

export const onDrop = (
  e,
  reactFlowInstance,
  dispatch,
  reactFlowWrapper,
  nodes,
  links,
  toast
) => {
  e.preventDefault();
  let nodeId = nanoid();

  let droppedElement = JSON.parse(e.dataTransfer.getData("appsData"));
  const position = reactFlowInstance.project({
    x: e.clientX - reactFlowWrapper.current.getBoundingClientRect().left,
    y: e.clientY - reactFlowWrapper.current.getBoundingClientRect().top,
  });
  const newNode = {
    id: nodeId,
    position: position,
    data: {
      ...droppedElement,
      nodeId: nodeId,
      loading: false,
      status: "Draft",
      captureMessage: "",
    },
    dragHandle: "#drag",
    type: NodeType({ element: droppedElement, nodes: nodes }),
  };
  let iteratorPresent = nodes.find(
    (node) => node.data.provider.toLowerCase() === "iterator"
  )
    ? true
    : false;
  if (nodes.length > 0 && droppedElement.side === "Left") {
    toast({
      position: "top",
      status: "error",
      variant: "solid",
      title: "Trigger already exist, continue with adding Action",
      duration: 2500,
      containerStyle: {
        fontWeight: 400,
      },
    });
    return;
  } else if (
    droppedElement.provider.toLowerCase() === "iterator" &&
    iteratorPresent === true
  ) {
    toast({
      position: "top",
      status: "error",
      variant: "solid",
      title: "Iterator already exist, continue with adding other Action",
      duration: 2500,
      containerStyle: {
        fontWeight: 400,
      },
    });
    return;
  } else {
    dispatch(onCanvasDrop(newNode));

    if (droppedElement.provider.toLowerCase() !== "webhook") {
      dispatch(getAppEvent(newNode));
    }
    if (droppedElement.type !== "WEBHOOK") {
      dispatch(getLinkedAccounts(newNode));
    }
  }
};

export const payloadMaker = ({ node, nodes, edges }) => {
  let payload = {
    app_account_id: node.data.selectedAccount?.id
      ? node.data.selectedAccount?.id
      : null,
    app_event_id: node.data.selectedEvent?.id
      ? node.data.selectedEvent?.id
      : null,
    app_id: node.data.id,
    konnect_id: node.data.trigger_konnect_id
      ? node.data.trigger_konnect_id
      : node.data.konnect_id,
    config: {},
    provider: node.data.provider,
  };

  node.data.appEventConfigurations?.map((config) => {
    if (config?.selected) {
      if (config.selected?.key_value === "custom_field") {
        if (config.selected?.value?.length) {
          if (config.selected.target_linked) {
            payload.config[config.config_key] = {
              type: "key-map",
              value: config.selected.passed_data.id,
              konnect_activity_id: config.selected.passed_data
                ? config.selected.passed_data.source_konnect_activity_id
                : 0,
            };
          } else
            payload.config[config.config_key] = {
              type: "user-defined",
              value: config.selected.id,
            };
        }
      } else payload.config[config.config_key] = config.selected.id;
    }
  });

  node.data.configResponses.map((config) => {
    if (
      !config.additional &&
      config.sequence == 0 &&
      config.value &&
      !config.customAdded
    ) {

      if (
        config.target_linked === true &&
        node.data.provider.toLowerCase() !== "textformatter" &&
        node.data.provider.toLowerCase() !== "numberformatter" &&
        node.data.provider.toLowerCase() !== "dateformatter"
      ) {
        payload.config[config.config_key] = {
          type: "key-map",
          value: config.passed_data.id,
          konnect_activity_id: config.passed_data.source_konnect_activity_id
            ? config.passed_data.source_konnect_activity_id
            : 0,
          iterator: config.iterator,
        };
      } else {
        payload.config[config.config_key] = {
          type: "user-defined",
          value: config.value,
        };
      }
    }
  });

  const conditionsFormatterConfig = [];

  if (node.data.type === "ADD_ON" && node.data.provider === "conditioner") {
    let operator = null;
    node.data.conditions.map((e) => {
      const condition = {
        attribute: {
          type: "key-map",
          value: e.field.value,
          konnect_activity_id: node.data.parent_konnect_activity_id,
        },
        condition: e.operator,
        condition_value: e.value.value
          ? e.value
          : {
              type: "user-defined",
              value: e.value,
              // konnect_activity_id: x.parent_konnect_activity_id,
            },
      };
      if (operator) condition.concat_value = operator;
      operator = e.queryOperator ? e.queryOperator.toUpperCase() : null;
      conditionsFormatterConfig.push(condition);
    });
  }
  const textFormatterConfig = [];

  if (
    node.data.type === "ADD_ON" &&
    (node.data.provider.toLowerCase() === "textformatter" ||
      node.data.provider.toLowerCase() === "numberformatter" ||
      node.data.provider.toLowerCase() === "dateformatter")
  ) {
    node.data.fromPorts?.map((e) => {
      const nodeFrom = nodes.find((u) => u.data.nodeId === e.info.node);

      textFormatterConfig.push({
        konnect_activity_id: nodeFrom.data.konnect_activity_id
          ? nodeFrom.data.konnect_activity_id
          : 0,
        display: e.display,
        id: e.id,
        config_key: nodeFrom.data.configResponses.find((i) => i.id === e.id)
          .config_key,
        iterator: e.info.iterator ? e.info.iterator : false,
      });
    });

    let string = payload.config?.text?.value + "";
    //let string = payload.data.config.text.value + ""
    textFormatterConfig.map((d) => {
      const f = `@[${d.display}]`;
      const mapValue = {
        type: "key-map",
        value: d.id,
        konnect_activity_id: d.konnect_activity_id,
        iterator: d.iterator,
      };
      const g = `@[${d.display}](${JSON.stringify(mapValue)})`;
      string = string.replace(f, g);
      // if (mapValue.iterator === true) {
      //   payload.config.text.iterator = true;
      // }
    });

    if (string !== undefined) {
      payload.config.text.value = string;
    }
  }
  const iteratorConfig = [];
  // if (
  //   node.data.type === "ADD_ON" &&
  //   node.data.provider.toLowerCase() === "iterator"
  // ) {
  //   node.data.typeOfValues.map((obj) =>
  //     iteratorConfig.push(
  //       `type:${obj.type},value:${obj.value} ,konnect_activity_id:${obj.konnect_activity_id} `
  //     )
  //   );
  // }

  if (node.data.type === "ADD_ON" && node.data.provider === "generator") {
    payload.config.appDetails = node.data.appDetails;
  } else if (
    node.data.type === "ADD_ON" &&
    node.data.provider.toLowerCase() === "textsplitter"
  ) {
    if (node.data.appDetails.textSplitter)
      payload.config.segmentIndex =
        node.data.appDetails.textSplitter.segmentIndex;
  } else if (
    node.data.type === "ADD_ON" &&
    node.data.provider.toLowerCase() === "api"
  ) {
    payload.config.apiDetails = node.data.api;
  } else if (
    node.data.type === "ADD_ON" &&
    node.data.provider.toLowerCase() === "conditioner"
  ) {
    payload.config = [...conditionsFormatterConfig];
  } else if (
    node.data.type === "ADD_ON" &&
    node.data.provider.toLowerCase() === "iterator"
  ) {
    //this is for new event of iterator i.e generate array
  }
  if(node.data.delayReady){
    payload.delay_config=node.data.delayConfigsForTest
  }
  if (
    node.data.type === "ADD_ON" &&
    node.data.provider.toLowerCase() === "data_forwarder"
  ) {
    let forwardDetails = {};
    node.data.configResponses
      .filter((x) => x.customAdded === true)
      ?.map((config) => {
        if (config.target_linked === true) {
          forwardDetails[config.config_key] = {
            type: "key-map",
            value: config.passed_data.id,
            konnect_activity_id: config.passed_data.source_konnect_activity_id
              ? config.passed_data.source_konnect_activity_id
              : 0,
          };
        } else {
          forwardDetails[config.config_key] = {
            type: "user-defined",
            value: config.value,
          };
        }
      });
    payload.config.forwardDetails = { details: forwardDetails };
  }
  edges.map((edge) => {
    const fromNode = nodes.find((n) => n.data.nodeId === edge.source);
    const toNode = nodes.find((n) => n.data.nodeId === edge.target);
    if (!!fromNode.data.pass_through_condition && edge.target === node.id) {
      payload.config.pass_through_condition =
        !!fromNode.data.pass_through_condition;
      payload.config.condition_activity_id = !!fromNode.data
        .pass_through_condition
        ? fromNode.data.condition_activity_id
        : null;
    }
  });

  return payload;
};

export const FlattenJSON = (json) => {
  let newData = [];
  const extract = (obj, parentKey = []) => {
    if (!obj) return;
    Object.keys(obj).map((key) => {
      const __key = nanoid();
      if (typeof obj[key] === "object") {
        const k = `${[...parentKey, key].join(".$")}`;
        const _parentKey = parentKey.map(
          (x) => `${x[0].toUpperCase()}${x.slice(1)}`
        );
        const nodeLabel = `${[
          ..._parentKey,
          key[0] ? `${key[0].toUpperCase()}${key.slice(1)}` : "Undefined",
        ].join(" > ")}`;
        obj[key] &&
          newData.push({
            id: k,
            type: "node",
            parent: parentKey,
            label: nodeLabel,
            level: parentKey.length,
            items: { ...obj[key] },
            k,
            children: Object.keys(obj[key]).map((z) => `${[k, z].join(".$")}`),
            isOpen: false,
            isAvailable: false,
            uiKey: __key,
          });

        extract(obj[key], [...parentKey, key]);
      } else {
        const k = `${[...parentKey, key].join(".$")}`;

        newData.push({
          id: k,
          label: key[0]
            ? `${key[0]?.toUpperCase()}${key?.slice(1)}`
            : "Undefined",
          value: obj[key],
          config_key: k,
          type: "leaf",
          parent: `${[...parentKey].join(".$")}`,
          level: parentKey.length,
          isOpen: false,

          uiKey: __key,
        });
      }
      return false;
    });
  };
  extract(json);

  return newData;
};

export const jsonMaker = ({ nodes, links }) => {
  // console.group("NODES || EDGES");
  // console.log("NODES :", nodes);
  // console.log("EDGES :", links);
  // console.groupEnd();
  let newEdges = [];
  let newNodes = nodes.map((node, index) => {
    let configResponses = node.data?.configResponses
      .concat(
        node.data.additionalResponses?.length
          ? node.data.additionalResponses?.map((c) => {
              if (c.items) {
                return { ...c, additional: true };
              } else {
                const sourceporthandle =
                  node.data.nodeid.toString() +
                  "|" +
                  node.data.id.toString() +
                  "|" +
                  node.data.appEvent.toString() +
                  "|" +
                  c.config_key.toString() +
                  "|" +
                  "source";

                const targetporthandle =
                  node.data.nodeid.toString() +
                  "|" +
                  node.data.id.toString() +
                  "|" +
                  node.data.appEvent.toString() +
                  "|" +
                  c.config_key.toString() +
                  "|" +
                  "target";
                return {
                  ...c,
                  additional: true,
                  source: {
                    id: sourceporthandle,
                  },
                  target: {
                    id: targetporthandle,
                  },
                };
              }
            })
          : []
      )
      .map((config, i) => {
        let existingLink = links.find((link) => {
          const targetHandleData = JSON.parse(link.targetHandle.split("|")[1]);
          const sourceHandleData = JSON.parse(link.sourceHandle.split("|")[1]);
          if (
            (targetHandleData.node === node.id &&
              targetHandleData.config_key === config.config_key) ||
            (sourceHandleData.node === node.id &&
              sourceHandleData.config_key === config.config_key)
          ) {
            return link;
          }
        });
        const { mapped, ts, ...rest } = config;
        const id = nanoid();
        const port = {
          id: id,
          nodeId: node.id,
          source: id + "|source",
        };
        if (index !== 0) {
          port.target = id + "|target";
        }

        if (existingLink) {
          let link_data = {
            source_node_id: existingLink.source,
            prev_source_field_port: existingLink,
          };
          return {
            ...rest,
            linked: true,
            link_data: link_data,
            port: port,
            checked: true,
            additional:
              index === 0 && node.data.provider.toLowerCase() === "webhook"
                ? true
                : config.additional === true
                ? true
                : null,
          };
        } else {
          if (config.config_key_required) {
            return {
              ...rest,
              checked: true,
              port: port,
            };
          } else
            return {
              ...rest,
              additional:
                index === 0 && node.data.provider.toLowerCase() === "webhook"
                  ? true
                  : config.additional === true
                  ? true
                  : null,
              checked: true,
              port: port,
            };
        }
      });

    let configForonditioner = configResponses.map((x) => {
      if (x.config_key === "conditions_setup") {
        return x;
      } else {
        return {
          ...x,
          additional: true,
        };
      }
    });
    const selectedTrigger = !node.data.appEventConfiguration
      ? "Choose"
      : configs?.find((x) => x.id === node.data.appEventConfiguration)
          ?.service_name;
    return {
      ...node,
      id: node.id,
      data: {
        ...node.data,
        nodeId: node.data.nodeid,
        trigger_konnect_id: nodes[0].data.konnect_id,
        appEvents: node.data.appEvents?.map((e) => {
          return {
            ...e,
            value: e.id,
            label: e.name,
          };
        }),
        associatedAccounts:
          node.data.linkedAccounts !== ""
            ? node.data.linkedAccounts?.map((l) => {
                return {
                  ...l,
                  label: l.name,
                  value: l.id,
                };
              })
            : [],
        selectedEvent: node.data.selectedEvent
          ? {
              ...node.data.selectedEvent,
              label: node.data.selectedEvent.name,
              value: node.data.selectedEvent.id,
            }
          : null,
        selectedAccount: node.data.selectedAccount
          ? {
              ...node.data.selectedAccount,
              label: node.data.selectedAccount.name,
              value: node.data.selectedAccount.id,
            }
          : null,
        configResponses:
          node.data.provider.toLowerCase() === "conditioner"
            ? configForonditioner
            : configResponses,
        nodeIdx: index,
        loading: false,
        status: node.data.test_status,
        captureMessage: node.data.display_message,
        display_message: node.data.display_message,
        appEventConfigurations: node.data.appEventConfigurations
          ?.map((c, i) => {
            return {
              ...c,
              value: c.id,
              config_details: node.data.appEventConfigurationDetails
                .filter((x) => x.config_key === c.config_key)
                .map((det) => {
                  return {
                    ...det,
                    label: det.name,
                    value: det.id,
                    sequence: c.sequence,
                  };
                }),
            };
          })
          .map((x) => {
            let title = x.config_key_required
              ? `${selectedTrigger} ${x.label} *`
              : `${selectedTrigger} ${x.label}`;
            return {
              ...x,
              title: title,
              selected: x.selected
                ? {
                    ...x.selected,
                    label: x.selected.name,
                    value: x.selected.value,
                  }
                : null,
            };
          }),
        selectedEvent: node.data.appEvents
          ?.map((e) => {
            return {
              ...e,
              value: e.id,
              label: e.name,
            };
          })
          .find((event) => event.id === node.data.appEvent),
        appDetails: node.data.appDetails
          ? {
              coupon: {
                expiryNum: node.data.appDetails?.coupon.expiryNum,
                duration: node.data.appDetails?.coupon?.duration.map((dur) => {
                  return { ...dur, label: dur.name, value: dur.id };
                }),
              },
              textSplitter: {
                segmentIndex:
                  node.data.appDetails?.textSplitter.segmentIndex.map((txt) => {
                    return {
                      ...txt,
                      label: txt.name,
                      value: txt.id,
                    };
                  }),
              },
            }
          : null,
        selectedAddonsValue:
          node.data.provider.toLowerCase() === "generator"
            ? node.data.appDetails.coupon.duration
                .map((x) => {
                  return {
                    ...x,
                    label: x.name,
                    value: x.id,
                  };
                })
                .find((dur) => dur.selected === true)
            : node.data.provider.toLowerCase() === "textsplitter"
            ? node.data.appDetails.textSplitter.segmentIndex
                .map((x) => {
                  return {
                    ...x,
                    label: x.name,
                    value: x.id,
                  };
                })
                .find((dur) => dur.selected === true)
            : null,
      },
      dragHandle: "#drag",
    };
  });
  newEdges = links.map((link) => {
    const sourcePortInfo = JSON.parse(link.sourceHandle.split("|")[1]);
    const targetPortInfo = JSON.parse(link.targetHandle.split("|")[1]);
    const { node, app, appEvent, port, type } = sourcePortInfo;
    let sourceporthandle =
      node.toString() +
      "|" +
      app.toString() +
      "|" +
      appEvent.toString() +
      "|" +
      port.toString() +
      "|" +
      type.toString();
    const {
      node: node1,
      app: app1,
      appEvent: appEvent1,
      port: port1,
      type: type1,
    } = targetPortInfo;

    const targetporthandle =
      node1.toString() +
      "|" +
      app1.toString() +
      "|" +
      appEvent1.toString() +
      "|" +
      port1.toString() +
      "|" +
      type1.toString();
    let id = nanoid();

    let checkConditionAppforsource = newNodes.find(
      (x) => x.id === sourcePortInfo.node
    ).data.provider;
    if (checkConditionAppforsource === "conditioner") {
      sourceporthandle =
        node.toString() +
        "|" +
        app.toString() +
        "|" +
        appEvent.toString() +
        "|" +
        port.toString() +
        "|" +
        "source";
    }
    let checkConditionApp = newNodes.find((x) => x.id === targetPortInfo.node)
      .data.provider;

    let source = nodes.find((x) =>
      x.data.configResponses.find((c) => c.source.id === sourceporthandle)
    );
    let target = nodes.find((x) =>
      x.data.configResponses.find((c) => c.target?.id === targetporthandle)
    );
    //"b776d8ba-2d85-4135-8780-452aebc3f395|83|undefined|"conditions_setup"|target"
    if (checkConditionApp === "conditioner") {
      const targetporthandle =
        node1.toString() +
        "|" +
        app1.toString() +
        "|" +
        "undefined" +
        "|" +
        port1.toString() +
        "|" +
        type1.toString();
    }
    if (checkConditionApp === "conditioner") {
      target = nodes.find((x) =>
        x.data.configResponses.find((c) => c.config_key === "conditions_setup")
      );
    }
    if (source && target) {
      let sourceHandle = newNodes
        .find((node) => node.id === source.id)
        .data.configResponses.find((c) => {
          if (c.source.id === sourceporthandle) {
            return c.port.source;
          }
        }).port.source;
      let targetHandle;
      if (checkConditionApp === "conditioner") {
        let target11 = newNodes.find((x) => x.id === target.id);
        targetHandle = target11.data.configResponses.find(
          (c) => c.config_key === "conditions_setup"
        ).port.target;
      } else {
        targetHandle = newNodes
          .find((node) => node.id === target.id)
          .data.configResponses.find((c) => c.target.id === targetporthandle)
          .port.target;
      }
      return {
        id: id,
        source: source.id,
        target: target.id,
        targetHandle: targetHandle,
        sourceHandle: sourceHandle,
        type: "buttonedge",
        data: {
          tested: true,
        },
      };
    }
  });

  newNodes = newNodes.map((node) => {
    return {
      ...node,
      data: {
        ...node.data,
        node_link: [],
        api:
          node.data.provider == "api"
            ? {
                ...node.data.api,
                payloadType: node.data.api.payloadType.map((pay) => {
                  return {
                    ...pay,
                    value: pay.id,
                    label: pay.name,
                  };
                }),
                wrapRequestInArray: node.data.api.wrapRequestInArray.map(
                  (pay) => {
                    return {
                      ...pay,
                      value: pay.id,
                      label: pay.name,
                    };
                  }
                ),
              }
            : null,
        link_data: {
          normal: node.data.configResponses
            .filter((config) => config.additional !== true)
            .filter(function (array_el) {
              return newEdges
                .map((edge) => {
                  return edge.sourceHandle;
                })
                .includes(array_el.port.source);
            })
            .map((con) => {
              return con.port.id;
            }),
          additional: node.data.configResponses
            .filter((config) => config.additional === true)
            .filter(function (array_el) {
              return newEdges
                .map((edge) => {
                  return edge.sourceHandle;
                })
                .includes(array_el.port.source);
            })
            .map((con) => {
              return con.port.id;
            }),
        },
        configResponses: node.data.configResponses.map((configs) => {
          return {
            ...configs,
            link_data: {
              source_node_id: [],
              source_field_port: [],
              target_node_id: [],
              target_field_port: [],
            },
          };
        }),
      },
    };
  });
  newEdges = newEdges.map((edge, i) => {
    let targetHandleToBePushed =
      edge.sourceHandle.split("|")[0].toString() + "|target";
    let arrayOfEdgesForDirectLink = newEdges
      .slice(0, i)
      .filter((edge) => edge.targetHandle === targetHandleToBePushed);

    return {
      ...edge,
      direct_link: arrayOfEdgesForDirectLink[0]?.direct_link
        ? arrayOfEdgesForDirectLink[0]?.direct_link.push(
            arrayOfEdgesForDirectLink[0]?.id
          )
        : arrayOfEdgesForDirectLink[0]?.id
        ? [arrayOfEdgesForDirectLink[0].id]
        : [],
    };
  });
  return { newNodes, newEdges };
};

export const jsonMaker3 = ({ nodes, links, version }) => {
  // console.group("NODES || EDGES");
  // console.log("NODES :", nodes);
  // console.log("EDGES :", links);
  // console.groupEnd();
  let updatedEdges = [],
    updatedNodes = [];
  nodes.map((node, idx) => {
    const found = node.appEvents.find((event) => event.id === node?.appEvent);
    const nodeq = {
      id: node.nodeId,
      position: {
        x: idx === 0 ? 160 : updatedNodes[idx - 1].position.x + 480,
        y: 101,
      },
      data: {
        ...node,
        loading: false,
        status: node.tested || node.reviewed ? "Success" : "Draft",
        captureMessage: node.display_message || "",
        nodeIdx: idx,
        enabled:
          node.appType.toLowerCase() === "webhook" && node?.apiKey?.length
            ? true
            : false,
        id: node.appId,
        webhook_instructions:
          (node.appType.toLowerCase() === "webhook" && node.instructions) || "",
        // auth_type remaining
        type: node.appType,
        appEvents: node.appEvents.map((event) => {
          return { ...event, value: event.id, label: event.name };
        }),
        trigger_konnect_id: nodes[0]?.konnect_id || null,
        konnect_id: nodes[0]?.konnect_id || null,
        associatedAccounts:
          node?.linkedAccounts?.map((acc) => {
            return { ...acc, value: acc.id, label: acc.name };
          }) || [],
        selectedAccount: node.selectedAccount
          ? {
              ...node.selectedAccount,
              value: node.selectedAccount.id,
              label: node.selectedAccount.name,
            }
          : null,
        selectedEvent: node.appEvent
          ? { ...found, value: found.id, label: found.name }
          : null,
        fetchFieldStatus:
          node.appType.toLowerCase() !== "webhook" &&
          node.configResponses.find(
            (con) => con.sequence === 0 && !con.additional
          )
            ? false
            : true,
        webhook_url:
          (node.appType.toLowerCase() === "webhook" && node?.webhookUrl) ||
          null,

        link_data: {
          normal: [],
          additional: [],
        },
        appDetails: {
          coupon: couponInitialState,
          textSplitter: textSplitterInitialState,
        },
        operatorList: [
          {
            id: "lesser than",
            name: "Lesser Than",
            label: "Lesser Than",
            value: "lesser than",
          },
          {
            id: "greater than",
            name: "Greater Than",
            label: "Greater Than",
            value: "greater than",
          },
          {
            id: "is exist",
            name: "Is Exist",
            label: "Is Exist",
            value: "is exist",
          },
          {
            id: "does not exist",
            name: "Does Not Exist",
            label: "Does Not Exist",
            value: "does not exist",
          },
          {
            id: "start with",
            name: "Start With",
            label: "Start With",
            value: "start with",
          },
          {
            id: "start with",
            name: "Does Not Start With",
            label: "Does Not Start With",
            value: "start with",
          },
          {
            id: "end with",
            name: "End With",
            label: "End With",
            value: "end with",
          },
          {
            id: "does not end with",
            name: "Does Not End With",
            label: "Does Not End With",
            value: "does not end with",
          },
          {
            id: "equal to",
            name: "Equal To",
            label: "Equal To",
            value: "equal to",
          },
          {
            id: "does not equal to",
            name: "Does Not Equal To",
            label: "Does Not Equal To",
            value: "does not equal to",
          },
          {
            id: "contain string",
            name: "Contain String",
            label: "Contain String",
            value: "contain string",
          },
          {
            id: "does not contain string",
            name: "Does Not Contain String",
            label: "Does Not Contain String",
            value: "does not contain string",
          },
        ],
      },
      dragHandle: "#drag",
      type:
        node.provider.toLowerCase() === "textformatter"
          ? "addOnInner"
          : node.provider.toLowerCase() === "generator"
          ? "coupan"
          : node.provider.toLowerCase() === "conditioner"
          ? "conditions"
          : node.provider.toLowerCase() === "textsplitter"
          ? "textSplitter"
          : node.provider.toLowerCase() === "api"
          ? "apiInner"
          : node.appType.toLowerCase() === "webhook"
          ? "WebHookInner"
          : "NodeInner",
      width: 360,

      selected: false,
      dragging: false,
    };
    const appEventConfigurations = node.appEventConfigurations.map((con) => {
      const selectedTrigger = !node?.appEventConfiguration
        ? "Choose"
        : node.appEventConfigurations?.find(
            (x) => x.id === node.data.appEventConfiguration
          )?.service_name;
      let title = con.config_key_required
        ? `${selectedTrigger} ${con.label} *`
        : `${selectedTrigger} ${con.label}`;
      const configDetails = node.appEventConfigurationDetails
        .filter((config) => config.config_key === con.config_key)
        .map((conDetail) => {
          return {
            ...conDetail,
            label: conDetail.name,
            value: conDetail.id,
            sequence: con.sequence,
          };
        });
      return {
        ...con,
        title: title,
        config_details: configDetails,
        selected: con.selected && {
          ...con.selected,
          sequence: con.sequence,
          value: con.selected.id,
          label: con.selected.name,
        },
      };
    });

    const additionalResponses = node?.additionalResponses?.map((con) => {
      let uniqueId = nanoid();
      return {
        ...con,
        additional: true,
        checked: con.type === "leaf" ? true : false,
        port: {
          prev: con.port,
          id: uniqueId,
          source: uniqueId + "|source",
          target: idx === 0 ? null : uniqueId + "|target",
          nodeId: node.nodeId,
        },
        link_data: {
          source_node_id: [],
          source_field_port: [],
          target_node_id: [],
          target_field_port: [],
        },
      };
    });

    let configResponses = node.configResponses.map((con) => {
      let uniqueId = nanoid();
      if (node.appType === "WEBHOOK") {
        return {
          ...con,
          additional: true,
          checked: con.type === "leaf" ? true : false,
          port: {
            prev: con.port,
            id: uniqueId,
            source: uniqueId + "|source",
            target: idx === 0 ? null : uniqueId + "|target",
            nodeId: node.nodeId,
          },
          link_data: {
            source_node_id: [],
            source_field_port: [],
            target_node_id: [],
            target_field_port: [],
          },
        };
      } else
        return {
          ...con,
          checked: true,
          sequence: 0,
          port: {
            prev: con.port,
            id: uniqueId,
            source: uniqueId + "|source",
            target: idx === 0 ? null : uniqueId + "|target",
            nodeId: node.nodeId,
          },
          link_data: {
            source_node_id: [],
            source_field_port: [],
            target_node_id: [],
            target_field_port: [],
          },
        };
    });

    if (node?.additionalResponses?.length) {
      configResponses = configResponses.concat(...additionalResponses);
    }

    if (node.additionalResponses)
      nodeq.data.additionalResponses = node.additionalResponses.length;
    nodeq.data.configResponses = configResponses.map((x) => {
      if (nodeq.data.provider === "conditioner") {
        if (x.config_key === "conditions_setup") {
          return x;
        } else {
          return {
            ...x,
            additional: true,
          };
        }
      } else {
        return x;
      }
    });

    nodeq.data.appEventConfigurations = appEventConfigurations;
    updatedNodes.push(nodeq);
  });

  updatedEdges = links.map((edge) => {
    let uniqueId = nanoid();
    let foundSource, foundTarget;
    updatedNodes = updatedNodes.map((node) => {
      let foundS = node.data.configResponses.find(
        (config) =>
          config.port.prev && config.port.prev?.source.includes(edge.from.port)
      );

      let foundT = node.data.configResponses.find(
        (config) =>
          config.port.prev && config.port.prev?.target.includes(edge.to.port)
      );
      if (foundS) {
        foundSource = foundS;
        foundS.target_linked = true;
      }
      if (foundT) {
        foundTarget = foundT;
        foundT.source_linked = true;
      }
      return node;
    });
    return {
      prev: edge,
      source: edge.from.node,
      sourceHandle: foundSource.port.source,
      target: edge.to.node,
      targetHandle: foundTarget.port.target,
      id: uniqueId,
      direct_link: [],
      type: "buttonedge",
    };
  });
  let newEdges = [];
  updatedEdges.map((edge) => {
    newEdges = updatedEdges.map((ed) => {
      edge.targetHandle.split("|")[0] === ed.sourceHandle.split("|")[0] &&
        ed.direct_link.push(edge.id);
      return ed;
    });
    updatedNodes = updatedNodes.map((node) => {
      node.data.configResponses.map((config) => {
        if (edge.sourceHandle === config.port.source) {
          config.additional
            ? node.data.link_data.additional.push(edge.id)
            : node.data.link_data.normal.push(edge.id);
        }
      });
      return node;
    });
  });
  updatedEdges = newEdges;

  return { updatedEdges, updatedNodes };
};

export const jsonMakerfor2 = ({ nodes, edges }) => {
  // console.log("jjjjjjj")
  let newNodes = nodes.map((node, index) => {
    let configResponses = node.data?.configResponses
      // .concat(
      //   node.data.additionalResponses?.length
      //     ? node.data.additionalResponses?.map((c) => {
      //         if (c.items) {
      //           return;
      //         } else {

      //           return {
      //             ...c,
      //             additional: true,

      //           };
      //         }
      //       })
      //     : []
      // )
      .filter((con) => con !== undefined)
      .map((config, i) => {
        let existingLink = edges.find((link) => {
          const { target, source, sourceHandle, targetHandle } = link;
          if (
            (target === node.id && targetHandle === config.port.target) ||
            (source === node.id && source === config.port.source)
          ) {
            return link;
          }
        });
        const id = nanoid();
        const port = {
          id: id,
          nodeId: node.id,
          source: id + "|source",
        };
        if (index !== 0) {
          port.target = id + "|target";
        }

        if (existingLink) {
          let link_data = {
            source_node_id: config.source_node_id
              ? config.source_node_id.push(existingLink.source)
              : [existingLink.source],
            prev_source_field_port: existingLink,
            source_field_port: [],
            target_node_id: [],
            target_field_port: [],
          };
          return {
            ...config,
            linked: true,
            link_data: link_data,
            checked: true,
            additional:
              index === 0 && node.data.type.toLowerCase() === "webhook"
                ? true
                : config.additional === true
                ? true
                : null,
          };
        } else {
          if (config?.config_key_required) {
            return {
              ...config,
              checked: true,
              link_data: {
                source_node_id: [],
                source_field_port: [],
                target_node_id: [],
                target_field_port: [],
              },
            };
          } else if (config !== undefined) {
            return {
              ...config,
              additional:
                index === 0 && node.data.type.toLowerCase() === "webhook"
                  ? true
                  : config?.additional === true
                  ? true
                  : null,
              checked: true,
              link_data: {
                source_node_id: [],
                source_field_port: [],
                target_node_id: [],
                target_field_port: [],
              },
            };
          }
        }
      });
    const selectedTrigger = !node.data.appEventConfiguration
      ? "Choose"
      : configs?.find((x) => x.id === node.data.appEventConfiguration)
          ?.service_name;
    return {
      ...node,
      id: node.id,
      data: {
        ...node.data,
        link_data: {
          normal: node.data.configResponses
            .filter((config) => config.additional !== true)
            .filter(function (array_el) {
              return edges
                .map((edge) => {
                  return edge.sourceHandle;
                })
                .includes(array_el.port.source);
            })
            .map((con) => {
              return con.port.id;
            }),
          additional: node.data.configResponses
            .filter((config) => config.additional === true)
            .filter(function (array_el) {
              return edges
                .map((edge) => {
                  return edge.sourceHandle;
                })
                .includes(array_el.port.source);
            })
            .map((con) => {
              return con.port.id;
            }),
        },
        node_link: [],
        trigger_konnect_id: node.data.konnect_id,
        appEvents: node.data.appEvents?.map((e) => {
          return {
            ...e,
            value: e.id,
            label: e.name,
          };
        }),
        associatedAccounts: node.data.associatedAccounts,
        selectedEvent: node.data.selectedEvent
          ? {
              ...node.data.selectedEvent,
              label: node.data.selectedEvent.name,
              value: node.data.selectedEvent.id,
            }
          : null,
        additionalResponses: node.data.additionalResponses
          ? node.data.additionalResponses.length
          : null,
        selectedAccount: node.data.selectedAccount
          ? {
              ...node.data.selectedAccount,
              label: node.data.selectedAccount.name,
              value: node.data.selectedAccount.id,
            }
          : null,
        configResponses: configResponses,
        nodeIdx: index,
        loading: false,
        status: node.data.test_status,
        captureMessage: node.data.display_message,
        display_message: node.data.display_message,
        appEventConfigurations: node.data.appEventConfigurations
          ?.map((c, i) => {
            return {
              ...c,
              value: c.id,
              config_details: node.data.appEventConfigurationDetails
                .filter((x) => x.config_key === c.config_key)
                .map((det) => {
                  return {
                    ...det,
                    label: det.name,
                    value: det.id,
                    sequence: c.sequence,
                  };
                }),
            };
          })
          .map((x) => {
            let title = x.config_key_required
              ? `${selectedTrigger} ${x.label} *`
              : `${selectedTrigger} ${x.label}`;
            return {
              ...x,
              title: title,
              selected: x.selected
                ? {
                    ...x.selected,
                    label: x.selected.name,
                    value: x.selected.value,
                  }
                : null,
            };
          }),
        selectedEvent: node.data.appEvents
          ?.map((e) => {
            return {
              ...e,
              value: e.id,
              label: e.name,
            };
          })
          .find((event) => event.id === node.data.appEvent),
        appDetails: node.data.appDetails?.coupon?.duration?.length
          ? {
              coupon: {
                expiryNum: node.data.appDetails?.coupon.expiryNum,
                duration: node.data.appDetails?.coupon?.duration.map((dur) => {
                  return { ...dur, label: dur.name, value: dur.id };
                }),
              },
              textSplitter: {
                segmentIndex:
                  node.data.appDetails?.textSplitter.segmentIndex.map((txt) => {
                    return {
                      ...txt,
                      label: txt.name,
                      value: txt.id,
                    };
                  }),
              },
            }
          : null,
        selectedAddonsValue:
          node.data.provider.toLowerCase() === "generator"
            ? node.data.appDetails.coupon.duration
                .map((x) => {
                  return {
                    ...x,
                    label: x.name,
                    value: x.id,
                  };
                })
                .find((dur) => dur.selected === true)
            : node.data.provider.toLowerCase() === "textsplitter"
            ? node.data.appDetails.textSplitter.segmentIndex
                .map((x) => {
                  return {
                    ...x,
                    label: x.name,
                    value: x.id,
                  };
                })
                .find((dur) => dur.selected === true)
            : null,
      },
      dragHandle: "#drag",
    };
  });
  let newEdges = [];
  newEdges = edges.map((edge, i) => {
    let targetHandleToBePushed =
      edge.sourceHandle.split("|")[0].toString() + "|target";
    let arrayOfEdgesForDirectLink = edges
      .slice(0, i)
      .filter((edge) => edge.targetHandle === targetHandleToBePushed);
    const { parentNode, ...rest } = edge;
    return {
      ...rest,
      direct_link: arrayOfEdgesForDirectLink[0]?.direct_link
        ? arrayOfEdgesForDirectLink[0]?.direct_link.push(
            arrayOfEdgesForDirectLink[0]?.id
          )
        : arrayOfEdgesForDirectLink[0]?.id
        ? [arrayOfEdgesForDirectLink[0].id]
        : [],
    };
  });
  // console.log(newNodes,"newNodes")
  return { newNodes, newEdges };
};
