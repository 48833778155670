import { createSlice } from "@reduxjs/toolkit";
import { getWidgetDetails } from "./thunk/dashThunk";
const initialState = {
  activeRoute: "Dashboard",
  loading: true,
  promotionalTask: [
    { taskNo: 0, label: "Consumed Tasks" },
    { taskNo: 0, label: "Remained Tasks" },
    { taskNo: 0, label: "Consumed Tasks" },
    { taskNo: 0, label: "Remained Tasks" },
  ],
  totalKonnectzData: {},
  taskConsumptionData: {
    datasets: [
      {
        data: [230, 15977],
        backgroundColor: ["#518EF8", "#F7C544"],
        borderColor: ["#518EF8", "#F7C544"],
        borderWidth: 1,
      },
    ],
  },
  latestKonnectz: [],
  updates: [
    { title: "Welcome to the new Dashboard.", version: "2.1.1" },
    { title: "Stronger , Smarter , Sleeker", version: "2.1.0" },
  ],
  loadingState: [false, false, false],
};

export const dashSlice = createSlice({
  name: "dash",
  initialState,
  reducers: {
    setRoute: (state, action) => {
      state.activeRoute = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWidgetDetails.fulfilled, (state, action) => {
      if (action.meta.arg === "latest_konnectz") {
        state.latestKonnectz = action.payload.konnects;
        state.loadingState[0] = true;
      }
      if (action.meta.arg === "total_konnectz") {
        state.totalKonnectzData.active = action.payload.active_konnects;
        state.totalKonnectzData.inactive = action.payload.inactive_konnects;
        state.totalKonnectzData.totalKonnects = action.payload.total_konnects;
        state.loadingState[1] = true;
      }
      if (action.meta.arg === "task_consumption") {
        state.taskConsumptionData.datasets[0].data = [
          action.payload.task_consumed,
          action.payload.task_available,
        ];
        state.taskConsumptionData.task_limit = action.payload.task_limit;
        state.loadingState[2] = true;
      }
      if (state.loadingState.every((element) => element === true)) {
        state.loading = false;
      }
    });
  },
});

export const { setRoute, setLoading } = dashSlice.actions;

export default dashSlice.reducer;
