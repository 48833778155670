import {
  Flex,
  Button,
  Collapse,
  Box,
  useDisclosure,
  Icon,
  Tooltip,
  Input,
  Spinner,
  Image,
  Progress,
  InputGroup,
  InputLeftElement,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dashSVG, svg_1 } from '../../../assets/objectSVG';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  InfoIcon,
} from '@chakra-ui/icons';
import ReactSwitch from 'react-switch';
import { useDispatch } from 'react-redux';
import { setRoute } from '../../../store/dashSlice';
import { getWidgetDetails } from '../../../store/thunk/dashThunk';
import AuthTokenService from '../../../utils/AuthTokenService';
import { useTranslation } from 'react-i18next';

import { updateKonnect } from '../../../store/thunk/konnectzThunk.js';
import { setEditMode } from '../../../store/slice/canvasSlice.js';
export const LatestKonnectz = () => {
  const { loading, latestKonnectz } = useSelector((state) => state.dash);
  const [renderValue, setRerenderValue] = React.useState(0);
  const [deleteKonnect, setDeleteKonnect] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    dispatch(getWidgetDetails('latest_konnectz')).then((res) => {
      if (res.error) {
        dispatch(setRoute('Dashboard'));
        AuthTokenService.clear();
        navigate('/login');
      }
    });
  }, [renderValue]);
  useEffect(() => {
    localStorage.setItem('editKonnectId', JSON.stringify(id));
    if (id > 0) {
      navigate('/konnect/' + id);
    }
  }, [id]);

  return (
    <Flex
      sx={{
        bgColor: '#fff',
        width: '70%',
        borderRadius: '4px',
        boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
        p: '20px',
        gap: '20px',
        flexDir: 'column',
      }}
    >
      {' '}
      <Skeleton isLoaded={!loading}>
        <Flex justifyContent="space-between">
          {' '}
          <Flex
            sx={{
              fontSize: [16, 18, 18, 20, 22, 24],
              fontWeight: 500,
              gap: '10px',
            }}
          >
            {t('dashboard.widgets.latestKonnectz.headerTitle')}
            <Tooltip
              placement="right"
              label="Only 3 latest konnectz are shown. To see more, click on View All"
              hasArrow
            >
              <Flex sx={{ m: 'auto 0px' }}>{dashSVG.info}</Flex>
            </Tooltip>
          </Flex>
          <NavLink
            to={'/konnectz'}
            onClick={() => dispatch(setRoute('Konnectz'))}
            style={{ textDecoration: 'none' }}
          >
            <Flex
              sx={{
                gap: '5px',
                fontSize: [12, 12, 14, 14, 16, 16],
                '&:hover': {
                  fontWeight: 600,
                },
              }}
            >
              {t('dashboard.widgets.latestKonnectz.vieawAll')}
              <Flex
                height="1em"
                sx={{ transform: 'rotate(270deg)', m: 'auto 0px' }}
              >
                {svg_1.expand}
              </Flex>
            </Flex>
          </NavLink>
        </Flex>
      </Skeleton>
      <Flex height="100%" position="relative">
        <Skeleton isLoaded={!loading} height="100%" width="100%">
          <Flex
            flexDir="column"
            sx={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              overflowY: 'auto',
              p: '2px',
              gap: '15px',
            }}
          >
            {latestKonnectz.length ? (
              latestKonnectz.map((konnect, i) => {
                return (
                  <Flex
                    key={i}
                    width="100%"
                    sx={{
                      bgColor: '#f3f7ff',
                      borderRadius: '6px',
                      p: '10px',
                      alignItems: 'center',
                      boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
                      '&:hover': {
                        outline: '1px solid rgb(81,142,248, 1)',
                      },
                    }}
                  >
                    <Flex flexDir="column" gap="5px" width="80%">
                      <Flex sx={{ fontSize: [10, 10, 12, 14, 14, 15] }}>
                        {konnect.konnect_name ? (
                          konnect.konnect_name
                        ) : (
                          <Flex sx={{ flexDirection: 'row' }}>
                            {konnect.left_app.name}
                            {konnect.right_apps.map((x, i) => {
                              return (
                                <Flex key={i}>{' - ' + x.app_name + '  '}</Flex>
                              );
                            })}
                          </Flex>
                        )}
                      </Flex>
                      <Flex>
                        <Image width="6%" src={konnect.left_app.image_url} />
                        {konnect.right_apps.length > 0 && (
                          <Flex
                            sx={{
                              m: 'auto 0px',
                              fill: 'black',
                            }}
                          >
                            {svg_1.dashed_1}
                          </Flex>
                        )}
                        {konnect.right_apps.length > 0 &&
                          konnect.right_apps.map((rightApp, index) => {
                            return (
                              <React.Fragment key={i}>
                                {index !== 0 && (
                                  <Flex
                                    key={i}
                                    sx={{
                                      m: 'auto 0px',
                                      fill: 'black',
                                    }}
                                  >
                                    {svg_1.dashed_1}
                                  </Flex>
                                )}

                                <Image
                                  key={i}
                                  width="6%"
                                  src={rightApp.image_url}
                                />
                              </React.Fragment>
                            );
                          })}
                      </Flex>
                    </Flex>
                    {deleteKonnect === i && deleteKonnect >= 0 ? (
                      <>
                        {' '}
                        <Button
                          sx={{
                            borderTop: '1px solid #152D73',
                            borderRight: '1px solid #152D73',
                            borderLeft: '1px solid #152D73',
                            borderBottom: '3px solid #152D73',
                            fontSize: [14, 14, 14, 16, 16, 18],
                            borderRadius: '4px',
                            // bgColor: renameFolder ? "#f3f7ff" : "inherit",
                            color: '#152D73',
                            px: '30px',
                            gap: '10px',
                            py: [
                              '10px',
                              '15px',
                              '20px',
                              '20px',
                              '22px',
                              '25px',
                            ],

                            '&:hover': {
                              '& #icon': {
                                fill: 'rgb(21,46,115)',
                              },
                              color: 'rgb(21,46,115)',
                              bgColor: '#fff',
                              borderTop: '1px solid #152D73',
                              borderRight: '1px solid #152D73',
                              borderLeft: '1px solid #152D73',
                              borderBottom: '3px solid #152D73',
                            },
                          }}
                        >
                          <InfoIcon size="1.5em" />
                          Sure, you want to delete?
                          <Flex gap="15px">
                            <CheckIcon
                              sx={{
                                m: 'auto 0px',
                                '&:hover': {
                                  color: 'green',
                                  transform: 'scale(1.2)',
                                  transition: 'transform 0.15s',
                                },
                              }}
                              onClick={() => {
                                dispatch(
                                  updateKonnect({
                                    id: konnect.id,
                                    status: 'DELETED',
                                  })
                                ).then((res) => {
                                  if (res.payload.message === 'SUCCESS') {
                                    toast({
                                      position: 'top',
                                      status: 'success',
                                      variant: 'solid',
                                      title: 'Deleted',
                                      duration: 2500,
                                      containerStyle: {
                                        fontWeight: 400,
                                        deleteKonnect,
                                      },
                                    });
                                    setRerenderValue((i) => i + 1);
                                    setDeleteKonnect(null);
                                  } else
                                    toast({
                                      position: 'top',
                                      status: 'error',
                                      variant: 'solid',
                                      title: 'Cannot Delete this Konnect',
                                      duration: 2500,
                                      containerStyle: {
                                        fontWeight: 400,
                                      },
                                    });
                                });
                              }}
                            />
                            <CloseIcon
                              onClick={() => setDeleteKonnect(false)}
                              boxSize="1em"
                              sx={{
                                m: 'auto 0px',
                                '&:hover': {
                                  color: 'red',
                                  transform: 'scale(1.2)',
                                  transition: 'transform 0.15s',
                                },
                              }}
                            />
                          </Flex>
                        </Button>
                      </>
                    ) : (
                      <Flex
                        width="20%"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Tooltip
                          label={
                            konnect.status === 'ACTIVE'
                              ? 'Turn InActive'
                              : 'Turn Active'
                          }
                          hasArrow
                        >
                          <Flex>
                            <ReactSwitch
                              checked={
                                konnect.status === 'ACTIVE' ? true : false
                              }
                              onChange={() =>
                                dispatch(
                                  updateKonnect({
                                    id: konnect.id,
                                    status:
                                      konnect.status === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE',
                                  })
                                ).then((res) => {
                                  if (res.payload.message === 'FAILED') {
                                    toast({
                                      position: 'top',
                                      status: 'error',
                                      variant: 'solid',
                                      title:
                                        res.payload.message +
                                        ', status is Draft',
                                      duration: 2500,
                                      containerStyle: {
                                        fontWeight: 400,
                                      },
                                    });
                                  }
                                  if (
                                    res.payload.message === 'SUCCESS' &&
                                    konnect.status === 'INACTIVE'
                                  ) {
                                    toast({
                                      position: 'top',
                                      status: 'success',
                                      variant: 'solid',
                                      title:
                                        res.payload.message +
                                        ', status changed to ACTIVE',
                                      duration: 2500,
                                      containerStyle: {
                                        fontWeight: 400,
                                      },
                                    });
                                  }
                                  if (
                                    res.payload.message === 'SUCCESS' &&
                                    konnect.status === 'ACTIVE'
                                  ) {
                                    toast({
                                      position: 'top',
                                      status: 'success',
                                      variant: 'solid',
                                      title:
                                        res.payload.message +
                                        ', status changed to INACTIVE',
                                      duration: 2500,
                                      containerStyle: {
                                        fontWeight: 400,
                                      },
                                    });
                                  }
                                  if (res.payload.message !== 'FAILED') {
                                    setRerenderValue((c) => c + 1);
                                  }
                                })
                              }
                              height={20}
                              width={40}
                              handleDiameter={14}
                              offColor="#162c73"
                              onColor="#162c73"
                              onHandleColor="#f7c544"
                            />{' '}
                          </Flex>
                        </Tooltip>
                        <Flex gap="20px">
                          {' '}
                          <Tooltip label="Edit" hasArrow>
                            <EditIcon
                              onClick={() => setId(konnect.id)}
                              boxSize={{
                                base: '4',
                                sm: '4',
                                md: '5',
                                lg: '6',
                                xl: '6',
                                '2xl': '6',
                              }}
                              color="#162c73"
                              sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                  color: '#f7c544',
                                },
                              }}
                            />
                          </Tooltip>
                          <Tooltip label="Delete" hasArrow>
                            <DeleteIcon
                              onClick={() => setDeleteKonnect(i)}
                              boxSize={{
                                base: '4',
                                sm: '4',
                                md: '5',
                                lg: '6',
                                xl: '6',
                                '2xl': '6',
                              }}
                              color="#162c73"
                              sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                  color: '#ea1762',
                                },
                              }}
                            />
                          </Tooltip>
                        </Flex>{' '}
                      </Flex>
                    )}
                  </Flex>
                );
              })
            ) : (
              <Flex
                width="100%"
                sx={{
                  bgColor: '#f3f7ff',
                  borderRadius: '6px',
                  px: '10px',
                  py: ['12px', '14px', '14px', '16px', '18px', '20px'],
                  boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
                  '&:hover': {
                    outline: '1px solid rgb(81,142,248, 1)',
                  },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Flex sx={{ fontSize: [14, 14, 16, 16, 16, 18] }}>
                  Seems like you have no konnectz.
                </Flex>
                <NavLink to={'/konnect'} style={{ textDecoration: 'none' }}>
                  <Button
                    sx={{
                      borderTop: '1px solid #f7c544',
                      borderRight: '1px solid #f7c544',
                      borderLeft: '1px solid #f7c544',
                      borderBottom: '3px solid #f7c544',
                      fontSize: [16, 16, 18, 18, 18, 20],
                      borderRadius: '4px',
                      bgColor: 'inherit',
                      color: '#f7c544',
                      px: '30px',
                      gap: '5px',
                      py: ['10px', '15px', '20px', '20px', '22px', '25px'],
                      '&:hover': {
                        '& #icon': {
                          fill: 'rgb(21,46,115)',
                        },
                        bgColor: '#fff',
                        color: 'rgb(21,46,115)',
                        borderTop: '1px solid #152D73',
                        borderRight: '1px solid #152D73',
                        borderLeft: '1px solid #152D73',
                        borderBottom: '3px solid #152D73',
                      },
                    }}
                    onClick={() => {
                      dispatch(setEditMode({mode:false}))
                     
                    }}
                  >
                    <Flex
                      id="icon"
                      sx={{ fill: '#f7c544', transition: 'fill 0.15s' }}
                    >
                      {svg_1.createKonnect}
                    </Flex>
                    {t('header.createKonnectzBtnLabel')}
                  </Button>
                </NavLink>
              </Flex>
            )}
          </Flex>
        </Skeleton>
      </Flex>
    </Flex>
  );
};
