import React, { useRef, useState, useCallback } from "react";
import { CaptureButton, EventWrapper, NodeOuter } from "../../styled";
import { AppInfo } from "../ui/AppInfo";
import { Flex } from "@chakra-ui/react";
import { NodeLoader } from "../../loader";
import { useTranslation } from "react-i18next";
import { Selector } from "../ui/Selector";
import { ResponseContainer } from "../ui/ResponseContainer.js";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import {
  OnTestandReview,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk.js";
import { useSelector, useDispatch } from "react-redux";
import { onLinkDelete } from "../../../store/slice/canvasSlice";
export const TextSplitter = React.memo(({ data, selected }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeOuter = useRef();
  const { editorState } = useSelector((state) => state.canvas);
  const [expand, setExpand] = useState(false);
  const [expandHeight, setExpandHeight] = useState(false);
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  const nodeIdx = editorState.nodes.findIndex(
    (node) => node.id === data.nodeId
  );

  const helper = {
    onHelp: useCallback(() => {
      !data.loading && setModalState({ display: true, type: "help" });
    }, [data.loading]),
    onExpand: useCallback(() => {
      if (expand && modalState.type === "accounts") {
      } else !data.loading && setExpand((c) => !c);
    }, [data.loading, modalState]),
    additionalResult: useCallback(() => {
      setModalState({ display: true, type: "Normal" });
    }, [data.configResponses]),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    onTestandReview: useCallback(() => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      dispatch(OnTestandReview(data.nodeId)).then((res) => {
        dispatch(saveKonnect({ publish: false }));
      });
    }, [data]),
  };
  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      expandheight={expandHeight ? "true" : "false"}
      id="drag"
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalState={setModalState}
        nodeIdx={data.nodeIdx}
      />

      {data.type === "ADD_ON" ? (
        <>
          <EventWrapper visibility={true}>
            <Flex>{nodeIdx === 0 ? t("konnect.sidebar.choose_trigger") :t("konnect.sidebar.choose_action")}</Flex>
            <Selector
              parentnode={nodeOuter}
              options={data.appEvents}
              nodeId={data.nodeId}
              selectType={"Action"}
              value={data.selectedEvent ? data.selectedEvent : null}
              isAddOn="true"
            />
          </EventWrapper>

          <EventWrapper
            visibility={data.configResponses?.length ? true : false}
          >
            <Flex>{"Segment Index"}</Flex>
            <Selector
              parentnode={nodeOuter}
              options={data.appDetails.textSplitter.segmentIndex}
              nodeId={data.nodeId}
              value={data.selectedAddonsValue}
              selectType={"TextSplitter"}
            />
          </EventWrapper>
          <EventWrapper
            visibility={data.configResponses?.length ? true : false}
          >
            <ResponseContainer
              result={data.configResponses}
              parentnode={nodeOuter}
              nodeId={data.nodeId}
            />
          </EventWrapper>
          <EventWrapper
            visibility={data.configResponses?.length ? true : false}
          >
            <CaptureButton
            isDisabled={data.trigger_konnect_id?false :true}
            onClick={helper.onTestandReview}>
              {t("konnect.sidebar.scheduler.test_review")}
            </CaptureButton>
          </EventWrapper>
          <Divider visibility={data?.additionalResponses} />
          <EventWrapper visibility={data?.additionalResponses} last="true">
            <ResultContainer
              response={data.configResponses}
              additional="true"
            />
          </EventWrapper>
        </>
      ) : null}
    </NodeOuter>
  );
});
