import React, { useEffect, useState } from "react";
import { bigSVG } from "../../../assets/objectSVG";
import { useFormik } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Flex,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NodeLoader } from "../../../components/loader";
import forgotFeatures from "../../../components/carousel/features/forgot-features.json";
import { Carousel } from "../../../components/carousel";
import { userResetPassword } from "../../../store/thunk/authThunk";

export const ResetPasswordContainer = () => {
  const { t } = useTranslation();
  let { token } = useParams();
  const { loading } = useSelector((state) => state.auth);
  return (
    <>
      <Flex
        width="65%"
        sx={{
          p: "3% 0px 3% 3%",
          position: "relative",
          zIndex: 0,
        }}
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {forgotFeatures?.features.map((feature, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Carousel t={t} feature={feature} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
      <Flex
        width="35%"
        sx={{
          m: "3%",
          position: "relative",
          zIndex: 2,
          borderRadius: "8px",
          flexDirection: "column",
          gap: "10px",
          p: "3%",
          backgroundColor: "rgb(21,46,115)",
          boxShadow: "0px 0px 15px -1px rgb(21,46,115,0.3)",
        }}
      >
        <NodeLoader loading={loading} stroke="#fff" />
        <Flex sx={{ flexDir: "column", gap: "20px" }}>
          <Flex sx={{ flexDir: "column", gap: "5px" }}>
            <Flex
              sx={{
                color: "#fff",
                fontSize: [16, 17, 17, 18, 20],
                fontWeight: 500,
                justifyContent: "center",
              }}
            >
              {t("auth.signup.subtitle")}
            </Flex>
            <Flex
              height="2px"
              width="100%"
              sx={{ bgColor: "#fff", borderRadius: "50%" }}
            />{" "}
          </Flex>
          <Flex
            sx={{
              color: "#fff",
              fontSize: [20, 22, 24, 26, 28],
              fontWeight: 500,
              justifyContent: "center",
            }}
          >
            {t("auth.reset.title")}
          </Flex>
          <Flex sx={{ fill: "#fff", m: "0px auto 20px auto" }}>
            {bigSVG.createPassord}
          </Flex>
          <CreatePassword token={token} />
        </Flex>
      </Flex>
    </>
  );
};

const CreatePassword = ({ token }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    const payload = {
      reset_password_token: token,
      password: formik.values.password,
      password_confirmation: formik.values.confirmPassword,
    };
    if (payload.password === payload.password_confirmation) {
      dispatch(userResetPassword(payload)).then((res) => {
        if (res.payload.key === "PASSWORD_NOT_UPDATED") {
          toast({
            position: "top",
            status: "error",
            variant: "solid",
            title: res.payload.message,
            duration: 2500,
            containerStyle: {
              fontWeight: 400,
            },
          });
        } else if (res.payload.key === "PASSWORD_UPDATED") {
          toast({
            position: "top",
            status: "success",
            variant: "solid",
            title: res.payload.message,
            duration: 2500,
            containerStyle: {
              fontWeight: 400,
            },
          });
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      });
    } else {
      toast({
        position: "top",
        status: "error",
        variant: "solid",
        title: "Password do not match. Try again!",
        duration: 2500,
        containerStyle: {
          fontWeight: 400,
        },
      });
    }
  };
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <LockIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder={t("auth.login.passwordPlaceholder")}
              variant="outlined"
              required
              autoComplete="off"
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <InputRightElement
              onClick={() => setShowPassword((c) => !c)}
              sx={{ cursor: "pointer" }}
            >
              {showPassword ? (
                <ViewOffIcon color="rgb(21,45,115)" boxSize="1.2em" />
              ) : (
                <ViewIcon color="rgb(21,45,115)" boxSize="1.2em" />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <LockIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirm ? "text" : "password"}
              placeholder={t("account.confirmPasswordLabel")}
              variant="outlined"
              autoComplete="off"
              required
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
            />
            <InputRightElement
              onClick={() => setShowConfirm((c) => !c)}
              sx={{ cursor: "pointer" }}
            >
              {showConfirm ? (
                <ViewOffIcon color="rgb(21,45,115)" boxSize="1.2em" />
              ) : (
                <ViewIcon color="rgb(21,45,115)" boxSize="1.2em" />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex sx={{ color: "#fff", fontSize: [10, 11, 11, 12, 14] }}>
          {t("auth.signup.createPassword.passwordRule")}
        </Flex>
        <Button
          type="submit"
          width="full"
          variant="outline"
          size={{
            base: "md",
            sm: "md",
            md: "md",
            lg: "md",
            xl: "lg",
            "2xl": "lg",
          }}
          sx={{
            borderTop: "1px solid #f7c544",
            borderRight: "1px solid #f7c544",
            borderLeft: "1px solid #f7c544",
            borderBottom: "4px solid #f7c544",
            fontSize: [14, 15, 15, 16, 18],
            borderRadius: "4px",
            color: "#f7c544",
            "&:hover": {
              color: "rgb(21,46,115)",
              borderTop: "1px solid #fff",
              borderRight: "1px solid #fff",
              borderLeft: "1px solid #fff",
              borderBottom: "4px solid #fff",
            },
          }}
        >
          {t("auth.signup.createPassword.continueBtnLabel")}
        </Button>
      </form>
    </div>
  );
};
