import { createSlice } from "@reduxjs/toolkit";
import {
  editAuthLabelPost,
  getAddonApps,
  getApps,
  getOauthData,
  updateAppAccountStatus,
} from "./thunk/appsThunk";
import {
  getFoldersList,
  getKonnectsList,
  searchKonnectz,
} from "./thunk/konnectzThunk";

const initialState = {
  folderList: [],
  konnectzList: [],
  loading: true,
  loadingInPagination:false
};

export const konnectzSlice = createSlice({
  name: "konnectz",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFoldersList.fulfilled, (state, action) => {
      state.folderList = action.payload.folders;
      state.loading = false;
    });
    builder.addCase(getKonnectsList.pending, (state, action) => {
      
      state.loadingInPagination = true;
    });
    builder.addCase(getKonnectsList.fulfilled, (state, action) => {
      state.konnectzList = action.payload.konnects;
      state.loadingInPagination = false;
    });
    builder.addCase(getKonnectsList.rejected, (state, action) => {
      
      state.loadingInPagination = false;
    });
  },
});

export const {} = konnectzSlice.actions;

export default konnectzSlice.reducer;
