import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const german = {
  translation: {
    common: {
      app: 'Konnectz',
    },
    header: {
      topNavigations: {
        tipsAndTricks: 'Tipps und Tricks',
        pricing: 'Preisgestaltung',
        blogs: 'Blogs',
        helpCenter: 'Hilfezentrum',
      },
      searchAppTextPlaceholder: 'Hier suchen...',
      createKonnectzBtnLabel: 'Konnectz erstellen',
      taskhistory: 'Aufgabenverlauf',
      success: 'Erfolg',
      error: 'Fehler',
      notask: 'Keine Aufgabe gefunden',
      tryagain: 'Passen Sie Ihren Filter an und versuchen Sie es erneut',
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        'Passwort zurücksetzen Anweisungen wurden per E-Mail gesendet. Bitte überprüfen Sie Ihre E-Mail.',
      SENT_RESET_INSTRUCTIONS_FAIL: 'Nicht autorisierter Zugriff!',
      VERIFY_EMAIL_FAIL:
        'Entschuldigung, die Bestätigung Ihrer E-Mail war nicht möglich.',
      PASSWORD_NOT_UPDATED:
        'Entschuldigung, Ihr Konto konnte nicht aktualisiert werden.',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL:
        'Das Senden der Passwortanweisungen ist fehlgeschlagen. Bitte versuchen Sie es später erneut!',
      AUTH_SIGN_UP_FAIL:
        'Etwas ist schiefgegangen. Bitte versuchen Sie es später erneut!',
      somethingWentWrong:
        'Etwas ist schiefgegangen. Bitte versuchen Sie es später erneut!',
    },
    successMessages: {
      PASSWORD_UPDATED:
        'Das Passwort wurde aktualisiert. Bitte melden Sie sich mit Ihrem neuen Passwort an!',
    },
    history: {
      task: {
        task: 'Aufgabe',
        tasks: 'Aufgaben',
        taskdate: 'Aufgabendatum: ',
        user: 'Benutzer: ',
        search_history: 'In Verlauf suchen',
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: 'Passwort erstellen',
          subtitle: 'Geben Sie sie ZWEIMAL ein, um zu bestätigen',
          passwordErrorMsg: 'Ungültiges Passwort!',
          confirmPasswordErrorMsg: 'Ungültiges Passwort!',
          passwordMatchingErrorMsg: 'Beide Passwörter stimmen nicht überein!',
          continueBtnLabel: 'WEITER',
          createPasswordPlaceholder: 'Passwort erstellen',
          confirmPasswordPlaceholder: 'Passwort bestätigen',
          passwordRule:
            'Hinweis: Ihr Passwort muss zwischen 8 und 15 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.',
          passwordRule1:
            'Ihr Passwort muss zwischen 8 und 15 Zeichen lang sein.',
          passwordRule2:
            'Ihr Passwort muss mindestens einen Großbuchstaben enthalten.',
          passwordRule3: 'Ihr Passwort muss mindestens eine Ziffer enthalten.',
          passwordRule4:
            'Ihr Passwort muss mindestens ein Sonderzeichen enthalten.',
        },
        verificationSent: {
          title: 'BESTÄTIGUNG',
          subtitle: 'E-Mail-Adresse bestätigen',
          verificationText1: 'Wir haben einen Bestätigungslink an ',
          verificationText2:
            ' gesendet. Öffnen Sie die E-Mail und bestätigen Sie Ihre E-Mail-Adresse.',
          didntReceiveEmailText: 'Keine E-Mail erhalten? ',
          resendText: 'Erneut senden',
        },
        verifySuccess: {
          title: 'Bestätigung abgeschlossen',
          verifyFailedTitle: 'Bestätigung fehlgeschlagen',
        },
        signupSuccess: {
          firstName: 'Vorname',
          firstNameErrorMsg: 'Vorname ist erforderlich!',
          lastName: 'Nachname',
          lastNameErrorMsg: 'Nachname ist erforderlich!',
          verifyErrorMsg:
            'Etwas ist schiefgegangen. Bitte melden Sie sich an, wenn Ihr Konto bereits bestätigt wurde.',
        },
        title: 'Erstellen Sie Ihr Konto',
        subtitle: 'Hey, Willkommen',
        verifyingMessage: 'Wir bestätigen Sie',
        emailInvalid: 'Die E-Mail ist ungültig!',
        emailExists: 'Die eingegebene E-Mail-Adresse ist bereits registriert',
        teamSignup: 'Melden Sie sich als Team an?',
        continueBtnLabel: 'Weiter',
        alreadyHaveAccountText: 'Haben Sie bereits ein Konto? ',
        loginBtnLabel: 'Anmelden',
        emailPlaceholder: 'E-Mail eingeben',
        features: {
          feature1: {
            title: 'Einfache Automatisierung',
            image: SimpleAutomation,
            description:
              'Erstellen Sie komplexe Workflows in Minuten mithilfe unseres visuellen Connect Builders.',
          },
          feature2: {
            title: 'Plattform für jedermann',
            image: PlatformForEveryone,
            description:
              'Kein gewöhnliches Automatisierungstool nur für Programmierer, jeder kann mit KonnectzIT Automatisierungen erstellen.',
          },
          feature3: {
            title: 'Nahtlose App-Integration',
            image: SeamlessAppsIntegration,
            description:
              'Verbinden Sie unbegrenzte Anwendungen aus unserer Integrationsbibliothek und automatisieren Sie tägliche Aufgaben.',
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          'Etwas ist schiefgegangen. Bitte melden Sie sich an, wenn Ihr Konto bereits bestätigt wurde',
      },
      login: {
        title: 'Melden Sie sich in Ihrem Konto an',
        subtitle: 'Hey, Willkommen zurück!',
        emailPlaceholder: 'E-Mail eingeben',
        emailInvalid: 'Ungültige E-Mail!',
        passwordRequired: 'Passwort ist erforderlich!',
        passwordPlaceholder: 'Passwort eingeben',
        rememberMe: 'Angemeldet bleiben',
        loginBtnLabel: 'JETZT ANMELDEN',
        forFirstTimeSignup: 'Zum ersten Mal? ',
        forgotPasswordLbl: 'Passwort vergessen',
        alreadyHaveAccountText: 'Sie haben noch kein Konto? ',
        clickingContinueText: 'Durch Klicken auf Weiter stimmen Sie unseren ',
        termsAndConditionsText: 'Geschäftsbedingungen zu.',
        signupNowText: 'Jetzt registrieren',
        features: {
          feature1: {
            title: 'Unbegrenzte Schritte',
            image: UnlimitedSteps,
            description:
              'Verbinden Sie mehrere Anwendungen und erstellen Sie unbegrenzte Schritte für nahtlose Automatisierung.',
          },
          feature2: {
            title: 'Visualisieren Sie alles',
            image: VisualizeEverything,
            description:
              'Erstellen Sie Ihre Workflows und visualisieren Sie die Automatisierung mithilfe eines visuellen Connect Builders.',
          },
          feature3: {
            title: 'Integrationsbibliothek',
            image: IntegrationLibrary,
            description:
              'Sammlung von Cloud-Anwendungen zum Zusammenführen und Senden von Daten zwischen ihnen, um Zeit zu sparen.',
          },
        },
        loginFailMsg: 'Benutzername oder Passwort ungültig!',
        captchaErrorMsg: 'Captcha-Fehler',
      },
      forgot: {
        title: 'Passwort vergessen',
        forgtoBtnLabel: 'Link zum Zurücksetzen des Passworts abrufen',
        passwordLink:
          'Wir haben den Link zum Zurücksetzen des Passworts gesendet an',
        successSubTitle: 'E-Mail gesendet',
        features: {
          feature1: {
            title: 'Einfache Automatisierung',
            image: SimpleAutomation,
            description:
              'Erstellen Sie komplexe Workflows in Minuten mithilfe unseres visuellen Connect Builders.',
          },
          feature2: {
            title: 'Plattform für jedermann',
            image: PlatformForEveryone,
            description:
              'Kein gewöhnliches Automatisierungstool nur für Programmierer, jeder kann mit KonnectzIT Automatisierungen erstellen.',
          },
          feature3: {
            title: 'Nahtlose App-Integration',
            image: SeamlessAppsIntegration,
            description:
              'Verbinden Sie unbegrenzte Anwendungen aus unserer Integrationsbibliothek und automatisieren Sie tägliche Aufgaben.',
          },
        },
        emailInvalid: 'Ungültige E-Mail!',
      },
      reset: {
        title: 'Passwort zurücksetzen',
        subTitle: 'Neues Passwort erstellen',
      },
    },

    konnect: {
      sidebar: {
        search_apps: 'In Anwendung suchen',
        search_apps_endList: 'Yay! Du hast alles gesehen',
        add_on_apps: 'Add-On Anwendungen',
        integrated_apps: 'Integrierte Anwendungen',
        choose_trigger: 'Auslöser auswählen',
        choose_action: 'Wähle die Aktion',
        textFormater: {
          formaterText: "Gib '@' ein und wähle die Werte",
        },
        scheduler: {
          userTimeInput: 'Gib den Wert in Minuten, Stunden oder Tagen ein',
          selectDate: 'Datum auswählen',
          select: 'Auswählen',
          test_review: 'Testen & Überprüfen',
          time: 'Zeit',
          dates: 'Termine',
          date: 'Datum',
          days: 'Tage',
          everyday_time: 'Tägliche Zeit',
        },
        responseModal: {
          searchByLable: 'Nach Label suchen',
          no_labelFound: 'Kein Label gefunden',
          nodeInner_title: 'Weitere Werte auswählen',
          labelText: 'Label:',
        },
        coupon_generator: {
          numberPlaceholder: 'Nummer',
        },
        choose_trigger_header: 'App-Ereignis auswählen',
        no_triggers_available: 'Keine verfügbaren Auslöser',
        choose_format_header: 'Formatoption auswählen',
        choose_config_header: 'Konfiguration auswählen',
        select_trigger_action_dropdown_header: 'Auswählen',
        choose_option_header: 'Option definieren',
        no_options_available: 'Keine verfügbaren Optionen',
        choose_linked_account: 'Konto auswählen',
        customize_form_header: 'Formular anpassen',
        customize_form_partial: 'Auswählen',
        configure_response: 'Antwort anpassen',
        basic_auth: 'Grundlegende Authentifizierung',
        api_key: 'API-Schlüssel',
        api_token: 'API-Secret',
        webhook_url: 'Webhook-URL',
        captureWebhookResponse: 'Webhook-Antwort erfassen',
        hereText: 'Hier',
        webhook_instructions: 'Webhook-Anweisungen - Klicken',
        webhook_url_toast: 'Webhook-URL in die Zwischenablage kopiert!',
        webhook_response: 'Webhook-Antwort',
        choose_action_event: 'Aktion auswählen',
        endpoint_url: 'Endpunkt-URL',
        payload_type: 'Payload-Typ',
        wrap_request_array: 'Anfrage in Array einbetten',
        set_params: 'Parameter festlegen',
        add_header: 'Header hinzufügen',
        delayLabel: 'Verzögerung',
        delayText: 'Wie viel Verzögerung möchtest du?',
        set_paramter: 'Parameter festlegen',
        expiry_time: 'Zeitintervall zählen',
        expiry_val: 'Zeitintervall',
        segmentIndex: 'Segmentindex',
        validate: 'Validieren',
        generate: 'Generieren',
      },
      tobar: {
        cancelBtnLabel: 'Abbrechen',
        inputLeftElm_placeholder:
          'In über 1000 integrierten Anwendungen suchen oder Add-On Anwendungen',
        redoBtnLabel: 'WIEDERHOLEN',
        undoBtnLabel: 'RÜCKGÄNGIG',
      },
      UI: {
        betaText: 'Beta',
        webhookDuplicateToastMsg: 'Webhook-Anwendung existiert bereits!',
      },
      header: {
        backBtnLabel: 'Zurück',
        saveBtnLabel: 'Speichern',
        publishBtnLabel: 'Veröffentlichen',
        nameOfKonnectz: 'Name des',
      },
      testAndReview: {
        btnLabel: 'Testen & Überprüfen',
        taskAvailableLabel: 'Verfügbare Aufgabe',
        taskConsumbedLabel: 'Verbrauchte Aufgabe',
        taskCnsumptionLabel: 'Aufgabenverbrauch',
      },
      proceed: {
        btnLabel: 'WEITER',
      },
      additionalConfigsModal: {
        cancelBtnLabel: 'Abbrechen',
        okBtnLabel: 'OK',
      },
      conditions: {
        conditionText: 'Bedingung',
        addNewConditionBtnLabel: 'Neue Bedingung hinzufügen +',
        cancelBtnLabel: 'Abbrechen',
        okBtnLabel: 'OK',
        addBtnLabel: 'Hinzufügen',
        operatorAndLabel: 'UND',
        operatorOrLabel: 'ODER',
        headerConditionText: 'Bedingung',
        selectFieldHeaderTitle: 'Feld auswählen',
        selectConditionHeaderTitle: 'Bedingung auswählen',
        validateBtnLabel: 'Validieren',
        conditionsHeaderText: 'Bedingungen',
      },
      publishSuccessMsg: 'Konnect wurde erfolgreich veröffentlicht!',
      saveSuccessMsg: 'Konnect wurde erfolgreich gespeichert!',
    },
    apps: {
      authenticatedAppsTitle: 'Authentifizierte Anwendungen',
      aunthenticateNowTitle: 'Jetzt Authentifizieren',
      noAppsToAunthenticate:
        'Keine Anwendungen zur Authentifizierung im Moment.',
      authenticateNow:
        'Du hast keine authentifizierten Anwendungen. Authentifiziere dich jetzt.',
      addAccountText: 'Konto hinzufügen',
      betaText: 'Beta',
      integratedApps: 'INTEGRIERTE ANWENDUNGEN',
      followInstrusctionText:
        'Bitte befolge die Anweisungen für weitere Informationen',
      oAuthHandlerText: 'KonnectzIT OAuth-Handler, bitte warten...',
      cancelBtnText: 'Abbrechen',
      newsLable: 'Neuigkeiten',

      yesContinueText: 'Ja, fortfahren',
      requiredText: '(Erforderlich)',
      accessAPIModalHeaderText1: 'Erlaube KonnectzIT den Zugriff auf das ',
      accessAPIModalHeaderText2: ' Konto?',
      accessAPIModalMainText1: ' ',

      accessAPIModalMainText2: ' ist verfügbar auf der',
      accessAPIModalMainText3: ' Integrationsseite',
      authorized: 'Autorisierte Anwendungen',
    },
    konnectzList: {
      homeText: 'Startseite',
      allText: 'Alle',
      searchText: 'Suche',
      createkonnectzLabel: 'Erstellen',
      noKonnectzInfo: 'Keine Konnectz im Moment!',
      deleteModalBodyText: 'Bist du sicher, dass du löschen möchtest?',
      deleteModalNoText: 'Nein',
      deleteModalDeleteText: 'Löschen',
      folders: {
        createFolderTitleText: 'Ordnername',
        enterFolderName: 'Ordnername eingeben',
        fodersLeftTitleTextL: 'Ordner',
        createFolderInputPlaceholder: 'Gib den Ordnernamen ein',
        createFolderBtn: 'Erstellen',
        nameTitle: 'Name',
        noKonnectz: 'Es scheint, dass du keine hast',
        deleteFolderConfirmationText:
          'Bist du sicher, dass du diesen Ordner löschen möchtest?',
        cancelBtn: 'Abbrechen',
        newFolder: 'Neuer Ordner',
        deleteBtn: 'Löschen',
        selectFolderInfo:
          'Wähle den Ordner aus, zu dem du die Datei verschieben möchtest',
        moveFolderCancelBtn: 'Abbrechen',
        moveBtn: 'Ordner verschieben',
        renameSaveBtn: 'Ordner umbenennen',
        renameFolder: 'Umbenennen',
        renameYourFolderText: 'Benenne deinen Ordner um',
        createFolderBtn: 'Ordner erstellen',
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: 'Neueste Anwendungen',
        },
        totalKonnectz: {
          headerTitle: 'Gesamtanzahl Konnectz',
          activeKonnectz: 'Aktive Konnectz',
          inactiveKonnectz: 'Inaktive Konnectz',
        },
        latestKonnectz: {
          headerTitle: 'Neueste Konnectz',
          vieawAll: 'Alle anzeigen',
        },
        subscription: {
          subscriptionTitle: 'Abonnement',
          contentText:
            'Schalte mehr Leistung frei, indem du auf Pro aktualisierst',
          applyNewCoupon: 'Neuen Gutschein anwenden',
          applyBtnLabel: 'Anwenden',
          featuresIncludedLabel: 'Enthaltene Funktionen',
          upgradeBtnLabel: 'JETZT AKTUALISIEREN',
          unlockMoreUpgradingText:
            'Schalte mehr Leistung frei, indem du auf Pro aktualisierst',
        },
        taskConsumption: {
          headerTitle: 'Aufgabenverbrauch',
        },
      },
    },
    account: {
      myProfileText: 'Mein Profil',
      billingtext: 'Rechnungsadresse',
      emailAddressLabel: 'E-Mail-Adresse',
      firstNameLabel: 'Vorname',
      lastNameLabel: 'Nachname',
      subAccountsLabel: 'Unterkonten',
      firstNameRequiredText: 'Der Vorname ist erforderlich!',
      lastNameRequiredText: 'Der Nachname ist erforderlich!',
      countryRequiredText: 'Das Land ist erforderlich!',
      addressLine1RequiredText: 'Adresszeile 1 ist erforderlich!',
      cityRequiredText: 'Die Stadt ist erforderlich!',
      stateRequiredText: 'Der Bundesstaat ist erforderlich!',
      zipCodeRequiredText: 'Die Postleitzahl ist erforderlich!',
      taxIdRequiredText: 'Die Steuer-ID ist erforderlich!',
      companyNameLabel: 'Firmenname',
      currentPositionLabel: 'Aktuelle Position',
      countryLabel: 'Land',
      addressLabel: 'Adresse',
      cityLabel: 'Stadt',
      stateLabel: 'Bundesstaat',
      zipCodeLabel: 'Postleitzahl',
      taxIdLabel: 'Steuer-ID',
      timeZoneLabel: 'Zeitzone',
      changeLanguageLabel: 'Sprache wählen',
      changePasswordHeaderText: 'Passwort ändern',
      currentPasswordLabel: 'Aktuelles Passwort',
      currentPasswordRequiredText: 'Das aktuelle Passwort ist erforderlich!',
      newPasswordLabel: 'Neues Passwort',
      newPasswordRequiredText: 'Das neue Passwort ist erforderlich!',
      confirmPasswordLabel: 'Passwort bestätigen',
      confirmPasswordRequiredText: 'Das Passwort muss bestätigt werden!',
      updateBtnLabel: 'Aktualisieren',
      editUpdateAccountSideBarText: 'Konto bearbeiten/aktualisieren',
      changePasswordSideBarText: 'Passwort ändern',
      billingText: 'Abrechnung',
      teamText: 'Team',
      agencyText: 'Agentur',
      sideBarHeaderAccountText: 'Konto',
      profileLable: 'Profil',
      profileUpdatedSuccessfullyText: 'Profil erfolgreich aktualisiert!',
      addTeamMemberText: 'Teammitglied hinzufügen',
      addSubAccountsText: 'Unterkonten hinzufügen',

      billing: {
        couponCodeText: 'Code',
        applyCodeBtnLbl: 'Code anwenden',
        tasksText: 'Aufgaben',
        promotionalTasksText: 'Zusätzliche Aufgaben',
        redeemedOnText: 'Eingelöst am',
        expiresOnText: 'Läuft ab am',
        noCouponsAppliedText: 'Keine Lizenz angewendet',
        plansAndFeaturesText: 'Pläne und Funktionen',
        currentPlanText: 'Aktueller Plan',
        upgradeText: 'Aktualisieren',
        billingText: 'Abrechnung',
        licenseerror: 'Ungültige Lizenz!',
        completionofprofile:
          'Bitte vervollständige dein Profil, bevor du den Code einlöst. Dies ist für die DSGVO/Datenverarbeitung & Abrechnungszwecke erforderlich',
      },
      team: {
        teamLabel: 'Team',
        name: 'Name',
        firstName: 'Vorname',
        lastName: 'Nachname',
        access: 'Zugang',
        email: 'E-Mail',
        addBtn: 'Hinzufügen',
        cancelBtn: 'Abbrechen',
        assignAccessLbl: 'Zugang zuweisen',
        noUsersInfo: 'Keine Benutzer verfügbar',
        collapseTeamLabel: 'Team reduzieren',
        expandTeamLabel: 'Team erweitern',
        teamMembersHeaderText: 'Teammitglieder',
      },
      agency: {
        agencyLabel: 'Agentur',
        name: 'Kundenname :',
        firstName: 'Vorname',
        lastName: 'Nachname',
        tasks: 'Zugewiesene Aufgabe :',
        consumed: 'Verbrauchte Aufgabe',
        agencyConsumed: 'Verbraucht',
        remainingLabel: 'Verbleibend',
        totalSubaccounts: 'Gesamtzahl der Unterkonten :',
        email: 'E-Mail :',
        active: 'Aktiv :',
        inActive: 'Inaktiv :',
        addBtn: 'Hinzufügen',
        addMember: 'Mitglied hinzufügen',
        deleteMember: 'Mitglied löschen',
        cancelBtn: 'Abbrechen',
        editMemberLabel: 'Mitglied bearbeiten',
        noAgenciesInfo: 'Keine Agenturen verfügbar',
        agencyMembersHeaderText: 'Unterkonten',
        total: 'Gesamtaufgaben :',
        update: 'Aktualisieren',
        saveChangeLabel: 'Änderungen speichern',
        updateTask: 'Aufgabe aktualisieren',
        delete: 'Löschen',
        available: 'Verfügbare Aufgabe',
        deactivate: 'Aktivieren',
        totalAssigned: 'Insgesamt zugewiesen',
        activate: 'Deaktivieren',
        detailsLabel: 'Details',
        subaccounterror: 'Fehler beim Aktualisieren der Unterkontodetails!',
      },
      reseller: {
        resellerLabel: 'Wiederverkäufer',
      },
    },
    sideNav: {
      homeLabel: 'Startseite',
      appsLabel: 'Anwendungen',
      konnectzLabel: 'Konnectz',
      historyLabel: 'Verlauf',
      historyCapsLabel: 'Verlauf',
      accountLabel: 'Konto',
      notificationsLabel: 'Benachrichtigungen',
      logoutLabel: 'Abmelden',
      getHelpLabel: 'Hilfe erhalten?',
      helpModalText: 'Bleib dran. Wir kommen bald!',
      konnectzITLogoText: 'KonnectzIT',
    },
  },
};
