import React, { useState, useEffect } from 'react';
import { svg } from '../../../assets/objectSVG';
import { useFormik } from 'formik';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {
  Flex,
  Button,
  Checkbox,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthTokenService from '../../../utils/AuthTokenService';
import { NodeLoader } from '../../../components/loader';
import loginFeatures from '../../../components/carousel/features/login-features.json';
import { Carousel } from '../../../components/carousel';
import { login } from '../../../store/thunk/authThunk';
import { setAccountEmail, setRoute } from '../../../store/dashSlice';

export const LoginContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (AuthTokenService.get()) {
      dispatch(setRoute('Dashboard'));
      navigate('/dashboard');
    } else navigate('/login');
  }, []);
  return (
    <>
      <Flex
        width="65%"
        sx={{
          p: '3% 0px 3% 3%',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {loginFeatures?.features.map((feature, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Carousel t={t} feature={feature} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
      <Flex
        width="35%"
        sx={{
          m: '3%',
          position: 'relative',
          zIndex: 2,
          borderRadius: '8px',
          flexDirection: 'column',
          gap: '10px',
          p: '3%',
          backgroundColor: 'rgb(21,46,115)',
          boxShadow: '0px 0px 15px -1px rgb(21,46,115,0.3)',
        }}
      >
        <NodeLoader loading={loading} stroke="#fff" />
        <Flex sx={{ m: '0px auto', gap: '5px' }}>
          <Flex
            sx={{
              m: '0px auto',
              width: ['20px', '22px', '24px', '26px', '26px'],
            }}
          >
            {svg.invertedLogo}
          </Flex>
          <Flex
            sx={{
              color: '#fff',
              fontSize: [18, 20, 22, 24, 26],
              fontWeight: 500,
            }}
          >
            KonnectzIT
          </Flex>
        </Flex>
        <Flex
          sx={{
            m: '0px auto 15% auto',
            fontSize: [39, 44, 51, 55, 59],
            fontWeight: 500,
            color: '#fff',
          }}
        >
          Sign In
        </Flex>
        <LoginForm loading={loading} />
        <Flex
          sx={{
            m: '10% auto 0px auto',
            flexDirection: 'column',
            gap: '20px',
            textAlign: 'center',
            fontSize: [14, 15, 15, 16, 18],
          }}
        >
          <NavLink
            to={'/forgot-password'}
            style={{
              textDecoration: 'none',
              paddingLeft: '5px',
            }}
          >
            <Flex
              sx={{
                color: '#fff',
                justifyContent: 'center',
                '&:hover': { color: '#f7c544' },
                transition: 'color 0.15s',
              }}
            >
              {t('auth.login.forgotPasswordLbl')}
            </Flex>
          </NavLink>
          <Flex
            sx={{
              color: '#fff',
              m: '0px auto',
              fontSize: [14, 15, 15, 16, 18],
            }}
          >
            {' '}
            {t('auth.login.alreadyHaveAccountText')}
            <NavLink
              to={'/signup'}
              style={{
                textDecoration: 'none',
                color: '#f7c544',
                paddingLeft: '5px',
              }}
            >
              {t('auth.login.signupNowText')}
            </NavLink>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const LoginForm = ({ loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const [captcha, setCaptcha] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  function displayError(e) {
    toast({
      position: 'top',
      status: 'error',
      variant: 'solid',
      title: t('auth.login.captchaErrorMsg'),
      duration: 2500,
      containerStyle: {
        fontWeight: 400,
      },
    });
  }
  const handleSubmit = async () => {
    const payload = {
      email: formik.values.email,
      password: formik.values.password,
      rememberMe: formik.values.rememberMe,
    };
    dispatch(login(payload)).then((res) => {
      if (!res.error) {
        AuthTokenService.storeToken({
          token: res.payload.auth_token,
          keepMeLoggedIn: payload.rememberMe,
          lang: res.payload.preferred_language,
          email: payload.email,
        });
        i18n.changeLanguage(res.payload.preferred_language);
        navigate('/dashboard');
      } else {
        toast({
          position: 'top',
          status: 'error',
          variant: 'solid',
          title: res?.payload?.message,
          duration: 2500,
          containerStyle: {
            fontWeight: 400,
          },
        });
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <form
        onSubmit={
          !loading
            ? captcha
              ? formik.handleSubmit
              : displayError
            : console.log('Resting')
        }
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <FormControl>
          <InputGroup
            size={{
              base: 'md',
              sm: 'md',
              md: 'md',
              lg: 'md',
              xl: 'lg',
              '2xl': 'lg',
            }}
          >
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="email"
              name="email"
              type="email"
              required
              placeholder={t('auth.signup.emailPlaceholder')}
              variant="outlined"
              sx={{ borderRadius: '4px', fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <InputGroup
            size={{
              base: 'md',
              sm: 'md',
              md: 'md',
              lg: 'md',
              xl: 'lg',
              '2xl': 'lg',
            }}
          >
            <InputLeftElement pointerEvents="none">
              <LockIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('auth.login.passwordPlaceholder')}
              required
              variant="outlined"
              sx={{ borderRadius: '4px', fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <InputRightElement
              onClick={() => setShowPassword((c) => !c)}
              sx={{ cursor: 'pointer' }}
            >
              {showPassword ? (
                <ViewOffIcon color="rgb(21,45,115)" boxSize="1.2em" />
              ) : (
                <ViewIcon color="rgb(21,45,115)" boxSize="1.2em" />
              )}
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex sx={{ width: '100%' }}>
          <ReCAPTCHA
            style={{
              display: 'flex',
              width: '100%',
              boxSizing: 'none !important',
            }}
            sitekey={process.env.GOOGLE_CAPTCHA_SITE_KEY}
            onChange={(value) => {
              setCaptcha(value ? true : false);
            }}
          />
        </Flex>
        <Checkbox
          id="rememberMe"
          name="rememberMe"
          size={{
            base: 'md',
            sm: 'md',
            md: 'md',
            lg: 'md',
            xl: 'lg',
            '2xl': 'lg',
          }}
          onChange={formik.handleChange}
          isChecked={formik.values.rememberMe}
          sx={{ color: '#fff', fontSize: [14, 15, 15, 16, 18] }}
          colorScheme="#fff"
        >
          {t('auth.login.rememberMe')}
        </Checkbox>
        <Button
          type="submit"
          width="full"
          variant="outline"
          size={{
            base: 'md',
            sm: 'md',
            md: 'md',
            lg: 'md',
            xl: 'lg',
            '2xl': 'lg',
          }}
          sx={{
            borderTop: '1px solid #f7c544',
            borderRight: '1px solid #f7c544',
            borderLeft: '1px solid #f7c544',
            borderBottom: '4px solid #f7c544',
            fontSize: [14, 15, 15, 16, 18],
            borderRadius: '4px',
            color: '#f7c544',
            '&:hover': {
              color: 'rgb(21,46,115)',
              borderTop: '1px solid #fff',
              borderRight: '1px solid #fff',
              borderLeft: '1px solid #fff',
              borderBottom: '4px solid #fff',
            },
          }}
        >
          {t('auth.login.loginBtnLabel')}
        </Button>
      </form>
    </div>
  );
};
