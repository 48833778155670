import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { MentionsInput, Mention } from "react-mentions"
import "./style.css"
import { onToggleDrag } from "../../store/slice/canvasSlice"
import { useDispatch } from "react-redux"


export const MentionInput = ({ data = [], value, text, onChange, nodes, componentData, }) => {
const [val, setVal] = useState(value)
const dispatch = useDispatch()

  const handleTextChange = e => {
    onChange(e)

    setVal(e.target.value)
    
  }

  const stopEventPropagation = e => {
    dispatch(onToggleDrag({ ...componentData, focused: "nodrag" }))
    e.stopPropagation()
  }

  const { t } = useTranslation()

  return (
    <MentionsInput
      className="mentions"
      singleLine={false}
      allowSuggestionsAboveCursor={false}
      value={val}
      onFocus={stopEventPropagation}
      onBlur={() => {
        dispatch(onToggleDrag({ ...componentData, focused: "" }))
      }}
      onChange={handleTextChange}
      placeholder={t("konnect.sidebar.textFormater.formaterText")}
    >
      <Mention
        trigger="@"
        data={data}
        markup={"@[__display__]"}
        className={"mentions__mention"}
        appendSpaceOnAdd
        displayTransform={(id, display) => {
          return data ? `@{${display}}` : ""
        }}
        z
      />
    </MentionsInput>
  )
}
