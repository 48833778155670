import React, { useRef, useState } from 'react';
import { VisualBuilderWrapper } from '../../components/styled';
import 'reactflow/dist/style.css';
import { Flex } from '@chakra-ui/react';

import { TopbarContainer } from './topbar.container';
import { CanvasContainer } from './canvas.container';
import { SidebarContainer } from './sidebar.container';

export const VisualStudio = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const builderRef = useRef();
  return (
    <VisualBuilderWrapper ref={builderRef}>
      <TopbarContainer builderRef={builderRef} />
      <Flex height="92%" width="100%" bgColor="#F3F7FF">
        <SidebarContainer />
        <CanvasContainer
          setReactFlowInstance={setReactFlowInstance}
          reactFlowInstance={reactFlowInstance}
        />
      </Flex>
    </VisualBuilderWrapper>
  );
};
