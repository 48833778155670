import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { nodeSVG, svg } from "../../../assets/objectSVG";

export const BasicButton = React.memo(({ visibility, title, onClick }) => {
  return (
    <Box
      sx={{
        display: visibility ? "flex" : "none",
        p: "10px",
        bgColor: "#162c73",
        border: "1px solid #b7c9ff",
        borderRadius: "4px",
        justifyContent: "center",
        gap: "8px",
        cursor: "pointer",
        color: "#fff",
        fill: "#fff",
        "&:hover": {
          color: "#f7c544",
          fill: "#f7c544",
          bgColor: "#162c73",
        },
        transition: "color 0.20s, fill 0.20s",
      }}
      onClick={onClick}
    >
      <Flex width={title === "Add Account" ? "16px" : "10px"}>
        {title === "Add Account" ? svg.addAccountButton : svg.addSubButton}
      </Flex>
      {title}
    </Box>
  );
});
