import React, { useState, useEffect } from "react";
import "../components/node/type/style.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
// import { NodeLoader } from "../components/loader/index";
import { NodeLoader } from "../components/loader/index";
// import { getAppsforGpt } from "../ReactFlow/features/index"
export const ChatGPT = (index) => {
  const { t } = useTranslation();

  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [apps, setApps] = useState([]);
  const [list, setList] = useState([]);
  const history1 = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [items, setItems] = useState();
  const [actions, setActions] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://chatgpt.kntz.it/chat", { prompt }, { timeout: 115000 })

      .then((res) => {
        setResponse(res.data.message);

        setLoading(false);
      })

      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  let apps4new = [];

  if (response && response.length) {
    let appswithprompt = JSON.parse(response).filter((a) => {
      a.app1.toLowerCase().includes(prompt.toLowerCase()) ||
        a.app2.toLowerCase().includes(prompt.toLowerCase()) ||
        a.app3.toLowerCase().includes(prompt.toLowerCase());
    });
    let apps1 = JSON.parse(response).filter((a) => {
      return apps.find((e) => {
        return a.app1.toLowerCase().includes(e.name.toLowerCase());
      });
    });

    let apps1new = apps1.map((itm) => ({
      ...itm,
      app1: {
        ...apps.find(
          (item) =>
            itm.app1.toLowerCase().includes(item.name.toLowerCase()) && item
        ),
      },
    }));

    let apps2 = apps1new.filter((a) => {
      return apps.find((e) => {
        return a.app2.toLowerCase().includes(e.name.toLowerCase());
      });
    });
    let apps2new = apps2.map((itm) => ({
      ...itm,
      app2: {
        ...apps.find(
          (item) =>
            itm.app2.toLowerCase().includes(item.name.toLowerCase()) && item
        ),
      },
    }));
    let apps4 = apps2new.filter((a) => {
      return apps.find((e) => {
        return a.app3.toLowerCase().includes(e.name.toLowerCase());
      });
    });
    apps4new = apps4.map((itm) => ({
      ...itm,
      app3: {
        ...apps.find(
          (item) =>
            itm.app3.toLowerCase().includes(item.name.toLowerCase()) && item
        ),
      },
    }));
  }

  const onSearch = (searchTerm) => {
    setPrompt(searchTerm.toLowerCase());
  };
  const url = process.env.API_BASE_URL
  const fetchData = async (searchQuery) => {
    var UrlInfo = {
      url: `${url}api/v1/apps/public_app_list`, //api/v1/apps/authorized_apps1
      method: "GET",
    };
    if (searchQuery) {
      const qs = Object.keys(searchQuery)
        .map((key) => `${key}=${searchQuery[key]}`)
        .join("&");

      UrlInfo.url = UrlInfo.url + `?${qs}`;
    }
    try {
      let result = await axios(UrlInfo);
      let response = await result.data;
      setApps(response.apps);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAppActions = async () => {
    let urlInfo = {
      url: `https://production.konnectzit.com/api/v1/apps/Facebook-Lead-Ads`,
      method: "GET",
    };
    try {
      let result = await axios(urlInfo);
      let response = await result.data;
      setActions(response.triggers);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (prompt === "") {
      apps4new = [];
      setResponse([]);
    }
  }, [prompt]);
  useEffect(() => {
    fetchData();
    fetchAppActions();
    const items = JSON.parse(localStorage.getItem("dataKey"));
    if (items) {
      setItems(items);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("dataKey", JSON.stringify(data));
    if (data) {
      navigate("/Konnect");
    }
  }, [data]);

  return (
    <Flex width="100%" height="100%" sx={{ paddingY: "20px", gap: "20px" }}>
      <Flex
        width="100%"
        sx={{
          bgColor: "#fff",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
          borderRadius: "4px",
          p: "20px",
          gap: "20px",
          flexDir: "column",
          position: "relative",
        }}
      >
        <Flex
          width="100%"
          sx={{
            // pb: "10px",
            // borderBottom: "2px solid #152d73",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "3%",
            overflow: "auto",
          }}
        >
          <div key={index}>
            <h1
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              Get Usecases for your apps
            </h1>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <div>
                    {" "}
                    <input
                      type="text"
                      width={"600px"}
                      value={prompt}
                      placeholder="Enter App Name"
                      onChange={(e) => {
                        setPrompt(e.target.value);
                      }}
                      style={{
                        padding: "12px",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        width: "500px",
                      }}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      style={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#152F73",
                        padding: "12px",
                        borderRadius: "8px",
                        marginLeft: "15px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>{" "}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="dropdown">
                {apps
                  .filter((item) => {
                    const searchTerm = prompt.toLowerCase();
                    const fullName = item.name.toLowerCase();

                    return (
                      searchTerm &&
                      fullName.startsWith(searchTerm) &&
                      fullName !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item, i) => (
                    <Tooltip
                      key={i}
                      isDisabled={item.name.length > 10 ? false : true}
                      label={item.name}
                      hasArrow
                    >
                      <Flex
                        key={i}
                        onClick={() => onSearch(item.name)}
                        width="70px"
                        flexDir="column"
                        gap="5px"
                        p={1}
                        sx={{
                          m: "5px",
                          cursor: "pointer",
                          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.85)",
                          borderRadius: "2px",
                          "&:hover": {
                            "& #icon": {
                              transform: "scale(1.1)",
                            },
                          },
                        }}
                      >
                        <Image
                          id="icon"
                          src={item.image_url}
                          sx={{ transition: "transform 0.15s" }}
                        />
                        <Flex
                          sx={{
                            fontSize: [8, 8, 8, 10, 12, 12],
                            justifyContent: "center",
                          }}
                        >
                          {item.name.length > 8
                            ? item.name.slice(0, 6) + ".."
                            : item.name}
                        </Flex>
                      </Flex>
                    </Tooltip>
                  ))}
              </div>{" "}
            </div>
            {loading && (
              <Flex sx={{ gap: "20px", flexDirection: "column" }}>
                {" "}
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "300px",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  We are getting some awesome automations from ChatGPT, it
                  usually takes few seconds. Please don't refresh or close this
                  page
                </p>
                <NodeLoader
                  width={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                  height={{ _: 40, sm: 40, md: 44, lg: 48, xl: 52 }}
                  loading={loading}
                  position="static"
                />{" "}
              </Flex>
            )}
            {prompt === "" ? null : (
              <div
                style={{
                  display: "contents",
                  width: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div>
                  {apps4new.length > 0 &&
                    apps4new.map((x, i) => (
                      <>
                        <Flex className="chat" sx={{ marginBottom: "5px" }}>
                          <Flex>
                            <div>
                              <div style={{ display: "flex" }}>
                                <img
                                  src={x.app1.image_url}
                                  width={40}
                                  height={40}
                                />{" "}
                                <img
                                  src={x.app2.image_url}
                                  width={40}
                                  height={40}
                                />{" "}
                                <img
                                  src={x.app3.image_url}
                                  width={40}
                                  height={40}
                                />{" "}
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "grid",
                                justifyContent: "center",
                                fontSize: "16px",
                                marginLeft: "25px",
                              }}
                            >
                              <div>{x.use_case}</div>
                              <div
                                style={{
                                  fontWeight: "semi-bold",
                                  fontSize: "14px",
                                }}
                              >
                                By{" "}
                                {x.app1.name +
                                  " + " +
                                  x.app2.name +
                                  " + " +
                                  x.app3.name}
                              </div>
                            </div>
                          </Flex>
                          <span
                            onClick={() => {
                              setData(x);
                            }}
                          >
                            <button
                              style={{
                                color: "white",
                                width: "70px",
                                height: "50px",
                                backgroundColor: "#152F73",
                                padding: "11px",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setData(x);
                                //   changeRoute()
                              }}
                            >
                              Try it
                            </button>
                          </span>{" "}
                        </Flex>
                      </>
                    ))}
                </div>
              </div>
            )}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
