import React, { useRef, useState, useCallback, memo } from "react";
import { CaptureButton, EventWrapper, NodeOuter } from "../../styled";
import { Flex, Box, useToast } from "@chakra-ui/react";
import { NodeLoader } from "../../loader";
import { useTranslation } from "react-i18next";
import { AppInfo } from "../ui/AppInfo";
import { ModalContainer } from "../../modal/index";
import { AdditionalResponse } from "../ui/AdditionalResponse";

import {
  CondtionsContainer,
} from "../ui/ResponseContainer.js";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import {
  OnTestandReview,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk.js";
import { onLinkDelete, setModalData } from "../../../store/slice/canvasSlice";

import { useSelector, useDispatch } from "react-redux";
export const Conditions = React.memo(({ data, selected }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeOuter = useRef();
  const { editorState } = useSelector((state) => state.canvas);
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventSequence, setEventSequence] = useState(null);
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  const [expandHeight, setExpandHeight] = useState(false);


  let hasSequence =
    data.appEventConfigurations &&
      data.appEventConfigurations.filter((x) => x.sequence !== 0).length
      ? true
      : false;
  let configForms = [];

  if (hasSequence) {
    configForms = data.appEventConfigurations.slice(
      0,
      data.appEventSequences.length + 1
    );
  }

  const helper = {
    onHelp: useCallback(() => {
      !data.loading && setModalState({ display: true, type: "help" });
    }, [data.loading]),
    onExpand: useCallback(() => {
      if (expand && modalState.type === "accounts") {
      } else !data.loading && setExpand((c) => !c);
    }, [data.loading, modalState]),
    additionalResult: useCallback(() => {
      setModalState({ display: true, type: "Normal" });
    }, [data.configResponses]),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    addAccount: useCallback(() => {
      dispatch(
        setModalData({
          nodeId: data.nodeId,
          type: "Account",
          auto: true,
        })
      );
      setExpand(true);
      setExpandHeight(true);
      setModalState({ display: true, type: "accounts" });
    }, []),
    onTestandReview: useCallback(() => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      dispatch(OnTestandReview(data.nodeId)).then((res) => {
        dispatch(saveKonnect({ publish: false }));
      });
    }, [data]),
  };
  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      expandheight={expandHeight ? "true" : "false"}
      id="drag"
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalData={setModalData}
        setModalState={setModalState}
        nodeIdx={data.nodeIdx}
      />

      {data.type === "ADD_ON" ? (
        <>
          <EventWrapper visibility={true}>
            <CondtionsContainer
              data={data}
              parentNode={nodeOuter}
              conditions={data?.conditions}
              dispatch={dispatch}
              appSide="right"
              visibility={true}
            />
          </EventWrapper>
          <EventWrapper visibility={data.configResponses ? true : false}>

          </EventWrapper>

          <Box
            sx={{
              border: "1px dotted black",
              p: "10px",
              textAlign: "center",
              borderRadius: "6px",
              color: "#162c73",
              mx: "5%",
              cursor: "pointer",
            }}
            onClick={() => {
              setExpand(true);
              setModalState({ display: true, type: "ConditionModal" });
              setExpandHeight(true);
            }}
          >
            {t("konnect.conditions.headerConditionText")}
          </Box>

          <EventWrapper visibility={data.appEvent ? true : false}>
            <CaptureButton onClick={helper.onTestandReview}>
              {t("konnect.sidebar.scheduler.test_review")}
            </CaptureButton>
          </EventWrapper>
          <Divider visibility={data.configResponses?.length > 1} />
          <EventWrapper
            visibility={data.configResponses?.length > 1}
            last="true"
          >
            <ResultContainer response={data.configResponses} additional="true" />

          </EventWrapper>
          {/* {data?.additionalResponses > 3 && !modalState.display ? (
            <AdditionalResponse
              visibility={data?.additionalResponses > 3 ? true : false}
              display={modalState.display}
              onClick={helper.additionalResponse}
            />
          ) : null} */}
        </>
      ) : null}
      {modalState.display && (
        <ModalContainer
          nodeOuter={nodeOuter}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
          setExpand={setExpand}
          setExpandHeight={setExpandHeight}

        />
      )}
    </NodeOuter>
  );
});
