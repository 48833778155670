import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Modal,
  Icon,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { dashSVG, svg, svg_1 } from "../../assets/objectSVG";
import { useDispatch, useSelector } from "react-redux";
import { NodeLoader } from "../../components/loader";
import { TbHistoryToggle } from "react-icons/tb";
import { SearchIcon } from "@chakra-ui/icons";
import { setRoute } from "../../store/dashSlice";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import AuthTokenService from "../../utils/AuthTokenService";
import { useTranslation } from "react-i18next";
import NoTasksFoundImg from "../../assets/images/no-tasks-found.png";
import {
  getTaskLogsList,
  getLogsList,
  getTaskDetails,
} from "../../store/thunk/historyThunk";
import { resetTask } from "../../store/historySlice";
export const HistoryContainer = () => {
  const [pageSize, setPageSize] = useState(20);
  const [taskLogType, setTaskLogType] = useState("SUCCESS");
  const [pageIndex, setPageIndex] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    logsList,
    taskLogsLoading,
    taskLogsList,
    taskDetails,
    logsLoading,
    taskDetailsLoading,
  } = useSelector((state) => state.history);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = useState("");
  const [selectedKonnect, setSelectedKonnect] = useState(null);
  const [taskDetailLoading, setTaskDetailLoading] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getTaskLogsList(
        searchValue
          ? {
              start_date: "2020-04-12",
              end_date: "2023-07-31",
              task_log_type: taskLogType,
              page_index: pageIndex,
              page_size: pageSize,
              search_query: searchValue,
            }
          : {
              start_date: "2020-04-12",
              end_date: "2023-07-31",
              task_log_type: taskLogType,
              page_index: pageIndex,
              page_size: pageSize,
            }
      )
    ).then((res) => {
      if (res.error) {
        dispatch(setRoute("Dashboard"));
        AuthTokenService.clear();
        navigate("/login");
      }
    });
  }, [taskLogType, pageSize, pageIndex, searchValue]);

  const onSearchItem = (e) => {
    setSearchValue(e.currentTarget.value);
  };
  return (
    <>
      <Flex width="100%" height="100%" sx={{ paddingY: "20px", gap: "20px" }}>
        <Flex
          width="100%"
          sx={{
            bgColor: "#fff",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            borderRadius: "4px",
            p: "20px",
            gap: "20px",
            flexDir: "column",
            position: "relative",
          }}
        >
          <Flex
            width="100%"
            sx={{
              // pb: "10px",
              // borderBottom: "2px solid #152d73",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {" "}
            <Icon as={TbHistoryToggle} boxSize="1.7em" />
            <Flex
              sx={{
                fontWeight: 500,
                width: "100%",
                fontSize: [16, 16, 18, 18, 18, 20],
                m: "auto 0px auto 10px",
                gap: "20px",
              }}
            >
              {t("sideNav.historyCapsLabel")}
            </Flex>
            <InputGroup
              size={{
                base: "sm",
                sm: "sm",
                md: "md",
                lg: "md",
                xl: "md",
                "2xl": "md",
              }}
              sx={{ justifySelf: "flex-end", width: "50%" }}
            >
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="rgb(21,45,115,0.6)" />
              </InputLeftElement>
              <Input
                onChange={onSearchItem}
                value={searchValue}
                variant="filled"
                placeholder={t("history.task.search_history")}
                sx={{
                  borderTop: "1px solid #162c73",
                  borderBottom: "3px solid #162c73",
                  bgColor: "#fff",
                  "&:hover": {
                    bgColor: "#fff",
                  },
                  borderLeft: "1px solid #162c73",
                  borderRight: "1px solid #162c73",
                }}
              />
            </InputGroup>
          </Flex>
          <Flex sx={{ gap: "20px" }} width="30%">
            <Box
              width="100%"
              sx={{
                pb: taskLogType === "ERROR" ? "6px" : "6px",
                border: "0px solid red",
                gap: "10px",
                pb: "5px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: taskLogType === "SUCCESS" ? "#F7C544" : "gray",
                borderBottom:
                  taskLogType === "SUCCESS"
                    ? "4px solid #F7C544"
                    : "4px solid #ffff",
              }}
              onClick={() => setTaskLogType("SUCCESS")}
            >
              <Flex
                sx={{
                  width: "1em",
                  fill: taskLogType === "SUCCESS" ? "#F7C544" : "gray",
                }}
              >
                {svg.success}
              </Flex>
              {t("header.success")}
            </Box>
            <Box
              width="100%"
              sx={{
                pb: taskLogType === "SUCCESS" ? "6px" : "6px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                cursor: "pointer",
                color: taskLogType === "ERROR" ? "red" : "gray",
                borderBottom:
                  taskLogType === "ERROR" ? "4px solid red" : "4px solid #fff",
              }}
              onClick={() => {
                setPageIndex(1);
                setTaskLogType("ERROR");
              }}
            >
              <Flex
                sx={{
                  width: "1em",
                  fill: taskLogType === "ERROR" ? "red" : "gray",
                  m: "5px",
                }}
              >
                {svg.error}
              </Flex>
              {t("header.error")}
            </Box>
          </Flex>
          <Flex position="relative" flexDir="column" height="100%">
            {taskLogsLoading ? (
              <NodeLoader loading={taskLogsLoading} bgcolor="#fff" />
            ) : (
           
              <Flex
                id="scrollableDiv1"
                sx={{
                  border: "0px solid rgb(81,142,248, 1)",
                  flexDirection: "column",
                  position: "absolute",
                  top: "0px",
                  bottom: "0px",
                  right: "0px",
                  height: "100%",
                  left: "0px",
                  overflowY: "auto",
                }}
              >
                   {taskLogsList?.length > 0 ?
                <InfiniteScroll
                  dataLength={taskLogsList?.length || 0}
                  next={() => setPageSize((c) => c + 20)}
                  hasMore={taskLogsList?.length > 1000 ? false : true}
                  pullDownToRefresh={false}
                  scrollableTarget="scrollableDiv1"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={
                    !taskLogsLoading && (
                      <Flex
                        width="80px"
                        sx={{ m: "20px auto", stroke: "#162c73" }}
                      >
                        {svg.loader}
                      </Flex>
                    )
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    padding: "3px",
                  }}
                >
                  {taskLogsList?.map((konnect, i) => {
                    return (
                      <Flex
                        key={i}
                        width="100%"
                        sx={{
                          bgColor: "#f3f7ff",
                          borderRadius: "6px",
                          p: "10px 20px 10px 10px",
                          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
                          justifyContent: "space-between",
                          "&:hover": {
                            outline: "1px solid rgb(81,142,248, 1)",
                          },
                        }}
                      >
                        <Flex flexDir="column" gap="5px" width="80%">
                          <Flex
                            sx={{
                              fontSize: [10, 10, 12, 14, 14, 15],
                              ml: "5px",
                            }}
                          >
                            {konnect.konnect_name ? (
                              konnect.konnect_name
                            ) : (
                              <Flex sx={{ flexDirection: "row" }}>
                                {konnect.left_app.name}
                                {konnect.right_apps.map((x, i) => {
                                  return (
                                    <Flex key={i}>
                                      {" - " + x.app_name + "  "}
                                    </Flex>
                                  );
                                })}
                              </Flex>
                            )}
                          </Flex>
                          <Flex>
                            <Image
                              width="4%"
                              src={konnect?.left_app.image_url}
                            />
                            {konnect?.right_apps.length > 0 && (
                              <Flex
                                sx={{
                                  m: "auto 0px",
                                  fill: "black",
                                }}
                              >
                                {svg_1.dashed_1}
                              </Flex>
                            )}
                            {konnect.right_apps.length > 0 &&
                              konnect.right_apps.map((rightApp, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {index !== 0 && (
                                      <Flex
                                        sx={{
                                          m: "auto 0px",
                                          fill: "black",
                                        }}
                                      >
                                        {svg_1.dashed_1}
                                      </Flex>
                                    )}

                                    <Image
                                      width="4%"
                                      src={rightApp.image_url}
                                    />
                                  </React.Fragment>
                                );
                              })}
                          </Flex>
                        </Flex>
                        <Flex>
                          <Flex
                            sx={{
                              flexDirection: "column",
                              mr: "20px",
                              textAlign: "center",
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                          >
                            <Box sx={{ fontSize: [16, 16, 16, 18, 18, 20] }}>
                              {konnect.task_count}
                            </Box>
                            <Box sx={{ fontSize: [14, 14, 14, 16, 16, 18] }}>
                              Tasks
                            </Box>
                          </Flex>
                          <Tooltip label="View Task Details" placement="bottom">
                            <Flex
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  transform: "scale(1.2)",
                                },
                                transition: "transform 0.25s",
                              }}
                              onClick={() => {
                                setSelectedKonnect(konnect);
                                onOpen();
                                dispatch(
                                  getLogsList({
                                    start_date: "2020-04-12",
                                    end_date: "2023-04-12",
                                    task_log_type: taskLogType,
                                    page_index: pageIndex,
                                    page_size: pageSize,
                                    konnect_id: konnect.id,
                                  })
                                );
                              }}
                            >
                              {svg_1.view}
                            </Flex>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    );
                  })}
                </InfiniteScroll> :<>   <Flex
                  flexDirection="column"
                  sx={{ m: "auto", height: "100%", alignItems: "center", justifyContent: "center" }}
                >
                  <Flex
                    flexDirection="column"
                    sx={{ width: "424px", height: "221px", backgroundColor: "app.secondary.60", alignItems: "center" }}
                  >
                    <Box sx={{ mt: "68px" }}>
                      <img width="36" height="36" src={NoTasksFoundImg} />
                    </Box>
                    <Box sx={{ mt: "42px" }}>
                      <Text
                        fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }}
                        sx={{ fontWeight: "500", color: "#000" }}
                      >
                        {t("header.notask")}
                      </Text>
                    </Box>
                    <Box sx={{}}>
                      <Text
                        fontSize={{ _: 11, sm: 11, md: 11, lg: 12, xl: 14 }}
                        sx={{ fontWeight: "500", color: "#AAA" }}
                      >
                        {t("header.tryagain")}
                      </Text>
                    </Box>
                  </Flex>
                </Flex></>}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Modal
          isCentered
          size={{
            base: "xl",
            sm: "2xl",
            md: "3xl",
            lg: "3xl",
            xl: "3xl",
            "2xl": "3xl",
          }}
          scrollBehavior="inside"
          isOpen={isOpen}
          onClose={() => {
            dispatch(resetTask());
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent flexDir="column" sx={{ minHeight: "150px" }}>
            <Flex
              sx={{
                justifyContent: "space-between",
                p: "10px 15px 5px 15px",
                borderBottom: "2px solid #152d73",
              }}
            >
              <Flex
                flexDir="row"
                gap="5px"
                width="100%"
                sx={{
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflowX: "auto",
                }}
              >
                <Flex sx={{ fontSize: [10, 10, 12, 14, 14, 15] }}>
                  {selectedKonnect?.konnect_name ? (
                    selectedKonnect?.konnect_name
                  ) : (
                    <Flex sx={{ flexDirection: "row" }}>
                      {selectedKonnect?.left_app.name}
                      {selectedKonnect?.right_apps.map((x, i) => {
                        return <Flex key={i}>{" - " + x.app_name + "  "}</Flex>;
                      })}
                    </Flex>
                  )}
                </Flex>
                <Flex sx={{}}>
                  <Image
                    width="40px"
                    src={selectedKonnect?.left_app.image_url}
                  />
                  {selectedKonnect?.right_apps.length > 0 && (
                    <Flex
                      sx={{
                        m: "auto 0px",
                        fill: "black",
                      }}
                    >
                      {svg_1.dashed_1}
                    </Flex>
                  )}
                  {selectedKonnect?.right_apps.length > 0 &&
                    selectedKonnect?.right_apps.map((rightApp, index) => {
                      return (
                        <React.Fragment key={index}>
                          {index !== 0 && (
                            <Flex
                              sx={{
                                m: "auto 0px",
                                fill: "black",
                              }}
                            >
                              {svg_1.dashed_1}
                            </Flex>
                          )}

                          <Image
                            key={index}
                            width="40px"
                            src={rightApp.image_url}
                          />
                        </React.Fragment>
                      );
                    })}
                </Flex>
              </Flex>
              <Flex
                onClick={() => {
                  dispatch(resetTask());
                  onClose();
                  setSelectedTaskId(null);
                }}
                sx={{
                  width: ["25px", "25px", "27px", "19px", "31px", "33px"],
                  fill: "#ff0000",
                  cursor: "pointer",
                  transition: "fill 0.15s",
                  "&:hover": {
                    fill: "#ff0000",
                  },
                }}
              >
                {dashSVG.close}
              </Flex>
            </Flex>
            <Flex
              sx={{
                m: "15px",
                fontSize: "20px",
                color:
                  selectedKonnect?.status === "SUCCESS" ? "#1E419D" : "red",
                fontWeight: "700",
              }}
            >
              {selectedKonnect?.status === "SUCCESS" ? "Successes" : "ERRORS"}
            </Flex>
            {logsList?.length === 0 && (
              <Flex
                sx={{
                  m: "auto",
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Flex
                  flexDirection="column"
                  sx={{
                    width: "424px",
                    height: "221px",
                    backgroundColor: "app.secondary.60",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mt: "68px" }}>
                    <img width="36" height="36" src={NoTasksFoundImg} />
                  </Box>
                  <Box sx={{ mt: "42px" }}>
                    <Text
                      fontSize={{ _: 12, sm: 12, md: 12, lg: 14, xl: 16 }}
                      sx={{ fontWeight: "500", color: "#000" }}
                    >
                      {t("header.notask")}
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            )}

            <Flex sx={{ overflowY: "auto" }}>
              <NodeLoader loading={logsLoading} />
              <Flex sx={{ width: "50%", flexDirection: "column" }}>
                {logsList?.map((task, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          width: "350px",
                          height: "80px",
                          backgroundColor:
                            selectedTaskId === task.id ? "#1E419D" : "#F3F7FF",
                          margin: "10px",
                          display: "flex",
                          p: "10px",
                          cursor: "pointer",
                          borderRadius: "12px",
                          color:
                            task.status == "SUCCESS"
                              ? selectedTaskId === task.id
                                ? "#F3F7FF"
                                : "#1E419D"
                              : selectedTaskId === task.id
                              ? "#F3F7FF"
                              : "red",
                          alignItems: "center",
                          justifyContent: "space-between",

                          "&:hover": {
                            // color: '#ffffff',
                            // backgroundColor:
                            //   task.status == 'SUCCESS' ? '#1E419D' : 'red',
                          },
                        }}
                        onClick={() => {
                          setTaskDetailLoading(true);
                          dispatch(getTaskDetails(task.id)).then((res) => {
                            if ((res.type = "getTaskDetails/fulfilled")) {
                              setTaskDetailLoading(false);
                            }
                          });
                          setSelectedTaskId(task.id);
                        }}
                      >
                        <Box>
                          {" "}
                          <Text sx={{ fontSize: "21px", fontWeight: "bold" }}>
                            {" "}
                            {task.status} #{task.event_description}
                          </Text>
                          <Text sx={{ fontSize: "12px" }}>
                            {" "}
                            {task.task_date}{" "}
                          </Text>
                        </Box>

                        <Flex
                          // onClick={handleAddAccount}
                          sx={{
                            bgColor:
                              selectedTaskId === task.id
                                ? "#f6bd2c"
                                : "#1E419D",
                            color: "#fff",
                            px: "15px",
                            py: "6px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontSize: [14, 14, 14, 15, 16, 18],
                            "&:hover": {
                              "& #icon": {
                                fill: "#1e419d",
                              },
                              bgColor: "#f6bd2c",
                              color: "#1e419d",
                            },
                            transition: "background 0.15s, color 0.15s",
                            gap: "5px",
                          }}
                        >
                          {t("account.agency.detailsLabel")}
                          <Flex
                            id="icon"
                            sx={{
                              width: "14px",
                              fill: "#fff",
                              transition: "fill 0.15s",
                              transform: "rotate(270deg)",
                            }}
                          >
                            {svg_1.expand}
                          </Flex>
                        </Flex>
                      </Box>
                    </>
                  );
                })}
              </Flex>
              <Flex
                sx={{
                  width: "50%",
                  flexDirection: "column",
                  overflowY: "auto",
                }}
              >
                <NodeLoader loading={taskDetailLoading} />
                {taskDetails?.map((detail, idx) => {
                  return (
                    <>
                      <Flex
                        key={idx + "_" + detail.id}
                        flexDirection="column"
                        sx={{ my: "20px" }}
                      >
                        <Text sx={{ fontSize: "20px", fontWeight: "bold" }}>
                          Found 1 Response in {detail.app}
                        </Text>
                        <Text sx={{ fontSize: "13px ", color: "gray" }}>
                          Created :{detail.task_date}
                        </Text>

                        <Text sx={{ fontSize: "13px ", color: "gray" }}>
                          form :{detail.data.coupon_code}
                        </Text>
                        <Text sx={{ fontSize: "13px ", color: "gray" }}>
                          User Name : {detail.user}
                        </Text>
                        <Flex flexDirection="column" sx={{ my: "5px" }}>
                          {Object.keys(detail.data).length > 0 &&
                            Object.keys(detail.data).map((item, idx) => (
                              <Text
                                key={idx}
                                sx={{ fontSize: "13px ", color: "gray" }}
                              >
                                {item +
                                  " : " +
                                  detail.data[Object.keys(detail.data)[idx]]}
                              </Text>
                            ))}
                        </Flex>
                      </Flex>
                    </>
                  );
                })}
              </Flex>
            </Flex>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
};
