import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next"	

import { useSelector } from "react-redux";

export const NewsLetter = () => {
  const { loading, updates } = useSelector((state) => state.dash);
  const { t } = useTranslation()

  return (
    <Flex
      sx={{
        bgColor: "#fff",
        width: "30%",
        borderRadius: "4px",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        p: "20px",
        gap: "20px",
        flexDir: "column",
      }}
    >
      <Skeleton isLoaded={!loading}>
        <Flex
          sx={{
            fontSize: [16, 18, 18, 20, 22, 24],
            fontWeight: 500,
          }}
        >
          {t("apps.newsLable")}
        </Flex>
      </Skeleton>
      <Flex height="100%" position="relative">
        <Skeleton isLoaded={!loading} height="100%" width="100%">
          <Flex
            overflowY="auto"
            flexDir="column"
            sx={{
              position: "absolute",
              top: "0px",
              bottom: "0px",
              left: "0px",
              right: "0px",
            }}
          >
            {updates.map((update, i) => {
              return (
                <Flex
                  key={i}
                  width="100%"
                  sx={{
                    borderBottom: "1px solid rgb(81,142,248, 0.35)",
                    py: "5px",
                    fontSize: [16, 16, 16, 18, 18, 20],
                  }}
                >
                  @{update.version} - {update.title}
                </Flex>
              );
            })}
          </Flex>
        </Skeleton>
      </Flex>
    </Flex>
  );
};
