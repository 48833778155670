import { Flex, Skeleton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { dashSVG } from "../../../assets/objectSVG";
import { useSelector, useDispatch } from "react-redux";
import { getWidgetDetails } from "../../../store/thunk/dashThunk";
import { useTranslation } from "react-i18next"	
import { setRoute } from "../../../store/dashSlice";

export const TotalKonnectz = () => {
  const { t } = useTranslation()
  const { loading, totalKonnectzData } = useSelector((state) => state.dash);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWidgetDetails("total_konnectz")).then((res) => {
      if (res.error) {
        dispatch(setRoute("Dashboard"));
        AuthTokenService.clear();
        navigate("/login");
      }
    });
  }, []);
  return (
    <Flex
      sx={{
        bgColor: "#fff",
        width: "24%",
        borderRadius: "4px",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        p: "20px",
        gap: "20px",
        flexDir: "column",
      }}
    >
      {" "}
      <Skeleton isLoaded={!loading}>
        <Flex
          sx={{
            fontSize: [16, 18, 18, 20, 22, 24],
            fontWeight: 500,
          }}
        >
          {t("dashboard.widgets.totalKonnectz.headerTitle")}
        </Flex>
      </Skeleton>
      <Flex height="100%" pb="10px">
        <Skeleton isLoaded={!loading} width="100%" height="100%" display="flex">
          <Flex flexWrap="wrap" width="100%">
            <Flex width="50%" flexDir="column" alignSelf="flex-end" gap="5px">
              <Flex
                sx={{ width: ["35px", "35px", "45px", "50px", "50px", "50px"] }}
              >
                {dashSVG.active}
              </Flex>
              <Flex
                sx={{ fontWeight: 500, fontSize: [18, 18, 22, 24, 24, 26] }}
              >
                {totalKonnectzData.active}
              </Flex>
              <Flex sx={{ fontSize: [12, 12, 14, 15, 15, 16] }}>
              {t("dashboard.widgets.totalKonnectz.activeKonnectz")}
              </Flex>
            </Flex>
            <Flex width="50%" flexDir="column" alignSelf="flex-end" gap="5px">
              <Flex
                sx={{ width: ["35px", "35px", "45px", "50px", "50px", "50px"] }}
              >
                {dashSVG.inactive}
              </Flex>
              <Flex
                sx={{
                  fontWeight: 500,
                  fontSize: [18, 18, 22, 24, 24, 26],
                }}
              >
                {totalKonnectzData.inactive}
              </Flex>
              <Flex sx={{ fontSize: [12, 12, 14, 15, 15, 16] }}>
              {t("dashboard.widgets.totalKonnectz.inactiveKonnectz")}
              </Flex>
            </Flex>
          </Flex>
        </Skeleton>
      </Flex>
    </Flex>
  );
};
