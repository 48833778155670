import React from "react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NodeType } from "../../features/visualstudio/helper/index";
import { useToast } from "@chakra-ui/react";
import {
  jsonMaker,
  jsonMakerfor2,
  jsonMaker3,
} from "../../features/visualstudio/helper/index";
import {
  getAppEvents,
  captureWebResponse,
  getLinkedAccount,
  getAppEventConfig,
  getAppEventConfigDetails,
  getAppEventConfigDetailsFetchsimple,
  testAndReview,
  onNodeDeleteV2,
  getKonnecttoEdit,
  savekonnect,
  getAppEventConfigDetailCust,
  getAppEventConfigDetailfetchuserDefined,
  getAppEventConfigDetailCustomKmap,
  getAppEventConfigDetailFetchKMap,
} from "../../api-client";
import { searchApp } from "./appsThunk";
import { payloadMaker } from "../../features/visualstudio/helper/index";
import { showChatGpt } from "../slice/canvasSlice";

export const getAppEvent = createAsyncThunk(
  "getAppEvent",
  async (body, { rejectWithValue, ...rest }) => {
    const { data } = body;
    try {
      const response = await getAppEvents(data.id);
      return response.data.map((res) => {
        return { ...res, label: res.name, value: res.id };
      });
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getLinkedAccounts = createAsyncThunk(
  "getLinkedAccounts",
  async (body, { rejectWithValue }) => {
    const { data } = body;
    try {
      const response = await getLinkedAccount(data.id);
      return response.data.authorized_app_accounts.map((res) => {
        return { ...res, label: res.name, value: res.id };
      });
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const onDeleteNodeV2 = createAsyncThunk(
  "onDeleteNodeV2",
  async (nodeId, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const nodeIdx = canvas.editorState.nodes.findIndex(
      (node) => node.id === nodeId
    );
    if (nodeIdx === 0 && canvas.editorState.nodes.length > 1) {
      return {
        message: "Cannot delete Trigger while Action present.",
        status: "warning",
      };
    }
    {
      try {
        const response = await onNodeDeleteV2({
          konnect_activity_id:
            canvas.editorState.nodes[nodeIdx].data.konnect_activity_id,
          konnect_id: canvas.editorState.nodes[nodeIdx].data?.konnect_id
            ? canvas.editorState.nodes[nodeIdx].data?.konnect_id
            : canvas.editorState.nodes[0].data?.konnect_id,
          nodeId: nodeId,
          canvas_json: canvas.editorState,
        });
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  }
);
export const onDeleteNodeActivityId = createAsyncThunk(
  "onDeleteNodeV2",
  async (nodeId, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const nodeIdx = canvas.editorState.nodes.findIndex(
      (node) => node.id === nodeId
    );

    try {
      const response = await onNodeDeleteV2({
        delay_activity_id:
          canvas.editorState.nodes[nodeIdx].data.delay_activity_id,
        konnect_id: canvas.editorState.nodes[nodeIdx].data?.konnect_id
          ? canvas.editorState.nodes[nodeIdx].data?.konnect_id
          : canvas.editorState.nodes[0].data?.konnect_id,
        nodeId: nodeId,
        canvas_json: canvas.editorState,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAppEventConfigs = createAsyncThunk(
  "getAppEventConfigs",
  async (nodeId, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const node = canvas.editorState.nodes.find((node) => node.id === nodeId);
    const nodeIdx = canvas.editorState.nodes.findIndex(
      (node) => node.id === nodeId
    );
    try {
      const response = await getAppEventConfig({
        ...node.data,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAppEventConfigDetailFetch = createAsyncThunk(
  "getAppEventConfigDetailFetch",
  async (props, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const node = canvas.editorState.nodes.find(
      (node) => node.id === props.nodeId
    );

    try {
      const response = await getAppEventConfigDetailsFetchsimple({
        ...node.data,
        selectedValue: props.selectedValue,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAppEventConfigDetail = createAsyncThunk(
  "getAppEventConfigDetail",
  async (data, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const node = canvas.editorState.nodes.find(
      (node) => node.id === data.nodeId
    );
    try {
      const response = await getAppEventConfigDetails({
        ...node.data,
        prevSequence: data.prevValue ? data.prevValue : null,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const OnTestandReview = createAsyncThunk(
  "onTestandReview",
  async (nodeId, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    const { editorState } = canvas;
    const node = canvas.editorState.nodes.find((node) => node.id === nodeId);
    const nodeIdx = canvas.editorState.nodes.findIndex(
      (node) => node.id === nodeId
    );
    let payload = {},
      dirty = false,
      appDetails = node.data.appDetails ? node.data.appDetails : [];
    switch (nodeIdx) {
      case 0:
        let selectedConfigDetails = {};
        if (node.data.name === "Scheduler") {
          const { localData, ...rest } = node.data.selectedValue;
          selectedConfigDetails = rest;
        } else if (node.data.name == "RSS") {
          selectedConfigDetails = {
            feed_url: node.data.configResponses[0].value,
          };
          
        } else {
          node.data.appEventConfigurations.map((config) => {
            if (config.selected) {
              if (config.selected?.key_value === "custom_field") {
                if (config.selected?.value?.length)
                  selectedConfigDetails[config.config_key] = config.selected.id;
              } else
                selectedConfigDetails[config.config_key] = config.selected.id;
            }
          });
        }

        payload = {
          left_app_id: node.data.id,
          left_app_event_id: node.data.appEvent,
          left_config: selectedConfigDetails,
          canvas_json: canvas.editorState,
          app_account_id: node.data?.selectedAccount
            ? node.data.selectedAccount.id
            : null,
          konnect_id: node.data?.konnect_id ? node.data.konnect_id : null,
        };
        break;
      default:
        const _payload = payloadMaker({
          node,
          nodes: canvas.editorState.nodes,
          edges: canvas.editorState.edges,
        });
        payload = _payload;
        payload.canvas_json = canvas.editorState;
        if (node.data.konnect_activity_id) {
          payload.konnect_activity_id = node.data.konnect_activity_id;
        }
        if (node.data.delay_activity_id) {
          payload.delay_activity_id = node.data.delay_activity_id;
        }
        editorState.nodes[nodeIdx].data.configResponses.map((x) => {
          if (x.config_key_required && !x.value) {
            return (dirty = true);
          }
        });
        break;
    }
    if (dirty) {
      return { test_status: "Warning", display_message: "Key Value Missing" };
    } else {
      try {
        const response = await testAndReview({ payload: payload });
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message);
      }
    }
  }
);

export const getKonnect = createAsyncThunk(
  "getKonnect",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getKonnecttoEdit(body);
      if (response.data.canvas_json.canvasname === "ReactFlow2.2") {
        return response.data.canvas_json;
      } else if (response.data.canvas_json.canvas_name === "ReactFlow") {
        const { nodes, konnect_name, links, canvas_name } =
          response.data.canvas_json;
        const { newNodes, newEdges } = jsonMaker({ nodes, links });
        let canvas_json = {
          nodes: newNodes,
          edges: newEdges,
          konnect_name: konnect_name,
          oldCanvasName: canvas_name,
        };
        return canvas_json;
      } else if (response.data.canvas_json?.nodes[0]?.data) {
        const { nodes, edges, konnect_name } = response.data.canvas_json;
        const { newNodes, newEdges } = jsonMakerfor2({ nodes, edges });
        let canvas_json = {
          nodes: newNodes,
          edges: newEdges,
          konnect_name: konnect_name,
          oldCanvasName: "2.1",
        };

        return canvas_json;
      } else if (!response.data.canvas_json?.nodes[0]?.data) {
        const { nodes, konnect_name, links } = response.data.canvas_json;
        const { updatedNodes, updatedEdges } = jsonMaker3({ nodes, links });
        let canvas_json = {
          nodes: updatedNodes,
          edges: updatedEdges,
          konnect_name: konnect_name,
          oldCanvasName: "1.0",
        };
        return canvas_json;
      } else {
        let canvas_json = {
          nodes: [],
          edges: [],
          konnect_name: "",
        };
        return canvas_json;
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const saveKonnect = createAsyncThunk(
  "saveKonnect",
  async (props, { rejectWithValue, getState }) => {
    const { canvas } = getState();
    try {
      const response = await savekonnect({
        editorState: canvas.editorState,
        ...props,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAppEventConfigDetailCustom = createAsyncThunk(
  "getAppEventConfigDetailCustom",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getAppEventConfigDetailCust(props);
      return response;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getAppEventConfigDetailFetchuserDefined = createAsyncThunk(
  "getAppEventConfigDetailFetchuserDefined",
  async (props, { rejectWithValue, getState }) => {
    const { canvas } = getState();

    try {
      const response = await getAppEventConfigDetailfetchuserDefined({
        ...props,
        nodes: canvas.editorState.nodes,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAppEventConfigDetailCustomKeymap = createAsyncThunk(
  "getAppEventConfigDetailCustomKeymap",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getAppEventConfigDetailCustomKmap(props);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAppEventConfigDetailFetchKeyMap = createAsyncThunk(
  "getAppEventConfigDetailFetchKeyMap",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getAppEventConfigDetailFetchKMap(props);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getAppEventGpt = createAsyncThunk(
  "getAppEventGpt",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getAppEvents(body.id);
      return response.data.map((res) => {
        return { ...res, label: res.name, value: res.id };
      });
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getLinkedAccountsGpt = createAsyncThunk(
  "getLinkedAccountsGpt",
  async (body, { rejectWithValue }) => {
    try {
      const response = await getLinkedAccount(body.id);
      return response.data.authorized_app_accounts.map((res) => {
        return { ...res, label: res.name, value: res.id };
      });
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const showOnCanvas = async (payload) => {
  const { items, dispatch, nanoid } = payload;
  const { app1, app2, app3, use_case, action1, action2, trigger } = items;
  const idx = use_case.indexOf("when");
  let newString = use_case.slice(idx);
  let newarray = [app1, app2, app3];
  let leftApp;
  let filteredArray = newarray.map((n) => {
    if (newString.toLowerCase().includes(n.name.toLowerCase())) {
      leftApp = n;
    }
  });
  let rightAppsArray = newarray.filter(
    (r) => r.name.toLowerCase() !== leftApp.name.toLowerCase()
  );
  let x = leftApp.name;
  let appsss = await dispatch(searchApp({ search_query: x }));
  let aaa = appsss.payload.apps.filter(
    (y) => y.name.toLowerCase() === leftApp.name.toLowerCase()
  );

  let secondApp = await dispatch(
    searchApp({ search_query: rightAppsArray[0].name })
  );
  let secApp = secondApp.payload.apps.filter(
    (y) => y.name.toLowerCase() === rightAppsArray[0].name.toLowerCase()
  );
  let thirdapp = await dispatch(
    searchApp({ search_query: rightAppsArray[1].name })
  );
  let thirapp = thirdapp.payload.apps.filter(
    (y) => y.name.toLowerCase() === rightAppsArray[1].name.toLowerCase()
  );
  let getEventLeftData = await dispatch(getAppEventGpt(leftApp));
  let getEventLeft = getEventLeftData.payload.filter((x) => x.side === "left");
  let accountsLeft = await dispatch(getLinkedAccountsGpt(leftApp));

  let getEventRightData = await dispatch(getAppEventGpt(rightAppsArray[0]));
  let getEventRight = getEventRightData.payload.filter(
    (x) => x.side === "right"
  );
  let getEventRight3Data = await dispatch(getAppEventGpt(rightAppsArray[1]));
  let getEventRight3 = getEventRight3Data.payload.filter(
    (x) => x.side === "right"
  );

  let accountsRight = await dispatch(getLinkedAccountsGpt(rightAppsArray[0]));
  let accountsthird = await dispatch(getLinkedAccountsGpt(rightAppsArray[1]));
  let newNode = {
    id: nanoid(),
  };
  let newNode1 = {
    id: nanoid(),
  };
  let newNode2 = {
    id: nanoid(),
  };
  let data = {
    id: aaa[0].id,
    name: aaa[0].name,
    auth_type: aaa[0].auth_type,
    background: aaa[0].background_color,
    image: aaa[0].image_url,
    provider: aaa[0].provider,
    associatedAccounts: aaa[0].account_count,
    webhook_enabled: aaa[0].webhook_enabled,
    webhook_instructions: aaa[0].webhook_instructions,
    type: aaa[0].app_type,
    webhook_url: aaa[0].webhook_url,
  };

  let data1 = {
    id: secApp[0].id,
    name: secApp[0].name,
    auth_type: secApp[0].auth_type,
    background: secApp[0].background_color,
    image: secApp[0].image_url,
    provider: secApp[0].provider,
    associatedAccounts: secApp[0].account_count,
    webhook_enabled: secApp[0].webhook_enabled,
    webhook_instructions: secApp[0].webhook_instructions,
    type: secApp[0].app_type,
    webhook_url: secApp[0].webhook_url,
  };
  let data2 = {
    id: thirapp[0].id,
    name: thirapp[0].name,
    auth_type: thirapp[0].auth_type,
    background: thirapp[0].background_color,
    image: thirapp[0].image_url,
    provider: thirapp[0].provider,
    associatedAccounts: thirapp[0].account_count,
    webhook_enabled: thirapp[0].webhook_enabled,
    webhook_instructions: thirapp[0].webhook_instructions,
    type: thirapp[0].app_type,
    webhook_url: thirapp[0].webhook_url,
  };
  let newNodes = [
    {
      id: newNode.id,
      position: { x: 500, y: 300 },
      data: {
        id: aaa[0].id,
        name: aaa[0].name,
        auth_type: aaa[0].auth_type,
        background: aaa[0].background_color,
        image: aaa[0].image_url,
        provider: aaa[0].provider,
        associatedAccounts: accountsLeft.payload ? accountsLeft.payload : null,
        webhook_enabled: aaa[0].webhook_enabled,
        webhook_instructions: aaa[0].webhook_instructions,
        type: aaa[0].app_type,
        webhook_url: aaa[0].webhook_url,
        nodeId: newNode.id,
        appEvent: getEventLeft
          ? getEventLeft.find(
              (x) => x.name.toLowerCase() === trigger.toLowerCase()
            )?.id
          : null,
        appEventConfigurations: [],
        appEventSequences: [],
        selectedEvent: getEventLeft
          ? getEventLeft.find(
              (x) => x.name.toLowerCase() === trigger.toLowerCase()
            )
          : null,
        appEvents: getEventLeft ? getEventLeft : [],

        isloading: false,
      },
      dragHandle: "#drag",
      type: NodeType({ element: data, nodes: [] }),
    },
    {
      id: newNode1.id,
      position: { x: 1000, y: 300 },
      data: {
        id: secApp[0].id,
        name: secApp[0].name,
        auth_type: secApp[0].auth_type,
        background: secApp[0].background_color,
        image: secApp[0].image_url,
        provider: secApp[0].provider,
        associatedAccounts: accountsRight.payload
          ? accountsRight.payload
          : null,
        webhook_enabled: secApp[0].webhook_enabled,
        webhook_instructions: secApp[0].webhook_instructions,
        type: secApp[0].app_type,
        webhook_url: secApp[0].webhook_url,
        nodeId: newNode1.id,
        linkedAccounts: accountsRight ? accountsRight : null,
        selectedEvent: getEventRight
          .filter((s) => s.side === "right")
          .find((x) =>
            x.name.toLowerCase() === action2.toLowerCase()
              ? x.name.toLowerCase() === action2.toLowerCase()
              : x.name.toLowerCase() === action1.toLowerCase()
          )
          ? getEventRight
              .filter((s) => s.side === "right")
              .find((x) =>
                x.name.toLowerCase() === action2.toLowerCase()
                  ? x.name.toLowerCase() === action2.toLowerCase()
                  : x.name.toLowerCase() === action1.toLowerCase()
              )
          : null,
        appEvent: getEventRight
          .filter((s) => s.side === "right")
          .find((x) =>
            x.name.toLowerCase() === action2.toLowerCase()
              ? x.name.toLowerCase() === action2.toLowerCase()
              : x.name.toLowerCase() === action1.toLowerCase()
          )?.id
          ? getEventRight
              .filter((s) => s.side === "right")
              .find((x) =>
                x.name.toLowerCase() === action2.toLowerCase()
                  ? x.name.toLowerCase() === action2.toLowerCase()
                  : x.name.toLowerCase() === action1.toLowerCase()
              )?.id
          : null,
        appEventConfigurations: [],
        appEventSequences: [],
        appEvents: getEventRight,
        configResponses: [],
        tested: false,
        reviewed: false,
        isloading: false,
      },
      dragHandle: "#drag",
      type: NodeType({ element: data1, nodes: [1] }),
    },
    {
      id: newNode2.id,
      position: { x: 1600, y: 300 },
      data: {
        id: thirapp[0].id,
        name: thirapp[0].name,
        auth_type: thirapp[0].auth_type,
        background: thirapp[0].background_color,
        image: thirapp[0].image_url,
        provider: thirapp[0].provider,
        associatedAccounts: accountsthird.payload,
        webhook_enabled: thirapp[0].webhook_enabled,
        webhook_instructions: thirapp[0].webhook_instructions,
        type: thirapp[0].app_type,
        webhook_url: thirapp[0].webhook_url,
        nodeId: newNode2.id,
        linkedAccounts: accountsthird,
        appEvent: getEventRight3.find((n) =>
          n.name.toLowerCase() === action1.toLowerCase()
            ? n.name.toLowerCase() === action1.toLowerCase()
            : n.name.toLowerCase() === action2.toLowerCase()
        )?.id
          ? getEventRight3.find((n) =>
              n.name.toLowerCase() === action1.toLowerCase()
                ? n.name.toLowerCase() === action1.toLowerCase()
                : n.name.toLowerCase() === action2.toLowerCase()
            )?.id
          : null,
        selectedEvent: getEventRight3.find((n) =>
          n.name.toLowerCase() === action1.toLowerCase()
            ? n.name.toLowerCase() === action1.toLowerCase()
            : n.name.toLowerCase() === action2.toLowerCase()
        )
          ? getEventRight3.find((n) =>
              n.name.toLowerCase() === action1.toLowerCase()
                ? n.name.toLowerCase() === action1.toLowerCase()
                : n.name.toLowerCase() === action2.toLowerCase()
            )
          : null,
        appEvents: getEventRight3,
        tested: false,
        reviewed: false,
        isloading: false,
      },
      dragHandle: "#drag",
      type: NodeType({ element: data2, nodes: [1, 2] }),
    },
  ];
  dispatch(showChatGpt(newNodes));
};
