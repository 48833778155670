import {
  Button,
  Checkbox,
  Flex,
  Icon,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { onSelectResponse } from "../../store/slice/canvasSlice";
import { MdOutlineExpandMore } from "react-icons/md";
import { memo } from "react";

export const ResponseModal = memo(
  ({ responses = [], setModalState, parentnode, nodeId, type }) => {
    let filteredResponse = [];
    if (type === "Normal") {
      filteredResponse = responses.filter(
        (res) => !res.additional && res?.config_key_type !== "custom"
      );
    } else {
      filteredResponse = responses.filter((res) => res.additional === true);
    }
    const toast = useToast();
    const [result, setResult] = useState(filteredResponse);
    const [list, setList] = useState(result);
    const [displayValue, setDisplayValue] = useState();
    const [child, setChild] = useState();
    const dispatch = useDispatch();
    const handleCheckedAll = (e) => {
      let checkedAll = result.every((val) => val.checked === true);
      if (checkedAll) {
        setResult((c) =>
          c.map((res) => {
            if (res.target_linked || res.source_linked) {
              return res;
            } else return { ...res, checked: false };
          })
        );
        setList((c) =>
          c.map((res) => {
            if (res.target_linked || res.source_linked) {
              return res;
            } else return { ...res, checked: false };
          })
        );
      } else {
        setResult((c) =>
          c.map((res) => {
            if (res.target_linked || res.source_linked) {
              return res;
            } else return { ...res, checked: true };
          })
        );
        setList((c) =>
          c.map((res) => {
            if (res.target_linked || res.source_linked) {
              return res;
            } else return { ...res, checked: true };
          })
        );
      }
    };

    const handleSearch = (e) => {
      if (!e.target.value) {
        setList(result);
      } else {
        let res = result?.filter((res) =>
          res?.id.toLowerCase().includes(e.target.value.toLowerCase())
        );
        if (res?.length > 0) {
          setList(res);
        } else {
          setList([{ id: "Not-found" }]);
        }
      }
    };
    return (
      <Flex
        height="100%"
        width="100%"
        sx={{
          p: "16px",
          flexDirection: "column",
          bgColor: "#fff",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          justifyContent: "space-between",
        }}
      >
        <Flex sx={{ flexDirection: "column", height: "100%", gap: "10px" }}>
          <Flex gap="20px" alignItems="center">
            <Input
              name="search_label"
              onFocus={() => (parentnode.current.id = "")}
              onBlur={() => (parentnode.current.id = "drag")}
              fontSize={15}
              size="md"
              placeholder="Search by Label"
              sx={{ py: "10px" }}
              onChange={handleSearch}
            />
            <Button
              onClick={() => {
                if (!result.find((config) => config.checked)) {
                  toast({
                    position: "top",
                    status: "warning",
                    variant: "solid",
                    title: "Please select one value to proceed",
                    duration: 2500,
                    containerStyle: {
                      fontWeight: 400,
                    },
                  });
                } else {
                  dispatch(
                    onSelectResponse({
                      nodeId: nodeId,
                      responses: result,
                      type: type,
                    })
                  );
                  setModalState({ display: false, type: null });
                }
              }}
              size="sm"
              sx={{
                px: "20px",
                bgColor: "#152d73",
                borderRadius: "4px",
                color: "#fff",
                "&:hover": {
                  bgColor: "#152d73",
                  color: "#F7C545",
                },
                display: "flex",
              }}
            >
              OK
            </Button>
          </Flex>
          <Checkbox
            isIndeterminate={
              !result.every((val, i, arr) => val.checked === arr[0].checked)
            }
            isChecked={result.every((val) => val.checked === true)}
            onChange={handleCheckedAll}
            sx={{
              fontSize: "13px !important",
              borderBottom: "1px solid #152d73",
              pb: "5px",
            }}
          >
            Label: (Value)
          </Checkbox>
          <Flex height="100%" width="100%" sx={{ position: "relative" }}>
            <Flex
              onMouseEnter={() => parentnode.current.classList.add("nowheel")}
              onMouseLeave={() =>
                parentnode.current.classList.remove("nowheel")
              }
              sx={{
                height: "100%",
                gap: "10px",
                flexDirection: "column",
                position: "absolute",
                p: "5px 5px 5px 2px",
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                borderBottomRightRadius: "4px",
                borderBottomLeftRadius: "4px",
                overflowY: "auto",
              }}
            >
              <LabelContainer
                list={list}
                setChild={setChild}
                setList={setList}
                setDisplayValue={setDisplayValue}
                child={child}
                setResult={setResult}
                displayValue={displayValue}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="center" gap="20px" mt="10px">
          <Button
            onClick={() => {
              setModalState({ display: false, type: null });
            }}
            size="sm"
            sx={{
              px: "20px",
              bgColor: "#152d73",
              borderRadius: "4px",
              color: "#fff",
              "&:hover": {
                bgColor: "#152d73",
                color: "red",
              },
              display: "flex",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!result.find((config) => config.checked)) {
                toast({
                  position: "top",
                  status: "warning",
                  variant: "solid",
                  title: "Please select one value to proceed.",
                  duration: 2500,
                  containerStyle: {
                    fontWeight: 400,
                  },
                });
              } else {
                dispatch(
                  onSelectResponse({
                    nodeId: nodeId,
                    responses: result,
                    type: type,
                  })
                );
                setModalState({ display: false, type: null });
              }
            }}
            size="sm"
            sx={{
              px: "20px",
              bgColor: "#152d73",
              borderRadius: "4px",
              color: "#fff",
              "&:hover": {
                bgColor: "#152d73",
                color: "#F7C545",
              },
              display: "flex",
            }}
          >
            OK
          </Button>
        </Flex>
      </Flex>
    );
  }
);

const LabelContainer = ({
  list,
  setList,
  setResult,
  setDisplayValue,
  displayValue,
}) => {
  const handleCheck = (e, id) => {
    setList((c) =>
      c.map((res) => {
        if (id === res.id) {
          return { ...res, checked: e.target.checked };
        } else return res;
      })
    );
    setResult((c) =>
      c.map((res) => {
        if (id === res.id) {
          return { ...res, checked: e.target.checked };
        } else return res;
      })
    );
  };

  const collapseAll = (parentId, children = false) => {
    let _list = JSON.parse(JSON.stringify(list));
    const collapseChildren = (item) => {
      if (item) {
        item.isOpen = false;
        item.isAvailable = false;

        if (item.type === "node") {
          item.children.map((childKey) => {
            let child = _list.findIndex((x) => x.id === childKey);
            if (_list[child].type === "leaf") {
              _list[child].isOpen = false;
            } else {
              _list[child].isAvailable = false;
              _list[child].isOpen = false;

              _list[child].children.map((x) => {
                const section = _list.findIndex((y) => y.id === x);
                collapseChildren(_list[section]);
              });
            }
          });
        }
      }
    };

    const section = _list.findIndex((x) => x.id === parentId);
    _list[section].isOpen = false;

    if (_list[section].type === "node") {
      _list[section].children.map((childKey) => {
        let child = _list.findIndex((x) => x.id === childKey);
        if (child) {
          if (_list[child]?.type === "leaf") {
            _list[child].isOpen = false;
          } else {
            _list[child].isAvailable = false;
            _list[child].isOpen = false;

            _list[child].children.map((x) => {
              const section = _list.findIndex((y) => y.id === x);
              collapseChildren(_list[section]);
            });
          }
        }
      });
    }
    setList(_list);
    return setResult(_list);
  };

  return list[0]?.id === "Not-found" ? (
    <>No Label Found</>
  ) : (
    <>
      {Object.keys(list).map((label, i) => {
        const response = list[label];
        if (
          response.fetch_fields === false ||
          response.fetch_fields === true ||
          (response.type === "leaf" &&
            (response.isOpen || response.level === 0))
        ) {
          return (
            <Checkbox
              key={i}
              onMouseEnter={() => setDisplayValue(i)}
              onMouseLeave={() => setDisplayValue(null)}
              isChecked={response.checked}
              isDisabled={
                response?.target_linked || response?.source_linked
                  ? true
                  : false
              }
              onChange={(e) => handleCheck(e, response.id)}
              sx={{
                wordBreak: "break-all",
                "&:hover": {
                  outline: "1px solid #b7caff",
                  borderRadius: "4px",
                },
              }}
            >
              {response?.checked && displayValue !== i
                ? `${response.label}  ${
                    +response.value && response.value?.length > 18
                      ? ":(" + response.value?.slice(0, 18) + ".."
                      : response.value
                      ? ":(" + response.value + ")"
                      : ""
                  }`
                : displayValue === i
                ? `${response.label}  ${
                    response.value ? ":(" + response.value + ")" : ""
                  }`
                : `${response.label}`}
            </Checkbox>
          );
        } else if (
          response.isAvailable ||
          response.isOpen ||
          response.level === 0
        ) {
          return (
            <Flex
              key={i}
              justifyContent="space-between"
              alignItems="center"
              sx={{
                cursor: "pointer",
                borderBottom: response.isOpen
                  ? "2px solid #152E73"
                  : "1px solid #b7caff",
                pb: "3px",
                "&:hover": {
                  outline: "1px solid #b7caff",
                  borderRadius: "4px",
                },
              }}
              onClick={() => {
                if (response.isOpen) {
                  collapseAll(response.id);
                } else {
                  let childArray = [],
                    finalArray = [];
                  const openList = list.map((res) => {
                    if (res.id === response.id) {
                      res.children.map((x) => {
                        let item = { ...list.find((y) => y.id === x) };
                        if (item.type === "node") {
                          item.isAvailable = true;
                        } else {
                          item.isOpen = true;
                        }
                        childArray.push(item);
                      });
                      return { ...res, isOpen: true, isAvailable: true };
                    }
                    return res;
                  });
                  finalArray = openList.map((res) => {
                    let item = childArray.find((x) => x.id === res.id);
                    if (item) {
                      return {
                        ...res,
                        isOpen: item.isOpen,
                        isAvailable: item.isAvailable,
                      };
                    }
                    return res;
                  });

                  setList(finalArray);
                  setResult(finalArray);
                }
              }}
            >
              <Flex>{response.label}</Flex>
              <Icon
                as={MdOutlineExpandMore}
                boxSize="1.4em"
                sx={{
                  transform: response.isOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.20s",
                }}
              />
            </Flex>
          );
        }
      })}
    </>
  );
};
