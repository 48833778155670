import React, { useEffect, useState } from "react";
import { Flex, Box, Image, useToast } from "@chakra-ui/react";
import { nodeSVG } from "../../../assets/objectSVG";
import { Tooltip } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  onDelete,
  onLinkDelete,
  onRefresh,
  resetStatus,
} from "../../../store/slice/canvasSlice";
import {
  onDeleteNodeActivityId,
  onDeleteNodeV2,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk";
import { useNavigate } from "react-router-dom";

export const AppInfo = React.memo(
  ({
    appImage = "",
    appName = "",
    status = "Draft",
    message = "",
    setModalState,
    expand,
    hookTimer,
    nodeId,
    onHelp,
    hasDelay,
    onDelay,
    onExpand,
    setExpand,
    setExpandHeight,
    setModalData,
    tested,
    delayActive,
    nodeIdx,
    modalState,
    delay_activity_id,
  }) => {
    const [expMessage, setExpMessage] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const isOnEditMode = useSelector((state) => state.canvas.isOnEditMode);
    useEffect(() => {
      if (status === "Warning") {
        setTimeout(() => {
          dispatch(resetStatus(nodeId));
        }, 2000);
      }
    }, [status]);
    const helper = {
      deleteActivityId: () => {
        if (tested) {
          dispatch(onDeleteNodeV2(nodeId))
            .then((res) => {
              if (res?.payload?.status === "warning") {
                toast({
                  position: "top",
                  status: "warning",
                  variant: "solid",
                  title: res?.payload?.message,
                  duration: 2500,
                  containerStyle: {
                    fontWeight: 400,
                  },
                });
              }
            })
            .then(() => dispatch(saveKonnect({ publish: false })))
            .then((res) => {
              if (
                res.payload.message == "Konnect Saved Successfully!" &&
                nodeIdx === 0 &&
                isOnEditMode === true
              ) {
                navigate("/konnectz");
              }
            });
        }
      },
      deleteDelayActivityId: () => {
        if (delay_activity_id) {
          dispatch(onDeleteNodeActivityId(nodeId)).then(() =>
            dispatch(saveKonnect({ publish: false }))
          );
        }
      },
      delete: () => {
        hookTimer && clearInterval(hookTimer);
        setModalState && setModalState({ display: false, type: "" });
        setModalData &&
          dispatch(setModalData({ nodeId: nodeId, type: "Delete" }));
        helper.deleteActivityId();
        helper.deleteDelayActivityId();
        dispatch(onLinkDelete({ nodeId: nodeId }));
        dispatch(onDelete(nodeId));
      },
      refresh: () => {
        setExpand && setExpand(false);
        setExpandHeight && setExpandHeight(false);
        setModalState({ display: false, type: "" });
        dispatch(onLinkDelete({ nodeId: nodeId }));
        dispatch(onRefresh(nodeId));
      },
      delay: () => {
        setExpandHeight(true);
        setExpand && setExpand((c) => !c);
        setModalState({ display: true, type: "DelayModal" });
      },
    };
    return (
      <Flex
        height="46px"
        p={2}
        sx={{
          zIndex: 2,
          borderBottom: "1px solid rgb(0,0,0, 0.15)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          color: "#000",
          bgColor:
            status === "Draft"
              ? "#fff"
              : status === "Success"
              ? "rgb(10,157,88, 0.7)"
              : status === "Error"
              ? "rgb(244,67,54,0.6)"
              : status === "Pending" || status === "Warning"
              ? "#F8BF08"
              : "#fff",
          flexDirection: "column",
          transition: "background 0.25s",
        }}
      >
        <Flex
          sx={{
            width: "100%",
            height: "100%",
            transition: "height 0.15s",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Flex height="100%" sx={{ alignItems: "center", gap: "5px" }}>
            <Image height="100%" src={appImage} />
            <Flex sx={{ flexDirection: "column" }}>
              <Flex sx={{ fontWeight: 500, lineHeight: 1 }}>
                {appName.length < 17
                  ? appName
                  : appName?.substring(0, 17) + ".."}
              </Flex>

              <Tooltip
                isOpen={expMessage}
                shouldWrapChildren={true}
                onOpen={() => {
                  message?.length > 40 && setExpMessage(true);
                }}
                onClose={() => {
                  setExpMessage(false);
                }}
                label={message}
                hasArrow={true}
                arrowSize={8}
              >
                <Box
                  sx={{
                    // height: "10%",
                    display:
                      status === "Pending" ||
                      status === "Warning" ||
                      status === "Error" ||
                      status === "Success"
                        ? "flex"
                        : "none",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflowX: "auto",
                    fontSize: 9,
                  }}
                >
                  {message?.length < 40
                    ? message
                    : message?.substring(0, 40) + "..."}
                </Box>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex height="100%" sx={{ gap: "15px", mr: "10px" }}>
            <Tooltip label="Help" hasArrow>
              <Flex
                onClick={() => {
                  if (modalState.display) {
                    setModalState({ display: false, type: "" });
                    if (expand) {
                      status !== "Pending" && onExpand();
                    }
                  } else {
                    status !== "Pending" && onHelp();
                  }
                }}
                sx={{
                  width: "14px",
                  fill: "rgb(0,0,0,0.6)",
                  "&:hover": {
                    fill: "#000",
                    transform: "scale(1.1)",
                  },
                  cursor: status === "Pending" ? "not-allowed" : "pointer",
                }}
              >
                {nodeSVG.info}
              </Flex>
            </Tooltip>
            {hasDelay ? (
              <Tooltip label={hasDelay ? "delay" : ""} hasArrow>
                <Flex
                  onClick={() => {
                    helper.delay();
                  }}
                  sx={{
                    width: "18px",
                    fill: "rgb(0,0,0,0.6)",
                    // paddingTop:"2px",
                    alignItems:"center",
                    stroke: delayActive ? "rgb(0,128,0)" : "#666666",
                    "&:hover": {
                      fill: "#000",
                      transform: "scale(1.1)",
                    },
                    cursor: "pointer",
                  }}
                >
                  {nodeSVG.clock}
                </Flex>
              </Tooltip>
            ) : (
              ""
            )}

            <Tooltip label={expand ? "Collapse" : "Expand"} hasArrow>
              <Flex
                onClick={() => status !== "Pending" && onExpand()}
                sx={{
                  width: "13px",
                  fill: "rgb(0,0,0,0.6)",
                  "&:hover": {
                    fill: "#000",
                    transform: "scale(1.1)",
                  },
                  cursor: status === "Pending" ? "not-allowed" : "pointer",
                }}
              >
                {expand ? nodeSVG.collapse : nodeSVG.expand}
              </Flex>
            </Tooltip>

            <Tooltip label="Refresh" hasArrow>
              <Flex
                onClick={() => helper.refresh()}
                sx={{
                  width: "13px",
                  fill: "rgb(0,0,0,0.6)",
                  "&:hover": {
                    fill: "#000",
                    transform: "scale(1.1)",
                  },
                  cursor: "pointer",
                }}
              >
                {nodeSVG.reload}
              </Flex>
            </Tooltip>
            <Tooltip label="Delete" hasArrow>
              <Flex
                onClick={helper.delete}
                sx={{
                  width: "11px",
                  fill: "rgb(0,0,0,0.6)",
                  "&:hover": {
                    fill: "#000",
                    transform: "scale(1.1)",
                  },
                  cursor: "pointer",
                }}
              >
                {nodeSVG.delete}
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);
