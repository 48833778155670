import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Tooltip,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { FaPeopleArrows, FaTelegramPlane } from "react-icons/fa";
import axios from "axios";
import { svg, svg_1 } from "../../assets/objectSVG";
import { MdImageNotSupported, MdImage } from "react-icons/md";
import { HiOutlineSortDescending } from "react-icons/hi";
import { Divider } from "../../components/node/ui/Divider";
import { useDispatch, useSelector } from "react-redux";
import { TimeZones } from "./timeZones";
import Langs from "./langs";
import AuthTokenService from "../../utils/AuthTokenService";
import {
  getCouponList,
  getFeaturesList,
  getProfile,
  redeemCouponPost,
} from "../../store/thunk/accountsThunk";
import { NodeLoader } from "../../components/loader";
import { SearchIcon } from "@chakra-ui/icons";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { GiCandlebright } from "react-icons/gi";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaInfinity, FaPeopleRoof } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { DashButton } from "../../components/styled";
import { FiSave } from "react-icons/fi";
import { BsMicrosoftTeams } from "react-icons/bs";
import { SiProcesswire } from "react-icons/si";

import { RiHandCoinFill, RiOpenaiFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  cancelPlan,
  paddleAuth,
  productDetail,
  subscriptionDetails,
} from "../../store/paddleSlice";
import { BiSolidCoupon } from "react-icons/bi";
const couponHeading = ["Code", "Redeemed On", "Expires On", "Tasks"];

export const SubscriptionContainer = ({ id }) => {
  const dispatch = useDispatch();
  const task_limit = useSelector(
    (state) => state.dash.taskConsumptionData.task_limit
  );
  const { couponList, featureData } = useSelector((state) => state.profile);
  const profileData = useSelector((state) => state.profile.profileData);
  const [newCoupon, setNewCoupon] = useState("");
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const navigate = useNavigate();
  const Paddle = window.Paddle;
  const [subscription, setSubscription] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [product, setProduct] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const baseUrl = "https://paddle-am1g.onrender.com";
  const toast = useToast();
  const [latestSubId, setlatestSubId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCancelSubscription = (sub_id) => {
    setlatestSubId(sub_id);

    setShowConfirmation(true);
  };
  const handleConfirmCancel = (sub_id) => {
    handleCancelSubscription1(latestSubId);
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleCancelSubscription1 = async (id) => {
    dispatch(paddleAuth()).then((res) => {
      setAuthToken(res.payload);
      setLoading(false);
      dispatch(cancelPlan({ subscription_id: id })).then((res) => {
        if (res?.type === "cancel_plan/fulfilled") {
          if (res?.payload?.data?.scheduled_change?.action === "cancel") {
            setLoading(true);
            toast({
              position: "top",
              status: "success",
              variant: "solid",
              title: "Plan will cancel by the end of this billing period",
              duration: 5000,
              containerStyle: {
                fontWeight: 400,
              },
            });
            dispatch(subscriptionDetails(id));
          } else {
            setLoading(true);
            toast({
              position: "top",
              status: "error",
              variant: "solid",
              title: "Plan can not be cancelled, Already Cancelled",
              duration: 5000,
              containerStyle: {
                fontWeight: 400,
              },
            });
          }
        } else {
          setLoading(true);
          toast({
            position: "top",
            status: "error",
            variant: "solid",
            title:
              "Plan can not be cancelled due to some technical error , Contact Support",
            duration: 5000,
            containerStyle: {
              fontWeight: 400,
            },
          });
        }
      });
    });
  };

  useEffect(() => {
    dispatch(getFeaturesList());
    dispatch(getCouponList());
    if (id) {
      dispatch(subscriptionDetails(id)).then((response) => {
        if (response.type === "subscription/pending") {
          setLoading(false);
        } else {
          let res1 = response?.payload?.data;
          const activePlans = res1.filter((item) => item.status === "active");
          setItems(activePlans);
          res1.forEach((res, index) => {
            if (res?.scheduled_change?.action === "cancel") {
              setCancelled(true);
            }
            setSubscription(res);
            setData(res?.items);
          });

          const productIds = activePlans
            .map((item) => item?.items[0]?.price?.product_id)
            .filter((productId) => productId !== undefined);
          setProductId(productIds);
          setLoading(true);
        }
      });
    }

    // if (productId) {
    //   dispatch(productDetail({product_id: productId})).then((res) => {
    //     setProduct(res?.payload?.data);
    //   })
    // }
  }, [id]);
  useEffect(() => {
    if (productId && productId?.length > 0) {
      const productPromises = productId.map((productId) =>
        dispatch(productDetail({ product_id: productId })).then(
          (res) => res?.payload?.data
        )
      );
      setLoading(false);
      Promise.all(productPromises)
        .then((products) => {
          // 'products' will be an array containing the details of both products
          // You can access them as 'products[0]' and 'products[1]'
          const planNames = products.map((plan) => plan.name);

          setProduct(products);
          setLoading(true);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    }
  }, [productId]);
  // const productIds = [productId1, productId2];

  return (
    <>
      {id ? (
        <Flex
          width="50%"
          height="100%"
          sx={{
            // height: loading ? "86%": "100%",
            bgColor: "#fff",
            display: "flex",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            borderRadius: "4px",
            p: "20px",
            gap: "20px",
            flexDir: "column",
            //  position: 'relative',
          }}
        >
          <Flex
            gap="10px"
            alignItems="center"
            pb="5px"
            borderBottom="2px solid #152D73"
          >
            <Icon
              as={FaTelegramPlane}
              boxSize="1.7em"
              sx={{
                transition: "transform 0.15s",
              }}
            />
            <Flex
              sx={{
                fontSize: [16, 16, 18, 20, 22, 24],
                fontWeight: 500,
                m: "auto 0px",
              }}
            >
              Subscription
            </Flex>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              gap: "20px",
              overflow: "scroll",
              p: "10px 20px",
              maxHeight: "550px",
            }}
          >
            {loading ? (
              <>
                {data && data.length > 0 ? (
                  <>
                    <Flex sx={{ flexDirection: "column", gap: "20px" }}>
                      {items.map((products, i) => {
                        let sub_id = products.id;
                        return (
                          <>
                            {products &&
                              products?.items?.map((plan, idx) => {
                                const firstChar = plan.status
                                  .charAt(0)
                                  .toUpperCase();
                                const remainingChars = plan.status.slice(1);
                                let status = `${firstChar}${remainingChars}`;
                                const dateString2 =
                                  products?.scheduled_change?.effective_at;
                                const dateString = plan.next_billed_at;
                                const dateString1 = plan.previously_billed_at;
                                const date = new Date(dateString);
                                const date1 = new Date(dateString1);
                                const date2 = new Date(dateString2);
                                const options = {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                };
                                let time =
                                  dateString === null
                                    ? "Valid Till"
                                    : "Renews On";
                                const formattedDate =
                                  dateString === null
                                    ? date2.toLocaleDateString(
                                        undefined,
                                        options
                                      )
                                    : date.toLocaleDateString(
                                        undefined,
                                        options
                                      );
                                const formattedDate1 = date1.toLocaleDateString(
                                  undefined,
                                  options
                                );
                                return (
                                  <>
                                    <Flex
                                      key={idx}
                                      sx={{
                                        cursor: "pointer",
                                        border: "0px solid gray",
                                        p: "3%",
                                        borderRadius: "5px",
                                        boxShadow: "1px 1px 5px 1px lightgray",
                                        flexDirection: "column",
                                        gap: "10px",
                                        transition:
                                          "box-shadow 0.3s ease-in-out",
                                        "&:hover": {
                                          boxShadow:
                                            "2px 2px 8px 3px lightgray",
                                        },
                                      }}
                                    >
                                      <Flex
                                        sx={{
                                          justifyContent: "end",
                                          gap: "10px",
                                        }}
                                      >
                                        <Tooltip
                                          label={
                                            // ? 'Plan already Cancelled'
                                            "Cancel"
                                          }
                                          hasArrow
                                        >
                                          <Button
                                            sx={{
                                              gap: "5px",
                                              width: "20%",
                                              color: "gray",
                                              cursor: "pointer",
                                              "&:hover": {
                                                "& #icon": {
                                                  fill: "rgb(21,46,115)",
                                                },
                                              },
                                            }}
                                            onClick={() =>
                                              handleCancelSubscription(
                                                products.id
                                              )
                                            }
                                            //  isDisabled={cancelled}
                                          >
                                            <Icon
                                              as={FcCancel}
                                              boxSize="1.3em"
                                              sx={{
                                                transition: "transform 0.15s",
                                              }}
                                            />
                                            Cancel
                                          </Button>
                                        </Tooltip>
                                        <DashButton
                                          onClick={() =>
                                            navigate("/manage-subscription")
                                          }
                                          sx={{
                                            gap: "5px",
                                            width: "30%",
                                            cursor: "pointer",
                                            "&:hover": {
                                              "& #icon": {
                                                fill: "rgb(21,46,115)",
                                              },
                                            },
                                          }}
                                        >
                                          <Icon
                                            as={FiSave}
                                            boxSize="1.3em"
                                            sx={{
                                              transition: "transform 0.15s",
                                            }}
                                          />
                                          Upgrade
                                        </DashButton>
                                      </Flex>
                                      <Flex
                                        key={idx}
                                        sx={{
                                          fontSize: "20px",

                                          fontWeight: "500",
                                          alignItems: "center",
                                          gap: "15px",
                                        }}
                                      >
                                        {product[i]?.name === "LTD Plan" ? (
                                          <FaInfinity
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        ) : product[i]?.name === "Pro" ? (
                                          <SiProcesswire
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        ) : product[i]?.name === "Team" ? (
                                          <BsMicrosoftTeams
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        ) : product[i]?.name === "Topup" ? (
                                          <RiHandCoinFill
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        ) : product[i]?.name ===
                                          "ChatGPT_Plugin" ? (
                                          <RiOpenaiFill
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        ) : (
                                          <FaPeopleArrows
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              color: "#162c73",
                                            }}
                                          />
                                        )}

                                        {product[i]?.name}
                                      </Flex>
                                      <Flex sx={{ gap: "10px" }}>
                                        Status:{" "}
                                        <Flex
                                          sx={{
                                            color: "#033803",
                                            bg: "#b6fac6",
                                            borderRadius: "5px",
                                            p: "2px 5px",
                                            alignItems: "center",
                                            textAlign: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <AiFillCheckCircle
                                            style={{ color: "#0a7e0a" }}
                                          />{" "}
                                          {status}
                                        </Flex>
                                        <Flex
                                          sx={{
                                            alignItems: "center",
                                            textAlign: "center",
                                            fontWeight: "500",
                                          }}
                                        >
                                          ${plan.price.unit_price.amount / 100}{" "}
                                          / {plan.price.billing_cycle.interval}
                                        </Flex>
                                      </Flex>

                                      <Flex>
                                        Details :{" "}
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {plan.price.description}
                                        </span>
                                      </Flex>
                                      <Flex>
                                        Starts From:{" "}
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {" "}
                                          {formattedDate1}{" "}
                                        </span>
                                      </Flex>
                                      <Flex>
                                        {time}:{" "}
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          {" "}
                                          {formattedDate}{" "}
                                        </span>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              })}

                            <Modal
                              isOpen={showConfirmation}
                              onClose={handleCancel}
                            >
                              <ModalOverlay />
                              <ModalContent>
                                <ModalHeader>Confirmation</ModalHeader>
                                <ModalBody>
                                  Are you sure you want to cancel?
                                </ModalBody>
                                <ModalFooter sx={{ gap: "10px" }}>
                                  <Button
                                    colorScheme="red"
                                    onClick={() => handleConfirmCancel(sub_id)}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    sx={{
                                      bg: "rgb(21,46,115)",
                                      color: "white",
                                      "&:hover": { color: "black" },
                                    }}
                                    onClick={handleCancel}
                                  >
                                    No
                                  </Button>
                                </ModalFooter>
                              </ModalContent>
                            </Modal>
                          </>
                        );
                      })}
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex sx={{ justifyContent: "end", gap: "10px" }}>
                      <DashButton
                        onClick={() => navigate("/manage-subscription")}
                        sx={{
                          gap: "5px",
                          width: "30%",
                          "&:hover": {
                            "& #icon": {
                              fill: "rgb(21,46,115)",
                            },
                          },
                        }}
                      >
                        <Icon
                          as={FiSave}
                          boxSize="1.3em"
                          sx={{
                            transition: "transform 0.15s",
                          }}
                        />
                        Explore Plans
                      </DashButton>
                    </Flex>
                    <Flex
                      sx={{
                        fontSize: "18px",
                        color: "#162c73",
                        fontWeight: "500",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      {/* <Image  width="10" height="10" src={"https://cdn3.vectorstock.com/i/1000x1000/18/52/security-agency-icon-flat-design-vector-14311852.jpg"}/> */}
                      <Flex sx={{ fontSize: "20px", textAlign: "baseline" }}>
                        <span style={{ color: "black", marginRight: "10px" }}>
                          {"Plan:"}
                        </span>{" "}
                        {profileData?.current_plan}
                      </Flex>
                    </Flex>
                    {!(couponList.length > 0) ? (
                      <>
                        <Flex>
                          {" "}
                          Details :{" "}
                          <span
                            style={{ fontWeight: "500", paddingLeft: "5px" }}
                          >
                            {task_limit
                              ? task_limit + " " + "Tasks per Month"
                              : "Check Dashboard for Task Consumption"}
                          </span>
                        </Flex>{" "}
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <NodeLoader loading={!loading} bgcolor="#fff" />
            )}
          </Flex>
          {couponList.length > 0 ? (
            <Flex width="100%" height="40%" flexDir="column" gap="10px">
              <Flex
                gap="10px"
                alignItems="center"
                pb="5px"
                mt="30px"
                mb="30px"
                borderBottom="2px solid #152D73"
              >
                <Icon
                  as={BiSolidCoupon}
                  boxSize="1.7em"
                  sx={{
                    transition: "transform 0.15s",
                  }}
                />
                <Flex
                  sx={{
                    fontSize: [16, 16, 18, 20, 22, 24],
                    fontWeight: 500,
                    m: "auto 8px",
                  }}
                >
                  Coupon Code Redeemed
                </Flex>
              </Flex>

              <Flex
                width="100%"
                height="100%"
                position="relative"
                flexDir="column"
                //   gap="10px"
                sx={{
                  borderRadius: "4px",
                  boxShadow: "0px 0px 2px 0px rgb(81,142,248, 0.55)",
                }}
              >
                <Flex pr="15px" bgColor="#f3f7ff">
                  {couponHeading.map((heading, i) => {
                    return (
                      <Flex
                        p="10px"
                        key={i}
                        width={1 / 4}
                        fontSize={14}
                        fontWeight={600}
                      >
                        {heading}
                      </Flex>
                    );
                  })}
                </Flex>
                <Flex width="100%" height="100%" position="relative">
                  <Flex
                    position="absolute"
                    sx={{
                      top: "0px",
                      bottom: "0px",
                      left: "0px",
                      width: "100%",
                      right: "0px",
                      borderBottomRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      flexDir: "column",
                    }}
                  >
                    {couponList.map((coupon, i) => {
                      return (
                        <Flex
                          key={i}
                          width="100%"
                          fontSize={14}
                          fontWeight={600}
                        >
                          <Flex width={1 / 4} p="10px">
                            {coupon.code.length > 14
                              ? coupon.code.slice(0, 14) + ".."
                              : coupon.code}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.redeemed_on.slice(0, 10)}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.expires_on.slice(0, 10)}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.task}
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      ) : (
        <Flex
          width="50%"
          height="100%"
          sx={{
            bgColor: "#fff",
            display: "flex",
            boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
            borderRadius: "4px",

            p: "20px",
            gap: "20px",
            flexDir: "column",
            position: "relative",
          }}
        >
          <Flex
            gap="10px"
            alignItems="center"
            pb="5px"
            borderBottom="2px solid #152D73"
          >
            <Icon
              as={FaTelegramPlane}
              boxSize="1.7em"
              sx={{
                transition: "transform 0.15s",
              }}
            />
            <Flex
              sx={{
                fontSize: [16, 16, 18, 20, 22, 24],
                fontWeight: 500,
                m: "auto 0px",
              }}
            >
              Subscription
            </Flex>
          </Flex>
          <Flex sx={{ justifyContent: "end", gap: "10px" }}>
            <DashButton
              onClick={() => navigate("/manage-subscription")}
              sx={{
                gap: "5px",
                width: "30%",
                "&:hover": {
                  "& #icon": {
                    fill: "rgb(21,46,115)",
                  },
                },
              }}
            >
              <Icon
                as={FiSave}
                boxSize="1.3em"
                sx={{
                  transition: "transform 0.15s",
                }}
              />
              Explore Plans
            </DashButton>
          </Flex>
          <Flex
            sx={{
              fontSize: "18px",
              color: "#162c73",
              fontWeight: "500",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {/* <Image  width="10" height="10" src={"https://cdn3.vectorstock.com/i/1000x1000/18/52/security-agency-icon-flat-design-vector-14311852.jpg"}/> */}
            <Flex sx={{ fontSize: "20px", textAlign: "baseline" }}>
              <span style={{ color: "black", marginRight: "10px" }}>
                {"Plan:"}
              </span>{" "}
              {profileData?.current_plan}
            </Flex>
          </Flex>
          {!(couponList.length > 0) ? (
            <>
              <Flex>
                {" "}
                Details :{" "}
                <span style={{ fontWeight: "500", paddingLeft: "5px" }}>
                  {task_limit
                    ? task_limit + " " + "Tasks per Month"
                    : "Check Dashboard for Task Consumption"}
                </span>
              </Flex>{" "}
            </>
          ) : null}
          {couponList.length > 0 ? (
            <Flex width="100%" height="40%" flexDir="column" gap="10px">
              <Flex
                gap="10px"
                alignItems="center"
                pb="5px"
                mt="30px"
                mb="30px"
                borderBottom="2px solid #152D73"
              >
                <Icon
                  as={BiSolidCoupon}
                  boxSize="1.7em"
                  sx={{
                    transition: "transform 0.15s",
                  }}
                />
                <Flex
                  sx={{
                    fontSize: [16, 16, 18, 20, 22, 24],
                    fontWeight: 500,
                    m: "auto 8px",
                  }}
                >
                  Coupon Code Redeemed
                </Flex>
              </Flex>

              <Flex
                width="100%"
                height="100%"
                position="relative"
                flexDir="column"
                //   gap="10px"
                sx={{
                  borderRadius: "4px",
                  boxShadow: "0px 0px 2px 0px rgb(81,142,248, 0.55)",
                }}
              >
                <Flex pr="15px" bgColor="#f3f7ff">
                  {couponHeading.map((heading, i) => {
                    return (
                      <Flex
                        p="10px"
                        key={i}
                        width={1 / 4}
                        fontSize={14}
                        fontWeight={600}
                      >
                        {heading}
                      </Flex>
                    );
                  })}
                </Flex>
                <Flex width="100%" height="100%" position="relative">
                  <Flex
                    position="absolute"
                    sx={{
                      top: "0px",
                      bottom: "0px",
                      left: "0px",
                      width: "100%",
                      right: "0px",
                      borderBottomRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      flexDir: "column",
                    }}
                  >
                    {couponList.map((coupon, i) => {
                      return (
                        <Flex
                          key={i}
                          width="100%"
                          fontSize={14}
                          fontWeight={600}
                        >
                          <Flex width={1 / 4} p="10px">
                            {coupon.code.length > 14
                              ? coupon.code.slice(0, 14) + ".."
                              : coupon.code}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.redeemed_on.slice(0, 10)}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.expires_on.slice(0, 10)}
                          </Flex>
                          <Flex width={1 / 4} p="10px">
                            {coupon.task}
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      )}
    </>
  );
};
