import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { svg } from "../../assets/objectSVG";

export const NodeLoader = React.memo(

  ({ loading, bgcolor, stroke, top, bottom }) => {
    return (
      <Box
        width="100%"
        sx={{
          display: loading ? "flex" : "none",
          position: "absolute",
          top: top ? "46px" : "0px",
          bottom: bottom ? bottom : "0px",
          right: "0px",
          left: "0px",
          m: "auto",
          zIndex: 2,
          bgColor: bgcolor ? bgcolor : "rgb(0,0,0,0.2)",
          borderRadius: !top ? "5px" : "0px",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Flex
          width="100%"
          sx={{ m: "auto", flexDirection: "column", alignItems: "center" }}
        >
          <Flex width="80px" sx={{ stroke: stroke ? stroke : "#162c73" }}>
            {svg.loader}
          </Flex>
          <Flex
            sx={{
              color: stroke ? stroke : "#162c73",
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Loading
          </Flex>
        </Flex>
      </Box>
    );
  }
);
