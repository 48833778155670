import React, { memo, useCallback, useRef, useState } from "react";
import { Checkbox, Flex, useToast } from "@chakra-ui/react";
import {
  BasicInput,
  CaptureButton,
  EventWrapper,
  NodeOuter,
} from "../../styled";
import { AppInfo } from "../ui/AppInfo";
import { useDispatch } from "react-redux";
import { NodeLoader } from "../../loader";
import { Selector } from "../ui/Selector";
import { CopyClipboard } from "../ui/CopyClipboard";
import { svg } from "../../../assets/objectSVG";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import {
  toggleBasicAuth,
  onWebhookAuthChange,
  onResetConfiguration,
  onLinkDelete,
} from "../../../store/slice/canvasSlice";
import { captureWebHookResponse } from "../../../store/thunk/webhookThunk";
import { AdditionalResponse } from "../ui/AdditionalResponse";
import { ModalContainer } from "../../modal/index";
import { t } from "i18next";
import { saveKonnect } from "../../../store/thunk/rightHandAppsThunk";

export const WebhookInner = memo(({ data, selected }) => {
  let hookTimer = null;
  const nodeOuter = useRef();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [copyClipboard, setCopyClipboard] = useState(false);
  const toast = useToast();
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  const helper = {
    onHelp: useCallback(
      () => !data.loading && setModalState({ display: true, type: "help" }),
      [data.loading]
    ),
    onExpand: useCallback(
      () => !data.loading && setExpand((c) => !c),
      [data.loading]
    ),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, []),
    onBasicAuth: (e) => {
      dispatch(
        toggleBasicAuth({
          enabled: e.target.checked,
          nodeId: data.nodeId,
        })
      );
    },
    onCaptureResponse: () => {
      if (!data.loading) {
        setCopyClipboard(true);
        let input = document.createElement("input");
        input.value = data.webhook_url;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        setTimeout(() => {
          setCopyClipboard(false);
        }, 2000);

        dispatch(onLinkDelete({ nodeId: data.nodeId }));
        dispatch(onResetConfiguration(data.nodeId));
        const hitCapture = () => {
          dispatch(captureWebHookResponse(data.nodeId)).then((res) => {
            if (res?.payload?.message !== "WAITING") {
              clearInterval(hookTimer);
            }
            if (res?.payload?.test_status === "Success") {
              // dispatch(saveKonnect({ publish: false }));
              toast({
                position: "top",
                status: "success",
                variant: "solid",
                title: res?.payload?.display_message,
                duration: 2500,
                containerStyle: {
                  fontWeight: 400,
                },
              });
            }
            dispatch(saveKonnect({ publish: false }));
          });
        };
        !hookTimer && hitCapture();
        hookTimer = setInterval(hitCapture, 10000);
      }
    },
  };
  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      id="drag"
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        hookTimer={hookTimer}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        nodeId={data.nodeId}
        expand={expand}
        onHelp={helper.onHelp}
        onExpand={helper.onExpand}
        setModalState={setModalState}
        nodeIdx={data.nodeIdx}
        
      />
      {data.appEvents?.length ? (
        <EventWrapper visibility={true}>
          <Flex sx={{ fontWeight: 500 }}>{t("konnect.sidebar.choose_trigger")}</Flex>
          <Selector
            parentnode={nodeOuter}
            options={data.appEvents}
            nodeId={data.nodeId}
            selectType={"Trigger"}
            value={data.selectedEvent ? data.selectedEvent : null}
          />
        </EventWrapper>
      ) : null}
      {data.provider === "webhook" ? (
        <EventWrapper visibility={true}>
          <Checkbox isChecked={data.enabled} onChange={helper.onBasicAuth}>
            Basic Authentication
          </Checkbox>
          <EventWrapper visibility={data.enabled} child="true">
            <BasicInput
              value={data.apiKey ? data.apiKey : ""}
              placeholder={t("konnect.sidebar.api_key")}
              onFocus={() => (nodeOuter.current.id = "")}
              onBlur={() => (nodeOuter.current.id = "drag")}
              onChange={(e) =>
                dispatch(
                  onWebhookAuthChange({
                    nodeId: data.nodeId,
                    type: "apiKey",
                    input: e.target.value,
                  })
                )
              }
            />
            <BasicInput
              value={data.secretKey ? data.secretKey : ""}
              onFocus={() => (nodeOuter.current.id = "")}
              onBlur={() => (nodeOuter.current.id = "drag")}
              placeholder={t("konnect.sidebar.api_token")}
              onChange={(e) =>
                dispatch(
                  onWebhookAuthChange({
                    nodeId: data.nodeId,
                    type: "secretKey",
                    input: e.target.value,
                  })
                )
              }
            />
          </EventWrapper>
        </EventWrapper>
      ) : null}

      <EventWrapper visibility={true}>
        <Flex sx={{ fontWeight: 500 }}>{t("konnect.sidebar.webhook_url")}</Flex>
        <CopyClipboard
          value={data.webhook_url}
          copyClipboard={copyClipboard}
          setCopyClipboard={setCopyClipboard}
        />
      </EventWrapper>
      {data.provider.toLowerCase() !== "webhook" ? (
        <EventWrapper visibility={true}>
          <Flex fontSize={14}>
            {t("konnect.sidebar.webhook_instructions")}
            <span
              onClick={() =>
                setModalState({ display: true, type: "instructions" })
              }
              style={{
                paddingLeft: "3px",
                paddingRight: "5px",
                color: "#152F73",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {t("konnect.sidebar.hereText")}
            </span>
            <Flex
              onClick={() =>
                setModalState({ display: true, type: "instructions" })
              }
              sx={{ width: "12px", fill: "#152d73", cursor: "pointer" }}
            >
              {svg.externalLink}
            </Flex>
          </Flex>
        </EventWrapper>
      ) : null}

      <EventWrapper visibility={true}>
        <CaptureButton onClick={helper.onCaptureResponse}>
          {t("konnect.sidebar.captureWebhookResponse")}
        </CaptureButton>
      </EventWrapper>
      {data.configResponses?.length ? (
        <>
          <Divider visibility={true} />
          <EventWrapper visibility={true} last="true">
            <ResultContainer response={data.configResponses} />
          </EventWrapper>
        </>
      ) : null}
      {data.configResponses?.length > 3 && (
        <AdditionalResponse
          visibility={true}
          display={modalState.display}
          onClick={helper.additionalResponse}
        />
      )}

      {modalState.display && (
        <ModalContainer
          nodeOuter={nodeOuter}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
        />
      )}
    </NodeOuter>
  );
});