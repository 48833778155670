import React, { useRef, useState } from 'react';
import {
  Flex,
  Input,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { svg } from '../../../assets/objectSVG';
export const ApiItem = ({
  parentNode,
  inputList,
  handleAddClick,
  handleInputChange,
  handleRemoveClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          gap: '10px',
          m: '0% 4%',
          p: '2% 4%',
          flexDirection: 'column',
          border: '1px solid white',
          borderRadius: '3px',
          boxShadow: '1px 1px 3px 1px lightgray',
        }}
      >
        {inputList.map((x, i) => {
          return (
            <>
              <Flex
                sx={{
                  gap: '10px',
                  alignItems: 'center',
                  height: '30px',
                  mb: '10px',
                }}
              >
                <Input width="80%" name="key" placeholder="key" value={x.key}
                onChange={e => handleInputChange(e, i)}
                />
                {inputList.length !== 1 && (
                  <Tooltip label="Delete" hasArrow>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: 'inherit',
                        color: '#666666',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveClick(i)}
                    >
                      {svg.delete}
                    </Box>
                  </Tooltip>
                )}
                {inputList.length - 1 === i && (
                  <Tooltip label="Add New Key" hasArrow>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: 'inherit',
                        color: '#666666',
                        cursor: 'pointer',
                      }}
                      onClick={handleAddClick}
                    >
                      {svg.plus}
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </>
          );
        })}
      </Flex>
    </>
  );
};
