// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentions {
  margin: 0;
  width: 100%;
  height:100%;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
}

.mentions--multiLine .mentions__highlighter {
  padding: 9px;
  min-height: 63px;
  background: #f3f7ff;
  border-radius: 8px;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #b7cbff;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  text-decoration: none;
  pointer-events: none;
}

textarea {
  flex: 1;
  border: 0px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 18px;
  line-height: 21px;
  padding: 14px;
  text-decoration: none;
  text-shadow: none;
  resize: none;
  background: #f3f7ff;
  border: 0px;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/MentionInput/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,OAAO;EACP,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".mentions {\n  margin: 0;\n  width: 100%;\n  height:100%;\n}\n\n.mentions--singleLine .mentions__control {\n  display: inline-block;\n  width: 130px;\n}\n.mentions--singleLine .mentions__highlighter {\n  padding: 1px;\n}\n.mentions--singleLine .mentions__input {\n  padding: 1px;\n}\n\n.mentions--multiLine .mentions__highlighter {\n  padding: 9px;\n  min-height: 63px;\n  background: #f3f7ff;\n  border-radius: 8px;\n}\n.mentions--multiLine .mentions__input {\n  padding: 9px;\n  outline: 0;\n}\n\n.mentions__suggestions__list {\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  font-size: 10pt;\n}\n\n.mentions__suggestions__item {\n  padding: 5px 15px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15);\n}\n\n.mentions__suggestions__item--focused {\n  background-color: #b7cbff;\n}\n\n.mentions__mention {\n  position: relative;\n  z-index: 1;\n  text-decoration: none;\n  pointer-events: none;\n}\n\ntextarea {\n  flex: 1;\n  border: 0px;\n  width: 100%;\n  border-radius: 8px;\n  box-sizing: border-box;\n  border-radius: 6px;\n  font-size: 18px;\n  line-height: 21px;\n  padding: 14px;\n  text-decoration: none;\n  text-shadow: none;\n  resize: none;\n  background: #f3f7ff;\n  border: 0px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
