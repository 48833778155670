import { Flex, Input } from "@chakra-ui/react";
import React, { useRef, useState } from "react";

export const CopyClipboard = React.memo(({ value = "",copyClipboard,setCopyClipboard }) => {
 
  const inputRef = useRef();
  return (
    <Flex sx={{ position: "relative" }}>
      <Input
        value={value}
        ref={inputRef}
        disabled
        sx={{
          bgColor: "#f3f7ff",
          cursor: "not-allowed",
          border: "1px solid #b7c9ff",
          "&:disabled": {
            opacity: 1,
            color: "rgb(0,0,0,07)",
          },
        }}
      />
      <Flex
        onClick={async () => {
          setCopyClipboard(true);
          let input = document.createElement("input");
          input.value = inputRef.current.value;
          document.body.appendChild(input);
          input.select();
          document.execCommand("copy");
          document.body.removeChild(input);

          setTimeout(() => {
            setCopyClipboard(false);
          }, 2000);
        }}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: "0px",
          right: "0px",
          bgColor: copyClipboard ? "#f7c544" : "#152e73",
          color: "#fff",
          height: "100%",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          ml: "10px",
          alignItems: "center",
          px: "10px",
          boxShadow: copyClipboard
            ? "0px 0px 2px 0px #f7c544"
            : "0px 0px 2px 0px #152e73",
          transition: "box-shadow 0.15s, background 0.15s, width 0.15s",
        }}
      >
        {copyClipboard ? "Copied" : "Copy"}
      </Flex>
    </Flex>
  );
});
