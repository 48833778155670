import { configureStore } from "@reduxjs/toolkit";
import canvasReducer from "./slice/canvasSlice";
import authReducer from "./authSlice";
import dashReducer from "./dashSlice";
import appsReducer from "./appsSlice";
import konnectzReducer from "./konnectzSlice";
import { profileReducer } from "./profileSlice";
import historyReducer from "./historySlice";
import { subscriptionReducer } from "./paddleSlice";
export const store = configureStore({
  reducer: {
    canvas: canvasReducer,
    auth: authReducer,
    dash: dashReducer,
    apps: appsReducer,
    konnectz: konnectzReducer,
    profile: profileReducer,
    history: historyReducer,
    subscriptions: subscriptionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
