import { createSlice, current } from "@reduxjs/toolkit";

import {
  editAuthLabelPost,
  getAddonApps,
  getApps,
  getOauthData,
  updateAppAccountStatusInApps,editAuthLabelPostIApps
} from "./thunk/appsThunk";

const initialState = {
  addOnloading: true,
  appsLoading: true,
  addOnList: [],
  appsList: [],
  modalData: null,
  getOauthData: null,
};

export const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    setAppList: (state, action) => {
      state.addOnList = action.payload;
    },
    setModalData: (state, action) => {
      if (action.payload) {
        const { addingAccount, ...rest } = action.payload;
        state.modalData = rest;
      } else {
        state.modalData = action.payload;
      }
    },
    refreshAccounts: (state, action) => {
      state.modalData.accounts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddonApps.fulfilled, (state, action) => {
        state.addOnloading = false;
        state.addOnList = action.payload.apps;
      })
      .addCase(getAddonApps.rejected, (state, action) => {
        state.addOnloading = false;
      })
      .addCase(getApps.fulfilled, (state, action) => {
        state.appsLoading = false;
        state.appsList = action.payload.apps;
      })
      .addCase(getApps.rejected, (state, action) => {
        state.appsLoading = false;
      })
      // .addCase(editAuthLabelPost.fulfilled, (state, action) => {
      //   const accounts = state.modalData.accounts;
      //   accounts.map((acc) => {
      //     if (acc.id === action.meta.arg.id) {
      //       acc.name = action.meta.arg.label;
      //     }
      //   });
      // })
      .addCase(updateAppAccountStatusInApps.fulfilled, (state, action) => {
        if (action.payload.message === "SUCCESS") {
          const accounts = state.modalData.accounts;
          const newAccount = accounts.filter(
            (acc) => acc.id !== action.meta.arg.appId
          );
          state.modalData.accounts = newAccount;
        }
      })
      .addCase(getOauthData.fulfilled, (state, action) => {
        if (state.modalData) {
          state.modalData.addingAccount = action.payload;
        }
        state.getOauthData = action.payload;
      })

      .addCase(editAuthLabelPostIApps.fulfilled, (state, action) => {
        const accounts = state.modalData.accounts;
        accounts.map((acc) => {
          if (acc.id === action.meta.arg.id) {
            acc.name = action.meta.arg.label;
          }
        });
      });
  },
});

export const { setModalData, refreshAccounts } = appsSlice.actions;

export default appsSlice.reducer;
