import React, { useRef, useState, useCallback, memo } from "react";
import { CaptureButton, EventWrapper, NodeOuter } from "../../styled";
import { Flex,Tooltip } from "@chakra-ui/react";
import { NodeLoader } from "../../loader";
import { useTranslation } from "react-i18next";
import { AppInfo } from "../ui/AppInfo";
import { Selector } from "../ui/Selector";
import { ResponseContainerMentionInput } from "../ui/ResponseContainerMentionInput";
import { AdditionalResponse } from "../ui/AdditionalResponse";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";

import { ModalContainer } from "../../modal/index";

import {
  OnTestandReview,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk.js";

import { useSelector, useDispatch } from "react-redux";
import { onLinkDelete } from "../../../store/slice/canvasSlice";

export const AddOnInner = memo(({ data, selected }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeOuter = useRef();
  const { editorState } = useSelector((state) => state.canvas);
  const [expand, setExpand] = useState(false);
  const [expandHeight, setExpandHeight] = useState(false);
  const [captureTooltip, setCaptureTooltip] = useState(false);

  const nodeIdx = editorState.nodes.findIndex(
    (node) => node.id === data.nodeId
  );
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  
  const helper = {
    onHelp: useCallback(() => {
      !data.loading && setModalState({ display: true, type: "help" });
    }, [data.loading]),
    onExpand: useCallback(() => {
      if (expand && modalState.type === "accounts") {
      } else !data.loading && setExpand((c) => !c);
    }, [data.loading, modalState]),
    additionalResult: useCallback(() => {
      setModalState({ display: true, type: "Normal" });
    }, [data.configResponses]),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    onTestandReview: useCallback(() => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      dispatch(OnTestandReview(data.nodeId)).then((res) => {
        dispatch(saveKonnect({ publish: false }));
      });
    }, [data]),
  };

  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      expandheight={expandHeight ? "true" : "false"}
      id="drag"
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalState={setModalState}
      />

      {data.type === "ADD_ON" ? (
        <>
          <EventWrapper visibility={true}>
            <Flex>{nodeIdx === 0 ? t("konnect.sidebar.choose_trigger") :t("konnect.sidebar.choose_action")}</Flex>
            <Selector
              parentnode={nodeOuter}
              options={data.appEvents}
              nodeId={data.nodeId}
              selectType="Action"
              value={data.selectedEvent ? data.selectedEvent : null}
              isAddOn="true"
            />
          </EventWrapper>
          <EventWrapper
            visibility={
              data.appEventConfigurations?.length
                ? true
                : false &&
                  data.appEventConfigurations.filter((x) => x.sequence !== 0)
                    .length
                ? true
                : false && data.appEvent
                ? true
                : false
            }
          >
            <Flex>{t("konnect.sidebar.choose_option_header")}</Flex>
            {data.appEventConfigurations?.map((x, i) => {
              let title = x?.title;
              return (
                <Selector
                  key={i}
                  index={i}
                  parentnode={nodeOuter}
                  selectType="Sequence"
                  totalSeq={data.appEventConfigurations.length}
                  fetchFieldStatus={data.fetchFieldStatus}
                  configResponses={data.configResponses}
                  nodeId={data.nodeId}
                  selectedConfig={x}
                  value={x.selected || null}
                  options={x.config_details}
                  customField={false}
                  placeholder={title || "Select..."}
                  nodeIdx={data.nodeIdx}
                />
              );
            })}
          </EventWrapper>

          <EventWrapper
            visibility={data.configResponses?.length ? true : false}
          >
            <ResponseContainerMentionInput
              result={data.configResponses}
              parentnode={nodeOuter}
              nodeId={data.nodeId}
              data={data}
            />
          </EventWrapper>
          <EventWrapper visibility={data?.appEvent ? true : false}>
          <Tooltip
            isOpen={captureTooltip}
            onOpen={() => !data.trigger_konnect_id && setCaptureTooltip(true)}
            onClose={() => !data.trigger_konnect_id && setCaptureTooltip(false)}
            hasArrow
            label={
              data.nodeIdx === 0
                ? "Only Mandatory fields must be filled to Trigger"
                : "Use Trigger first to create a Konnectz"
            }
          >
            <CaptureButton 
            isDisabled={data.trigger_konnect_id?false :true}
            onClick={helper.onTestandReview}>
              {t("konnect.sidebar.scheduler.test_review")}
            </CaptureButton>
          </Tooltip>

          </EventWrapper>
          <Divider visibility={data?.additionalResponses} />
          <EventWrapper visibility={data?.additionalResponses} last="true">
            <ResultContainer
              response={data.configResponses}
              additional="true"
            />
          </EventWrapper>
          {data?.additionalResponses > 3 && !modalState.display ? (
            <AdditionalResponse
              visibility={data?.additionalResponses > 3 ? true : false}
              display={modalState.display}
              onClick={helper.additionalResponse}
            />
          ) : null}

          {modalState.display && (
            <ModalContainer
              nodeOuter={nodeOuter}
              modalState={modalState}
              setModalState={setModalState}
              data={data}
              setExpand={setExpand}
              setExpandHeight={setExpandHeight}
            />
          )}
        </>
      ) : null}
    </NodeOuter>
  );
});
