import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const chinese = {
  translation: {
    common: {
      app: '康内茨',
    },
    header: {
      topNavigations: {
        tipsAndTricks: '技巧与窍门',
        pricing: '定价',
        blogs: '博客',
        helpCenter: '帮助中心',
      },
      searchAppTextPlaceholder: '在这里搜索...',
      createKonnectzBtnLabel: '创建 Konnectz',
      taskhistory: '任务历史',
      success: '成功',
      error: '错误',
      notask: '未找到任务',
      tryagain: '调整您的筛选条件，然后重试',
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        '密码重置说明已发送至电子邮件，请查看您的邮箱。',
      SENT_RESET_INSTRUCTIONS_FAIL: '未经授权的请求！',
      VERIFY_EMAIL_FAIL: '抱歉，无法确认您的电子邮件。',
      PASSWORD_NOT_UPDATED: '抱歉，无法更新您的帐户',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL: '未能发送密码说明。请稍后再试！',
      AUTH_SIGN_UP_FAIL: '出了些问题。请稍后再试！',
      somethingWentWrong: '出了些问题。请稍后再试！',
    },
    successMessages: {
      PASSWORD_UPDATED: '密码已更新。请使用新密码登录！',
    },
    history: {
      task: {
        task: '任务',
        tasks: '任务',
        taskdate: '任务日期：',
        user: '用户：',
        search_history: '搜索历史',
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: '创建密码',
          subtitle: '输入两次以确认',
          passwordErrorMsg: '无效的密码！',
          confirmPasswordErrorMsg: '无效的密码！',
          passwordMatchingErrorMsg: '两次输入的密码不匹配！',
          continueBtnLabel: '继续',
          createPasswordPlaceholder: '创建密码',
          confirmPasswordPlaceholder: '确认密码',
          passwordRule:
            '提示：您的密码必须在8到15个字符之间，且必须包含至少一个大写字母、一个小写字母、一个数字和一个特殊字符。',
          passwordRule1: '您的密码必须在8到15个字符之间。',
          passwordRule2: '您的密码必须包含至少一个大写字母。',
          passwordRule3: '您的密码必须包含至少一个数字。',
          passwordRule4: '您的密码必须包含至少一个特殊字符。',
        },
        verificationSent: {
          title: '验证',
          subtitle: '验证电子邮件地址',
          verificationText1: '我们已发送验证链接至',
          verificationText2: '，请打开邮件并验证您的电子邮件地址。',
          didntReceiveEmailText: '没有收到邮件？',
          resendText: '重新发送',
        },
        verifySuccess: {
          title: '验证已完成',
          verifyFailedTitle: '验证失败',
        },
        signupSuccess: {
          firstName: '名字',
          firstNameErrorMsg: '名字是必需的！',
          lastName: '姓氏',
          lastNameErrorMsg: '姓氏是必需的！',
          verifyErrorMsg: '出了些问题。如果您的帐户已经通过验证，请尝试登录。',
        },
        title: '创建您的帐户',
        subtitle: '嗨，欢迎',
        verifyingMessage: '正在验证您',
        emailInvalid: '电子邮件无效！',
        emailExists: '您输入的电子邮件地址已注册',
        teamSignup: '以团队身份注册？',
        continueBtnLabel: '继续',
        alreadyHaveAccountText: '已经有帐户了？',
        loginBtnLabel: '登录',
        emailPlaceholder: '输入电子邮件',
        features: {
          feature1: {
            title: '简单自动化',
            image: SimpleAutomation,
            description:
              '使用我们的可视化连接构建器，在几分钟内简单轻松地构建复杂工作流程',
          },
          feature2: {
            title: '适用于所有人的平台',
            image: PlatformForEveryone,
            description:
              '不仅仅是针对程序员的常规自动化工具，任何人都可以使用 KonnectzIT 构建自动化',
          },
          feature3: {
            title: '无缝应用集成',
            image: SeamlessAppsIntegration,
            description: '从我们的集成库中连接无限的应用程序，并自动化日常任务',
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          '出了些问题。如果您的帐户已经验证，请尝试登录。',
      },
      login: {
        title: '登录您的帐户',
        subtitle: '嗨，欢迎回来！',
        emailPlaceholder: '输入电子邮件',
        emailInvalid: '无效的电子邮件！',
        passwordRequired: '密码是必需的！',
        passwordPlaceholder: '输入密码',
        rememberMe: '记住我',
        loginBtnLabel: '立即登录',
        forFirstTimeSignup: '第一次？',
        forgotPasswordLbl: '忘记密码',
        alreadyHaveAccountText: '还没有帐户？',
        clickingContinueText: '点击继续，即表示您同意我们的',
        termsAndConditionsText: '条款和条件。',
        signupNowText: '现在注册',
        features: {
          feature1: {
            title: '无限步骤',
            image: UnlimitedSteps,
            description: '连接多个应用程序，为无缝自动化构建无限的步骤。',
          },
          feature2: {
            title: '可视化一切',
            image: VisualizeEverything,
            description: '使用可视化连接构建器构建工作流并设想自动化。',
          },
          feature3: {
            title: '集成库',
            image: IntegrationLibrary,
            description:
              '汇集了各种云应用程序，可以在它们之间传输和发送数据，节省您的时间。',
          },
        },
        loginFailMsg: '用户名或密码无效！',
        captchaErrorMsg: '验证码错误',
      },
      forgot: {
        title: '忘记密码',
        forgtoBtnLabel: '获取忘记密码链接',
        passwordLink: '我们已发送忘记密码链接至',
        successSubTitle: '已发送电子邮件',
        features: {
          feature1: {
            title: '简单自动化',
            image: SimpleAutomation,
            description:
              '使用我们的可视化连接构建器，在几分钟内简单轻松地构建复杂工作流程',
          },
          feature2: {
            title: '适用于所有人的平台',
            image: PlatformForEveryone,
            description:
              '不仅仅是针对程序员的常规自动化工具，任何人都可以使用 KonnectzIT 构建自动化',
          },
          feature3: {
            title: '无缝应用集成',
            image: SeamlessAppsIntegration,
            description: '从我们的集成库中连接无限的应用程序，并自动化日常任务',
          },
        },
        emailInvalid: '无效的电子邮件！',
      },
      reset: {
        title: '重置密码',
        subTitle: '创建新密码',
      },
    },
    konnect: {
      sidebar: {
        search_apps: '在应用中搜索',
        search_apps_endList: '耶！您已经查看了全部内容',
        add_on_apps: '附加应用程序',
        integrated_apps: '集成的应用程序',
        choose_trigger: '选择触发器',
        choose_action: '选择动作',
        textFormater: {
          formaterText: '输入“@”并选择值',
        },
        scheduler: {
          userTimeInput: '以分钟、小时或天为单位输入值',
          selectDate: '选择日期',
          select: '选择',
          test_review: '测试与审核',
          time: '时间',
          dates: '日期',
          date: '日期',
          days: '天',
          everyday_time: '每天时间',
        },
        responseModal: {
          searchByLable: '按标签搜索',
          no_labelFound: '未找到标签',
          nodeInner_title: '选择更多值',
          labelText: '标签：',
        },
        coupon_generator: {
          numberPlaceholder: '编号',
        },
        choose_trigger_header: '选择应用事件',
        no_triggers_available: '没有可用的触发器',
        choose_format_header: '格式选项',
        choose_config_header: '选择配置',
        select_trigger_action_dropdown_header: '选择',
        choose_option_header: '定义选项',
        no_options_available: '没有可用的选项',
        choose_linked_account: '选择帐户',
        customize_form_header: '配置表单',
        customize_form_partial: '选择',
        configure_response: '自定义响应',
        basic_auth: '基本身份验证',
        api_key: 'API 密钥',
        api_token: 'API 密钥',
        webhook_url: 'Webhook URL',
        captureWebhookResponse: '捕获 Webhook 响应',
        hereText: '这里',
        webhook_instructions: 'Webhook 说明 - 点击',
        webhook_url_toast: 'Webhook URL 已复制到剪贴板！',
        webhook_response: 'Webhook 响应',
        choose_action_event: '选择操作',
        endpoint_url: '端点 URL',
        payload_type: '载荷类型',
        wrap_request_array: '在数组中包装请求',
        set_params: '设置参数',
        add_header: '添加标头',
        delayLabel: '延迟',
        delayText: '您想要多长时间的延迟',
        set_paramter: '设置参数',
        expiry_time: '时间线计数',
        expiry_val: '时间线',
        segmentIndex: '分段索引',
        validate: '验证',
        generate: '生成',
      },
      tobar: {
        cancelBtnLabel: '取消',
        inputLeftElm_placeholder: '在 1000 多个集成应用程序或附加应用中搜索',
        redoBtnLabel: '重做',
        undoBtnLabel: '撤消',
      },
      UI: {
        betaText: '测试版',
        webhookDuplicateToastMsg: 'Webhook 应用程序已存在！',
      },
      header: {
        backBtnLabel: '返回',
        saveBtnLabel: '保存',
        publishBtnLabel: '发布',
        nameOfKonnectz: '名称：',
      },
      testAndReview: {
        btnLabel: '测试与审核',
        taskAvailableLabel: '任务可用',
        taskConsumbedLabel: '任务已消耗',
        taskCnsumptionLabel: '任务消耗',
      },
      proceed: {
        btnLabel: '继续',
      },
      additionalConfigsModal: {
        cancelBtnLabel: '取消',
        okBtnLabel: '确定',
      },
      conditions: {
        conditionText: '条件',
        addNewConditionBtnLabel: '添加新条件 +',
        cancelBtnLabel: '取消',
        okBtnLabel: '确定',
        addBtnLabel: '添加',
        operatorAndLabel: '和',
        operatorOrLabel: '或',
        headerConditionText: '条件',
        selectFieldHeaderTitle: '选择字段',
        selectConditionHeaderTitle: '选择条件',
        validateBtnLabel: '验证',
        conditionsHeaderText: '条件',
      },
      publishSuccessMsg: 'Konnectz 已成功发布！',
      saveSuccessMsg: 'Konnectz 已成功保存！',
    },
    apps: {
      authenticatedAppsTitle: '已验证的应用',
      aunthenticateNowTitle: '立即验证',
      noAppsToAunthenticate: '目前没有要验证的应用。',
      authenticateNow: '您还没有验证的应用。立即验证。',
      addAccountText: '添加帐户',
      betaText: '测试版',
      integratedApps: '集成的应用',
      followInstrusctionText: '请按照说明进行更多参考',
      oAuthHandlerText: 'KonnectzIT OAuth 处理程序，请等待...',
      cancelBtnText: '取消',
      newsLable: '新闻',
      yesContinueText: '是的，继续',
      requiredText: '（必填）',
      accessAPIModalHeaderText1: '允许 KonnectzIT 访问',
      accessAPIModalHeaderText2: ' 帐户？',
      accessAPIModalMainText1: ' ',
      accessAPIModalMainText2: ' 可在',
      accessAPIModalMainText3: ' 集成页面找到',
      authorized: '已授权的应用',
    },
    konnectzList: {
      homeText: '主页',
      allText: '全部',
      searchText: '搜索',
      createkonnectzLabel: '创建',
      noKonnectzInfo: '暂时没有 konnectz！',
      deleteModalBodyText: '确定要删除吗？',
      deleteModalNoText: '否',
      deleteModalDeleteText: '删除',
      folders: {
        createFolderTitleText: '文件夹名称',
        enterFolderName: '输入文件夹名称',
        fodersLeftTitleTextL: '文件夹',
        createFolderInputPlaceholder: '输入文件夹名称',
        createFolderBtn: '创建',
        nameTitle: '名称',
        noKonnectz: '似乎您没有',
        deleteFolderConfirmationText: '确定要删除此文件夹吗？',
        cancelBtn: '取消',
        newFolder: '新建文件夹',
        deleteBtn: '删除',
        selectFolderInfo: '选择要将文件移动到的文件夹',
        moveFolderCancelBtn: '取消',
        moveBtn: '移动文件夹',
        renameSaveBtn: '重命名文件夹',
        renameFolder: '重命名',
        renameYourFolderText: '重命名您的文件夹',
        createFolderBtn: '创建文件夹',
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: '最新的应用',
        },
        totalKonnectz: {
          headerTitle: '总的 Konnectz',
          activeKonnectz: '活动的 Konnectz',
          inactiveKonnectz: '不活动的 Konnectz',
        },
        latestKonnectz: {
          headerTitle: '最新的 Konnectz',
          vieawAll: '查看全部',
        },
        subscription: {
          subscriptionTitle: '订阅',
          contentText: '升级至 Pro 版以解锁更多功能',
          applyNewCoupon: '应用新优惠券',
          applyBtnLabel: '应用',
          featuresIncludedLabel: '包括的功能',
          upgradeBtnLabel: '立即升级',
          unlockMoreUpgradingText: '升级至 Pro 版以解锁更多功能',
        },
        taskConsumption: {
          headerTitle: '任务消耗',
        },
      },
    },

    account: {
      myProfileText: '我的个人资料',
      billingtext: '账单地址',
      emailAddressLabel: '电子邮件地址',
      firstNameLabel: '名字',
      lastNameLabel: '姓氏',
      subAccountsLabel: '子账户',
      firstNameRequiredText: '名字不能为空！',
      lastNameRequiredText: '姓氏不能为空！',
      countryRequiredText: '必须填写国家！',
      addressLine1RequiredText: '必须填写地址第一行！',
      cityRequiredText: '必须填写城市！',
      stateRequiredText: '必须填写州/省份！',
      zipCodeRequiredText: '必须填写邮政编码！',
      taxIdRequiredText: '必须填写税号！',
      companyNameLabel: '公司名称',
      currentPositionLabel: '当前职位',
      countryLabel: '国家',
      addressLabel: '地址',
      cityLabel: '城市',
      stateLabel: '州/省份',
      zipCodeLabel: '邮政编码',
      taxIdLabel: '税号',
      timeZoneLabel: '时区',
      changeLanguageLabel: '选择语言',
      changePasswordHeaderText: '更改密码',
      currentPasswordLabel: '当前密码',
      currentPasswordRequiredText: '必须填写当前密码！',
      newPasswordLabel: '新密码',
      newPasswordRequiredText: '必须填写新密码！',
      confirmPasswordLabel: '确认密码',
      confirmPasswordRequiredText: '必须填写确认密码！',
      updateBtnLabel: '更新',
      editUpdateAccountSideBarText: '编辑/更新帐户',
      changePasswordSideBarText: '更改密码',
      billingText: '账单',
      teamText: '团队',
      agencyText: '机构',
      sideBarHeaderAccountText: '帐户',
      profileLable: '个人资料',
      profileUpdatedSuccessfullyText: '个人资料更新成功！',
      addTeamMemberText: '添加团队成员',
      addSubAccountsText: '添加子账户',

      billing: {
        couponCodeText: '优惠码',
        applyCodeBtnLbl: '应用优惠码',
        tasksText: '任务',
        promotionalTasksText: '附加任务',
        redeemedOnText: '已兑换于',
        expiresOnText: '到期于',
        noCouponsAppliedText: '未应用许可证',
        plansAndFeaturesText: '计划和特性',
        currentPlanText: '当前计划',
        upgradeText: '升级',
        billingText: '账单',
        licenseerror: '无效的许可证！',
        completionofprofile:
          '在兑换代码之前，请完善您的个人资料。这是GPDR/数据处理和计费目的所必需的',
      },
      team: {
        teamLabel: '团队',
        name: '姓名',
        firstName: '名字',
        lastName: '姓氏',
        access: '访问权限',
        email: '电子邮件',
        addBtn: '添加',
        cancelBtn: '取消',
        assignAccessLbl: '分配访问权限',
        noUsersInfo: '没有可用用户',
        collapseTeamLabel: '折叠团队',
        expandTeamLabel: '展开团队',
        teamMembersHeaderText: '团队成员',
      },
      agency: {
        agencyLabel: '机构',
        name: '客户姓名：',
        firstName: '名字',
        lastName: '姓氏',
        tasks: '已分配任务：',
        consumed: '已消耗的任务',
        agencyConsumed: '已消耗',
        remainingLabel: '剩余',
        totalSubaccounts: '子账户总数：',
        email: '电子邮件：',
        active: '激活：',
        inActive: '未激活：',
        addBtn: '添加',
        addMember: '添加成员',
        deleteMember: '删除成员',
        cancelBtn: '取消',
        editMemberLabel: '编辑成员',
        noAgenciesInfo: '没有可用机构',
        agencyMembersHeaderText: '子账户',
        total: '总任务数：',
        update: '更新',
        saveChangeLabel: '保存更改',
        updateTask: '更新任务',
        delete: '删除',
        available: '可用任务',
        deactivate: '激活',
        totalAssigned: '总分配任务数',
        activate: '停用',
        detailsLabel: '详情',
        subaccounterror: '无法更新子账户详情！',
      },
      reseller: {
        resellerLabel: '经销商',
      },
    },

    sideNav: {
      homeLabel: '首页',
      appsLabel: '应用',
      konnectzLabel: '连接',
      historyLabel: '历史',
      historyCapsLabel: '历史',
      accountLabel: '帐户',
      notificationsLabel: '通知',
      logoutLabel: '退出登录',
      getHelpLabel: '需要帮助？',
      helpModalText: '敬请期待。我们即将推出！',
      konnectzITLogoText: 'KonnectzIT',
    },
  },
};
