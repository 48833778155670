import React, { useEffect, useState, useTransition } from 'react';
import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
  Text,
  Button,
} from '@chakra-ui/react';
import { dashSVG, svg, svg_1 } from '../../assets/objectSVG';
import { useDispatch, useSelector } from 'react-redux';
import {
  getApps,
  getLinkedAccountsinModal,
  saveOauthData,
  searchApp,
} from '../../store/thunk/appsThunk';
import { NodeLoader } from '../../components/loader';
import { SearchIcon } from '@chakra-ui/icons';
import { setModalData } from '../../store/appsSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AccountModalInapps } from '../../components/modal/AccountModalInapps';
import AuthTokenService from '../../utils/AuthTokenService';
import { useNavigate } from 'react-router-dom';
import { setRoute } from '../../store/dashSlice';
import { useTranslation } from 'react-i18next';
export const AppsContainer = () => {
  const { addOnloading, addOnList, appsLoading, appsList, modalData } =
    useSelector((state) => state.apps);
  const navigate = useNavigate();
  const dummyList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const dispatch = useDispatch();
  const [listedApp, setListedApp] = useState([]);
  const [pageSize, setPageSize] = useState(60);
  const [searching, setSearching] = useState(false);
  const [inst, setInst] = useState(false);
  const [webInst, setWebInst] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getApps({ page_size: pageSize })).then((res) => {
      if (res.error) {
        dispatch(setRoute('Dashboard'));
        AuthTokenService.clear();
        navigate('/login');
      } else {
        setListedApp(res.payload.apps);
      }
    });
  }, [pageSize]);

  const handleChange = (e) => {
    if (!e.target.value) {
      setListedApp(appsList);
      setSearching(false);
    } else {
      setSearching(true);
      e.target.value.length > 1 &&
        dispatch(searchApp({ search_query: e.target.value })).then((res) => {
          if (res.payload.apps.length > 0) {
            setListedApp(res.payload.apps);
          } else setListedApp([]);
        });
    }
  };

  return (
    <Flex width="100%" height="100%" sx={{ paddingY: '20px', gap: '20px' }}>
      <Flex
        width="100%"
        sx={{
          bgColor: '#fff',
          boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
          borderRadius: '4px',
          p: '20px',
          gap: '20px',
          flexDir: 'column',
          position: 'relative',
        }}
      >
        <Flex
          width="100%"
          sx={{
            pb: '10px',
            borderBottom: '2px solid #152d73',
            justifyContent: 'space-between',
          }}
        >
          <Flex
            sx={{
              fontWeight: 600,
              width: '100%',
              fontSize: [16, 16, 18, 18, 18, 20],
              m: 'auto 0px',
              gap: '20px',
            }}
          >
            {t('apps.integratedApps')}
            <Tooltip
              label="Add, re-authorize or delete authorized accounts in more than 1000+ applications."
              placement="right"
            >
              <Flex
                sx={{
                  m: 'auto 0px',
                  fill: 'rgb(0,0,0,0.5)',
                  cursor: 'pointer',
                  '&:hover': {
                    fill: '#000',
                    transform: 'scale(1.1)',
                  },
                  transition: 'fill 0.15s, transform 0.15s',
                }}
              >
                {dashSVG.info}
              </Flex>
            </Tooltip>
          </Flex>
          <InputGroup
            size={{
              base: 'sm',
              sm: 'sm',
              md: 'md',
              lg: 'md',
              xl: 'md',
              '2xl': 'md',
            }}
            sx={{ justifySelf: 'flex-end', width: '50%' }}
          >
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="rgb(21,45,115,0.6)" />
            </InputLeftElement>
            <Input
              onChange={handleChange}
              variant="filled"
              placeholder={t('konnect.sidebar.search_apps')}
              sx={{
                borderTop: '1px solid #162c73',
                borderBottom: '3px solid #162c73',
                bgColor: '#fff',
                '&:hover': {
                  bgColor: '#fff',
                },
                borderLeft: '1px solid #162c73',
                borderRight: '1px solid #162c73',
              }}
            />
          </InputGroup>
        </Flex>
        <Flex position="relative" sx={{ height: '100%', width: '100%' }}>
          <Flex
            id="scrollableDiv1"
            sx={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              overflowY: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={listedApp.length || 0} //This is important field to render the next data
              next={() => setPageSize((c) => c + 20)}
              hasMore={listedApp.length > 1000 ? false : true}
              pullDownToRefresh={false}
              scrollableTarget="scrollableDiv1"
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>{t('konnect.sidebar.search_apps_endList')}</b>
                </p>
              }
              loader={
                !searching && (
                  <Flex width="80px" sx={{ m: '20px auto', stroke: '#162c73' }}>
                    {svg.loader}
                  </Flex>
                )
              }
            >
              <Wrap
                spacing="10px"
                justify="center"
                width="100%"
                sx={{
                  p: '2px',
                  pl: '10px',
                }}
              >
                {appsLoading ? (
                  <NodeLoader loading={appsLoading} bgcolor="#fff" />
                ) : (
                  listedApp.map((app, i) => {
                    return (
                      <Tooltip
                        key={i}
                        isDisabled={app.name.length > 10 ? false : true}
                        label={app.name}
                        hasArrow
                      >
                        <WrapItem
                          key={i}
                          onClick={() => {
                            if (app.auth_type) {
                              setInst(false);
                              onOpen();
                              dispatch(getLinkedAccountsinModal(app)).then((res) =>
                                dispatch(
                                  setModalData({
                                    ...app,
                                    accounts:
                                      res.payload.authorized_app_accounts,
                                  })
                                )
                              );
                            } else {
                              onOpen();
                              setInst(true);
                              setWebInst(app.webhook_instructions);
                            }
                          }}
                          p={2}
                          flexDir="column"
                          sx={{
                            width: [
                              '100px',
                              '100px',
                              '110px',
                              '120px',
                              '130px',
                              '140px',
                            ],
                            boxShadow: '0px 0px 3px 1px rgb(81,142,248, 0.35)',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: '0px 0px 3px 1px rgb(81,142,248, 1)',
                              '& #image': {
                                transform: 'scale(1.1)',
                              },
                            },
                          }}
                        >
                          <Flex
                            id="image"
                            sx={{
                              position: 'relative',
                              transition: 'transform 0.15s',
                            }}
                          >
                            <Image width="100px" src={app.image_url} />
                            <Box
                              sx={{
                                display:
                                  app.account_count > 0 ? 'flex' : 'none',
                                position: 'absolute',
                                right: '0px',
                                top: '0px',
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                bgColor: '#152d73',
                                color: '#fff',
                                justifyContent: 'center',
                                p: '2px',
                                fontSize: 7,
                              }}
                            >
                              {app.account_count}
                            </Box>
                            <Box
                              sx={{
                                display:
                                  app.status === 'Beta' ? 'flex' : 'none',
                                position: 'absolute',
                                left: '0px',
                                top: '0px',
                                bgColor: '#f7c544',
                                color: '#fff',
                                justifyContent: 'center',
                                py: '2px',
                                borderTopLeftRadius: '4px',
                                borderBottomRightRadius: '4px',
                                px: '5px',
                                fontSize: 7,
                              }}
                            >
                              {app.status}
                            </Box>
                          </Flex>

                          <Flex
                            sx={{
                              fontSize: [12, 12, 12, 14, 14, 14],
                              textAlign: 'center',
                            }}
                          >
                            {app.name.length > 10
                              ? app.name.slice(0, 10) + '..'
                              : app.name}
                          </Flex>
                        </WrapItem>
                      </Tooltip>
                    );
                  })
                )}{' '}
              </Wrap>
            </InfiniteScroll>
          </Flex>
        </Flex>
      </Flex>

      <Modal
        isCentered
        size={{
          base: 'lg',
          sm: 'lg',
          md: 'xl',
          lg: '2xl',
          xl: '2xl',
          '2xl': '3xl',
        }}
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          dispatch(setModalData(null));
        }}
      >
        <ModalOverlay />
        <ModalContent flexDir="column" sx={{ minHeight: '180px' }}>
          {modalData ? (
            <>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  p: '10px 15px 5px 15px',
                  borderBottom: '2px solid #152d73',
                }}
              >
                <Flex width="100%" sx={{ alignItems: 'center', gap: '10px' }}>
                  <Image
                    src={modalData.image_url}
                    sx={{ width: ['8%', '8%', '8%', '7%', '7%', '6%'] }}
                  />
                  <Flex sx={{ fontSize: [14, 14, 16, 16, 18, 20] }}>
                    {' '}
                    {modalData.name}
                  </Flex>
                  <Flex
                    sx={{
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      fontSize: 9,
                      justifyContent: 'center',
                      pt: '2px',
                      color: '#fff',
                      bgColor: '#152d73',
                    }}
                  >
                    {modalData.account_count}
                  </Flex>
                </Flex>
                <Flex
                  onClick={() => {
                    onClose();
                    dispatch(setModalData(null));
                  }}
                  sx={{
                    width: ['25px', '25px', '27px', '19px', '31px', '33px'],
                    fill: '#ff0000',
                    cursor: 'pointer',
                    transition: 'fill 0.15s',
                    '&:hover': {
                      fill: '#ff0000',
                    },
                  }}
                >
                  {dashSVG.close}
                </Flex>
              </Flex>
              <AccountModalInapps inst={inst} />
            </>
          ) : inst ? (
            <Flex
              height="100%"
              width="100%"
              sx={{
                p: '16px',
                flexDirection: 'column',
                bgColor: '#fff',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              <Flex sx={{ justifyContent: 'end', alignItems: 'center' }}>
                <Flex
                  onClick={() => {
                    onClose();
                    setInst(false);
                    setWebInst(null);
                  }}
                  sx={{
                    width: ['25px', '25px', '27px', '19px', '31px', '33px'],
                    fill: '#ff0000',
                    cursor: 'pointer',
                    transition: 'fill 0.15s',
                    '&:hover': {
                      fill: '#ff0000',
                    },
                  }}
                >
                  {dashSVG.close}
                </Flex>{' '}
              </Flex>
              <Flex sx={{ flexDirection: 'column', overflowY: 'auto' }}>
                {webInst.trim() !== '' &&
                  JSON.parse(webInst).map((item, i) => (
                    <Flex
                      key={i}
                      sx={{
                        color: '#7E7E7E',
                        fontSize: 16,
                        '& a': { color: '#F7C545', textDecoration: 'none' },
                      }}
                    >
                      -{' '}
                      <label dangerouslySetInnerHTML={{ __html: item }}></label>
                      <br />
                    </Flex>
                  ))}
              </Flex>{' '}
            </Flex>
          ) : (
            <NodeLoader
              loading={!modalData && !inst && !webInst}
              bgcolor="#fff"
            />
          )}
        </ModalContent>
      </Modal>
    </Flex>
  );
};
