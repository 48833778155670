import { Button, Flex, ScaleFade, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export const InstructionModal = React.memo(({ data, setModalState }) => {
  const {t} = useTranslation()
  return (
    <>
      <Flex
        height="100%"
        width="100%"
        sx={{
          p: "16px",
          flexDirection: "column",
          bgColor: "#fff",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          justifyContent: "space-between",
        }}
      >
        <Flex sx={{ flexDirection: "column", overflowY: "auto" }}>
          {data.trim() !== "" &&
            JSON.parse(data).map((item, i) => (
              <Flex
                key={i}
                sx={{
                  color: "#7E7E7E",
                  fontSize: 13,
                  "& a": { color: "#F7C545", textDecoration: "none" },
                }}
              >
                - <label dangerouslySetInnerHTML={{ __html: item }}></label>
                <br />
              </Flex>
            ))}
        </Flex>{" "}
        <Button
          onClick={() => setModalState({ display: false, type: null })}
          size="sm"
          sx={{
            px: "20px",
            bgColor: "#152d73",
            color: "#fff",
            "&:hover": {
              bgColor: "#152d73",
              color: "#F7C545",
            },
            display: "flex",
            m: "0px auto",
          }}
        >
         {t("konnect.additionalConfigsModal.okBtnLabel")}
        </Button>
      </Flex>
    </>
  );
});
