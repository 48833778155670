import React from "react";
import { Flex, Image } from "@chakra-ui/react";

export const Carousel = ({ feature, t }) => {
  return (
    <Flex
      sx={{
        m: "auto",
        flexDirection: "column",
        height: "80%",
        justifyContent: "space-between",
        // gap: "40px",
      }}
    >
      <Flex
        height="80%"
        width="100%"
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Image width="100%" src={t(feature.img)} />
      </Flex>
      <Flex
        sx={{
          height: "20%",
          gap: "10px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Flex
          sx={{
            fontWeight: 600,
            fontSize: [20, 22, 24, 26, 28],
            color: "#152e73",
          }}
        >
          {t(feature.title)}
        </Flex>
        <Flex
          sx={{
            width: "70%",
            color: "#5e5e5e",
            textAlign: "center",
            justifyContent: "center",
            fontSize: [14, 15, 15, 16, 18],
          }}
        >
          {t(feature.description)}
        </Flex>
      </Flex>
    </Flex>
  );
};
