import React, { useEffect, useState } from "react";
// import { Box, Box, Flex, Link } from "rebass"
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Flex,
  Input,
  ScaleFade,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { Selector } from "../node/ui/Selector";
import { dashSVG, svg, svg_1 } from "../../assets/objectSVG";
import "./style.css";
import { EventWrapper } from "../styled";
// import { svg } from "../../../asset/svg.js"
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  cancelDelay,
  setDelayConfigInNode,
  setDelayConfigs,
} from "../../store/slice/canvasSlice";
import { useDispatch, useSelector } from "react-redux";
export const DelayModal = React.memo(
  ({ parentnode, data, setModalState, setExpandHeight, setExpand }) => {
    const dispatch = useDispatch();
    const delayConfInStore = useSelector((state) => state.canvas.delayConfigs);

    const today = moment();
    const [scheduleDate, setScheduleDate] = useState(
      data.delayConfigs?.value ? data.delayConfigs?.value : new Date()
    );
    const todayDate = today.format("DD/MM/YYYY HH:mm:ss");
    const value = [
      {
        value: 515,
        label: "Delay for",
        name: "Delay for",
        id: 515,
        selected: false,
      },
      {
        value: 328,
        label: "Delay Until",
        name: "Delay Until",
        id: 328,
        selected: false,
      },
    ];

    const delayUnitValues = [
      {
        value: 514,
        label: "Minutes",
        name: "Minutes",
        id: 514,
        selected: false,
      },
      {
        value: 322,
        label: "Hours",
        name: "Hours",
        id: 322,
        selected: false,
      },
      {
        value: 511,
        label: "Days",
        name: "Days",
        id: 511,
        selected: false,
      },
      {
        value: 3228,
        label: "Weeks",
        name: "Weeks",
        id: 3228,
        selected: false,
      },
    ];

    const handleSave = () => {
      if (data.delayConfigs.delay_type.label == "Delay Until") {
        if (scheduleDate !== null) {
          dispatch(
            setDelayConfigInNode({
              nodeId: data.nodeId,
              type: "delayUntil",
              delayConfigs: {
                delay_type: {
                  value: 328,
                  label: "Delay Until",
                  name: "Delay Until",
                  id: 328,
                  selected: false,
                },
                value: scheduleDate.target.value,
              },
            })
          );
        } else {
          dispatch(cancelDelay(data.nodeId));
          // setDelayConf(delayConfInStore)
        }
      } else {
        if (
          data.delayConfigs.delay_unit?.value &&
          data.delayConfigs.delay_value !== "" &&
          data.delayConfigs.delay_type?.value
        ) {
          dispatch(
            setDelayConfigInNode({
              nodeId: data.nodeId,
              delayConfigs: data.delayConfigs,
            })
          );
        }
      }

      setModalState({ display: false, type: null });
      setExpandHeight(false);
      setExpand(false);
    };
    const handleCancelDelayConfig = () => {
      dispatch(cancelDelay(data.nodeId));
      setModalState({ display: false, type: null });
      setExpandHeight(false);
      setExpand(false);
    };
    const { t } = useTranslation();
    useEffect(() => {
      // Update delayConf when delayConfInStore changes
      if (!data.delayConfigs) {
        // setDelayConf(delayConfInStore);
      }
    }, [delayConfInStore]);

    return (
      <>
        <Flex
          width="100%"
          sx={{
            p: "3% 5%",
            flexDirection: "column",
            bgColor: "#fff",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            height: "100%",
            // justifyContent: "space-between",
          }}
        >
          <Flex flexDirection="row" sx={{ justifyContent: "space-between" }}>
            <Box fontSize={20} sx={{ color: "#152F73", mb: "10px" }}>
              {t("konnect.sidebar.delayLabel")}
            </Box>
          </Flex>
          <Box
            sx={{
              p: "5px",
              justifyContent: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              style={{
                fontWeight: "400",
                display: "flex",
                justifyContent: "left",
              }}
            >
              {t("konnect.sidebar.delayText")}
            </Box>

            <Selector
              parentnode={parentnode}
              options={value}
              selectType="Delay"
              delayConfig="First"
              value={data.delayConfigs?.delay_type}
              nodeId={data.nodeId}
            />

            {data.delayConfigs &&
            data.delayConfigs.delay_type.label === "Delay for" &&
            data.delayConfigs.delay_type.label !== "Delay Until" ? (
              <>
                {" "}
                <Box
                  style={{
                    fontWeight: "400",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  {t("konnect.sidebar.delayText")}
                </Box>
                <Selector
                  options={delayUnitValues}
                  parentnode={parentnode}
                  selectType="Delay"
                  delayConfig="Second"
                  value={data.delayConfigs?.delay_unit}
                  nodeId={data.nodeId}
                />
                <Box
                  sx={{
                    mt: "0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    {t("konnect.sidebar.delayText")}
                  </Box>
                  <Input
                    type="number"
                    defaultValue={
                      data.delayConfigs?.delay_value
                        ? data.delayConfigs?.delay_value
                        : ""
                    }
                    sxProps={{
                      "& ~ span": {
                        color: "#B7CBFF !important",

                        pl: "5px",
                        width: "80%",
                        fontSize: "12px",
                        border: "0px solid red",
                      },
                    }}
                    width="100%"
                    placeholder={"Enter Value"}
                    onChange={(evt) => {
                      dispatch(
                        setDelayConfigs({
                          delay_type: "input",
                          value: evt.target.value,
                          nodeId: data.nodeId,
                        })
                      );
                    }}
                  />
                </Box>
                <Flex justifyContent="center" gap="20px" mt="10px">
                  <Button
                    onClick={handleSave}
                    size="sm"
                    sx={{
                      px: "20px",
                      bgColor: "#152d73",
                      borderRadius: "4px",
                      color: "#fff",
                      "&:hover": {
                        bgColor: "#152d73",
                        color: "#F7C545",
                      },
                      display: "flex",
                    }}
                  >
                    {t("konnect.header.saveBtnLabel")}
                  </Button>
                  <Button
                    onClick={handleCancelDelayConfig}
                    size="sm"
                    sx={{
                      px: "20px",
                      bgColor: "#152d73",
                      borderRadius: "4px",
                      color: "#fff",
                      "&:hover": {
                        bgColor: "#152d73",
                        color: "red",
                      },
                      display: "flex",
                    }}
                  >
                    Cancel
                  </Button>
                </Flex>
              </>
            ) : null}
          </Box>

          {data.delayConfigs &&
          data.delayConfigs.delay_type.label === "Delay Until" ? (
            <>
              <EventWrapper visibility={true}>
                Date
                <input
                  type="datetime-local"
                  placeholder="select date"
                  min={new Date().toISOString().slice(0, 16)}
                  onChange={(val) => {
                    
                    setScheduleDate(val);
                  }}
                  value={data.delayConfigs?.value}

                  style={{ border: "1px solid #B7C9FF", padding: "5px", backgroundColor:"#F3F7FF" }}
                />
              
              </EventWrapper>
              <Flex justifyContent="center" gap="20px" mt="10px">
                <Button
                  onClick={handleSave}
                  size="sm"
                  sx={{
                    px: "20px",
                    bgColor: "#152d73",
                    borderRadius: "4px",
                    color: "#fff",
                    "&:hover": {
                      bgColor: "#152d73",
                      color: "#F7C545",
                    },
                    display: "flex",
                  }}
                >
                  {t("konnect.header.saveBtnLabel")}
                </Button>
                <Button
                  onClick={handleCancelDelayConfig}
                  size="sm"
                  sx={{
                    px: "20px",
                    bgColor: "#152d73",
                    borderRadius: "4px",
                    color: "#fff",
                    "&:hover": {
                      bgColor: "#152d73",
                      color: "red",
                    },
                    display: "flex",
                  }}
                >
                  Cancel
                </Button>
              </Flex>
            </>
          ) : null}
        </Flex>
      </>
    );
  }
);
