import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const dutch = {
  translation: {
    common: {
      app: 'Konnectz',
    },

    header: {
      topNavigations: {
        tipsAndTricks: 'Tips en Trucs',
        pricing: 'Prijzen',
        blogs: 'Blogs',
        helpCenter: 'Helpcentrum',
      },
      searchAppTextPlaceholder: 'Zoek hier...',
      createKonnectzBtnLabel: 'Maak Konnectz',
      taskhistory: 'Taakgeschiedenis',
      success: 'Succes',
      error: 'Fout',
      notask: 'Geen Taak Gevonden',
      tryagain: 'Pas je filter aan en probeer opnieuw',
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        'Wachtwoord reset instructies zijn per e-mail verzonden, controleer je e-mail.',
      SENT_RESET_INSTRUCTIONS_FAIL: 'Ongeautoriseerd verzoek!',
      VERIFY_EMAIL_FAIL:
        'Sorry, het is niet mogelijk om je e-mail te bevestigen.',
      PASSWORD_NOT_UPDATED:
        'Sorry, het is niet gelukt om je account bij te werken',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL:
        'Het is niet gelukt om de wachtwoordinstructies te verzenden. Probeer het later opnieuw!',
      AUTH_SIGN_UP_FAIL: 'Er is iets misgegaan. Probeer het later opnieuw!',
      somethingWentWrong: 'Er is iets misgegaan. Probeer het later opnieuw!',
    },
    successMessages: {
      PASSWORD_UPDATED:
        'Wachtwoord is bijgewerkt. Log in met je nieuwe wachtwoord!',
    },
    history: {
      task: {
        task: 'taak',
        tasks: 'taken',
        taskdate: 'taakdatum: ',
        user: 'Gebruiker: ',
        search_history: 'Zoeken in geschiedenis',
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: 'Maak Wachtwoord',
          subtitle: 'Typ ze TWEE KEER om te bevestigen',
          passwordErrorMsg: 'Ongeldig wachtwoord!',
          confirmPasswordErrorMsg: 'Ongeldig wachtwoord!',
          passwordMatchingErrorMsg: 'Beide wachtwoorden komen niet overeen!',
          continueBtnLabel: 'GA VERDER',
          createPasswordPlaceholder: 'Maak wachtwoord',
          confirmPasswordPlaceholder: 'Bevestig wachtwoord',
          passwordRule:
            'Hint: Je wachtwoord moet tussen 8 en 15 tekens lang zijn en minstens één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten.',
          passwordRule1: 'Je wachtwoord moet tussen 8 en 15 tekens lang zijn.',
          passwordRule2:
            'Je wachtwoord moet minstens één hoofdletter bevatten.',
          passwordRule3: 'Je wachtwoord moet minstens één cijfer bevatten.',
          passwordRule4:
            'Je wachtwoord moet minstens één speciaal teken bevatten.',
        },
        verificationSent: {
          title: 'VERIFICATIE',
          subtitle: 'Verifieer E-mailadres',
          verificationText1: 'We hebben een verificatielink gestuurd naar ',
          verificationText2: ', open de e-mail en verifieer je e-mailadres.',
          didntReceiveEmailText: 'Geen e-mail ontvangen? ',
          resendText: 'Opnieuw verzenden',
        },
        verifySuccess: {
          title: 'Verificatie Voltooid',
          verifyFailedTitle: 'Verificatie Mislukt',
        },
        signupSuccess: {
          firstName: 'Voornaam',
          firstNameErrorMsg: 'Voornaam is verplicht!',
          lastName: 'Achternaam',
          lastNameErrorMsg: 'Achternaam is verplicht!',
          verifyErrorMsg:
            'Er is iets misgegaan. Probeer in te loggen als je account al is geverifieerd.',
        },
        title: 'Maak Je Account',
        subtitle: 'Hey, Welkom',
        verifyingMessage: 'Aan het verifieren',
        emailInvalid: 'Ongeldige e-mail!',
        emailExists: 'Het ingevoerde e-mailadres is al geregistreerd',
        teamSignup: 'Aanmelden als team?',
        continueBtnLabel: 'Doorgaan',
        alreadyHaveAccountText: 'Heb je al een account? ',
        loginBtnLabel: 'Aanmelden',
        emailPlaceholder: 'Voer e-mail in',
        features: {
          feature1: {
            title: 'Eenvoudige Automatisering',
            image: 'Eenvoudige Automatisering',
            description:
              'Bouw complexe workflows eenvoudig in enkele minuten met behulp van onze visuele verbindingsbouwer',
          },
          feature2: {
            title: 'Platform voor Iedereen',
            image: 'Platform voor Iedereen',
            description:
              'Niet zomaar een automatiseringstool alleen voor programmeurs, iedereen kan automatisering bouwen met KonnectzIT',
          },
          feature3: {
            title: 'Naadloze App-integratie',
            image: 'Naadloze App-integratie',
            description:
              'Verbind onbeperkte applicaties uit onze integratiebibliotheek en automatiseer dagelijkse taken',
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          'Er is iets misgegaan. Probeer in te loggen als je account al is geverifieerd',
      },
      login: {
        title: 'Meld Je Aan Bij Je Account',
        subtitle: 'Hey, Welkom terug!',
        emailPlaceholder: 'Voer e-mail in',
        emailInvalid: 'Ongeldige e-mail!',
        passwordRequired: 'Wachtwoord is verplicht!',
        passwordPlaceholder: 'Voer wachtwoord in',
        rememberMe: 'Onthoud mij',
        loginBtnLabel: 'NU AANMELDEN',
        forFirstTimeSignup: 'Voor de eerste keer? ',
        forgotPasswordLbl: 'Wachtwoord Vergeten',
        alreadyHaveAccountText: 'Heb je nog geen account? ',
        clickingContinueText: 'Door verder te gaan, ga je akkoord met onze ',
        termsAndConditionsText: 'Algemene Voorwaarden.',
        signupNowText: 'Nu aanmelden',
        features: {
          feature1: {
            title: 'Onbeperkte Stappen',
            image: 'Onbeperkte Stappen',
            description:
              'Verbind meerdere applicaties en bouw onbeperkte stappen voor naadloze automatisering.',
          },
          feature2: {
            title: 'Visualiseer Alles',
            image: 'Visualiseer Alles',
            description:
              'Bouw je workflows en visualiseer de automatisering met behulp van een visuele verbindingsbouwer.',
          },
          feature3: {
            title: 'Integratiebibliotheek',
            image: 'Integratiebibliotheek',
            description:
              'Verzameling van cloudapplicaties om gegevens tussen hen samen te voegen en te verzenden om tijd te besparen.',
          },
        },
        loginFailMsg: 'Gebruikersnaam of wachtwoord is ongeldig!',
        captchaErrorMsg: 'Captcha-fout',
      },
      forgot: {
        title: 'Wachtwoord Vergeten',
        forgtoBtnLabel: 'Ontvang Wachtwoord Vergeten Link',
        passwordLink: 'We hebben een wachtwoord vergeten link gestuurd naar',
        successSubTitle: 'E-mail Verzonden',
        features: {
          feature1: {
            title: 'Eenvoudige Automatisering',
            image: 'Eenvoudige Automatisering',
            description:
              'Bouw complexe workflows eenvoudig in enkele minuten met behulp van onze visuele verbindingsbouwer',
          },
          feature2: {
            title: 'Platform voor Iedereen',
            image: 'Platform voor Iedereen',
            description:
              'Niet zomaar een automatiseringstool alleen voor programmeurs, iedereen kan automatisering bouwen met KonnectzIT',
          },
          feature3: {
            title: 'Naadloze App-integratie',
            image: 'Naadloze App-integratie',
            description:
              'Verbind onbeperkte applicaties uit onze integratiebibliotheek en automatiseer dagelijkse taken',
          },
        },
        emailInvalid: 'Ongeldige e-mail!',
      },
      reset: {
        title: 'Wachtwoord Herstellen',
        subTitle: 'Nieuw wachtwoord aanmaken',
      },
    },

    konnect: {
      sidebar: {
        search_apps: 'Zoeken in Applicatie',
        search_apps_endList: 'Yay! Je hebt alles gezien',
        add_on_apps: 'Add-On Apps',
        integrated_apps: 'Geïntegreerde Apps',
        choose_trigger: 'Kies Trigger',
        choose_action: 'Kies de actie',
        textFormater: {
          formaterText: "Voer '@' in en selecteer de waarden",
        },
        scheduler: {
          userTimeInput: 'Voer de waarde in minuten, uren of dagen in',
          selectDate: 'Selecteer Datum',
          select: 'Selecteer',
          test_review: 'Test & Beoordeling',
          time: 'Tijd',
          dates: 'Data',
          date: 'Datum',
          days: 'Dagen',
          everyday_time: 'Dagelijkse Tijd',
        },
        responseModal: {
          searchByLable: 'Zoeken op Label',
          no_labelFound: 'Geen label gevonden',
          nodeInner_title: 'Selecteer Meer Waarden',
          labelText: 'Label:',
        },
        coupon_generator: {
          numberPlaceholder: 'Nummer',
        },
        choose_trigger_header: 'Kies App-gebeurtenis',
        no_triggers_available: 'Geen beschikbare Triggers',
        choose_format_header: 'Format Optie',
        choose_config_header: 'Kies Configuratie',
        select_trigger_action_dropdown_header: 'Selecteer',
        choose_option_header: 'Definieer Optie',
        no_options_available: 'Geen beschikbare Opties',
        choose_linked_account: 'Selecteer Account',
        customize_form_header: 'Configureer Formulier',
        customize_form_partial: 'Kies',
        configure_response: 'Pas Reactie Aan',
        basic_auth: 'Basis Authenticatie',
        api_key: 'API Sleutel',
        api_token: 'API Geheim',
        webhook_url: 'Webhook URL',
        captureWebhookResponse: 'Webhook Reactie Vastleggen',
        hereText: 'Hier',
        webhook_instructions: 'Webhook Instructies - Klik',
        webhook_url_toast: 'Webhook URL gekopieerd naar klembord!',
        webhook_response: 'Webhook Reactie',
        choose_action_event: 'Kies Actie',
        endpoint_url: 'Eindpunt URL',
        payload_type: 'Payload Type',
        wrap_request_array: 'Verzoek inpakken in Array',
        set_params: 'Parameters instellen',
        add_header: 'Header Toevoegen',
        delayLabel: 'Vertraging',
        delayText: 'Hoeveel vertraging wil je?',
        set_paramter: 'Parameter Instellen',
        expiry_time: 'Tijdslijn Telling',
        expiry_val: 'Tijdslijn',
        segmentIndex: 'Segment Index',
        validate: 'Valideren',
        generate: 'Genereren',
      },
      tobar: {
        cancelBtnLabel: 'Annuleren',
        inputLeftElm_placeholder:
          'Zoeken in 1000+ Geïntegreerde Toepassingen of Add-On Apps',
        redoBtnLabel: 'OPNIEUW',
        undoBtnLabel: 'ONGEDAAN MAKEN',
      },
      UI: {
        betaText: 'Beta',
        webhookDuplicateToastMsg: 'Webhook app bestaat al!',
      },
      header: {
        backBtnLabel: 'Terug',
        saveBtnLabel: 'Opslaan',
        publishBtnLabel: 'Publiceren',
        nameOfKonnectz: 'Naam van de',
      },
      testAndReview: {
        btnLabel: 'Test & Beoordeling',
        taskAvailableLabel: 'Taak Beschikbaar',
        taskConsumbedLabel: 'Taak Verbruikt',
        taskCnsumptionLabel: 'Taak Verbruik',
      },
      proceed: {
        btnLabel: 'DOORGAAN',
      },
      additionalConfigsModal: {
        cancelBtnLabel: 'Annuleren',
        okBtnLabel: 'OK',
      },
      conditions: {
        conditionText: 'Voorwaarde',
        addNewConditionBtnLabel: 'Nieuwe Voorwaarde Toevoegen +',
        cancelBtnLabel: 'Annuleren',
        okBtnLabel: 'OK',
        addBtnLabel: 'Toevoegen',
        operatorAndLabel: 'EN',
        operatorOrLabel: 'OF',
        headerConditionText: 'Voorwaarde',
        selectFieldHeaderTitle: 'Selecteer Veld',
        selectConditionHeaderTitle: 'Selecteer Voorwaarde',
        validateBtnLabel: 'Valideren',
        conditionsHeaderText: 'Voorwaarden',
      },
      publishSuccessMsg: 'Konnect is succesvol gepubliceerd!',
      saveSuccessMsg: 'Konnect is succesvol opgeslagen!',
    },
    apps: {
      authenticatedAppsTitle: 'Geauthenticeerde apps',
      aunthenticateNowTitle: 'Nu Authenticeren',
      noAppsToAunthenticate: 'Geen apps om op dit moment te authenticeren.',
      authenticateNow: 'Je hebt geen geauthenticeerde apps. Nu authenticeren.',
      addAccountText: 'Account Toevoegen',
      betaText: 'Beta',
      integratedApps: 'GEÏNTEGREERDE APPS',
      followInstrusctionText: 'Volg de instructies voor meer referentie',
      oAuthHandlerText: 'KonnectzIT OAuth Handler, Even Geduld AUB...',
      cancelBtnText: 'Annuleren',
      newsLable: 'Nieuws',

      yesContinueText: 'Ja, doorgaan',
      requiredText: '(Verplicht)',
      accessAPIModalHeaderText1: 'KonnectzIT toegang geven tot ',
      accessAPIModalHeaderText2: ' account?',
      accessAPIModalMainText1: ' ',

      accessAPIModalMainText2: ' is beschikbaar in',
      accessAPIModalMainText3: ' integratiepagina',
      authorized: 'Geautoriseerde Apps',
    },
    konnectzList: {
      homeText: 'Home',
      allText: 'Alles',
      searchText: 'Zoeken',
      createkonnectzLabel: 'Aanmaken',
      noKonnectzInfo: 'Geen konnectz op dit moment!',
      deleteModalBodyText: 'Weet je zeker dat je wilt verwijderen?',
      deleteModalNoText: 'Nee',
      deleteModalDeleteText: 'Verwijderen',
      folders: {
        createFolderTitleText: 'Mapnaam',
        enterFolderName: 'Voer de naam van de map in',
        fodersLeftTitleTextL: 'Mappen',
        createFolderInputPlaceholder: 'Voer de naam van de map in',
        createFolderBtn: 'Aanmaken',
        nameTitle: 'Naam',
        noKonnectz: 'Het lijkt erop dat je geen',
        deleteFolderConfirmationText:
          'Weet je zeker dat je deze map wilt verwijderen?',
        cancelBtn: 'Annuleren',
        newFolder: 'Nieuwe Map',
        deleteBtn: 'Verwijderen',
        selectFolderInfo:
          'Selecteer de map waarnaar je het bestand wilt verplaatsen',
        moveFolderCancelBtn: 'Annuleren',
        moveBtn: 'Verplaats Map',
        renameSaveBtn: 'Map Hernoemen',
        renameFolder: 'Hernoemen',
        renameYourFolderText: 'Hernoem je map',
        createFolderBtn: 'Map Aanmaken',
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: 'Laatste Apps',
        },
        totalKonnectz: {
          headerTitle: 'Totaal Konnectz',
          activeKonnectz: 'Actieve Konnectz',
          inactiveKonnectz: 'Inactieve Konnectz',
        },
        latestKonnectz: {
          headerTitle: 'Laatste Konnectz',
          vieawAll: 'Bekijk Alles',
        },
        subscription: {
          subscriptionTitle: 'Abonnement',
          contentText: 'Ontgrendel meer kracht door te upgraden naar Pro',
          applyNewCoupon: 'Nieuwe Coupon Toepassen',
          applyBtnLabel: 'Toepassen',
          featuresIncludedLabel: 'Inbegrepen Functies',
          upgradeBtnLabel: 'NU UPGRADEN',
          unlockMoreUpgradingText: 'Ontgrendel meer kracht door te upgraden',
        },
        taskConsumption: {
          headerTitle: 'Taak Verbruik',
        },
      },
    },
    account: {
      myProfileText: 'Profiel',
      billingtext: 'Factuuradres',
      emailAddressLabel: 'E-mailadres',
      firstNameLabel: 'Voornaam',
      lastNameLabel: 'Achternaam',
      subAccountsLabel: 'Sub-Accounts',
      firstNameRequiredText: 'Voornaam is verplicht!',
      lastNameRequiredText: 'Achternaam is verplicht!',
      countryRequiredText: 'Land is verplicht!',
      addressLine1RequiredText: 'Adresregel 1 is verplicht!',
      cityRequiredText: 'Stad is verplicht!',
      stateRequiredText: 'Staat is verplicht!',
      zipCodeRequiredText: 'Postcode is verplicht!',
      taxIdRequiredText: 'BTW-nummer is verplicht!',
      companyNameLabel: 'Bedrijfsnaam',
      currentPositionLabel: 'Huidige Positie',
      countryLabel: 'Land',
      addressLabel: 'Adres',
      cityLabel: 'Stad',
      stateLabel: 'Staat',
      zipCodeLabel: 'Postcode',
      taxIdLabel: 'BTW-nummer',
      timeZoneLabel: 'Tijdzone',
      changeLanguageLabel: 'Kies Taal',
      changePasswordHeaderText: 'Wachtwoord Wijzigen',
      currentPasswordLabel: 'Huidig Wachtwoord',
      currentPasswordRequiredText: 'Huidig wachtwoord is verplicht!',
      newPasswordLabel: 'Nieuw Wachtwoord',
      newPasswordRequiredText: 'Nieuw wachtwoord is verplicht!',
      confirmPasswordLabel: 'Bevestig Wachtwoord',
      confirmPasswordRequiredText: 'Bevestig het wachtwoord!',
      updateBtnLabel: 'Bijwerken',
      editUpdateAccountSideBarText: 'Account Bewerken/Bijwerken',
      changePasswordSideBarText: 'Wachtwoord Wijzigen',
      billingText: 'Facturatie',
      teamText: 'Team',
      agencyText: 'Bureau',
      sideBarHeaderAccountText: 'Account',
      profileLable: 'Profiel',
      profileUpdatedSuccessfullyText: 'Profiel succesvol bijgewerkt!',
      addTeamMemberText: 'Teamlid Toevoegen',
      addSubAccountsText: 'Sub-Accounts Toevoegen',

      billing: {
        couponCodeText: 'Code',
        applyCodeBtnLbl: 'Code Toepassen',
        tasksText: 'Taken',
        promotionalTasksText: 'Extra Taken',
        redeemedOnText: 'Ingezet Op',
        expiresOnText: 'Verloopt Op',
        noCouponsAppliedText: 'Geen licentie toegepast',
        plansAndFeaturesText: 'Plannen en Functies',
        currentPlanText: 'Huidig Plan',
        upgradeText: 'Upgraden',
        billingText: 'Facturatie',
        licenseerror: 'Ongeldige Licentie!',
        completionofprofile:
          'Vul je profiel in voordat je de code inwisselt. Dit is vereist voor GPDR/Data-verwerking & Facturering Doeleinden',
      },
      team: {
        teamLabel: 'Team',
        name: 'Naam',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        access: 'Toegang',
        email: 'E-mail',
        addBtn: 'Toevoegen',
        cancelBtn: 'Annuleren',
        assignAccessLbl: 'Toegang Toewijzen',
        noUsersInfo: 'Geen gebruikers beschikbaar',
        collapseTeamLabel: 'Team Inklappen',
        expandTeamLabel: 'Team Uitklappen',
        teamMembersHeaderText: 'Teamleden',
      },
      agency: {
        agencyLabel: 'Bureau',
        name: 'Klantnaam :',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        tasks: 'Taak Toegewezen :',
        consumed: 'Taak Verbruikt',
        agencyConsumed: 'Verbruikt',
        remainingLabel: 'Resterend',
        totalSubaccounts: 'Totaal Sub-Accounts :',
        email: 'E-mail :',
        active: 'Actief :',
        inActive: 'Inactief :',
        addBtn: 'Toevoegen',
        addMember: 'Lid Toevoegen',
        deleteMember: 'Lid Verwijderen',
        cancelBtn: 'Annuleren',
        editMemberLabel: 'Lid Bewerken',
        noAgenciesInfo: 'Geen bureaus beschikbaar',
        agencyMembersHeaderText: 'Sub-Accounts',
        total: 'Totaal Taken :',
        update: 'Bijwerken',
        saveChangeLabel: 'Wijziging Opslaan',
        updateTask: 'Taak Bijwerken',
        delete: 'Verwijderen',
        available: 'Taak beschikbaar',
        deactivate: 'Activeren',
        totalAssigned: 'Totaal Toegewezen',
        activate: 'Deactiveren',
        detailsLabel: 'Details',
        subaccounterror: 'Fout bij het bijwerken van de subaccountgegevens!',
      },
      reseller: {
        resellerLabel: 'Verkoper',
      },
    },
    sideNav: {
      homeLabel: 'Home',
      appsLabel: 'Apps',
      konnectzLabel: 'Konnectz',
      historyLabel: 'Geschiedenis',
      historyCapsLabel: 'Geschiedenis',
      accountLabel: 'Account',
      notificationsLabel: 'Meldingen',
      logoutLabel: 'Uitloggen',
      getHelpLabel: 'Hulp Nodig?',
      helpModalText: 'Blijf op de hoogte. We komen binnenkort!',
      konnectzITLogoText: 'KonnectzIT',
    },
  },
};
