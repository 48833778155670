import React, { useRef, useState, useCallback } from "react";
import { CaptureButton, EventWrapper, NodeOuter } from "../../styled";
import { Flex, Box, Tooltip } from "@chakra-ui/react";
import { NodeLoader } from "../../loader";
import { useTranslation } from "react-i18next";
import { AppInfo } from "../ui/AppInfo";
import { Selector } from "../ui/Selector";
import { ResponseContainer } from "../ui/ResponseContainer.js";
import { AdditionalResponse } from "../ui/AdditionalResponse";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import { BasicButton } from "../ui/BasicButton";
import { ModalContainer } from "../../modal/index";
import {

  OnTestandReview,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk.js";

import { onLinkDelete, setModalData } from "../../../store/slice/canvasSlice";

import {  useDispatch } from "react-redux";

export const ApiInner = React.memo(({ data, selected }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeOuter = useRef();
  const [expand, setExpand] = useState(false);
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });
  const [expandHeight, setExpandHeight] = useState(false);
  const [captureTooltip, setCaptureTooltip] = useState(false);
  const helper = {
    onHelp: useCallback(() => {
      !data.loading && setModalState({ display: true, type: "help" });
    }, [data.loading]),
    onExpand: useCallback(() => {
      if (expand && modalState.type === "accounts") {
      } else !data.loading && setExpand((c) => !c);
    }, [data.loading, modalState]),
    additionalResult: useCallback(() => {
      setModalState({ display: true, type: "Normal" });
    }, [data.configResponses]),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    addAccount: useCallback(() => {
      dispatch(
        setModalData({
          nodeId: data.nodeId,
          type: "Account",
          auto: true,
        })
      );
      setExpand(true);
      setExpandHeight(true);
      setModalState({ display: true, type: "accounts" });
    }, []),
    onTestandReview: useCallback(() => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      dispatch(OnTestandReview(data.nodeId)).then((res) => {
        dispatch(saveKonnect({ publish: false }));
      });
    }, [data]),
  };
  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      expandheight={expandHeight ? "true" : "false"}
      id="drag"
    >
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalData={setModalData}
        nodeIdx={data.nodeIdx}
        setModalState={setModalState}
      />
      {data.type === "ADD_ON" ? (
        <>
          <EventWrapper visibility={true}>
            <Flex>{data.nodeIdx === 0 ? t("konnect.sidebar.choose_trigger") :t("konnect.sidebar.choose_action")}</Flex>
            <Selector
              parentnode={nodeOuter}
              options={data.appEvents}
              nodeId={data.nodeId}
              selectType="Action"
              value={data.selectedEvent ? data.selectedEvent : null}
            />
          </EventWrapper>
          <EventWrapper
            visibility={data.selectedEvent && data.configResponses?.length ? true : false}
          >
            <ResponseContainer
              result={data.configResponses.slice(0, 1)}
              parentnode={nodeOuter}
              nodeId={data.nodeId}
            />
          </EventWrapper>
          <EventWrapper visibility={data.appEvent ? true : false}>
            <Flex>{t("konnect.sidebar.payload_type")}</Flex>
            <Selector
              parentnode={nodeOuter}
              options={data.api.payloadType}
              nodeId={data.nodeId}
              value={data.api.payloadType}
              selectType="payload_type"
              name="payload"
            />
            <Box></Box>
          </EventWrapper>
          <EventWrapper visibility={data.appEvent ? true : false}>
            <Flex>{t("konnect.sidebar.wrap_request_array")}</Flex>
            <Box>
              <Selector
                parentnode={nodeOuter}
                options={data.api.wrapRequestInArray}
                value={data.api.wrapRequestInArray}
                nodeId={data.nodeId}
                selectType="payload_type"

                name="wrap_request"
              />
            </Box>
          </EventWrapper>
          <EventWrapper visibility={data.appEvent ? true : false}>
            <BasicButton
              onClick={() => {
                setExpand(true);
                setModalState({ display: true, type: "ApiModal" });
                setExpandHeight(true);
              }}
              title={t("konnect.sidebar.set_params")}
              visibility={true}
            />
          </EventWrapper>
          <EventWrapper visibility={data.configResponses?.length > 1}>
            <ResponseContainer
              result={data.configResponses.slice(1)}
              parentnode={nodeOuter}
              nodeId={data.nodeId}
            />
          </EventWrapper>
          <EventWrapper visibility={data?.appEvent ? true : false}>
            <Tooltip
              isOpen={captureTooltip}
              onOpen={() => !data.trigger_konnect_id && setCaptureTooltip(true)}
              onClose={() => !data.trigger_konnect_id && setCaptureTooltip(false)}
              hasArrow
              label={
                data.nodeIdx === 0
                  ? "Only Mandatory fields must be filled to Trigger"
                  : "Use Trigger first to create a Konnectz"
              }
            >
              <CaptureButton
                isDisabled={data.trigger_konnect_id ? false : true}
                onClick={helper.onTestandReview}>
                {t("konnect.sidebar.scheduler.test_review")}
              </CaptureButton>
            </Tooltip>
          </EventWrapper>
          <Divider visibility={data?.additionalResponses} />
      <EventWrapper visibility={data?.additionalResponses} last="true">
        <ResultContainer response={data.configResponses} additional="true" />
      </EventWrapper>
      {data?.additionalResponses > 3 && !modalState.display ? (
        <AdditionalResponse
          visibility={data?.additionalResponses > 3 ? true : false}
          display={modalState.display}
          onClick={helper.additionalResponse}
        />
      ) : null}
        </>
      ) : null}
      {modalState.display && (
        <ModalContainer
          nodeOuter={nodeOuter}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
          setExpand={setExpand}
          setExpandHeight={setExpandHeight}
        />
      )}
    </NodeOuter>
  );
});
