import React, { useRef, useState } from "react";
import {
  Button,
  Flex,
 
  Box,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ApiItem } from "./Components/ApiItem";
import { updateHeadersAndParams } from "../../store/slice/canvasSlice";
export const ApiModal = React.memo(
  ({
    setModalState,
    onCancel = () => {
      setModalState({ display: false, data: null });
    },
    data,
    setExpand,
  }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [headerList, setHeaderList] = useState(data.api.headers);
    const [paramsList, setParamsList] = useState(data.api.params);

    const prepareNodeConfigDetails = (list, text) => {
      const nodes = [];
      let id = nanoid();
      list.forEach((obj) => {
        if (obj.key !== "") {
          nodes.push({
            sequence: 0,
            config_key: obj.key,
            error: null,
            key_value_type: "input",
            value: "",
            mapped: true,
            label: `${text}- ${obj.key}`,
            side: "left",
            app_id: data.id,

            id: id,
            config_key_required: true,
            app_event_id: data.appEvent,
            fetch_fields: false,
            ts: new Date().getTime(),
            port: {
              id: id,
              source: id + "|" + "source",
              target: id + "|" + "target",
            },
          });
        }
      });
      return nodes;
    };
    const handleHeaderListInputChange = (e, index) => {
      const list = [...headerList];
      let list1 = JSON.parse(JSON.stringify(list));
      if (e.target) {
        const { name, value } = e.target;
        list1[index][name] = value;
        setHeaderList(list1);
      }
    };
    const updateHeaderAndParamsDetails = (headersList, paramsList) => {
      const headerNodeDetails = prepareNodeConfigDetails(headersList, "Header");
      const paramNodeDetails = prepareNodeConfigDetails(paramsList, "Param");
      dispatch(
        updateHeadersAndParams({
          headers: headersList,
          params: paramsList,
          nodeId: data.nodeId,
          // nodeIdx: editorState.nodes.findIndex(n=>n.id===data.nodeId),
          newNodeDetails: headerNodeDetails.concat(paramNodeDetails),
        })
      );
    };
    // handle click event of the Remove button
    const handleHeaderListRemoveClick = (index) => {
      const list = [...headerList];
      list.splice(index, 1);
      setHeaderList(list);
    };

    // handle click event of the Add button
    const handleHeaderListAddClick = () => {
      setHeaderList([...headerList, { key: "" }]);
    };

    // handle input change
    const handleParamsListInputChange = (e, index) => {
      const list = [...paramsList];
      let list1 = JSON.parse(JSON.stringify(list));

      if (e.target) {
        const { name, value } = e.target;
        list1[index][name] = value;
        setParamsList(list1);
      }
    };

    // handle click event of the Remove button
    const handleParamsListRemoveClick = (index) => {
      const list = [...paramsList];
      list.splice(index, 1);
      setParamsList(list);
    };

    // handle click event of the Add button
    const handleParamsListAddClick = () => {
      setParamsList([...paramsList, { key: "" }]);
    };

    return (
      <Flex
        sx={{
          p: "16px",
          flexDirection: "column",
          bgColor: "#fff",
          borderBottomRightRadius: "4px",
          height: "100%",
          borderBottomLeftRadius: "4px",
          overflowY: "auto",
          justifyContent: "space-between",
        }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          <Box sx={{ p: "4% 7%" }}>{t("konnect.sidebar.add_header")}</Box>
          <ApiItem
            inputList={headerList}
            handleInputChange={handleHeaderListInputChange}
            handleAddClick={handleHeaderListAddClick}
            handleRemoveClick={handleHeaderListRemoveClick}
            //   fromFields={fromFields}
          />

          <Box sx={{ p: "4% 7%" }}>{t("konnect.sidebar.set_paramter")}</Box>
          <ApiItem
            inputList={paramsList}
            handleInputChange={handleParamsListInputChange}
            handleAddClick={handleParamsListAddClick}
            handleRemoveClick={handleParamsListRemoveClick}
          />
        </Flex>

        <Flex
          height="8%"
          minHeight="30px"
          maxHeight="30px"
          sx={{ justifyContent: "center", gap: "10px", mb: "3%" }}
        >
          <Button
            onClick={() => {
              updateHeaderAndParamsDetails(headerList, paramsList);
              setExpand(false);
              onCancel();
            }}
            sx={{
              bg: "#152d73",
              color: "#fff",
              "&:hover": {
                color: "rgb(247,197,68)",
                bg: "#152d73",
              },
            }}
          >
            {t("konnect.conditions.okBtnLabel")}
          </Button>
          <Button
            onClick={() => {
              setExpand(false);
              onCancel();
            }}
            sx={{
              display: "flex",
              bg: "#152d73",
              color: "#fff",
              "&:hover": {
                color: "red",
                bg: "#152d73",
              },
            }}
          >   {t("apps.cancelBtnText")}</Button>
          
           
        </Flex>
      </Flex>
    );
  }
);
