import React, { useEffect, useState } from "react";
import { bigSVG, svg } from "../../../assets/objectSVG";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Flex, Button } from "@chakra-ui/react";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthTokenService from "../../../utils/AuthTokenService";
import { NodeLoader } from "../../../components/loader";
import forgotFeatures from "../../../components/carousel/features/forgot-features.json";
import { Carousel } from "../../../components/carousel";
import { userVerify } from "../../../store/thunk/authThunk";

export const VerifyEmailContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { token } = useParams();
  const dispatch = useDispatch();
  const { verifyLoading } = useSelector((state) => state.auth);
  const [status, setStatus] = useState("Verification Pending");
  useEffect(() => {
    dispatch(userVerify({ confirmation_token: token })).then((res) => {
      if (res.payload?.key === "VERIFY_EMAIL_SUCCESS") {
        setStatus("Verification Completed");
        AuthTokenService.storeToken({
          token: res.payload.auth_token,
          lang: "en",
        });
      } else if (res.payload?.key === "VERIFY_EMAIL_FAIL") {
        setStatus("Verification Failed");
      } else {
        setStatus("Verification Failed");
      }
    });
  }, []);
  return (
    <>
      <Flex
        width="65%"
        sx={{
          p: "3% 0px 3% 3%",
          position: "relative",
          zIndex: 0,
        }}
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {forgotFeatures?.features.map((feature, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Carousel t={t} feature={feature} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
      <Flex
        width="35%"
        sx={{
          m: "3%",
          position: "relative",
          zIndex: 2,
          borderRadius: "8px",
          flexDirection: "column",
          gap: "10px",
          p: "3%",
          backgroundColor: "rgb(21,46,115)",
          boxShadow: "0px 0px 15px -1px rgb(21,46,115,0.3)",
        }}
      >
        <NodeLoader loading={verifyLoading} stroke="#fff" />
        <Flex sx={{ flexDir: "column", gap: "5px" }}>
          <Flex sx={{ m: "0px auto", gap: "5px" }}>
            <Flex
              sx={{
                m: "0px auto",
                width: ["20px", "22px", "24px", "26px", "26px"],
              }}
            >
              {svg.invertedLogo}
            </Flex>
            <Flex
              sx={{
                color: "#fff",
                fontSize: [18, 20, 22, 24, 26],
                fontWeight: 500,
              }}
            >
              KonnectzIT
            </Flex>
          </Flex>
          <Flex
            height="2px"
            width="100%"
            sx={{ bgColor: "#fff", borderRadius: "50%" }}
          />{" "}
        </Flex>
        <Flex
          sx={{
            color: "#fff",
            fontSize: [24, 26, 26, 28, 30],
            fontWeight: 500,
            justifyContent: "center",
          }}
        >
          {status}
        </Flex>
        {status === "Verification Completed" ? (
          <>
            <Flex sx={{ m: "20px auto 40px auto" }}>
              {bigSVG.verificationDone}
            </Flex>
            <Button
              onClick={() => navigate("/dashboard")}
              width="full"
              variant="outline"
              size={{
                base: "md",
                sm: "md",
                md: "md",
                lg: "md",
                xl: "lg",
                "2xl": "lg",
              }}
              sx={{
                borderTop: "1px solid #f7c544",
                borderRight: "1px solid #f7c544",
                borderLeft: "1px solid #f7c544",
                borderBottom: "4px solid #f7c544",
                fontSize: [14, 15, 15, 16, 18],
                borderRadius: "4px",
                color: "#f7c544",
                "&:hover": {
                  color: "rgb(21,46,115)",
                  borderTop: "1px solid #fff",
                  borderRight: "1px solid #fff",
                  borderLeft: "1px solid #fff",
                  borderBottom: "4px solid #fff",
                },
              }}
            >
              {t("auth.signup.createPassword.continueBtnLabel")}
            </Button>
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                 {t("auth.signup.signupSuccess.verifyErrorMsg")}
                <NavLink
                  to={"/signup"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  Sign In
                </NavLink>
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}
                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </>
        ) : status === "Verification Failed" ? (
          <>
            <Flex sx={{ m: "20px auto 0px auto" }}>
              {bigSVG.verificationFail}
            </Flex>
            <Flex
              sx={{
                color: "#fff",
                textAlign: "center",
                m: "20px auto 20px auto",
              }}
            >
              {t("auth.emailVerification.accountAlreadyVerified")}
            </Flex>
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                 {t("auth.reset.alreadyHaveAccountText")}
                <NavLink
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  Sign In
                </NavLink>
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}
                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};
