import { Flex, Skeleton } from "@chakra-ui/react";
import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as Tool,
  Legend,
} from "chart.js";
ChartJS.register(ArcElement, Tool, Legend);
import { Doughnut } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { getWidgetDetails } from "../../../store/thunk/dashThunk";
import { useTranslation } from "react-i18next";

export const PromotionalTask = () => {
  const { t } = useTranslation();
  const { loading, promotionalTask } = useSelector((state) => state.dash);
  const taskConsumptionData = {
    datasets: [
      {
        data: [0, 1],
        backgroundColor: ["#F7C544", "#518EF8"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <Flex
      sx={{
        bgColor: "#fff",
        width: "30%",
        borderRadius: "4px",
        p: "20px",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
      }}
    >
      <Flex
        width="100%"
        sx={{
          flexDir: "column",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Skeleton isLoaded={!loading}>
          <Flex
            sx={{
              fontSize: [16, 18, 18, 20, 22, 24],
              fontWeight: 500,
              height: "20%",
            }}
          >
            {t("account.billing.promotionalTasksText")}
          </Flex>
        </Skeleton>
        <Flex height="100%">
          {" "}
          <Skeleton
            isLoaded={!loading}
            width="100%"
            height="100%"
            display="flex"
          >
            <Flex flexWrap="wrap" width="100%">
              <Flex
                sx={{
                  gap: "5px",
                  fontSize: [12, 12, 14, 15, 15, 16],
                  m: "auto 0px",
                }}
              >
                <Flex
                  sx={{
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    bgColor: "#518EF8",
                    m: "auto 0px",
                  }}
                />
                Consumed Tasks : {taskConsumptionData.datasets[0].data[0]}
              </Flex>{" "}
              <Flex
                sx={{
                  gap: "5px",
                  fontSize: [12, 12, 14, 15, 15, 16],
                  m: "auto 0px",
                }}
              >
                <Flex
                  sx={{
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    bgColor: "#F7C544",
                    m: "auto 0px",
                  }}
                />
                Remained Tasks : 0
              </Flex>{" "}
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
      <Skeleton isLoaded={!loading}>
        <Doughnut data={taskConsumptionData} options={options} />
      </Skeleton>
    </Flex>
  );
};
