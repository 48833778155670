import {
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { RiSaveFill } from "react-icons/ri";
import { BiCollapse } from "react-icons/bi";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { FaUserEdit, FaUserMinus, FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeamMember,
  deleteMemberId,
  fetchMemberList,
  updateMemberDataPost,
} from "../../store/thunk/accountsThunk";
import { ontoggleExtra, ontoggleProfile } from "../../store/profileSlice";
import { NodeLoader } from "../../components/loader";

const selectOption = [
  { label: "Create", value: "Create" },
  { label: "Create/Delete", value: "Create/Delete" },
  { label: "FULL", value: "FULL" },
];

export const TeamContainer = () => {
  const dispatch = useDispatch();
  const [accountList, setAccountList] = useState([]);
  const [editAccount, setAccountEdit] = useState();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingState, setEditingState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    sub_account_id: 0,
    sub_account_access: "",
  });
  const [addMember, setAddMember] = useState({
    first_name: "",
    last_name: "",
    email: "",
    access: "",
  });
  const [adding, setAdding] = useState(false);
  const { toggleProfile, toggleExtra, profileData } = useSelector(
    (state) => state.profile
  );
  useEffect(() => {
    dispatch(fetchMemberList()).then((res) =>
      setAccountList(res.payload.team_users)
    );
  }, []);
  return (
    <>
      <Flex
        gap="10px"
        alignItems="center"
        pb="5px"
        borderBottom="2px solid #152D73"
        justifyContent="space-between"
      >
        <Flex gap="10px">
          <Icon
            as={AiOutlineTeam}
            boxSize="1.7em"
            sx={{
              transition: "transform 0.15s",
            }}
          />
          <Flex
            sx={{
              fontSize: [16, 16, 18, 20, 22, 24],
              fontWeight: 500,
              m: "auto 0px",
            }}
          >
            Team
          </Flex>
        </Flex>
        {profileData.current_plan !== "Pro Plan" &&
          profileData.current_plan !== "Free" && (
            <Tooltip
              hasArrow
              label={toggleExtra ? "Collapse Team" : "Expand Team"}
            >
              <Flex>
                <Icon
                  as={toggleExtra ? BiCollapse : HiOutlineArrowsExpand}
                  onClick={() => {
                    dispatch(ontoggleExtra());
                    if (toggleProfile) {
                      dispatch(ontoggleProfile());
                    }
                  }}
                  boxSize="1.3em"
                  color="#162c73"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                    transition: "transform 0.20s",
                  }}
                />
              </Flex>
            </Tooltip>
          )}
      </Flex>
      <Flex
        sx={{
          display: toggleProfile ? "none" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Flex>Sub-Accounts</Flex>
        <Button
          onClick={onOpen}
          sx={{
            bgColor: "#fff",
            borderRadius: "4px",
            border: "1px solid #162c73",
            borderBottom: "3px solid #162c73",
            gap: "10px",
          }}
        >
          <Icon as={FaUserPlus} boxSize="1.3em" />
          Add Member
        </Button>
      </Flex>
      <Flex
        position="relative"
        width="100%"
        height="100%"
        sx={{ display: toggleProfile ? "none" : "flex" }}
      >
        <Flex
          flexDir="column"
          width="100%"
          height="100%"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            overflowY: "auto",
            gap: "10px",
            p: "2px",
          }}
        >
          {accountList?.map((account, i) => {
            return (
              <Flex
                key={i}
                width="100%"
                bgColor="#f3f7ff"
                p="10px"
                border="1px solid #fff"
                borderRadius="4px"
                sx={{
                  position: "relative",
                  boxShadow: "0px 0px 3px 1px rgb(81,142,248, 0.35)",
                  "&:hover": {
                    border: "1px solid #162c73",
                  },
                }}
              >
                <Flex width="15%" alignItems="center">
                  <Image
                    width="60px"
                    height="60px"
                    borderRadius="4px"
                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  />
                </Flex>
                <Flex
                  flexDir="column"
                  width="50%"
                  pr="10px"
                  gap="10px"
                  justifyContent="center"
                >
                  {editing && editAccount === i ? (
                    <Flex gap="10px">
                      <Input
                        value={editingState.first_name}
                        onChange={(e) => {
                          setEditingState((c) => ({
                            ...c,
                            first_name: e.target.value,
                          }));
                        }}
                      />
                      <Input
                        value={editingState.last_name}
                        onChange={(e) => {
                          setEditingState((c) => ({
                            ...c,
                            last_name: e.target.value,
                          }));
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex>{account.name}</Flex>
                  )}
                  {editing && editAccount === i ? (
                    <Flex gap="10px">
                      <Input
                        value={editingState.email}
                        onChange={(e) => {
                          setEditingState((c) => ({
                            ...c,
                            email: e.target.value,
                          }));
                        }}
                      />
                    </Flex>
                  ) : (
                    <Flex fontSize={14}>{account.email}</Flex>
                  )}
                </Flex>
                <Flex
                  width="35%"
                  alignItems="center"
                  justifyContent={
                    editing && editAccount === i ? "space-between" : "flex-end"
                  }
                >
                  {editing && editAccount === i && (
                    <Select
                      width="60%"
                      value={editingState.access}
                      onChange={(e) => {
                        setEditingState((c) => ({
                          ...c,
                          sub_account_access: e.target.value,
                        }));
                      }}
                    >
                      {selectOption.map((option, i) => {
                        return (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                  <Flex justifyContent="space-between" width="30%">
                    {editing && editAccount === i ? (
                      <Tooltip hasArrow label="Save Change">
                        <Flex>
                          <Icon
                            as={RiSaveFill}
                            onClick={() => {
                              setLoading(i);
                              dispatch(updateMemberDataPost(editingState)).then(
                                (res) => {
                                  setEditing(false);
                                  setAccountEdit(null);
                                  setEditingState({
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    sub_account_id: 0,
                                    sub_account_access: "",
                                  });
                                  setLoading(null);
                                }
                              );
                            }}
                            boxSize="1.2em"
                            color="#162c73"
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                color: "#f7c544",
                              },
                            }}
                          />
                        </Flex>
                      </Tooltip>
                    ) : (
                      <Tooltip hasArrow label="Edit Member">
                        <Flex>
                          <Icon
                            as={FaUserEdit}
                            onClick={() => {
                              setEditing(true);
                              setAccountEdit(i);
                              setEditingState({
                                email: account.email,
                                first_name: account.first_name,
                                last_name: account.last_name,
                                sub_account_access: account.access,
                                sub_account_id: account.id,
                              });
                            }}
                            boxSize="1.2em"
                            color="#162c73"
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                color: "#f7c544",
                              },
                            }}
                          />
                        </Flex>
                      </Tooltip>
                    )}
                    <Tooltip hasArrow label="Delete Member">
                      <Flex>
                        <Icon
                          as={FaUserMinus}
                          onClick={() => {
                            setLoading(i);
                            dispatch(
                              deleteMemberId({
                                sub_account_id: account.id,
                              })
                            ).then((res) =>
                              dispatch(fetchMemberList()).then((res) => {
                                setAccountList(res.payload.team_users);
                                setLoading(null);
                              })
                            );
                          }}
                          boxSize="1.2em"
                          color="#162c73"
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              color: "red",
                            },
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Flex>
                <Progress
                  size="xs"
                  isIndeterminate
                  sx={{
                    display: loading === i ? "flex" : "none",
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent sx={{ borderRadius: "4px" }}>
          <ModalHeader
            sx={{
              bgColor: "#162c73",
              color: "#fff",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            }}
          >
            Add New Member
          </ModalHeader>
          <ModalCloseButton
            boxSize="3em"
            size="lg"
            color="#fff"
            sx={{
              "&:hover": {
                color: "red",
              },
            }}
          />
          <ModalBody
            flexDir="column"
            gap="10px"
            display="flex"
            position="relative"
          >
            <NodeLoader loading={adding} bgcolor="#fff" />
            <Flex gap="20px" width="100%">
              <Flex flexDir="column" width="100%" gap="5px">
                <Flex fontSize={14}>First Name</Flex>
                <Input
                  width="100%"
                  onChange={(e) =>
                    setAddMember((c) => ({ ...c, first_name: e.target.value }))
                  }
                />
              </Flex>
              <Flex flexDir="column" width="100%" gap="5px">
                <Flex fontSize={14}>Last Name</Flex>
                <Input
                  width="100%"
                  onChange={(e) =>
                    setAddMember((c) => ({ ...c, last_name: e.target.value }))
                  }
                />
              </Flex>
            </Flex>
            <Flex flexDir="column" width="100%" gap="5px">
              <Flex fontSize={14}>Email Address</Flex>
              <Input
                width="100%"
                onChange={(e) =>
                  setAddMember((c) => ({ ...c, email: e.target.value }))
                }
              />
            </Flex>
            <Flex flexDir="column" width="100%" gap="5px">
              <Flex fontSize={14}>Assign Access</Flex>
              <Select
                onChange={(e) =>
                  setAddMember((c) => ({ ...c, access: e.target.value }))
                }
              >
                {selectOption.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Flex width="100%" gap="20px" my="10px">
              <Button
                onClick={() => {
                  setAdding(true);
                  dispatch(addTeamMember(addMember)).then((res) => {
                    if (res.payload.key === "SIGNUP_FAIL") {
                      //toast
                      onClose();
                      setAdding(false);
                    } else
                      dispatch(fetchMemberList()).then((res) => {
                        setAccountList(res.payload.team_users);
                        onClose();
                        setAdding(false);
                      });
                  });
                }}
                width="100%"
              >
                Add
              </Button>
              <Button width="100%">Cancel</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
