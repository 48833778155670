import React, { useCallback, useState } from "react";
import { Checkbox, Button, Flex, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  OnTestandReview,
  onDeleteNodeV2,
  saveKonnect,
} from "../../store/thunk/rightHandAppsThunk";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { getProfile } from "../../store/thunk/accountsThunk";

export const GSheetModal = React.memo(
  ({ parentnode, data, setModalState, nodeId }) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { editorState } = useSelector((state) => state.canvas);
    const captureRequest = Axios.CancelToken.source();

    const [isAndSelected, setIsSelected] = React.useState(true);
    const [result, setResult] = useState(data.configResponses);
    const [list, setList] = useState(
      result.map((r) => {
        return {
          ...r,
          checked: false,
        };
      })
    );
    const helper = {
      onTestandReview: useCallback(() => {
        if (
          data?.delayReady &&
          data?.delayConfigsForTest?.delay_type === "Delay Until"
        ) {
          dispatch(getProfile()).then((res) => {
            if (res?.payload?.timezone === null) {
              toast({
                position: "top",
                status: "warning",
                variant: "solid",
                title:
                  "Update the time zone in the profile section to proceed further.",
                duration: 2500,
                containerStyle: {
                  fontWeight: 400,
                },
              });
            } else {
              if (
                !data.delay_activity_id &&
                data?.delayReady &&
                data?.konnect_activity_id
              ) {
                dispatch(OnTestandReview(data.nodeId)).then((res) => {
                  dispatch(saveKonnect({ publish: false }));
                });
              } else {
                dispatch(OnTestandReview(data.nodeId)).then((res) => {
                  dispatch(saveKonnect({ publish: false }));
                });
              }
            }
          });
        } else {
          if (
            !data.delay_activity_id &&
            data?.delayReady &&
            data?.konnect_activity_id
          ) {
            dispatch(onDeleteNodeV2(data.nodeId)).then((res) => {
              dispatch(OnTestandReview(data.nodeId)).then((res) => {
                dispatch(saveKonnect({ publish: false }));
              });
            });
          } else {
            dispatch(OnTestandReview(data.nodeId)).then((res) => {
              dispatch(saveKonnect({ publish: false }));
            });
          }
        }
        setModalState({ display: false, type: null });
      }, [data]),
    };
    const { t } = useTranslation();
    const [displayValue, setDisplayValue] = useState();
    const nodeIdx = editorState.nodes.findIndex(
      (node) => node.id === data.nodeId
    );
    const handleCheck = (e, id) => {
      setList((c) =>
        c.map((res) => {
          if (id === res.port.id) {
            return { ...res, checked: e.target.checked };
          } else return res;
        })
      );
      setResult((c) =>
        c.map((res) => {
          if (id === res.port.id) {
            return { ...res, checked: e.target.checked };
          } else return res;
        })
      );
    };
    return (
      <>
        <Flex
          height="100%"
          width="100%"
          sx={{
            p: "16px",
            flexDirection: "column",
            bgColor: "#fff",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            justifyContent: "space-between",
          }}
        >
          {isAndSelected ? (
            <>
              <Button
                colorScheme="teal"
                variant="solid"
                onClick={() => setIsSelected(true)}
              >
                {t("konnect.conditions.operatorAndLabel")}
              </Button>
              <Button onClick={() => setIsSelected(false)}>OR</Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsSelected(true)}>AND</Button>
              <Button
                colorScheme="teal"
                variant="solid"
                onClick={() => setIsSelected(false)}
              >
                {t("konnect.conditions.operatorAndLabel")}
              </Button>
            </>
          )}
          {
            <Flex height="100%" width="100%" sx={{ position: "relative" }}>
              <Flex
                onMouseEnter={() => parentnode.current.classList.add("nowheel")}
                onMouseLeave={() =>
                  parentnode.current.classList.remove("nowheel")
                }
                sx={{
                  height: "100%",
                  gap: "10px",
                  flexDirection: "column",
                  position: "absolute",
                  p: "5px 5px 5px 2px",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  borderBottomRightRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  overflowY: "auto",
                }}
              >
                {list[0].id !== "Not-found" ? (
                  list.map((response, i) => {
                    return (
                      <Checkbox
                        key={i}
                        onMouseEnter={() => setDisplayValue(i)}
                        onMouseLeave={() => setDisplayValue(null)}
                        isChecked={response.checked}
                        onChange={(e) => handleCheck(e, response.port.id)}
                        sx={{
                          wordBreak: "break-all",
                          "&:hover": {
                            outline: "1px solid #b7caff",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        {`${response.label}`}
                      </Checkbox>
                    );
                  })
                ) : (
                  <>{t("konnect.sidebar.responseModal.no_labelFound")}</>
                )}
              </Flex>
            </Flex>
          }
          <Button onClick={helper.onTestandReview}>
            {t("konnect.proceed.btnLabel")}
          </Button>
        </Flex>
      </>
    );
  }
);
