import { Box, Flex, Tooltip } from "@chakra-ui/react";
import React, { memo } from "react";
import { svg } from "../../../assets/objectSVG";

export const AdditionalResponse = memo(({ onClick, display, visibility }) => {
  return (
    <Box
      sx={{
        visibility: visibility && !display ? "visible" : "hidden",
        zIndex: visibility && !display ? 2 : -10,
        opacity: visibility && !display ? 1 : 0,
        position: "absolute",
        bottom: "-15px",
        left: "0px",
        width: "100%",
      }}
    >
      <Tooltip label={"Show More Response"} hasArrow>
        <Flex
          onClick={onClick}
          sx={{
            "&:hover": {
              transform: "scale(1.1)",
            },
            width: "27px",
            m: "auto",
            bgColor: "#fff",
            fill: "#162c73",
            p: "3px 4px",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "transform 0.15s",
          }}
        >
          {svg.addReponse}
        </Flex>
      </Tooltip>
    </Box>
  );
});
