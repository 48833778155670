import { Flex, Icon, useToast } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ControlButton, Controls, ReactFlow, useReactFlow } from "reactflow";
import { RiExpandLeftFill } from "react-icons/ri";
import { nanoid } from "@reduxjs/toolkit";
import { ButtonEdge } from "../../components/node/type/buttonEdge";
import {
  onNodesChange,
  toggleTopSearchBar,
  toggleAppContainer,
  onEdgesChange,
  reArrange,
  setDisplayAddOn,
  clearState,
  onLinkComplete,
  onLinkDelete,
} from "../../store/slice/canvasSlice";
import { onDrop } from "./helper";
import {
  WebhookInner,
  NodeInner,
  Scheduler,
  AddOnInner,
  ApiInner,
  CouponGenerator,
  Conditions,
  TextSplitter,
  CommonAddOnInner,
} from "../../components/node/type";
import { useLocation } from "react-router-dom";
import { getKonnect, showOnCanvas } from "../../store/thunk/rightHandAppsThunk";

const nodeTypes = {
  WebHookInner: WebhookInner,
  NodeInner: NodeInner,
  scheduler: Scheduler,
  addOnInner: AddOnInner,
  conditions: Conditions,
  apiInner: ApiInner,
  coupan: CouponGenerator,
  textSplitter: TextSplitter,
  commonAddOnInner: CommonAddOnInner,
};

const edgeTypes = {
  buttonedge: ButtonEdge,
};
export const CanvasContainer = ({
  reactFlowInstance,
  setReactFlowInstance,
}) => {
  const history = useLocation();
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.canvas.editorState.nodes);
  const links = useSelector((state) => state.canvas.editorState.edges);
  const canvasLoad = useSelector((state) => state.canvas.canvasLoad);
  const appContainer = useSelector((state) => state.canvas.appContainer);
  const toast = useToast();
  const reactFlowWrapper = useRef(null);
  const onDropFunction = (e) => {
    onDrop(
      e,
      reactFlowInstance,
      dispatch,
      reactFlowWrapper,
      nodes,
      links,
      toast
    );
  };
  let idd = history.pathname.split("/").pop();
  useEffect(() => {
    if (idd && !isNaN(idd)) {
      dispatch(getKonnect(idd));
    } else {
      dispatch(clearState());
    }
  }, []);
  const items = JSON.parse(localStorage.getItem("dataKey"));
  useEffect(() => {
    if (items) {
      showOnCanvas({ items, dispatch, nanoid });
    }
    return localStorage.setItem("dataKey", JSON.stringify(null));
  }, []);
  // console.group("EDITOR-STATE");
  // console.log("NODES: ", nodes);
  // console.log("EDGES: ", links);
  // console.groupEnd();
  return (
    <Flex width="100%" height="100%" ref={reactFlowWrapper} position="relative">
      {canvasLoad ? (
        <Flex
          width="100%"
          height="100%"
          background="rgb(0,0,0,0.2)"
          sx={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex sx={{ color: "#fff", fontSize: 24, textAlign: "center" }}>
            Please wait while we setup <br />
            the konnect for you.
          </Flex>
        </Flex>
      ) : (
        <ReactFlow
          onInit={(inst) => {
            setReactFlowInstance(inst);
            // inst.fitView()
          }}
          onClick={() => {
            dispatch(toggleTopSearchBar(false));
            dispatch(setDisplayAddOn(false));
          }}
          onMouseDownCapture={() => {
            dispatch(toggleTopSearchBar(false));
            dispatch(setDisplayAddOn(false));
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.dataTransfer.dropEffect = "move";
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onDrop={onDropFunction}
          nodes={nodes}
          edges={links}
          onConnect={(c) => {
            dispatch(
              onLinkDelete({
                nodeId: c.target,
                additionalOnly: true,
              })
            );

            dispatch(
              onLinkComplete({
                ...c,
                id: nanoid(),
                direct_link: [],
                type: "buttonedge",
              })
            );
          }}
          onNodesChange={(c) => dispatch(onNodesChange(c))}
          onEdgesChange={(c) => dispatch(onEdgesChange(c))}
          minZoom={0.1}
          maxZoom={5}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          connectionLineStyle={{ stroke: "rgb(247, 150, 10)", strokeWidth: 3 }}
          connectionRadius={25}
        >
          <Controls style={{ border: "2px solid #3182ce" }}>
            <ControlButton
              onClick={() => {
                dispatch(reArrange());
                reactFlowInstance.fitView();
              }}
              title="Re-Arrange"
            >
              <div>R</div>
            </ControlButton>
            {appContainer === "Topbar" ? (
              <ControlButton
                onClick={() => dispatch(toggleAppContainer("Sidebar"))}
                title="Expand Sidebar"
                style={{ maxH: "16px !important", maxWidth: "16px !important" }}
              >
                <Icon
                  as={RiExpandLeftFill}
                  boxSize="2em"
                  sx={{ transform: "rotate(180deg)" }}
                />
              </ControlButton>
            ) : (
              <ControlButton
                onClick={() => dispatch(toggleAppContainer("Topbar"))}
                title="Collapse Sidebar"
                style={{ maxH: "16px !important", maxWidth: "16px !important" }}
              >
                <Icon as={RiExpandLeftFill} boxSize="2em" />
              </ControlButton>
            )}
          </Controls>
        </ReactFlow>
      )}
    </Flex>
  );
};
