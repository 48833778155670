import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from "../translations/translations"
// Configure and initialize i18next
i18n
  .use(initReactI18next) // Pass the react-i18next module
  .init({
    resources,
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Fallback language in case the translation is missing
    interpolation: {
      escapeValue: false, // React handles escaping
    },
  });

export default i18n;
