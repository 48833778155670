import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Tooltip,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  useToast,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { CgArrowsExpandDownLeft } from "react-icons/cg";
import { IoIosRedo, IoIosUndo } from "react-icons/io";
import { FaSave } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAddonApps, searchApp } from "../../store/thunk/appsThunk";
import { useNavigate } from "react-router-dom";
import {
  toggleTopSearchBar,
  saveKonnectName,
  clearState,
  redo,
  undo,
  setDisplayAddOn,
  toggleDisplayAddOn,
  saveSearchingAppName,
} from "../../store/slice/canvasSlice";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { PiUploadBold } from "react-icons/pi";
import { FaRegSave } from "react-icons/fa";
import { GiExpand } from "react-icons/gi";
import { saveKonnect } from "../../store/thunk/rightHandAppsThunk";
import { NodeLoader } from "../../components/loader";
import { Axios } from "axios";
export const TopbarContainer = ({ builderRef }) => {
  // const [searchingApp, setSearchingApp] = useState("");
  const [listedApp, setListedApp] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const canPublish = useSelector((state) => state.canvas.canPublish);
  const canSave = useSelector((state) => state.canvas.canSave);
  const konnectName = useSelector(
    (state) => state.canvas.editorState.konnect_name
  );
  const saving = useSelector((state) => state.canvas.topbarState.saving);
  const searchingApp = useSelector(
    (state) => state.canvas.topbarState.searchingApp
  );
  const appContainer = useSelector((state) => state.canvas.appContainer);
  const appsList = useSelector((state) => state.apps.appsList);
  const addOnList = useSelector((state) => state.apps.addOnList);
  const [loading, setLoading] = useState(false);
  const topSearchBar = useSelector(
    (state) => state.canvas.topbarState.topSearchBar
  );
  const nodeId = useSelector((state) => state.canvas.editorState.nodes[0]?.id);
  const toast = useToast();
  const displayAddOn = useSelector(
    (state) => state.canvas.topbarState.displayAddOn
  );
  const { onOpen, onClose, isOpen } = useDisclosure();
  let timer;
  const inputRef = useRef();

  const handleChange = (e) => {
    setLoading(true);
    dispatch(saveSearchingAppName(e.target.value));
    //setSearchingApp(e.target.value);
    if (!e.target.value) {
      setListedApp(appsList);
      setLoading(false);
    } else {
      e.target.value.length > 0 &&
        dispatch(searchApp({ search_query: e.target.value })).then((res) => {
          if (res.payload.apps.length > 0) {
            setListedApp(res.payload.apps);
            setLoading(false);
          } else {
            setListedApp([]);
            setLoading(false);
          }
        });
    }
  };
  useEffect(() => {
    dispatch(getAddonApps()).catch((err) => {
      navigate("/login");
    });
  }, []);

  return (
    <Flex
      width="100%"
      bgColor="#152E73"
      zIndex={2}
      sx={{
        boxShadow: "0px 0px 7px -2px rgb(0,0,0,0.5)",
        height: "8%",
        minHeight: "70px",
        maxHeight: "100px",
        alignItems: "center",
        px: "20px",
      }}
    >
      <Flex width="100%" alignItems="center">
        <Flex
          onClick={() => {
            if (nodeId) {
              clearInterval(null);
            }
            dispatch(clearState());
            navigate(-1);
          }}
          width="20%"
          alignItems="center"
          gap="5px"
          cursor="pointer"
        >
          <Icon
            as={MdOutlineKeyboardBackspace}
            boxSize={{
              base: "1.4em",
              sm: "1.4em",
              md: "1.5em",
              lg: "1.6em",
              xl: "1.7em",
              "2xl": "1.9em",
            }}
            cursor="pointer"
            color="#fff"
          />
          <Flex sx={{ fontSize: [14, 14, 16, 16, 16, 18], color: "#fff" }}>
            {t("konnect.header.backBtnLabel")}
          </Flex>
        </Flex>

        <Flex
          position="relative"
          width={appContainer === "Topbar" ? "60%" : "40%"}
          justifyContent="center"
        >
          {appContainer === "Topbar" ? (
            <>
              <InputGroup
                size={{
                  base: "md",
                  sm: "md",
                  md: "md",
                  lg: "md",
                  xl: "lg",
                  "2xl": "lg",
                }}
                sx={{
                  display: "flex",
                  position: "relative",
                  zIndex: 3,
                  width: [
                    "500px",
                    "600px",
                    "600px",
                    "700px",
                    "900px",
                    "1200px",
                  ],
                }}
              >
                <InputLeftElement>
                  {searchingApp ? (
                    <CloseIcon
                      onClick={() => {
                        dispatch(saveSearchingAppName(""));

                        // setSearchingApp("");
                        inputRef.current.focus();
                      }}
                      color="rgb(21,45,115,0.6)"
                      boxSize="0.8em"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "#E57387",
                          transform: "scale(1.2)",
                        },
                        transition: "color 0.15s, transform 0.15s",
                      }}
                    />
                  ) : (
                    <SearchIcon color="rgb(21,45,115,0.6)" />
                  )}
                </InputLeftElement>
                <Input
                  ref={inputRef}
                  onFocus={() => dispatch(toggleTopSearchBar(true))}
                  placeholder={t("konnect.tobar.inputLeftElm_placeholder")}
                  onChange={handleChange}
                  value={searchingApp}
                  sx={{
                    borderRadius: "4px",
                    py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                    "&:hover": {
                      border: "1px solid #f7c544",
                      borderBottom: "3px solid #f7c544",
                    },
                    bgColor: "#fff",
                    border: "1px solid #f7c544",
                    borderBottom: "3px solid #f7c544",
                  }}
                />
                <Tooltip
                  label={displayAddOn ? "Collapse Add-On" : "Expand Add-On"}
                >
                  <Flex
                    onClick={() => {
                      dispatch(toggleDisplayAddOn());
                      dispatch(toggleTopSearchBar(true));
                    }}
                    sx={{
                      boxShadow: "0px 0px 7px -2px rgb(0,0,0,0.75)",
                      cursor: "pointer",
                      display: "flex",
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      height: "100%",
                      alignItems: "center",
                      px: "20px",
                      gap: "7px",
                      borderRadius: "0px",
                      border: "1px solid #f7c544",
                      borderLeft: "none",
                      borderBottom: "3px solid #f7c544",
                      "&:hover": {
                        bgColor: "#DADEE5",
                      },
                      transition: "background 0.20s",
                      bgColor: "#f3f7ff",
                      color: "#152E73",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                    }}
                  >
                    <Icon as={CgArrowsExpandDownLeft} />
                    Add-On
                  </Flex>
                </Tooltip>
              </InputGroup>
              <Flex
                position="absolute"
                height="100%"
                sx={{
                  width: [
                    "500px",
                    "600px",
                    "600px",
                    "700px",
                    "900px",
                    "1200px",
                  ],
                  minH:
                    displayAddOn || (searchingApp && topSearchBar)
                      ? "150px"
                      : "0px",
                  bgColor: "#fff",
                  borderRadius: "4px",
                  top:
                    displayAddOn || (searchingApp && topSearchBar)
                      ? ["45px", "45px", "45px", "45px", "55px", "55px"]
                      : "-150px",
                  opacity:
                    displayAddOn || (searchingApp && topSearchBar) ? 1 : 0,
                  border: "1px solid #152E73",
                  borderBottom: "3px solid #152E73",
                  boxShadow: "0px 8px 6px -6px rgb(81,142,248, 0.65)",
                  transition: "height 0.15s, top 0.15s, opacity 0.15s",
                }}
              >
                <Flex
                  sx={{
                    pos: "absolute",
                    top: "10px",
                    bottom: "10px",
                    left: "10px",
                    right: "10px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Wrap
                    spacing="10px"
                    justify="center"
                    width="100%"
                    sx={{
                      p: "2px",
                      pl: "10px",
                    }}
                  >
                    {topSearchBar && !loading && !displayAddOn ? (
                      listedApp.length ? (
                        listedApp.map((app, i) => {
                          return (
                            <Tooltip key={i} label={app.name} hasArrow>
                              <Flex
                                key={i}
                                draggable
                                onDragStart={(event) => {
                                  event.dataTransfer.setData(
                                    "appsData",
                                    JSON.stringify({
                                      id: app.id,
                                      name: app.name,
                                      image: app.image_url,
                                      background: app.background_color,
                                      associatedAccounts: app.account_count
                                        ? app.account_count
                                        : 1,
                                      webhook_enabled: app.webhook_enabled,
                                      webhook_instructions:
                                        app.webhook_instructions,
                                      webhook_url: app.webhook_url,
                                      provider: app.provider,
                                      type: app.app_type,
                                      auth_type: app.auth_type,
                                      side: app.side,
                                    })
                                  );
                                  event.dataTransfer.effectAllowed = "move";
                                }}
                                width="70px"
                                flexDir="column"
                                gap="5px"
                                p={1}
                                sx={{
                                  boxShadow:
                                    "0px 0px 3px 0px rgb(81,142,248, 0.85)",
                                  borderRadius: "2px",
                                  "&:hover": {
                                    "& #icon": {
                                      transform: "scale(1.1)",
                                    },
                                  },
                                }}
                              >
                                <Image
                                  id="icon"
                                  src={app.image_url}
                                  sx={{ transition: "transform 0.15s" }}
                                />
                                <Flex
                                  sx={{
                                    fontSize: [8, 8, 8, 10, 12, 12],
                                    justifyContent: "center",
                                  }}
                                >
                                  {app.name.length > 8
                                    ? app.name.slice(0, 6) + ".."
                                    : app.name}
                                </Flex>
                              </Flex>
                            </Tooltip>
                          );
                        })
                      ) : (
                        <>No App Found</>
                      )
                    ) : loading ? (
                      <NodeLoader loading={loading} bgcolor="#fff" />
                    ) : (
                      addOnList.map((app, i) => {
                        return (
                          <Tooltip key={i} label={app.name} hasArrow>
                            <Flex
                              key={i}
                              draggable
                              onDragStart={(event) => {
                                event.dataTransfer.setData(
                                  "appsData",
                                  JSON.stringify({
                                    id: app.id,
                                    name: app.name,
                                    image: app.image_url,
                                    background: app.background_color,
                                    associatedAccounts: app.account_count
                                      ? app.account_count
                                      : 1,
                                    webhook_enabled: app.webhook_enabled,
                                    webhook_instructions:
                                      app.webhook_instructions,
                                    webhook_url: app.webhook_url,
                                    provider: app.provider,
                                    type: app.app_type,
                                  })
                                );
                                event.dataTransfer.effectAllowed = "move";
                              }}
                              width="70px"
                              flexDir="column"
                              gap="5px"
                              p={1}
                              sx={{
                                boxShadow:
                                  "0px 0px 3px 0px rgb(81,142,248, 0.85)",
                                borderRadius: "2px",
                                "&:hover": {
                                  "& #icon": {
                                    transform: "scale(1.1)",
                                  },
                                },
                              }}
                            >
                              <Image
                                id="icon"
                                src={app.image_url}
                                sx={{ transition: "transform 0.15s" }}
                              />
                              <Flex
                                sx={{
                                  fontSize: [8, 8, 8, 10, 12, 12],
                                  justifyContent: "center",
                                }}
                              >
                                {app.name.length > 8
                                  ? app.name.slice(0, 6) + ".."
                                  : app.name}
                              </Flex>
                            </Flex>
                          </Tooltip>
                        );
                      })
                    )}
                  </Wrap>
                </Flex>
              </Flex>
            </>
          ) : (
            <></>
          )}
        </Flex>

        <Flex
          gap="20px"
          alignItems="center"
          width={appContainer === "Topbar" ? "25%" : "40%"}
          justifyContent="flex-end"
        >
          {appContainer === "Sidebar" && (
            <Input
              width="50%"
              value={konnectName ? konnectName : ""}
              placeholder={`${t("konnect.header.nameOfKonnectz")} Konnectz`}
              sx={{
                py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                borderTop: "1px solid #f7c544",
                borderRight: "1px solid #f7c544",
                borderLeft: "1px solid #f7c544",
                borderBottom: "3px solid #f7c544",
                bgColor: "#f3f7ff",
                "&:focus-visible": {
                  borderColor: "#f7c544 !important",
                  boxShadow: "none",
                },
                "&:hover": {
                  borderColor: "#f7c544 !important",
                  boxShadow: "none",
                },
              }}
              onChange={(e) => {
                dispatch(saveKonnectName(e.target.value));
              }}
            />
          )}
          <ButtonGroup size="sm" isAttached variant="outline" sx={{}}>
            <Tooltip label="Undo">
              <IconButton
                onClick={() => dispatch(undo())}
                aria-label="Add to friends"
                icon={<IoIosUndo />}
                sx={{
                  backgroundColor: "white",
                  // border: "1px solid #f7c544",
                  borderBottom: "3px solid #f7c544",
                  py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                }}
              />
            </Tooltip>
            <Tooltip label="Redo">
              <IconButton
                onClick={() => dispatch(redo())}
                aria-label="Add to friends"
                icon={<IoIosRedo />}
                sx={{
                  backgroundColor: "white",
                  // border: "1px solid #f7c544",
                  borderBottom: "3px solid #f7c544",
                  py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                }}
              />
            </Tooltip>
          </ButtonGroup>
          <Tooltip label="Save" hasArrow>
            <Button
              onClick={() => {
                dispatch(saveKonnect({ publish: false })).then((res) => {
                  if ((res.type = "saveKonnect/fulfilled")) {
                    toast({
                      position: "top",
                      status: "success",
                      variant: "solid",
                      title: res.payload.message,
                      duration: 2500,
                      containerStyle: {
                        fontWeight: 400,
                      },
                    });
                    navigate("/konnectz");
                  }
                });
                dispatch(clearState());
              }}
              isDisabled={!canSave}
              sx={{
                borderTop: "1px solid #f7c544",
                borderRight: "1px solid #f7c544",
                borderLeft: "1px solid #f7c544",
                borderBottom: "3px solid #f7c544",
                fontSize: [16, 16, 18, 18, 18, 20],
                borderRadius: "4px",
                bgColor: "inherit",
                color: "#f7c544",
                px: "10px",
                gap: "10px",
                py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                "&:disabled": {
                  backgroundColor: "white",
                  color: "black",
                },
                "&:hover": {
                  "& #icon": {
                    fill: "rgb(21,46,115)",
                  },
                  color: "rgb(21,46,115)",
                  borderTop: "1px solid #fff",
                  borderRight: "1px solid #fff",
                  borderLeft: "1px solid #fff",
                  borderBottom: "3px solid #fff",
                },
              }}
            >
              {!saving ? (
                <Icon as={FaRegSave} boxSize="1.3em" width="32px" />
              ) : (
                <Spinner
                  thickness="4px"
                  speed="1.5s"
                  emptyColor="gray.200"
                  color="152E73"
                  size={{
                    base: "sm",
                    sm: "sm",
                    md: "md",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "lg",
                  }}
                />
              )}
            </Button>
          </Tooltip>
          {appContainer === "Sidebar" ? (
            <Button
              sx={{
                borderTop: isOpen ? "1px solid #fff" : "1px solid #f7c544",
                borderRight: isOpen ? "1px solid #fff" : "1px solid #f7c544",
                borderLeft: isOpen ? "1px solid #fff" : "1px solid #f7c544",
                borderBottom: isOpen ? "3px solid #fff" : "3px solid #f7c544",
                fontSize: [16, 16, 18, 18, 18, 20],
                borderRadius: "4px",
                bgColor: isOpen ? "#fff" : "inherit",
                color: isOpen ? "rgb(21,46,115)" : "#f7c544",
                px: "20px",
                gap: "10px",
                py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                "& #icon": {
                  fill: isOpen && "rgb(21,46,115)",
                },
                "&:hover": {
                  bgColor: "#fff",

                  "& #icon": {
                    fill: "rgb(21,46,115)",
                  },
                  color: "rgb(21,46,115)",
                  borderTop: "1px solid #fff",
                  borderRight: "1px solid #fff",
                  borderLeft: "1px solid #fff",
                  borderBottom: "3px solid #fff",
                },
                "&:disabled": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              isDisabled={!canPublish}
              onClick={() => {
                dispatch(saveKonnect({ publish: true })).then((res) => {
                  if (
                    (res.type =
                      "saveKonnect/fulfilled" &&
                      res.payload.publish_status === "Success")
                  ) {
                    navigate("/konnectz");
                    toast({
                      position: "top",
                      status: "success",
                      variant: "solid",
                      title: res.payload.message,
                      duration: 2500,
                      containerStyle: {
                        fontWeight: 400,
                      },
                    });
                  }
                });
                dispatch(clearState());
              }}
            >
              <Icon as={PiUploadBold} boxSize="1.2em" />

              {t("konnect.header.publishBtnLabel")}
            </Button>
          ) : (
            <Popover
              placement="bottom"
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <Button
                  sx={{
                    borderTop: isOpen ? "1px solid #fff" : "1px solid #f7c544",
                    borderRight: isOpen
                      ? "1px solid #fff"
                      : "1px solid #f7c544",
                    borderLeft: isOpen ? "1px solid #fff" : "1px solid #f7c544",
                    borderBottom: isOpen
                      ? "3px solid #fff"
                      : "3px solid #f7c544",
                    fontSize: [16, 16, 18, 18, 18, 20],
                    borderRadius: "4px",
                    bgColor: isOpen ? "#fff" : "inherit",
                    color: isOpen ? "rgb(21,46,115)" : "#f7c544",
                    px: "20px",
                    gap: "10px",
                    py: ["10px", "15px", "20px", "20px", "22px", "25px"],
                    "& #icon": {
                      fill: isOpen && "rgb(21,46,115)",
                    },
                    "&:hover": {
                      bgColor: "#fff",
                      "& #icon": {
                        fill: "rgb(21,46,115)",
                      },
                      color: "rgb(21,46,115)",
                      borderTop: "1px solid #fff",
                      borderRight: "1px solid #fff",
                      borderLeft: "1px solid #fff",
                      borderBottom: "3px solid #fff",
                    },
                    "&:disabled": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                  isDisabled={!canPublish}
                >
                  <Icon as={PiUploadBold} boxSize="1.2em" />
                  {t("konnect.header.publishBtnLabel")}
                </Button>
              </PopoverTrigger>

              <PopoverContent
                sx={{ marginRight: "20px", padding: "20px", gap: "10px" }}
              >
                <PopoverArrow />
                <Input
                  value={konnectName ? konnectName : ""}
                  placeholder={`${t("konnect.header.nameOfKonnectz")} Konnectz`}
                  sx={{
                    fontSize: [14, 14, 14, 16, 16, 18],
                    borderRadius: "4px",
                    bgColor: "#fff",
                    borderTop: "1px solid #152E73",
                    borderRight: "1px solid #152E73",
                    borderLeft: "1px solid #152E73",
                    borderBottom: "3px solid #152E73",
                  }}
                  onChange={(e) => {
                    dispatch(saveKonnectName(e.target.value));
                  }}
                />
                <Flex gap="20px">
                  <Button
                    onClick={() => {
                      dispatch(saveKonnect({ publish: true })).then((res) => {
                        if (
                          (res.type =
                            "saveKonnect/fulfilled" &&
                            res.payload.publish_status === "Success")
                        ) {
                          navigate("/konnectz");
                          toast({
                            position: "top",
                            status: "success",
                            variant: "solid",
                            title: res.payload.message,
                            duration: 2500,
                            containerStyle: {
                              fontWeight: 400,
                            },
                          });
                        }
                      });
                      dispatch(clearState());

                      onClose();
                    }}
                    sx={{
                      borderTop: "1px solid #152E73",
                      width: "100%",
                      borderRight: "1px solid #152E73",
                      borderLeft: "1px solid #152E73",
                      borderBottom: "3px solid #152E73",
                      fontSize: [14, 14, 14, 16, 16, 18],
                      borderRadius: "4px",
                      bgColor: "inherit",
                      color: "#152E73",
                      gap: "10px",
                      "&:hover": {
                        "& #icon": {
                          fill: "rgb(21,46,115)",
                        },
                        color: "rgb(21,46,115)",
                        borderTop: "1px solid #fff",
                        borderRight: "1px solid #fff",
                        borderLeft: "1px solid #fff",
                        borderBottom: "3px solid #fff",
                      },
                    }}
                  >
                    {t("konnect.conditions.okBtnLabel")}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                    sx={{
                      borderTop: "1px solid #152E73",
                      borderRight: "1px solid #152E73",
                      borderLeft: "1px solid #152E73",
                      borderBottom: "3px solid #152E73",
                      fontSize: [14, 14, 14, 16, 16, 18],
                      borderRadius: "4px",
                      bgColor: "inherit",
                      width: "100%",
                      color: "#152E73",
                      gap: "10px",
                      "&:hover": {
                        "& #icon": {
                          fill: "rgb(21,46,115)",
                        },
                        color: "rgb(21,46,115)",
                        borderTop: "1px solid #fff",
                        borderRight: "1px solid #fff",
                        borderLeft: "1px solid #fff",
                        borderBottom: "3px solid #fff",
                      },
                    }}
                  >
                    {t("konnect.tobar.cancelBtnLabel")}
                  </Button>
                </Flex>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
