import React, { useRef, useState } from "react";
import {
  Button,
  Flex,
 
  Box,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ApiItem } from "./Components/ApiItem";
import { svg } from "../../assets/objectSVG"

import { updateHeadersAndParams, updateInputKey } from "../../store/slice/canvasSlice";
export const ForwarderModal= React.memo(
  ({
    setModalState,
    onCancel = () => {
      setModalState({ display: false, data: null });
    },
    data,
    setExpand,
  }) => {
    const dispatch = useDispatch();
    const [inputList, setInputList] = useState(data.keyList);
    const { t } = useTranslation();
    const handleInputRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
      };
  
     
      const handleInputListAddClick = () => {
        setInputList([...inputList, { key: "" }]);
      };
      const prepareNodeConfigDetails = (list, text) => {
        const nodes = [];
        
        list.forEach((obj) => {
            let id = nanoid();
          if (obj.key !== "") {
            nodes.push({
              sequence: 0,
              customAdded:true,
              config_key: obj.key,
              error: null,
              key_value_type: "input",
              value: "",
              mapped: true,
              label: `${obj.key}`,
              side: "left",
              app_id: data.id,
  checked:true,
              id: id,
              config_key_required: true,
              app_event_id: data.appEvent,
              fetch_fields: false,
              ts: new Date().getTime(),
              port: {
                id: id,
                source: id + "|" + "source",
                target: id + "|" + "target",
              },
            });
          }
        });
        return nodes;
      };
      const updateInputKeyDetails = (headersList, paramsList) => {
        const headerNodeDetails = prepareNodeConfigDetails(inputList, "inputKey");
        dispatch(
          updateInputKey({
            inputList: inputList,
            nodeId: data.nodeId,
            newNodeDetails: headerNodeDetails,
          })
        );
      };
      const handleInputChange= (e, index) => {
        const list = [...inputList];
        let list1 = JSON.parse(JSON.stringify(list));
  
        if (e.target) {
          const { name, value } = e.target;
          list1[index][name] = value;
          setInputList(list1);
        }
      };
    return (
      <Flex
        sx={{
          p: "16px",
          flexDirection: "column",
          bgColor: "#fff",
          borderBottomRightRadius: "4px",
          height: "100%",
          borderBottomLeftRadius: "4px",
          overflowY: "auto",
          justifyContent: "space-between",
        }}
      >
       <Flex sx={{ flexDirection: "column" }}>
          <Box sx={{ p: "4% 7%" }}>{t("konnect.sidebar.add_key")}</Box>
          <ForwardItem
            inputList={inputList}
            handleAddClick={handleInputListAddClick}
            handleRemoveClick={handleInputRemoveClick}
            handleInputChange={handleInputChange}
            //   fromFields={fromFields}
          />
        </Flex>

        <Flex
          height="8%"
          minHeight="30px"
          maxHeight="30px"
          sx={{ justifyContent: "center", gap: "10px", mb: "3%" }}
        >
          <Button
            onClick={() => {
                updateInputKeyDetails(inputList);
              setExpand(false);
              onCancel();
            }}
            sx={{
              bg: "#152d73",
              color: "#fff",
              "&:hover": {
                color: "rgb(247,197,68)",
                bg: "#152d73",
              },
            }}
          >
            {t("konnect.conditions.okBtnLabel")}
          </Button>
          <Button
            onClick={() => {
               
              setExpand(false);
              onCancel();
            }}
            sx={{
              display: "flex",
              bg: "#152d73",
              color: "#fff",
              "&:hover": {
                color: "red",
                bg: "#152d73",
              },
            }}
          >   {t("apps.cancelBtnText")}</Button>
          
           
        </Flex>
      </Flex>
    );
  }
);



const ForwardItem = ({
  parentNode,
  inputList,
  handleAddClick,
  handleInputChange,
  handleRemoveClick,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          gap: '10px',
          m: '0% 4%',
          p: '2% 4%',
          flexDirection: 'column',
          border: '1px solid white',
          borderRadius: '3px',
          boxShadow: '1px 1px 3px 1px lightgray',
        }}
      >
        {inputList.map((x, i) => {
          return (
            <>
              <Flex
                sx={{
                  gap: '10px',
                  alignItems: 'center',
                  height: '30px',
                  mb: '10px',
                }}
              >
                <Input width="80%" name="key" placeholder="key" value={x.key}
                onChange={e => handleInputChange(e, i)}
                />
                {inputList.length !== 1 && (
                  <Tooltip label="Delete" hasArrow>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: 'inherit',
                        color: '#666666',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveClick(i)}
                    >
                      {svg.delete}
                    </Box>
                  </Tooltip>
                )}
                {inputList.length - 1 === i && (
                  <Tooltip label="Add New Key" hasArrow>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: 'inherit',
                        color: '#666666',
                        cursor: 'pointer',
                      }}
                      onClick={handleAddClick}
                    >
                      {svg.plus}
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </>
          );
        })}
      </Flex>
    </>
  );
};

