import { Button, Flex, Image, Tooltip } from "@chakra-ui/react";
import React,{useEffect} from "react";
import { useTranslation } from "react-i18next"	
import Upgradenow from "../../../assets/images/UpgradeNow.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {getWidgetDetails} from "../../../store/thunk/dashThunk"

export const UpgradeNow = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const { t } = useTranslation()
  useEffect(()=>{
    dispatch(getWidgetDetails("total_konnectz"))


  },[])

  return (
    <Flex
      sx={{
        bgColor: "#152F73",
        width: "19%",
        borderRadius: "4px",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        flexDir: "column",
        p: "20px",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      <Flex height="60%" sx={{ gap: "10px" }}>
        <Image height="90%" width="40%" src={Upgradenow} />
        <Flex
          width="70%"
          sx={{
            color: "#fff",
            m: "auto 0px",
            fontSize: [12, 13, 16, 16, 18, 20],
            textAlign: "right",
          }}
        >
          {t("dashboard.widgets.subscription.unlockMoreUpgradingText")}
        </Flex>
      </Flex>
 
        <Button
        onClick={() => navigate('/manage-subscription')}
          sx={{
            borderTop: "1px solid #f7c544",
            borderRight: "1px solid #f7c544",
            borderLeft: "1px solid #f7c544",
            borderBottom: "3px solid #f7c544",
            fontSize: [16, 16, 18, 18, 18, 20],
            borderRadius: "4px",
            display: "flex",
            m: "0px auto",
            width: "100%",
            bgColor: "inherit",
            color: "#f7c544",
            px: "30px",
            gap: "5px",
            py: ["10px", "15px", "20px", "20px", "22px", "25px"],
            "&:hover": {
              "& #icon": {
                fill: "rgb(21,46,115)",
              },
              color: "rgb(21,46,115)",
              borderTop: "1px solid #fff",
              borderRight: "1px solid #fff",
              borderLeft: "1px solid #fff",
              borderBottom: "3px solid #fff",
            },
          }}
        >
          {t("dashboard.widgets.subscription.upgradeBtnLabel")}
        </Button>
    </Flex>
  );
};
