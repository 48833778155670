import { createSlice, current } from "@reduxjs/toolkit";
import { applyEdgeChanges, applyNodeChanges } from "reactflow";
import {
  getAppEvent,
  getLinkedAccounts,
  getAppEventConfigs,
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  OnTestandReview,
  getKonnect,
  saveKonnect,
  onDeleteNodeV2,
} from "../thunk/rightHandAppsThunk";
import { getLinkedAccountsinModal } from "../thunk/appsThunk";
import { captureWebHookResponse } from "../thunk/webhookThunk";
import { FlattenJSON } from "../../features/visualstudio/helper";
import { nanoid } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import {
  editAuthLabelPost,
  getOauthData,
  updateAppAccountStatus,
} from "../thunk/appsThunk";
const couponInitialState = {
  expiryNum: "",
  duration: [
    { id: 1, name: "Days", label: "Days", selected: true, value: 1 },
    { id: 2, name: "Week", label: "Week", selected: false, value: 2 },
    { id: 3, name: "Months", label: "Months", selected: false, value: 3 },
    { id: 4, name: "Years", label: "Years", selected: false, value: 4 },
  ],
};
const textSplitterInitialState = {
  segmentIndex: [
    { id: 1, name: "First", label: "First", selected: true, value: 1 },
    { id: 2, name: "Second", label: "Second", selected: false, value: 2 },
    { id: 3, name: "Last", label: "Last", selected: false, value: 3 },
    {
      id: 4,
      name: "Second to Last",
      label: "Second to Last",
      selected: false,
      value: 4,
    },
    { id: 5, name: "All", label: "All", selected: false, value: 5 },
  ],
};
const apiAppInitialState = {
  endpointURL: "",
  payloadType: [
    { id: 1, name: "JSON", selected: true, label: "JSON", value: 1 },
  ],
  wrapRequestInArray: [
    { id: 1, name: "NO", selected: true, label: "NO", value: 1 },
    { id: 2, name: "YES", selected: false, label: "YES", value: 2 },
  ],
  headers: [{ key: "" }],
  params: [{ key: "" }],
};
let InitialDelayConfig = {
  delay_type: "",
  delay_unit: "",
  delay_value: "",
};
const initialState = {
  editorState: {
    nodes: [],
    edges: [],
    canvasname: "ReactFlow2.2",
  },
  delayConfigs: InitialDelayConfig,
  canPublish: false,
  canSave: false,
  isOnEditMode: false,
  history: {
    past: [],
    future: [],
    present: {
      nodes: [],
      edges: [],
    },
  },
  customAppData: {
    coupon: couponInitialState,
    textSplitter: textSplitterInitialState,
  },
  apiAppInitialState: apiAppInitialState,
  appContainer: "Sidebar",
  sidebarState: {
    addOnCollapse: false,
  },
  topbarState: {
    displayAddOn: false,
    topSearchBar: false,
    saving: false,
    konnectzName: "",
    searchingApp: "",
  },
  gSheetPopUp: false,
  modalData: {},
  getOauthData: null,
  RedoUndoNodes: [],
  canvasLoad: false,
};

export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    toggleTopSearchBar: (state, action) => {
      state.topbarState.topSearchBar = action.payload;
    },
    setDisplayAddOn: (state, action) => {
      state.topbarState.displayAddOn = action.payload;
    },
    setCanvasLoad: (state, action) => {
      state.canvasLoad = action.payload;
    },
    resetStatus: (state, action) => {
      const node = state.editorState.nodes.find((n) => n.id === action.payload);
      node.data.status = node.data.prevStatus;
      node.data.captureMessage = node.data.prevCaptureMessage;
      delete node.data.prevStatus;
      delete node.data.prevCaptureMessage;
    },
    toggleDisplayAddOn: (state, action) => {
      state.topbarState.displayAddOn = !state.topbarState.displayAddOn;
    },
    updateAddonCollapse: (state, action) => {
      state.sidebarState.addOnCollapse = action.payload;
    },
    toggleAddonCollapse: (state) => {
      state.sidebarState.addOnCollapse = !state.sidebarState.addOnCollapse;
    },
    toggleAppContainer: (state, action) => {
      state.appContainer = action.payload;
    },
    onNodesChange: (state, action) => {
      state.editorState.nodes = applyNodeChanges(
        action.payload,
        state.editorState.nodes
      );
    },
    onEdgesChange: (state, action) => {
      state.editorState.edges = applyEdgeChanges(
        action.payload,
        state.editorState.edges
      );
    },
    clearState: (state, action) => {
      state.editorState = initialState.editorState;
    },

    // ON DROPPING APPLICATION TO THE CANVAS || DONE FOR APPS
    onCanvasDrop: (state, action) => {
      const { data } = action.payload;
      state.canPublish = false;
      state.topbarState.topSearchBar = false;
      state.topbarState.searchingApp = "";
      data.nodeIdx = state.editorState.nodes.length;
      data.configResponses = [];
      data.node_link = [];
      data.link_data = {
        normal: [],
        additional: [],
      };

      if (
        data.type === "ADD_ON" &&
        data.provider.toLowerCase() === "generator"
      ) {
        data.appDetails = state.customAppData;
      }
      if (
        data.type === "ADD_ON" &&
        data.provider.toLowerCase() === "data_forwarder"
      ) {
        data.keyList = [{ key: "" }];
      }
      if (
        data.type === "ADD_ON" &&
        data.provider.toLowerCase() === "conditioner"
      ) {
        data.appEvent = 3;
        data.conditions = [
          { key: nanoid(), field: "", operator: "", value: "" },
        ];
        let id = nanoid();
        data.configResponses = [
          {
            app_event_id: 3,
            app_id: data.id,
            config_key: "conditions_setup",
            config_key_required: false,
            fetch_fields: false,
            id: "conditions_setup",
            key_value_type: "button",
            label: "",
            sequence: 0,
            side: "left",
            link_data: {
              target_node_id: [],
              target_field_port: [],

              source_node_id: [],
              source_field_port: [],
            },
            port: {
              id: id,
              source: id + "|" + "source",
              target: id + "|" + "target",
              nodeId: data.nodeId,
            },
          },
        ];
        data.operatorList = [
          {
            id: "lesser than",
            name: "Lesser Than",
            label: "Lesser Than",
            value: "lesser than",
          },
          {
            id: "greater than",
            name: "Greater Than",
            label: "Greater Than",
            value: "greater than",
          },
          {
            id: "is exist",
            name: "Is Exist",
            label: "Is Exist",
            value: "is exist",
          },
          {
            id: "does not exist",
            name: "Does Not Exist",
            label: "Does Not Exist",
            value: "does not exist",
          },
          {
            id: "start with",
            name: "Start With",
            label: "Start With",
            value: "start with",
          },
          {
            id: "start with",
            name: "Does Not Start With",
            label: "Does Not Start With",
            value: "start with",
          },
          {
            id: "end with",
            name: "End With",
            label: "End With",
            value: "end with",
          },
          {
            id: "does not end with",
            name: "Does Not End With",
            label: "Does Not End With",
            value: "does not end with",
          },
          {
            id: "equal to",
            name: "Equal To",
            label: "Equal To",
            value: "equal to",
          },
          {
            id: "does not equal to",
            name: "Does Not Equal To",
            label: "Does Not Equal To",
            value: "does not equal to",
          },
          {
            id: "contain string",
            name: "Contain String",
            label: "Contain String",
            value: "contain string",
          },
          {
            id: "does not contain string",
            name: "Does Not Contain String",
            label: "Does Not Contain String",
            value: "does not contain string",
          },
        ];
      }
      if (data.type === "ADD_ON" && data.provider.toLowerCase() === "api") {
        let id = nanoid();
        data.api = state.apiAppInitialState;

        data.configResponses = [
          {
            id: "EndPoint URL",
            app_id: "",
            app_event_id: "",
            config_key: "",
            label: "EndPoint URL",
            side: "",
            key_value_type: "",
            fetch_fields: "",
            uiKey: id,
            checked: true,
            type: "customApi",
            link_data: {
              target_node_id: [],
              target_field_port: [],

              source_node_id: [],
              source_field_port: [],
            },
            port: {
              id: id,
              source: id + "|" + "source",
              target: id + "|" + "target",
              nodeId: data.nodeId,
            },
          },
        ];
      }
      if (data.type !== "ADD_ON" && data.type !== "WEBHOOK") {
        if(state.editorState.nodes.length>0){
          data.hasDelay = true;
        }
       
      }
      if (
        data.type === "ADD_ON" &&
        data.provider.toLowerCase() === "textsplitter"
      ) {
        data.appDetails = state.customAppData;
      } else if (data.type === "ADD_ON" && data.name === "Text Formatter") {
        data.fromPorts = [];
      }
      /*     PUSHING KONNECT_ID (IF AVAILABLE) TO ALL DROPPING APPLICATION        */
      if (state.editorState.nodes?.length > 0) {
        if (state.editorState.nodes[0].data.konnect_id) {
          data.trigger_konnect_id = state.editorState.nodes[0].data.konnect_id;
        }
        state.canSave = state.editorState?.nodes[0]?.data?.konnect_id
          ? true
          : false;
      }
      /* --------------------X------------------- */

      state.editorState.nodes = [...state.editorState.nodes, action.payload];
      if (data.provider.toLowerCase() === "webhook") {
        let curr = {
          nodes: state.editorState.nodes,
          edges: state.editorState.edges,
        };
        {
          const lastHistory = state.history.present;

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.history.past.push(state.history.present);
            state.history.present = curr;
          }
        }
      }
    },

    // SET SELECTED EVENT : TRIGGER OR ACTION || DONE FOR APPS
    setSelectedEvent: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      if (
        node.data.selectedEvent &&
        node.data.selectedEvent.id === action.payload.value.id
      ) {
        return;
      } else {
        node.data.selectedEvent = action.payload.value;
        node.data.appEvent = action.payload.value.id;
        node.data.captureMessage = "";
        node.data.status = "";
        node.data.tested = false;
        node.data.reviewed = false;
        if (node.data.provider.toLowerCase() === "api") {
          node.data.configResponses = node.data.configResponses.filter(
            (config) => config.type === "customApi"
          );
        } else {
          node.data.configResponses = [];
        }
        delete node.data.additionalResponses;
        delete node.data.display_message;
        delete node.data.selectedAccount;
        if (node.data.fromPorts) {
          delete node.data.fromPorts;
          delete node.data.fromFields;
        }
        node.data.appEventConfigurations = [];
        if (node.data.name === "Scheduler") {
          delete node.data.selectedValue;
          switch (action.payload.value.name) {
            case "Days of the week":
              node.data.selectedValue = {
                schedule_type: "DaysOfTheWeek",
                days: "",
                schedule_time: "",
                localData: {
                  time: "",
                  days: [],
                },
              };
              break;
            case "Days of the month":
              node.data.selectedValue = {
                schedule_type: "DaysOfTheMonth",
                dates: "",
                schedule_time: "",
                localData: {
                  time: "",
                  dates: [],
                },
              };
              break;
            case "At regular intervals":
              node.data.selectedValue = {
                time_span: "",
                schedule_type: "AtRegularIntervals",
                every: "",
                interval: "",
                schedule_time: "",
                days: null,
                time: "",
                localData: {
                  every: "",
                  dates: [],
                  days: [],
                  timespan: "",
                  time: "",
                },
              };
          }
        }
      }
    },

    // SET SELECTED ACCOUNT : TRIGGER OR ACTION || DONE FOR APPS
    setLinkedAccount: (state, action) => {
      const node = state.editorState.nodes.find(
        (n) => n.id === action.payload.nodeId
      );
      if (
        node.data.selectedAccount &&
        node.data.selectedAccount.id === action.payload.selected.id
      ) {
        return;
      } else {
        state.canPublish = false;
        node.data.tested = false;
        delete node.data.test_status;
        node.data.captureMessage = "";
        node.data.status = "Draft";
        delete node.data.additionalResponses;
        node.data.selectedAccount = action.payload.selected;
        node.data.reviewed = false;
        delete node.data.appEventConfigurations;
        node.data.configResponses = [];
      }
    },

    // SETTING MODAL DATA - ex: ACCOUNT  || DONE FOR APPS
    setModalData: (state, action) => {
      const { nodeId, type, auth_type } = action.payload;
      const node = state.editorState.nodes.find((node) => node.id === nodeId);
      if (type === "Account") {
        delete state.modalData.addingAccount;
        if (action.payload.auto) {
          state.modalData.addingAccount = true;
          state.modalData.auto = true;
        }
        state.modalData.auth_type = auth_type || node.data.auth_type;
        state.modalData.id = node.data.id;
        state.modalData.nodeId = node.data.nodeId;
        state.modalData.accounts = node.data.associatedAccounts;
      } else {
        state.modalData = {};
      }
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },

    // SET EVENT CONFIG VALUE   || DONE FOR APPS
    setEventsConfig: (state, action) => {
      const { selectedValue, selectedConfig, nodeId } = action.payload;
      const node = state.editorState.nodes.find((x) => x.id === nodeId);
      node.data.captureMessage = "";
      node.data.status = "";
      node.data.tested = false;
      node.data.reviewed = false;
      delete node.data.additionalResponses;
      const eventConfig = node.data.appEventConfigurations?.find(
        (con) => con.sequence === selectedConfig.sequence
      );
      const config = node.data.configResponses.find(
        (con) =>
          con.config_key_type === "custom" &&
          con.sequence === selectedConfig.sequence
      );
      let selected = { ...eventConfig.selected, ...selectedValue };
      if (config && config.target_linked) {
        selected.passed_data = {
          source_konnect_activity_id: config.passed_data
            .source_konnect_activity_id
            ? config.passed_data.source_konnect_activity_id
            : 0,
          id: config.passed_data.id,
        };
        selected.target_linked = true;
      }
      node.data.appEventConfigurations = node.data.appEventConfigurations.map(
        (config) => {
          if (eventConfig.sequence < config.sequence) {
            const { selected, config_details, custom_field, ...rest } = config;
            return rest;
          } else if (eventConfig.sequence === config.sequence) {
            return {
              ...eventConfig,
              selected: selected,
            };
          } else return config;
        }
      );
    },

    // LINKING BETWEEN TWO APPLICATION     || DONE FOR APPS
    // LINKING BETWEEN TWO APPLICATION     || DONE FOR APPS
    onLinkComplete: (state, action) => {
      const sourceNode = state.editorState.nodes.find(
        (node) => node.data.nodeId === action.payload.source
      ).data;
      const targetNode = state.editorState.nodes.find(
        (node) => node.data.nodeId === action.payload.target
      ).data;
      const sourceField = sourceNode.configResponses.find(
        (x) => x.port.source === action.payload.sourceHandle
      );
      const targetField = targetNode.configResponses.find(
        (x) => x.port.target === action.payload.targetHandle
      );
      if (targetNode.nodeId === sourceNode.nodeId) {
        return;
      }
      if (targetField.dirty === true) {
        targetField.dirty = false;
      }
      //this is for link exists on same targethandle from same sourceHandle
      let linkExists = state.editorState.edges.filter(
        (edge) =>
          edge.sourceHandle === action.payload.sourceHandle &&
          edge.targetHandle === action.payload.targetHandle
      ).length;
      if (linkExists > 0) {
        return;
      }
      if (targetNode.tested) {
        state.canPublish = false;
        targetNode.tested = false;
        delete targetNode.test_status;
        targetNode.captureMessage = "";
        targetNode.status = "Draft";
        delete targetNode.additionalResponses;
        targetNode.configResponses = targetNode.configResponses.filter(
          (config) => !config.additional
        );
      }
      if (!sourceField.value) {
        targetNode.status = "Warning";
        targetNode.captureMessage = `No value found: ${sourceField.label}`;
        return;
      } else {
        if (!sourceNode.tested) {
          sourceNode.status = "Warning";
          sourceNode.captureMessage = `Test & Review before passing value`;
        } else if (
          targetField.target_linked &&
          targetNode.provider.toLowerCase() !== "textformatter" &&
          targetNode.provider.toLowerCase() !== "dateformatter" &&
          targetNode.provider.toLowerCase() !== "numberformatter" &&
          targetNode.provider.toLowerCase() !== "conditioner" &&
          targetNode.provider.toLowerCase() !== "iterator"
        ) {
          //this term is only valid for right side apps and few add-ons ,must be changed (only for info or to do after addons complete)
          targetNode.status = "Warning";
          targetNode.captureMessage = "The Field is already linked";
          return;
        } else {
          if (sourceField.additional) {
            sourceNode.link_data.additional.push(action.payload.id);
          } else {
            sourceNode.link_data.normal.push(action.payload.id);
          }
          if (sourceNode?.node_link) {
            targetNode.node_link = [...sourceNode.node_link, sourceNode.nodeId];
          } else {
            targetNode.node_link = [sourceNode.nodeId];
          }
          targetNode.captureMessage = "";
          targetNode.status = "Draft";

          const existEdge = state.editorState.edges.find(
            (edge) => edge.targetHandle === sourceField.port.target
          );

          if (existEdge) {
            action.payload.direct_link = [
              ...existEdge.direct_link,
              existEdge.id,
            ];
          }
          if (
            targetNode.provider === "api" &&
            targetField.label === "EndPoint URL"
          ) {
            targetNode.api.endpointURL = {
              type: "key-map",
              value: sourceField.config_key,
              konnect_activity_id: sourceNode.konnect_activity_id,
            };
          }
          if (targetNode.provider.toLowerCase() === "conditioner") {
            let isFromSameNode = false;

            const fromNodeLinks = state.editorState.edges.filter(
              (d) => d.target === targetNode.nodeId
            );

            if (
              fromNodeLinks.length &&
              fromNodeLinks[0].source === sourceNode.nodeId
            ) {
              isFromSameNode = true;
            }

            if (fromNodeLinks.length && !isFromSameNode) {
              state.editorState.edges = state.editorState.edges.filter(
                (edge) => edge.id !== action.payload.id
              );
            } else {
              if (!targetNode.fromFields) targetNode.fromFields = [];

              targetNode.fromFields.push({
                ...sourceField,
                linkId: action.payload.id,
              });
              targetNode.parentNode = sourceNode.nodeId;
              targetNode.parent_konnect_activity_id =
                sourceNode.konnect_activity_id;
            }
          }
          let sourcePortInfo = {
            ...sourceField,
            type: "source",
            node: sourceNode.nodeId,
            iterator: sourceField.iterator
              ? sourceField.iterator
              : sourceNode.iterator
              ? sourceNode.iterator
              : sourceNode.provider === "iterator"
              ? true
              : false,
          };
          if (
            targetNode.type === "ADD_ON" &&
            sourcePortInfo.type === "source"
          ) {
            if (!targetNode.fromPorts) {
              targetNode.fromPorts = [];
            }

            const newEntry = {
              info: sourcePortInfo,
              id: sourcePortInfo.id,
              display: sourcePortInfo.label,
              linkId: action.payload.id,
            };

            targetNode.fromPorts.push(newEntry);
          }
          sourceField.source_linked = true;
          sourceField.link_data = {
            source_node_id: sourceField.link_data.source_node_id,
            source_field_port: sourceField.link_data.source_field_port,
            target_node_id: [
              ...targetField?.link_data.target_node_id,
              targetNode.nodeId,
            ],
            target_field_port: [
              ...targetField?.link_data.target_field_port,
              action.payload.targetHandle,
            ],
          };
          if (targetNode.provider.toLowerCase() === "iterator") {
            if (!targetNode.typeOfValues) {
              targetNode.typeOfValues = [];
            }
            const newTypeOfEntry = {
              type: "key-map",
              value: sourcePortInfo.config_key,

              konnect_activity_id: sourceNode.konnect_activity_id,
              valueToShow: sourcePortInfo.value,
              linkId: action.payload.id,
            };

            targetNode.typeOfValues.push(newTypeOfEntry);
            const resultString = targetNode.typeOfValues
              .map((obj) => obj.valueToShow)
              .join(", ");
            targetField.value = resultString;
          } else {
            targetField.value = sourceField.value;
          }
          targetField.passed_data = {
            source_konnect_activity_id: sourceNode.konnect_activity_id
              ? sourceNode.konnect_activity_id
              : 0,
            id: sourceField.id,
          };
          targetField.target_linked = true;
          targetField.link_data = {
            target_node_id: targetField.link_data.target_node_id,
            target_field_port: targetField.link_data.target_field_port,
            source_node_id: [
              ...sourceField?.link_data.source_node_id,
              sourceNode.nodeId,
            ],
            source_field_port: [
              ...sourceField?.link_data.source_field_port,
              action.payload.sourceHandle,
            ],
          };

          if (
            sourceNode.provider.toLowerCase() === "iterator" ||
            sourceNode.iterator === true
          ) {
            targetField.iterator = true;
            targetNode.iterator = true;
          }

          state.editorState.edges = [
            ...state.editorState.edges,
            action.payload,
          ];
          let curr = {
            nodes: state.editorState.nodes,
            edges: state.editorState.edges,
          };
          {
            const lastHistory = state.history.present;

            if (lastHistory && !isEqual(lastHistory, curr)) {
              state.history.past.push(state.history.present);
              state.history.present = curr;
            }
          }
        }
      }
    },

    // DELETING LINK BETWEEN TWO APPLICATION
    onLinkDelete: (state, action) => {
      const {
        edge: selectedEdgeId,
        nodeId,
        selected,
        customField,
        additional,
        onlyConfig,
        sequence,
        onlyCustom,
        additionalOnly,
        selectedConfig,
      } = action.payload;
      const filterData = (id) => {
        const edge = state.editorState.edges.find((ed) => ed.id === id);
        if (edge) {
          const targetNode = state.editorState.nodes.find(
            (node) => node.id === edge.target
          );
          const targetConfig = targetNode.data.configResponses.find(
            (config) => config.port.target === edge.targetHandle
          );
          const sourceNode = state.editorState.nodes.find(
            (node) => node.id === edge.source
          );
          const sourceConfig = sourceNode.data.configResponses.find(
            (config) => config.port.source === edge.sourceHandle
          );
          let currentLinks = state.editorState.edges.filter(
            (ed) => ed.target === targetNode.id
          );
          if (targetNode.data.fromPorts) {
            targetNode.data.fromPorts = targetNode.data.fromPorts.filter(
              (data) => data.linkId !== id
            );
          }
          if (sourceConfig.additional)
            sourceNode.data.link_data.additional =
              sourceNode.data.link_data.additional.filter(
                (id) => id !== edge.id
              );
          else
            sourceNode.data.link_data.normal =
              sourceNode.data.link_data.normal.filter((id) => id !== edge.id);
          sourceConfig.link_data.target_node_id =
            sourceConfig.link_data.target_node_id.filter(
              (id) => id !== targetNode.id
            );
          sourceConfig.link_data.target_field_port =
            sourceConfig.link_data.target_node_id.filter(
              (id) => id !== targetConfig.port.target
            );
          targetConfig.link_data.source_node_id =
            targetConfig.link_data.source_node_id.filter(
              (id) => id !== sourceNode.id
            );
          targetConfig.link_data.source_field_port =
            targetConfig.link_data.source_field_port.filter(
              (id) => id !== sourceConfig.port.source
            );

          if (
            targetNode.data.type === "ADD_ON" &&
            targetNode.data.provider === "conditioner"
          ) {
            targetNode.data.fromFields = targetNode.data.fromFields.filter(
              (x) => x.linkId !== id
            );

            targetNode.data.conditions = targetNode.data.conditions
              ? targetNode.data.conditions.filter(
                  (condition) => condition.field.key !== id
                )
              : [];

            let conditionsTxt = targetNode.data.conditions.map((condition) => {
              let queryOperatorText =
                condition.queryOperator === undefined
                  ? ""
                  : condition.queryOperator.toUpperCase();
              return (
                condition.field.label +
                " " +
                condition.operator +
                " " +
                condition.value +
                " " +
                queryOperatorText +
                "\r\n"
              );
            });
            targetNode.data.conditionsText = conditionsTxt;

            if (!currentLinks.length) {
              targetNode.data.additionalResponses = [];
              const firstPort = { ...targetNode.data.configResponses[0] };
              targetNode.data.configResponses = [firstPort];
              // chart.links = chart.links.filter(x => x.from.node !== toNodeId)
              targetNode.data.conditions = [];
              targetNode.data.conditionsText = "";
              targetNode.data.reviewed = false;
              targetNode.data.tested = false;
              targetNode.data.showTestAndReview = false;
            }
          }
          delete sourceConfig.source_linked;
          delete targetConfig.target_linked;
          // if (targetNode.data.typeOfValues) {
          //   targetConfig.value = targetNode.data.typeOfValues
          //     .map((obj) => obj.valueToShow)
          //     .join(", ");
          // } else {
          delete targetConfig.value;
          // }
          delete targetConfig.passed_data;
          delete targetNode.data.additionalResponses;
          delete targetNode.data.raw_response;
          delete targetNode.data.test_status;
          targetNode.data.tested = false;
          targetNode.data.configResponses =
            targetNode.data.configResponses.filter((config) => {
              if (config.additional) {
                // if more than one are linked
                const additionalEdge = state.editorState.edges.filter(
                  (ed) => ed.sourceHandle === config.port.source
                );
                additionalEdge.forEach((aed) => {
                  state.editorState.edges.map(
                    (ed) => ed.direct_link.includes(aed.id) && filterData(ed.id)
                  ) && filterData(aed.id);
                });
              } else return config;
            });
          targetNode.data.captureMessage = "";
          targetNode.data.status = "Draft";

          state.editorState.edges = state.editorState.edges.filter(
            (ed) => ed.id !== edge.id
          );
        }
      };

      if (selected && selectedEdgeId) {
        let nodeWithValue = state.editorState.edges.find(
          (edge) => edge.id === selectedEdgeId
        ).target;
        const nodeIdx = state.editorState.nodes.findIndex(
          (node) => node.id === nodeWithValue
        );

        let nodeToBeChange = state.editorState.nodes.find(
          (node) => node.id === nodeWithValue
        );
        if (nodeIdx !== -1 && nodeToBeChange.data.fromPorts) {
          nodeToBeChange.data.fromPorts = nodeToBeChange.data.fromPorts.filter(
            (port) => port.linkId !== selectedEdgeId
          );
          if (nodeIdx !== -1 && nodeToBeChange.data.typeOfValues) {
            nodeToBeChange.data.typeOfValues =
              nodeToBeChange.data.typeOfValues.filter(
                (port) => port.linkId !== selectedEdgeId
              );
          }
        }
        state.editorState.edges.map((edge) => {
          if (edge.direct_link.includes(selectedEdgeId)) filterData(edge.id);
        });
        filterData(selectedEdgeId);
      } else if (nodeId && selected && customField) {
        state.editorState.edges.map((edge) => {
          if (
            edge.sourceHandle === customField.port.source ||
            edge.targetHandle === customField.port.target
          ) {
            state.editorState.edges.map((ed) => {
              if (ed.direct_link.includes(edge.id)) filterData(ed.id);
            });
            filterData(edge.id);
          }
        });
      } else if (
        nodeId &&
        !selected &&
        !additional &&
        !onlyConfig &&
        !additionalOnly
      ) {
        state.editorState.edges.map((edge) => {
          if (edge.source === nodeId || edge.target === nodeId) {
            state.editorState.edges.map((ed) => {
              if (ed.direct_link.includes(edge.id)) filterData(ed.id);
            });
            filterData(edge.id);
          }
        });
      } else if (nodeId && additional) {
        const node = state.editorState.nodes.find((nd) => nd.id === nodeId);
        node.data.configResponses.map((config) => {
          const edge = state.editorState.edges.find(
            (ed) => ed.sourceHandle === config.port.source
          );
          if (edge) {
            state.editorState.edges.map((ed) => {
              if (ed.direct_link.includes(edge.id)) filterData(ed.id);
            });
            filterData(edge.id);
          }
        });
      } else if (nodeId && onlyConfig && !additional && sequence) {
        const node = state.editorState.nodes.find((nd) => nd.id === nodeId);
        node.data.configResponses.map((config) => {
          if (
            (config.config_key_type === "custom" &&
              config.sequence > sequence) ||
            (config.config_key_type !== "custom" && !onlyCustom)
          ) {
            const edge = state.editorState.edges.find(
              (ed) =>
                ed.sourceHandle === config.port.source ||
                ed.targetHandle === config.port.target
            );
            if (edge) {
              state.editorState.edges.map((ed) => {
                if (ed.direct_link.includes(edge.id)) filterData(ed.id);
              });
              filterData(edge.id);
            }
          }
        });
      } else if (nodeId && additionalOnly) {
        if (selectedConfig) {
          state.editorState.nodes.map((nd) => {
            const found = nd.data?.configResponses?.find((con) =>
              con.link_data.source_field_port.includes(
                selectedConfig?.port?.source
              )
            );
            if (found) {
              delete nd.data.additionalResponses;
              nd.data.captureMessage = "";
              nd.data.status = "Draft";
              nd.data.configResponses.map((config) => {
                if (config.additional) {
                  const edge = state.editorState.edges.find(
                    (ed) => ed.sourceHandle === config.port.source
                  );
                  if (edge) {
                    state.editorState.edges.map((ed) => {
                      if (ed.direct_link.includes(edge.id)) filterData(ed.id);
                    });
                    filterData(edge.id);
                  }
                }
              });
              nd.data.configResponses = nd.data.configResponses.filter(
                (con) => !con.additional
              );
            }
          });
        }
        const node = state.editorState.nodes.find((nd) => nd.id === nodeId);
        node.data.configResponses.map((config) => {
          if (config.additional) {
            const edge = state.editorState.edges.find(
              (ed) => ed.sourceHandle === config.port.source
            );
            if (edge) {
              state.editorState.edges.map((ed) => {
                if (ed.direct_link.includes(edge.id)) filterData(ed.id);
              });
              filterData(edge.id);
            }
          }
        });
      }
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },

    // DELETING NODE ON CANVAS ONLY
    onDelete: (state, action) => {
      const newNode = state.editorState.nodes.find(
        (node) => node.id === action.payload
      );
      const localNodeIdx = newNode?.data?.nodeIdx;
      const nodeIdx = state.editorState.nodes.findIndex(
        (node) => node.id === action.payload
      );
      if (state.modalData) {
        state.modalData = {};
      }
      if (nodeIdx === 0 && state.editorState.nodes.length > 1) {
        newNode.data.prevStatus = newNode.data.status;
        newNode.data.prevCaptureMessage = newNode.data.captureMessage;
        newNode.data.status = "Warning";
        newNode.data.captureMessage =
          "Cannot delete Trigger while Action Present";
        return;
      } else {
        if (newNode.data.tested || newNode.data.konnect_activity_id) {
          state.RedoUndoNodes = [...state.RedoUndoNodes, newNode];
        }
        state.editorState.nodes = state.editorState.nodes.filter(
          (node, index) => {
            if (node.id !== action.payload) {
              if (localNodeIdx > index) {
                return node;
              } else {
                const { data } = node;
                data.nodeIdx = data.nodeIdx - 1;
                return node;
              }
            }
          }
        );
        if (state.editorState.nodes.length === 0) {
          state.canPublish = false;
          state.canSave = false;
        }
      }
    },

    // RESET WEBHOOK RESPONSES
    onResetConfiguration: (state, action) => {
      let node = state.editorState.nodes.find(
        (node) => node.id === action.payload
      );

      const { configResponses, ...rest } = node.data;
      node.data = rest;
      node.data.refreshed = false;
    },

    // ON REFRESHING NODE
    onRefresh: (state, action) => {
      const nodeToBeRefresh = state.editorState.nodes.find(
        (node) => node.id === action.payload
      );
      const nodeIdx = state.editorState.nodes.findIndex(
        (node) => node.id === action.payload
      );
      if (state.modalData) {
        state.modalData = {};
      }
      state.editorState.nodes[nodeIdx] = {
        ...nodeToBeRefresh,
        data: {
          id: nodeToBeRefresh.data.id,
          nodeId: nodeToBeRefresh.data.nodeId,
          nodeIdx: nodeToBeRefresh.data.nodeIdx,
          name: nodeToBeRefresh.data.name,
          image: nodeToBeRefresh.data.image,
          background: nodeToBeRefresh.data.background,
          enabled: false,
          loading: false,
          hasDelay: nodeToBeRefresh.data.hasDelay
            ? nodeToBeRefresh.data.hasDelay
            : false,
          status: "Draft",
          link_data: {
            normal: [],
            additional: [],
          },
          captureMessage: "",
          trigger_konnect_id: nodeToBeRefresh.data.trigger_konnect_id
            ? nodeToBeRefresh.data.trigger_konnect_id
            : null,
          associatedAccounts: nodeToBeRefresh.data.associatedAccounts
            ? nodeToBeRefresh.data.associatedAccounts
            : "",
          webhook_enabled: nodeToBeRefresh.data.webhook_enabled,
          webhook_instructions: nodeToBeRefresh.data.webhook_instructions
            ? nodeToBeRefresh.data.webhook_instructions
            : "",
          webhook_url: nodeToBeRefresh.data.webhook_url
            ? nodeToBeRefresh.data.webhook_url
            : "",
          provider: nodeToBeRefresh.data.provider,
          type: nodeToBeRefresh.data.type,
          appEvents: nodeToBeRefresh.data.appEvents,
          auth_type: nodeToBeRefresh.data.auth_type,
          konnect_activity_id: nodeToBeRefresh.data.konnect_activity_id
            ? nodeToBeRefresh.data.konnect_activity_id
            : null,
          konnect_id: nodeToBeRefresh.data.konnect_id
            ? nodeToBeRefresh.data.konnect_id
            : null,
          link_data: {
            normal: [],
            additional: [],
          },
          appDetails: state.customAppData,
          fromPorts: [],
          configResponses:
            nodeToBeRefresh.data.provider === "api"
              ? nodeToBeRefresh.data.configResponses
                  .filter((config) => config.type === "customApi")
                  .map((conf) => {
                    const { value, ...rest } = conf;
                    return { ...rest };
                  })
              : nodeToBeRefresh.data.provider === "conditioner"
              ? nodeToBeRefresh.data.configResponses.filter(
                  (config) => config.config_key === "conditions_setup"
                )
              : [],
          api:
            nodeToBeRefresh.data.provider === "api"
              ? state.apiAppInitialState
              : null,
          keyList:
            nodeToBeRefresh.data.provider === "data_forwarder"
              ? [{ key: "" }]
              : null,
          conditions:
            nodeToBeRefresh.data.provider === "conditioner"
              ? [{ key: nanoid(), field: "", operator: "", value: "" }]
              : null,
        },
      };

      if (nodeIdx === 0) {
        state.editorState.nodes[0].data.refreshed = true;
        state.editorState.nodes[0].display_message = "Refreshed";
      }
    },

    // WEBHOOK BASIC AUTH INPUT FILLER  || DONE FOR WEBHOOK
    onWebhookAuthChange: (state, action) => {
      const { nodeId, type, input } = action.payload;
      let node = state.editorState.nodes.find((node) => node.id === nodeId);
      node.data[type] = input;
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },

    // WEBHOOK BASIC AUTH TOGGLER || DONE FOR WEBHOOK
    toggleBasicAuth: (state, action) => {
      let node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      node.data.enabled = action.payload.enabled;
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },

    // SELECT ADDITIONAL CONFIG VALUES/FIELDS || DONE FOR ALL APPS
    onSelectResponse: (state, action) => {
      const chart = state.editorState.nodes;
      const node = chart.find((node) => node.id === action.payload.nodeId);
      let visibleResponses = [];
      action.payload.responses.map((value) => {
        if (value.checked) {
          let { checked, ...rest } = value;
          visibleResponses.push(rest);
        }
      });
      if (action.payload.type === "Normal") {
        node.data.configResponses = node.data.configResponses.map((config) => {
          if (!config.additional) {
            const exist = action.payload.responses.find(
              (con) => con.config_key === config.config_key
            );
            return exist ? exist : config;
          } else return config;
        });
      }
      if (action.payload.type === "Additional") {
        node.data.configResponses = node.data.configResponses.map((config) => {
          if (config.additional) {
            const exist = action.payload.responses.find(
              (con) => con.uiKey === config.uiKey
            );
            return exist ? exist : config;
          } else return config;
        });
      }
    },

    // SET INPUT / TEXT VALUE (USER-DEFINED)  || DONE FOR ALL APPS
    onInputTextType: (state, action) => {
      const { nodeId, input, portInfo } = action.payload;
      const node = state.editorState.nodes.find((node) => node.id === nodeId);

      const selectedConfig = node.data.configResponses.find(
        (config) => config.config_key === portInfo.config_key
      );
      if (node.data.tested) {
        state.canPublish = false;
        node.data.tested = false;
        delete node.data.test_status;
        node.data.captureMessage = "";
        node.data.status = "Draft";
        delete node.data.additionalResponses;
        node.data.configResponses = node.data.configResponses.filter(
          (config) => !config.additional
        );
      }
      selectedConfig.value = input;
      selectedConfig.dirty = false;
      if (selectedConfig.source_linked) {
        state.editorState.nodes.map((node) => {
          node.data.configResponses = node.data.configResponses.map(
            (config) => {
              if (
                config.link_data.source_field_port.includes(
                  selectedConfig.port.source
                )
              ) {
                return { ...config, value: input };
              } else return config;
            }
          );
        });
      }
      if (
        node.data.provider === "api" &&
        selectedConfig.label === "EndPoint URL"
      ) {
        node.data.api.endpointURL = { type: "user-defined", value: input };
      }
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },

    /*  CUSTOM FIELD ADDITION */
    addCustom: (state, action) => {
      const { selectedConfig, nodeId } = action.payload;
      const node = state.editorState.nodes.find((n) => n.id === nodeId);
      const selectedEnvConfig = node.data.appEventConfigurations.find(
        (config) => config.id === selectedConfig.id
      );
      if (selectedEnvConfig.selected) {
        selectedEnvConfig.prevSelected = selectedEnvConfig.selected;
      }
      selectedEnvConfig.selected = {
        label: `Custom ${selectedEnvConfig.label}`,
        key_value: "custom_field",
      };
      selectedEnvConfig.custom_field = true;
      node.data.appEventConfigurations = node.data.appEventConfigurations.map(
        (config) => {
          if (config.sequence > selectedEnvConfig.sequence) {
            const { config_details, selected, ...rest } = config;
            if (selected?.key_value === "custom_field") {
              return {
                ...rest,
                prev_config_details: config_details,
                selected: selected,
              };
            }
            return {
              ...rest,
              prev_config_details: config_details,
              prevSelected: selected ? selected : null,
            };
          } else return config;
        }
      );
      node.data.configResponses = [
        ...(node.data.configResponses ? node.data.configResponses : []),
        ...[
          {
            app_event_id: Number(`${selectedEnvConfig.app_event_id}`),
            app_id: Number(`${selectedEnvConfig.app_id}`),
            config_key: `${selectedEnvConfig.config_key}` + ">>" + "custom",
            config_key_type: "custom",
            sequence: Number(`${selectedEnvConfig.sequence}`),
            config_key_required: false,
            fetch_fields: false,
            id: `${selectedEnvConfig.config_key}`,
            portId: Number(`${selectedEnvConfig.id}`),
            key_value_type: "list",
            label: `Enter ${selectedEnvConfig.label} ID`,
          },
        ].map((c) => {
          let id = nanoid();
          return {
            ...c,
            link_data: {
              target_node_id: [],
              target_field_port: [],
              source_node_id: [],
              source_field_port: [],
            },
            passed_data: {},
            port: {
              id: id,
              source: id + "|" + "source",
              target: id + "|" + "target",
              nodeId: node.id,
            },
          };
        }),
      ];
    },

    /*  CUSTOM FIELD DELETION */
    deleteCustom: (state, action) => {
      const { selectedConfig, nodeId } = action.payload;
      const node = state.editorState.nodes.find((n) => n.id === nodeId);
      const selectedEnvConfig = node.data.appEventConfigurations.find(
        (config) => config.id === selectedConfig.id
      );
      if (selectedEnvConfig.prevSelected) {
        selectedEnvConfig.selected = selectedEnvConfig.prevSelected;
        delete selectedEnvConfig.prevSelected;
      } else selectedEnvConfig.selected = null;

      selectedEnvConfig.custom_field = false;
      node.data.appEventConfigurations = node.data.appEventConfigurations.map(
        (config) => {
          if (config.sequence > selectedEnvConfig.sequence) {
            const { prev_config_details, selected, prevSelected, ...rest } =
              config;
            if (selected?.key_value === "custom_field") {
              return {
                ...rest,
                config_details: prev_config_details,
                selected: selected,
              };
            }
            return {
              ...rest,
              config_details: prev_config_details,
              selected: prevSelected ? prevSelected : null,
            };
          } else return config;
        }
      );
      node.data.configResponses = node.data.configResponses.filter((config) => {
        if (config.config_key_type === "custom") {
          if (config.sequence !== selectedEnvConfig.sequence) {
            return config;
          }
        } else return config;
      });
    },

    onToggleDrag: (state, action) => {
      const node = state.editorState.nodes.find(
        (x) => x.id === action.payload.nodeId
      );
      node.className = action.payload.focused;
    },
    updateGsheetPop: (state, action) => {
      state.gSheetPopUp = false;
    },
    modifySegmentIndex: (state, action) => {
      let node = state.editorState.nodes.find(
        (n) => n.id === action.payload.nodeId
      );
      node.data.appDetails.textSplitter.segmentIndex = action.payload.list;
      node.data.selectedAddonsValue = action.payload.list.find(
        (seg) => seg.selected === true
      );
    },
    updateDuration: (state, action) => {
      let node = state.editorState.nodes.find(
        (n) => n.id === action.payload.nodeId
      );
      node.data.appDetails.coupon.duration = action.payload.list1;
      node.data.selectedAddonsValue = action.payload.list1.find(
        (dur) => dur.selected === true
      );
    },
    updateExpiryNum: (state, action) => {
      let node = state.editorState.nodes.find(
        (n) => n.id === action.payload.data.nodeId
      );
      node.data.appDetails.coupon.expiryNum = action.payload.value;
    },
    updateUrl: (state, action) => {
      const { value, nodeIdx } = action.payload;
      state.editorState.nodes[nodeIdx].data.api.endpointURL = value;
    },
    updateWrapRequestInArray: (state, action) => {
      const { value, nodeId } = action.payload;
      const node = state.editorState.nodes.find((node) => node.id === nodeId); //[nodeIdx]//
      node.data.api.wrapRequestInArray = value;
      node.data.api.wrapRequestInArray.selectedValue = value.find(
        (x) => x.selected === true
      );
    },
    updateHeadersAndParams: (state, action) => {
      const { headers, params, nodeIdx, newNodeDetails, nodeId } =
        action.payload;
      const node = state.editorState.nodes.find((n) => n.id === nodeId);
      node.data.api.headers = headers;
      node.data.api.params = params;
      let newResponses = newNodeDetails.map((n) => {
        let id = nanoid();
        return {
          ...n,
          checked: true,
          link_data: {
            target_node_id: [],
            target_field_port: [],

            source_node_id: [],
            source_field_port: [],
          },
          port: {
            id: id,
            source: id + "|" + "source",
            target: id + "|" + "target",
          },
        };
      });
      let previousconfigs = node.data.configResponses?.length
        ? node.data.configResponses.filter(
            (config) => config.type === "customApi"
          )
        : [];
      node.data.configResponses = [...previousconfigs, ...newResponses];
    },
    updatePayloadType: (state, action) => {
      const { value, nodeId } = action.payload;
      const chart = state.editorState;
      const node = chart.nodes.find((node) => node.id === nodeId);

      node.data.api.payloadType = value;
      node.data.api.payloadType.selectedValue = value.find(
        (x) => x.selected === true
      );
    },
    modifyConditions: (state, action) => {
      const chart = state.editorState;
      const { data, conditionsText, conditions } = action.payload;
      const currentNodeIdx = chart.nodes.findIndex((x) => x.id === data.nodeId);

      chart.nodes[currentNodeIdx].data.conditionsText = conditionsText;
      chart.nodes[currentNodeIdx].data.conditions = conditions;
      chart.nodes[currentNodeIdx].data.showTestAndReview = true;
      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },
    updateCondition: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      ).data;
      let conditions = node.conditions.find(
        (cond) => cond.key === action.payload.key
      );

      let index = node.conditions.findIndex(
        (condition) => condition.key === action.payload.key
      );

      index < 0
        ? (node.conditions = [action.payload.conditionObj])
        : (node.conditions[index] = { ...action.payload.conditionObj });
    },
    updateConditionsOnCancel: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      ).data;
      node.conditions = node.prevCond ? node.prevCond : node.conditions;
    },
    saveKonnectName: (state, action) => {
      state.editorState.konnect_name = action.payload;
    },
    setDelayConfigInNode: (state, action) => {
      let node = state.editorState.nodes.find(
        (n) => n.id === action.payload.nodeId
      );
      node.data.delayReady = true;
      if (action.payload.type === "delayUntil") {
        node.data.delayConfigs = action.payload.delayConfigs;
        node.data.delayConfigsForTest = {
          ...node.data.delayConfigs,
          delay_type: node.data.delayConfigs.delay_type.label,
        };
      } else {
        node.data.delayConfigs = action.payload.delayConfigs;
        node.data.delayConfigsForTest = {
          value: Number(node.data.delayConfigs.delay_value),
          delay_type: node.data.delayConfigs.delay_type.label,
          delay_unit: node.data.delayConfigs.delay_unit.label,
        };
      }
      state.delayConfigs = { delay_unit: "", delay_value: "", delay_type: "" };
    },
    cancelDelay: (state, action) => {
      let node = state.editorState.nodes.find((n) => n.id === action.payload);
      delete node.data.delayReady;
      delete node.data.delayConfigs;
      delete node.data.delayConfigsForTest;
      state.delayConfigs = {
        delay_type: "",
        delay_unit: "",
        delay_value: "",
      };
    },
    redo: (state, action) => {
      const history = state.history;
      const chart = state.editorState;

      if (!history.past.length && !history.future.length && history.present) {
        state = state;
      }

      if (!history.future.length) {
        state = state;
      }

      history.past.push(history.present);
      history.present = history.future.pop();

      const currentChart = {
        ...chart,
        ...history.present,
      };

      state.editorState = currentChart;
      state.history = history;
    },
    undo: (state, action) => {
      const history = state.history;
      const chart = state.editorState;
      const currentState = {
        nodes: chart.nodes,
        edges: chart.edges,
        ports: chart.ports,
      };

      if (!history.past.length && !history.future.length && history.present) {
        state = state;
      }

      if (!history.past.length) {
        state = state;
      }

      history.future.push(history.present);
      history.present = history.past.pop();
      if (state.RedoUndoNodes.length) {
        let result;

        history.present.nodes = history.present?.nodes?.map((n) => {
          state.RedoUndoNodes.map((r) => {
            if (n.id === r.id) {
              return (result = {
                ...n,
                data: {
                  id: n.data.id,
                  name: n.data.name,
                  image: n.data.image,
                  background: n.data.background,
                  associatedAccounts: n.data.associatedAccounts,
                  webhook_enabled: n.webhook_enabled === true ? true : false,
                  webhook_instructions: "",
                  webhook_url: n.data.webhook_url ? n.data.webhook_url : "",
                  provider: n.data.provider,
                  type: n.data.type,
                  nodeId: n.data.nodeId,

                  appEvents: n.data.appEvents,
                  webhookUrl: n.data.webhookUrl ? n.data.webhookUrl : "",
                  webhook: n.webhook ? n.webhook : "",
                  tested: false,
                  reviewed: false,
                  isloading: false,
                  appDetails: state.customAppData,

                  linkedAccounts: n.data.linkedAccounts,
                  configResponses:
                    n.data.provider === "api"
                      ? n.data.configResponses
                          .filter((config) => config.type === "customApi")
                          .map((conf) => {
                            const { value, ...rest } = conf;
                            return { ...rest };
                          })
                      : n.data.provider === "conditioner"
                      ? n.data.configResponses.filter(
                          (config) => config.config_key === "conditions_setup"
                        )
                      : [],
                  api:
                    n.data.provider === "api" ? state.apiAppInitialState : null,
                  conditions:
                    n.data.provider === "conditioner"
                      ? [{ key: nanoid(), field: "", operator: "", value: "" }]
                      : null,
                },
              });
            } else {
              return (result = { ...n });
            }
          });

          return result;
        });
        history.present.edges = history.present.edges.filter((l) => {
          return state.RedoUndoNodes.find((n) => {
            return n.id !== l.target;
          });
        });
      } else {
        state.editorState = currentChart;
        state.history = history;
      }

      const currentChart = {
        ...chart,
        ...history.present,
      };

      state.editorState = currentChart;
      state.history = history;
    },
    reArrange: (state, action) => {
      state.editorState.nodes = state.editorState.nodes.map((node, i) => {
        return {
          ...node,
          position: {
            x: 127 + 500 * i,
            y: 101,
          },
        };
      });
    },
    showChatGpt: (state, action) => {
      state.editorState.nodes = action.payload;
    },
    clearConfigs: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );

      node.data.configResponses = node.data.configResponses?.filter(
        (c) => c.config_key_type === "custom"
      );

      state.editorState.edges = node.data.configResponses.length
        ? state.editorState.edges.filter((edge) => {
            return node.data.configResponses?.map((configs) => {
              return (
                configs.port.source === edge.sourceHandle ||
                configs.port.target === edge.targetHandle
              );
            });
          })
        : state.editorState.edges.filter(
            (edge) => edge.target !== node.id && edge.source !== node.id
          );
    },
    checkStatus: (state, action) => {
      let canPublish =
        state.editorState.nodes.length > 0 &&
        state.editorState.nodes?.every(
          (node) => node.data.tested && node.data.reviewed
        );
      state.canPublish = canPublish;
    },
    saveSearchingAppName: (state, action) => {
      state.topbarState.searchingApp = action.payload;
    },
    setSchedulerData: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      const { value, type, label } = action.payload;
      switch (node.data.selectedEvent.name) {
        case "Once":
          const date = `${value.substring(8, 10)}-${value.substring(
            5,
            7
          )}-${value.substring(0, 4)}`;
          const time = value.substring(11);
          node.data.selectedValue = {
            schedule_type: "Once",
            schedule_time: date + " " + time,
            localData: { date: value },
          };
          break;
        case "Every day":
          node.data.selectedValue = {
            schedule_type: "Everyday",
            schedule_time: value,
            localData: { time: value },
          };
          break;
        case "Days of the week":
          if (type === "select") {
            let days = [];
            value.map((e) => days.push(e.value));
            node.data.selectedValue["days"] = days.toString();
            node.data.selectedValue.localData["days"] = value;
          } else {
            node.data.selectedValue.schedule_time = value;
            node.data.selectedValue.localData["time"] = value;
          }
          break;
        case "Days of the month":
          if (type === "select") {
            let dates = [];
            value.map((e) => dates.push(e.value));
            node.data.selectedValue["dates"] = dates.toString();
            node.data.selectedValue.localData["dates"] = value;
          } else {
            node.data.selectedValue.schedule_time = value;
            node.data.selectedValue.localData["time"] = value;
          }
          break;
        case "At regular intervals":
          if (type === "select" && label === "timespan") {
            const { time_span, interval, localData, ...rest } =
              node.data.selectedValue;
            node.data.selectedValue = {
              ...rest,
              time_span: "",
              every: "",
              days: "",
              interval: "",
              localData: {
                every: "",
                dates: [],
                days: [],
                timespan: "",
                time: "",
              },
            };
            node.data.selectedValue.time_span = value.value;
            node.data.selectedValue.interval = value.value;
            node.data.selectedValue.localData.timespan = value;
          } else if (type === "input" && label !== "time") {
            node.data.selectedValue.every = value;
            node.data.selectedValue.localData.every = value;
          } else if (type === "input" && label === "time") {
            node.data.selectedValue.schedule_time = value;
            node.data.selectedValue.localData.time = value;
          } else if (type === "select" && label === "week") {
            let days = [];
            value.map((e) => days.push(e.value));
            node.data.selectedValue.days = days.toString();
            node.data.selectedValue.localData.days = value;
          }
          break;
      }
    },
    addNewCondition: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      node.data.conditions = [
        ...node.data.conditions,
        { key: nanoid(), field: "", operator: "", value: "" },
      ];
    },
    deleteCondition: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      let filteredConditions = node.data.conditions.filter(
        (condition) => condition.key !== action.payload.key
      );
      node.data.conditions = filteredConditions.length = 0
        ? []
        : filteredConditions;
    },
    updateConditions: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      let conditionsText = node.data.conditions.map((condition) => {
        let queryOperatorText =
          condition.queryOperator === undefined
            ? ""
            : condition.queryOperator.toUpperCase();
        return (
          "<h1>" +
          condition.field.label +
          " " +
          condition.operator +
          " " +
          condition.value.name +
          " " +
          queryOperatorText +
          "\r\n" +
          "</h1>"
        );
      });
      node.data.conditionsText = conditionsText;
      node.data.conditions = node.data.conditions;
      node.data.prevCond = node.data.conditions;

      let curr = {
        nodes: state.editorState.nodes,
        edges: state.editorState.edges,
      };
      {
        const lastHistory = state.history.present;

        if (lastHistory && !isEqual(lastHistory, curr)) {
          state.history.past.push(state.history.present);
          state.history.present = curr;
        }
      }
    },
    uploadAppType: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      node.data.auth_type = action.payload.auth_type;
    },
    updateInputKey: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      node.data.configResponses = node.data.configResponses.filter(
        (conf) => !conf.customAdded
      );
      node.data.keyList = action.payload.inputList;
      let newResponses = action.payload.newNodeDetails.map((n) => {
        return {
          ...n,
          checked: true,
          link_data: {
            target_node_id: [],
            target_field_port: [],

            source_node_id: [],
            source_field_port: [],
          },
        };
      });
      node.data.configResponses = [
        ...node.data.configResponses,
        ...newResponses,
      ];
    },
    updateQuesKey: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      node.data.configResponses = node.data.configResponses.filter(
        (conf) => !conf.customAddedParser
      );
      node.data.quesList = action.payload.inputList;
      let newResponses = action.payload.newNodeDetails;
      node.data.configResponses = [
        ...node.data.configResponses,
        ...newResponses,
      ];
    },

    setEditMode: (state, action) => {
      state.isOnEditMode = action.payload.mode;
    },
    setDelayConfigs: (state, action) => {
      const node = state.editorState.nodes.find(
        (node) => node.id === action.payload.nodeId
      );
      if (action.payload.delay_type === "delay_type") {
        node.data.delayConfigs = {
          delay_unit: "",
          delay_value: "",
          delay_type: action.payload.value,
        };
      }
      if (action.payload.delay_type === "Second") {
        node.data.delayConfigs = {
          ...node.data.delayConfigs,
          delay_unit: action.payload.value,
        };
      }

      if (action.payload.delay_type === "input") {
        node.data.delayConfigs = {
          ...node.data.delayConfigs,
          delay_value: action.payload.value,
        };
      }
    },
  },

  extraReducers: (builder) => {
    builder

      // GET APP EVENTS || DONE FOR APPS
      .addCase(getAppEvent.pending, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.id
        ).data;
        nodeData.loading = true;
      })
      .addCase(getAppEvent.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.id
        );
        node.data.loading = false;
        if (node) {
          const nodeIdx = state.editorState.nodes.findIndex(
            (node) => node.id === action.meta.arg.id
          );
          let _events;
          if (nodeIdx === 0) {
            _events = action.payload.filter((x) => x.side === "left");
          } else {
            _events = action.payload.filter((x) => x.side === "right");
          }
          node.data.appEvents = _events;
        }
        if (node.data.provider.toLowerCase() !== "webhook") {
          let curr = {
            nodes: state.editorState.nodes,
            edges: state.editorState.edges,
          };
          {
            const lastHistory = state.history.present;

            if (lastHistory && !isEqual(lastHistory, curr)) {
              state.history.past.push(state.history.present);
              state.history.present = curr;
            }
          }
        }
      })
      .addCase(getAppEvent.rejected, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg.id
        ).data;
        nodeData.loading = false;
        nodeData.captureMessage = "Inadequate data to Fetch Events";
        nodeData.status = "Error";
      })

      // GET LINKED ACCOUNTS || DONE FOR APPS
      .addCase(getLinkedAccounts.pending, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.id
        );
        node.data.loading = true;
      })
      .addCase(getLinkedAccounts.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.id
        );
        node.data.associatedAccounts = action.payload;
        node.data.loading = false;
      })
      .addCase(getLinkedAccounts.rejected, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.id
        );
        node.data.loading = false;
        node.data.captureMessage = "Inadequate data to Fetch Accounts";
        node.data.status = "Error";
      })
      .addCase(getLinkedAccountsinModal.fulfilled, (state, action) => {
        if (action.meta.arg.nodeId) {
          let node = state.editorState.nodes.find(
            (x) => x.id === action.meta.arg.nodeId
          );
          node.data.associatedAccounts =
            action.payload?.authorized_app_accounts.map((x) => {
              return {
                ...x,
                label: x.name,
                value: x.id,
              };
            });
        }
      })

      //  ACCOUNT MODAL  || DONE FOR APPS
      .addCase(editAuthLabelPost.fulfilled, (state, action) => {
        const accounts = state.modalData.accounts;
        accounts.map((acc) => {
          if (acc.id === action.meta.arg.id) {
            acc.name = action.meta.arg.label;
          }
        });
      })
      .addCase(getOauthData.fulfilled, (state, action) => {
        if (state.modalData) {
          state.modalData.addingAccount = action.payload;
        }
        state.getOauthData = action.payload;
      })
      .addCase(updateAppAccountStatus.fulfilled, (state, action) => {
        if (action.payload.message === "SUCCESS") {
          const accounts = state.modalData.accounts;
          const newAccount = accounts.filter(
            (acc) => acc.id !== action.meta.arg.appId
          );
          state.modalData.accounts = newAccount;
        }
      })

      //  GET APP EVENT CONFIG  || DONE FOR APPS
      .addCase(getAppEventConfigs.pending, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg
        );
        node.data.loading = true;
      })
      .addCase(getAppEventConfigs.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg
        );
        node.data.loading = false;
        const configs = action.payload.sort((x, y) => {
          return x.sequence - y.sequence;
        });
        const hasSeqZero = configs.filter((x) => x.sequence === 0).length;
        if (hasSeqZero) {
          node.data.fetchFieldStatus = false;
          const _configs = configs.filter((x) => x.sequence === 0);
          let importConfigs = _configs
            .filter((configs) => configs.config_key_required === true)
            .map((c) => {
              let id = nanoid();
              return {
                ...c,
                uiKey: id,
                link_data: {
                  target_node_id: [],
                  target_field_port: [],
                  source_node_id: [],
                  source_field_port: [],
                },
                port: {
                  id: id,
                  source: id + "|" + "source",
                  target: id + "|" + "target",
                  nodeId: node.data.nodeId,
                },
                checked: true,
              };
            });
          let unImportConfigs = _configs
            .filter((configs) => configs.config_key_required === false)
            .map((config, i) => {
              let id = nanoid();
              let num = 5 - importConfigs.length;
              let portInfo = {
                uiKey: id,

                port: {
                  id: id,
                  source: id + "|" + "source",
                  target: id + "|" + "target",
                  nodeId: node.data.nodeId,
                },
              };
              if (i < num) {
                return {
                  ...config,
                  link_data: {
                    target_node_id: [],
                    target_field_port: [],
                    source_node_id: [],
                    source_field_port: [],
                  },
                  ...portInfo,
                  checked: true,
                };
              } else {
                return {
                  ...config,
                  link_data: {
                    target_node_id: [],
                    target_field_port: [],
                    source_node_id: [],
                    source_field_port: [],
                  },
                  ...portInfo,
                  checked: false,
                };
              }
            });
          node.data.configResponses = [...importConfigs, ...unImportConfigs];
        } else {
          node.data.fetchFieldStatus = true;
        }

        /* ----- SETTING TITLE OF LABEL FOR APP EVENT CONFIGURATION (SEQUENCE > 0)  ------ */
        const selectedTrigger = !node.data.appEventConfiguration
          ? "Choose"
          : configs?.find((x) => x.id === node.data.appEventConfiguration)
              ?.service_name;
        node.data.appEventConfigurations = configs
          .filter((x) => x.sequence !== 0)
          .map((x) => {
            let title = x.config_key_required
              ? `${selectedTrigger} ${x.label} *`
              : `${selectedTrigger} ${x.label}`;
            return { ...x, title: title };
          });
        /* ----------------- -------------- --------------  -------------------- */
      })
      .addCase(getAppEventConfigs.rejected, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg
        ).data;
        nodeData.loading = false;
        nodeData.captureMessage = "Inadequate data to Fetch Configurations";
        nodeData.status = "Error";
      })

      // GET APP EVENT CONFIG DETAILS || DONE FOR APPS
      .addCase(getAppEventConfigDetail.pending, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.nodeId
        ).data;
        nodeData.loading = true;
      })
      .addCase(getAppEventConfigDetail.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.nodeId
        );
        node.data.loading = false;
        node.data.status = "";
        node.data.captureMessage = "";
        const prevValue = action.meta.arg.prevValue;
        node.data.configResponses = node.data.configResponses.filter(
          (config) => !config.additional && config.fetch_fields !== true
        );
        let configDetails = [],
          config_key = action.meta.arg.config_key,
          selectedConfig = node.data.appEventConfigurations.find(
            (config) => config.config_key === action.meta.arg.config_key
          );
        if (prevValue) {
          selectedConfig = node.data.appEventConfigurations.find(
            (config) => config.sequence === prevValue.sequence + 1
          );
          config_key = selectedConfig.config_key;
        }
        if (action.payload.data !== true) {
          if (action.payload.data.length === 0) {
            node.data.captureMessage = `No ${selectedConfig.label}s Found`;
            node.data.status = "Error";
          }
          configDetails = action.payload.data?.map((x) => {
            return {
              ...x,
              config_key: config_key,
              sequence: selectedConfig.sequence,
              label: x.name,
              value: x.id,
            };
          });
          selectedConfig.config_details = configDetails;
        }
        let curr = {
          nodes: state.editorState.nodes,
          edges: state.editorState.edges,
        };
        {
          const lastHistory = state.history.present;

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.history.past.push(state.history.present);
            state.history.present = curr;
          }
        }
      })
      .addCase(getAppEventConfigDetail.rejected, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg.nodeId
        ).data;
        nodeData.loading = false;
        nodeData.captureMessage =
          "Inadequate data to fetch Detail for this Event";
        nodeData.status = "Error";
      })

      //  GET APP EVENT CONFIG FIELDS || DONE FOR APPS
      .addCase(getAppEventConfigDetailFetch.pending, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg.nodeId
        ).data;
        if (nodeData.type !== "ADD_ON") {
          nodeData.loading = true;
        }
      })
      .addCase(getAppEventConfigDetailFetch.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (x) => x.data.nodeId === action.meta.arg.nodeId
        );
        node.data.loading = false;
        node.data.status = "";
        node.data.captureMessage = "";
        node.data.reviewed = false;
        node.data.tested = false;
        const sourceFields = action.payload.map((x) => {
          return {
            app_event_id: node.data.selectedEvent.id,
            app_id: node.data.id,
            config_key: x.config_key ? x.config_key : x.name,
            config_key_required:
              x.config_key_required === true
                ? true
                : x.id == "row_index"
                ? true
                : false,
            id: x.id,
            label: x.name,
            fetch_fields: true,
            placeholder: x.placeholder || x.name,
            key_value_type: x.key_value_type ? x.key_value_type : "display",
            type: "source",
          };
        });
        node.data.configResponses = [
          ...node.data.configResponses.filter(
            (config) => config.fetch_fields !== true
          ),
          ...sourceFields.map((c) => {
            let id = nanoid();
            return {
              ...c,
              sequence: 0,
              checked: c.config_key_required,
              link_data: {
                target_node_id: [],
                target_field_port: [],
                source_node_id: [],
                source_field_port: [],
              },
              uiKey: id,
              port: {
                id: id,
                source: id + "|" + "source",
                target: id + "|" + "target",
                nodeId: node.data.nodeId,
              },
            };
          }),
        ];
        let importConfigs = node.data.configResponses
          .filter((configs) => configs.config_key_required === true)
          .map((c) => {
            return {
              ...c,
              checked: true,
            };
          });
        let unImportConfigs = node.data.configResponses
          .filter((configs) => configs.config_key_required === false)
          .map((config, i) => {
            let num = 5 - importConfigs.length;
            if (i < num) {
              return {
                ...config,
                checked: true,
              };
            } else {
              return {
                ...config,
                checked: false,
              };
            }
          });
        node.data.configResponses = [...importConfigs, ...unImportConfigs];
        let curr = {
          nodes: state.editorState.nodes,
          edges: state.editorState.edges,
        };
        {
          const lastHistory = state.history.present;

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.history.past.push(state.history.present);
            state.history.present = curr;
          }
        }
      })
      .addCase(getAppEventConfigDetailFetch.rejected, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg.nodeId
        ).data;
        if (nodeData.type !== "ADD_ON") {
          nodeData.loading = false;
          if (action.payload.response.data.status === 500) {
            nodeData.captureMessage = "No Fields available";
          } else
            nodeData.captureMessage =
              "Inadequate data to Fetch Fields on this Event";
          nodeData.status = "Error";
        }
      })

      //  CAPTURE WEBHOOK RESPONSE  || DONE FOR WEBHOOK
      .addCase(captureWebHookResponse.pending, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg
        );
        if (!node.data.refreshed) {
          node.data.loading = true;
          node.data.status = "Pending";
        }
      })
      .addCase(captureWebHookResponse.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg
        );
        if (
          action.payload.message &&
          action.payload.message.toUpperCase() === "WAITING"
        ) {
          node.data.awaitingWebhookResponse = true;
          node.data.loading = true;
          node.data.status = "Pending";
          node.data.captureMessage = action.payload.display_message;
        } else if (
          action.payload.message &&
          action.payload.display_message === "Failure: Didn't receive any data"
        ) {
          node.data.loading = false;
          node.data.status = "Error";
          node.data.captureMessage = action.payload.display_message;
        } else if (
          action.payload.message &&
          (action.payload.display_message === "Refreshed" ||
            node.data.display_message === "Captured")
        ) {
          node.data.loading = false;
          node.data.status = "Draft";
        } else if (!node.data.refreshed) {
          state.canSave = true;
          const { raw_response, test_response, ...rest } = action.payload;
          node.data.awaitingWebhookResponse = false;
          node.data.loading = false;
          node.data.display_message = "Captured";
          node.data.status = "Success";
          node.data.captureMessage = action.payload.display_message;
          node.data.webhookRawResponse = action.payload.raw_response;
          node.data.tested = true;
          node.data.reviewed =
            action.payload.test_status.toLowerCase() === "success";
          node.data = { ...node.data, ...rest };
          let responseWithHandleinfo = FlattenJSON(action.payload.raw_response);

          node.data.configResponses = responseWithHandleinfo.map((r, i) => {
            let id = nanoid();
            if (i <= 2) {
              return {
                ...r,
                additional: true,
                checked: true,
                link_data: {
                  target_node_id: [],
                  target_field_port: [],
                  source_node_id: [],
                  source_field_port: [],
                },
                port: {
                  id: id,
                  source: id + "|" + "source",
                  nodeId: node.data.nodeId,
                },
              };
            } else {
              return {
                ...r,
                checked: false,
                additional: true,
                link_data: {
                  target_node_id: [],
                  target_field_port: [],
                  source_node_id: [],
                  source_field_port: [],
                },
                port: {
                  id: id,
                  source: id + "|" + "source",
                  nodeId: node.data.nodeId,
                },
              };
            }
          });
          if (state.editorState.nodes.length > 1) {
            state.editorState.nodes = state.editorState.nodes.map((node, i) => {
              if (i !== 0) {
                const { data } = node;
                data.trigger_konnect_id = rest.konnect_id;
                return node;
              } else return node;
            });
          }
        }
        let curr = {
          nodes: state.editorState.nodes,
          edges: state.editorState.edges,
        };
        {
          const lastHistory = state.history.present;

          if (lastHistory && !isEqual(lastHistory, curr)) {
            state.history.past.push(state.history.present);
            state.history.present = curr;
          }
        }
      })
      .addCase(captureWebHookResponse.rejected, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.data.nodeId === action.meta.arg
        );
        if (node) {
          node.data.loading = false;
          node.data.status = "Error";
          node.data.captureMessage = "Inadequate data to Capture Webhook";
        }
      })

      //  GET APP EVENT CONFIG FIELDS || DONE FOR APPS
      .addCase(OnTestandReview.pending, (state, action) => {
        const nodeData = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg
        ).data;
        nodeData.loading = true;
        nodeData.status = "Pending";
        nodeData.captureMessage = `Sending data to ${nodeData.name}`;
        delete nodeData.additionalResponses;
        nodeData.configResponses = nodeData.configResponses.filter(
          (config) => !config.additional
        );
        state.topbarState.saving = true;
      })
      .addCase(OnTestandReview.fulfilled, (state, action) => {
        if (action.payload?.test_status === "Success") {
          const {
            display_message,
            konnect_id,
            raw_response,
            test_status,
            konnect_activity_id,
            condition_activity_id,
          } = action.payload;
          const node = state.editorState.nodes.find(
            (x) => x.data.nodeId === action.meta.arg
          );
          let parentNode = null;
          const currentLinks = state.editorState.edges.filter(
            (edge) => edge.target === action.meta.arg
          );
          node.data = { ...node.data, ...action.payload };
          node.data.loading = false;
          node.data.status = "Success";
          node.data.captureMessage = display_message;
          state.canPublish = true;
          state.canSave = true;
          node.data.tested = true;
          node.data.reviewed = test_status
            ? test_status.toLowerCase() === "success"
            : false;
          node.data.additionalResponses = 0;
          node.data.configResponses = [
            ...node.data.configResponses,
            ...FlattenJSON(raw_response).map((response, i) => {
              node.data.additionalResponses += 1;
              let id = nanoid();
              if (i <= 2) {
                return {
                  ...response,
                  additional: true,
                  link_data: {
                    target_node_id: [],
                    target_field_port: [],
                    source_node_id: [],
                    source_field_port: [],
                  },
                  port: {
                    id: id,
                    source: id + "|" + "source",
                    nodeId: node.data.nodeId,
                  },
                  checked: true,
                };
              } else {
                return {
                  ...response,
                  additional: true,
                  link_data: {
                    target_node_id: [],
                    target_field_port: [],
                    source_node_id: [],
                    source_field_port: [],
                  },
                  port: {
                    id: id,
                    source: id + "|" + "source",
                    nodeId: node.data.nodeId,
                  },
                };
              }
            }),
          ];

          if (
            node.data.type === "ADD_ON" &&
            node.data.provider === "conditioner"
          ) {
            if (node.data.configResponses.length === 1 && currentLinks.length) {
              parentNode = state.editorState.nodes.find(
                (x) => x.data.nodeId === currentLinks[0].source
              );
              const newConfigResponses = parentNode.data.configResponses.map(
                (x) => {
                  const props = _.pick(x, [
                    "app_event_id",
                    "app_id",
                    "config_key",
                    "id",
                    "label",
                    "type",
                    "value",
                    "isOpen",
                    "level",
                    "children",
                  ]);
                  return props;
                }
              );
              node.data.configResponses = [
                ...node.data.configResponses,
                ...newConfigResponses.map((n) => {
                  let id = nanoid();
                  return {
                    ...n,
                    additional: true,
                    link_data: {
                      target_node_id: [],
                      target_field_port: [],
                      source_node_id: [],
                      source_field_port: [],
                    },
                    checked: true,
                    port: {
                      id: id,
                      source: id + "|" + "source",
                      target: id + "|" + "target",
                      nodeId: node.data.nodeId,
                    },
                  };
                }),
              ];
              node.data.additionalResponses = newConfigResponses.length;

              if (node.data.reviewed) {
                node.data.pass_through_condition = true;
                node.data.condition_activity_id = konnect_activity_id;
              }
            }
          }
          if (node.data.pass_through_condition) {
            node.data.pass_through_condition = true;
            node.data.condition_activity_id = konnect_activity_id;
            node.data.konnect_activity_id = konnect_activity_id;
          }
          if (node.data.pass_through_condition) {
            node.data.pass_through_condition = true;
            node.data.condition_activity_id = condition_activity_id
              ? condition_activity_id
              : konnect_activity_id;
            node.data.konnect_activity_id = konnect_activity_id;
          }
          if (state.editorState.nodes.length > 1) {
            state.editorState.nodes = state.editorState.nodes.map((node, i) => {
              if (i !== 0) {
                const { data } = node;
                data.trigger_konnect_id = konnect_id;
                return node;
              } else return node;
            });
          }
          if (
            (node.data.provider === "google-form" ||
              node.data.provider === "google_sheet") &&
            node.data.konnect_custom
          ) {
            state.gSheetPopUp = true;
          }
          if (
            node.data.type === "ADD_ON" &&
            node.data.provider === "conditioner"
          ) {
            node.data.configResponses = node.data.configResponses.map(
              (x, i) => {
                if (i === 0) {
                  return x;
                } else if (i < 4) {
                  return {
                    ...x,
                    additional: true,
                    checked: true,
                  };
                } else
                  return {
                    ...x,
                    additional: true,
                  };
              }
            );
          }
          let curr = {
            nodes: state.editorState.nodes,
            edges: state.editorState.edges,
          };
          {
            const lastHistory = state.history.present;
            if (lastHistory && !isEqual(lastHistory, curr)) {
              state.history.past.push(state.history.present);
              state.history.present = curr;
            }
          }
        } else {
          const node = state.editorState.nodes.find(
            (n) => n.id === action.meta.arg
          );
          node.data.loading = false;
          node.data.status = "Error";
          node.data.captureMessage = action.payload?.display_message;
          node.data.konnect_activity_id = action.payload?.konnect_activity_id;
          node.data.delay_activity_id = action.payload?.delay_activity_id;
          let dirty = false;
          node.data.configResponses = node.data.configResponses.map((x) => {
            if (x.config_key_required && !x.value) {
              dirty = true;
              return { ...x, dirty: true };
            } else {
              return x;
            }
          });
          if (dirty) {
            node.data.captureMessage = "Key Value Missing";
          } else {
            node.data.captureMessage = action.payload?.display_message;
          }
          node.data.status = "Error";
          node.data.konnect_activity_id = action.payload.konnect_activity_id;
          node.data.delay_activity_id = action.payload.delay_activity_id;
        }
      })
      .addCase(OnTestandReview.rejected, (state, action) => {
        const node = state.editorState.nodes.find(
          (x) => x.data.nodeId === action.meta.arg
        );
        node.data.loading = false;
        node.data.captureMessage = "Inadequate data to Test and Review";
        node.data.status = "Error";
        state.topbarState.saving = false;
      })

      .addCase(getKonnect.pending, (state, action) => {
        state.canvasLoad = true;
      })

      .addCase(getKonnect.fulfilled, (state, action) => {
        const { nodes, edges } = action.payload;
        state.editorState.nodes = nodes;
        state.editorState.edges = edges;
        state.canvasLoad = false;
        state.isOnEditMode = true;
        state.editorState.konnect_name = action.payload?.konnect_name;
      })
      .addCase(getKonnect.rejected, (state, action) => {
        const { nodes, edges } = action.payload;

        state.canvasLoad = false;
        state.isOnEditMode = true;
      })

      .addCase(saveKonnect.pending, (state, action) => {
        state.topbarState.saving = true;
      })
      .addCase(saveKonnect.fulfilled, (state, action) => {
        state.topbarState.saving = false;
      })
      .addCase(saveKonnect.rejected, (state, action) => {
        state.topbarState.saving = false;
      })
      .addCase(onDeleteNodeV2.fulfilled, (state, action) => {
        const node = state.editorState.nodes.find(
          (node) => node.id === action.meta.arg
        );
        if (node) {
          delete node.data.konnect_activity_id;
        }
      });
  },
});

export const {
  onCanvasDrop,
  onNodesChange,
  onEdgesChange,
  toggleTopSearchBar,
  setSelectedEvent,
  onDelete,
  toggleBasicAuth,
  onRefresh,
  onWebhookAuthChange,
  onSelectResponse,
  setLinkedAccount,
  resetStatus,
  setEventsConfig,
  onInputTextType,
  onLinkComplete,
  onLinkDelete,
  toggleAppContainer,
  onResetConfiguration,
  updateGsheetPop,
  toggleAddonCollapse,
  updateAddonCollapse,
  modifySegmentIndex,
  updateExpiryNum,
  updateDuration,
  updateUrl,
  updateWrapRequestInArray,
  updateHeadersAndParams,
  modifyConditions,
  updatePayloadType,
  saveKonnectName,
  setDelayConfigInNode,
  onToggleDrag,
  clearState,
  reArrange,
  addCustom,
  redo,
  undo,
  setDisplayAddOn,
  toggleDisplayAddOn,
  showChatGpt,
  deleteDependentLinks,
  clearConfigs,
  checkStatus,
  setModalData,
  deleteCustom,
  saveSearchingAppName,
  setSchedulerData,
  updateCondition,
  addNewCondition,
  deleteCondition,
  updateConditions,
  updateConditionsOnCancel,
  setEditMode,
  uploadAppType,
  setDelayConfigs,
  cancelDelay,
  updateInputKey,
  updateQuesKey,
} = canvasSlice.actions;

export default canvasSlice.reducer;

// case ONDELETELINK: {
//   const chart = state.get("editorState").toJS()
//   const { id } = action.data
//   const linkData = chart.links.find(x => x.id === id)
//   chart.links = chart.links.filter(x => x.id !== id)
//   const fromNodeId = linkData.from.node
//   const toNodeId = linkData.to.node
//   const nodeIdx = chart.nodes.findIndex(c => c.nodeId === linkData.to.node)
//   if (nodeIdx !== -1 && chart.nodes[nodeIdx].fromPorts) {
//     chart.nodes[nodeIdx].fromPorts = chart.nodes[nodeIdx].fromPorts.filter(port => port.linkId !== id)
//   }

//   const currentLinks = chart.links.filter(x => x.to.node === toNodeId)
//   const currentNodeIdx = chart.nodes.findIndex(x => x.nodeId === toNodeId)
//   const currentNode = chart.nodes[currentNodeIdx]

//   if (currentNode.appType === "ADD_ON" && currentNode.provider === "conditioner") {
//     chart.nodes[currentNodeIdx].fromFields = chart.nodes[currentNodeIdx].fromFields.filter(x => x.linkId !== id)

//     /* Update conditions and conditions text */
//     //let currentConditions = chart.nodes[currentNodeIdx].conditions

//     chart.nodes[currentNodeIdx].conditions = chart.nodes[currentNodeIdx].conditions
//       ? chart.nodes[currentNodeIdx].conditions.filter(condition => condition.field.key !== id)
//       : []

//     let conditionsTxt = chart.nodes[currentNodeIdx].conditions.map(condition => {
//       let queryOperatorText = condition.queryOperator === undefined ? "" : condition.queryOperator.toUpperCase()
//       return (
//         condition.field.label + " " + condition.operator + " " + condition.value + " " + queryOperatorText + "\r\n"
//       )
//     })
//     chart.nodes[currentNodeIdx].conditionsText = conditionsTxt

//     if (!currentLinks.length) {
//       chart.nodes[currentNodeIdx].additionalResponses = []
//       const firstPort = { ...chart.nodes[currentNodeIdx].configResponses[0] }
//       chart.nodes[currentNodeIdx].configResponses = [firstPort]
//       chart.links = chart.links.filter(x => x.from.node !== toNodeId)
//       chart.nodes[currentNodeIdx].conditions = []
//       chart.nodes[currentNodeIdx].conditionsText = ""
//       chart.nodes[currentNodeIdx].reviewed = false
//       chart.nodes[currentNodeIdx].tested = false
//       chart.nodes[currentNodeIdx].showTestAndReview = false
//     }
//   }

//   const configIdx = chart.nodes[currentNodeIdx].configResponses.findIndex(c => c.port.id === linkData.to.port)
//   if (configIdx !== -1) {
//     chart.nodes[currentNodeIdx].configResponses[configIdx].value = ""
//   }

//   const _chart = fromJS(chart)

//   const _state = changeHistory(state, ONDELETELINK, chart)

//   return _state.set("editorState", _chart)
// }
