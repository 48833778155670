import { es } from "./es"
import { en } from "./en"
import { fr } from "./fr"
import { chinese } from "./chinese"
import { dutch } from "./dutch"
import { arabic } from "./arabic"
import { german } from "./german"

export const SUPPORTED_LANGUAGE = {
  ENGLISH: "en",
  SPANISH: "es",
  FRENCH: "fr",
  CHINESE: "chinese",
  DUTCH: "dutch",
  ARABIC: "arabic",
  GERMAN: "german",
}

// TODO: decide where to store translations
export const resources = {
  [SUPPORTED_LANGUAGE.ENGLISH]: en,
  [SUPPORTED_LANGUAGE.SPANISH]: es,
  [SUPPORTED_LANGUAGE.FRENCH]: fr,
  [SUPPORTED_LANGUAGE.CHINESE]: chinese,
  [SUPPORTED_LANGUAGE.DUTCH]: dutch,
  [SUPPORTED_LANGUAGE.ARABIC]: arabic,
  [SUPPORTED_LANGUAGE.GERMAN]: german,

}
