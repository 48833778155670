import React from "react";
import { Flex, Input,Box } from "@chakra-ui/react";
import { Handle } from "reactflow";
import { useDispatch } from "react-redux";
import { onInputTextType, onLinkDelete } from "../../../store/slice/canvasSlice";
import { MentionsInput } from "../../styled/index";

export const ResponseContainerMentionInput = ({
  result = [],
  nodeId,
  parentnode,
  data,
}) => {
  const dispatch = useDispatch();
  const configResult = result.filter(
    (c) => c.checked === true && !c.additional && c.config_key_type !== "custom"
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "7px",
      }}
    >
      {configResult
        ?.filter(
          (c) =>
            c.checked === true && !c.additional && c.key_value_type === "input"
        )
        .map((x) => {
          return (
            <>
              <Flex sx={{ color: "#8c8c8c", fontSize: 12 }}>{x.label}</Flex>
              <Flex>
                <Input
                  bgColor="#f3f7ff"
                  name="config_value"
                  width="100%"
                  onFocus={() => (parentnode.current.id = "")}
                  onBlur={() => (parentnode.current.id = "drag")}
                  placeholder={
                    x.label.includes("(")
                      ? x.label.slice(0, x.label.indexOf("("))
                      : x.label
                  }
                  onChange={(e) =>{
                    dispatch(
                      onLinkDelete({
                        nodeId,
                        additionalOnly: true,
                        selectedConfig: x,
                      })
                    )
                    dispatch(
                      onInputTextType({
                        nodeId: nodeId,
                        input: e.target.value,
                        portInfo: x,
                      })
                    )}
                  }
                  value={XMLHttpRequestEventTarget?.value}
                />
              </Flex>
            </>
          );
        })}
      {configResult
        ?.filter(
          (c) =>
            c.checked === true && !c.additional && c.key_value_type === "text"
        )
        .map((x) => {
          return (
            <>
              <Flex sx={{ color: "#8c8c8c", fontSize: 12 }}>{x.label}</Flex>
              <Flex>
                <Box
                  width="6%"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Handle
                    style={{
                      height: "10px",
                      background: "#152f73",
                      width: "10px",
                      borderRadius: "50%",
                      outline: "2px solid #152f73",
                    }}
                    type="target"
                    position="left"
                    isConnectable="true"
                    id={x.port.target}
                  />
                </Box>

                <MentionsInput
                  parentNode={parentnode}
                  width="88%"
                  onChange={(e) => {
                    dispatch(
                      onLinkDelete({
                        nodeId,
                        additionalOnly: true,
                        selectedConfig:x, 
                      })
                    );
                    dispatch(
                      onInputTextType({
                        nodeId: nodeId,
                        input: e.target.value,
                        portInfo: x,
                      })
                    );
                  }}
                  placeholder={!x.dirty ? x.label : `${x.label}*`}
                  data={data.fromPorts ? data.fromPorts : []}
                  componentData={data}
                  value={x.value ? x.value : ""}
                />
                <Flex
                  width="6%"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Handle
                    style={{
                      height: "10px",
                      background: "#152f73",
                      width: "10px",
                      borderRadius: "50%",
                      outline: "2px solid #152f73",
                    }}
                    type="source"
                    position="right"
                    isConnectable="true"
                    id={x.port.source}
                  />
                </Flex>
              </Flex>
            </>
          );
        })}
    </Box>
  );
};
