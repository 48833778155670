import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProfileGet,
  updatePasswordPost,
  updateProfilePost,
} from "../api-client";
import {
  fetchMemberList,
  getCouponList,
  getFeaturesList,
  getProfile,
  updatePassword,
} from "./thunk/accountsThunk";

export const updateProfile = createAsyncThunk("updateProfile", async (data) => {
  const response = await updateProfilePost(data);
  return response?.data;
});

const initialState = {
  profileData: null,
  couponList: [],
  featureData: null,
  loading: true,
  profileLoading: false,
  toggleProfile: false,
  toggleExtra: false,
  extraData: null,
  extraDataLoading: true,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    ontoggleProfile: (state, action) => {
      state.toggleProfile = !state.toggleProfile;
      // if (state.toggleProfile === true) {
      //   state.toggleExtra = false;
      // } else state.toggleExtra = true;
    },
    ontoggleExtra: (state, action) => {
      state.toggleExtra = !state.toggleExtra;
      // if (state.toggleExtra === true) {
      //   state.toggleProfile = false;
      // } else state.toggleProfile = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // ... (existing cases)

      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
        // Handle the data after getting the profile if needed
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.profileLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profileLoading = false;
        //   state.profile = action.payload
        // Handle the data after updating the profile if needed
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.profileLoading = false;
      })
      .addCase(updatePassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
        // Handle the data after updating the password if needed
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCouponList.pending, (state, action) => {})
      .addCase(getCouponList.fulfilled, (state, action) => {
        state.couponList = action.payload.redeemed_coupons;
      })
      .addCase(getCouponList.rejected, (state, action) => {})
      .addCase(getFeaturesList.pending, (state, action) => {})
      .addCase(getFeaturesList.fulfilled, (state, action) => {
        state.featureData = action.payload;
      })
      .addCase(getFeaturesList.rejected, (state, action) => {})
      .addCase(fetchMemberList.pending, (state, action) => {
        state.extraDataLoading = true;
      })
      .addCase(fetchMemberList.fulfilled, (state, action) => {
        state.extraDataLoading = false;
        state.extraData = action.payload.team_users;
      })
      .addCase(fetchMemberList.rejected, (state, action) => {
        state.extraDataLoading = false;
      });
  },
});

export const { ontoggleProfile, ontoggleExtra } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
