import React, { useState } from "react";
import Select from "react-select";
import { svg } from "../../../assets/objectSVG";
import { Flex, Tooltip, Box, Input, Button } from "@chakra-ui/react";
import { Handle } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import {
  addCustom,
  onInputTextType,
  setEventsConfig,
  setLinkedAccount,
  setSelectedEvent,
  setModalData,
  deleteCustom,
  onLinkDelete,
  updateDuration,
  modifySegmentIndex,
  updatePayloadType,
  updateWrapRequestInArray,
  updateCondition,
  uploadAppType,
  setDelayConfigs,
} from "../../../store/slice/canvasSlice";
import CreatableSelect from "react-select/creatable";
import {
  getAppEventConfigDetail,
  getAppEventConfigDetailFetch,
  getAppEventConfigs,
} from "../../../store/thunk/rightHandAppsThunk";
import { searchApp } from "../../../store/thunk/appsThunk";
export const selectStyle = {
  container: (styles) => ({
    ...styles,
    width: "100%",
    "&:focus-visible": {
      outline: "none",
    },
  }),
  control: (styles) => ({
    ...styles,
    "&:hover": {
      outline: "none",
    },
    transition: "outline 0.15s",
    boxShadow: "none",
    minHeight: "40px",
    backgroundColor: "#f3f7ff",
    border: "1px solid #b7caff",
    fontSize: 16,
  }),
  valueContainer: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles, color: "#000" }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#fff",
      border: "1px solid #b7c9ff !important",
    };
  },
};

export const Selector = React.memo(
  ({
    options = [],
    selectType,
    index,
    parentnode,
    addAccount,
    placeholder,
    fetchFieldStatus,
    configResponses,
    customField,
    nodeId,
    setModalState,
    onFocus,
    value,
    totalSeq,
    selectedConfig,
    setExpandHeight,
    setExpand,
    isAddOn,
    name,
    _condition = {},
    isConditionDrop,
    data,
    conditionsInfo,
    condition,
    auth_type,delayConfig
  }) => {
    const dispatch = useDispatch();
    const dataValue = value;
    const nodes = useSelector((state) => state.canvas.editorState.nodes);
    const customFieldConfig = configResponses?.find(
      (config) =>
        config.config_key_type === "custom" &&
        config.sequence === selectedConfig.sequence
    );
    const helper = {
      selectEvent: (value) => {
        switch (selectType) {
          case "Trigger":
            if (!dataValue || (dataValue && dataValue?.id !== value.id)) {
              dispatch(onLinkDelete({ nodeId: nodeId }));
              dispatch(
                setSelectedEvent({
                  nodeId: nodeId,
                  value: value,
                })
              );
            }
            break;
          case "Action":
            if (!dataValue || (dataValue && dataValue?.id !== value.id)) {
              dispatch(onLinkDelete({ nodeId: nodeId }));
              dispatch(
                setSelectedEvent({
                  nodeId: nodeId,
                  value: value,
                })
              );
              if (isAddOn) {
                dispatch(getAppEventConfigs(nodeId)).then((res) => {
                  if (res.payload?.filter((r) => r.sequence >= 1)?.length > 0) {
                    dispatch(
                      getAppEventConfigDetail({
                        nodeId,
                        config_key: res.payload.find((x) => x.sequence === 1)
                          .config_key,
                      })
                    );
                  }
                });
              }
            }
            break;
          case "Account":
            if (!dataValue || (dataValue && dataValue?.id !== value.id)) {
              dispatch(onLinkDelete({ nodeId: nodeId }));
              dispatch(setLinkedAccount({ selected: value, nodeId }));
              dispatch(getAppEventConfigs(nodeId)).then((res) => {
                if (res.payload?.filter((r) => r.sequence >= 1)?.length > 0) {
                  dispatch(
                    getAppEventConfigDetail({
                      nodeId,
                      config_key: res.payload.find((x) => x.sequence === 1)
                        .config_key,
                    })
                  );
                }
              });
            }
            break;
          case "Sequence":
            if (selectedConfig?.custom_field) {
              dispatch(
                onLinkDelete({
                  nodeId,
                  selected: true,
                  customField: customFieldConfig,
                })
              );
              dispatch(deleteCustom({ selectedConfig, nodeId }));
            }

            if (!fetchFieldStatus) {
              dispatch(
                onLinkDelete({
                  nodeId,
                  additionalOnly: true,
                })
              );
            } else
              dispatch(
                onLinkDelete({
                  nodeId,
                  onlyConfig: true,
                  onlyCustom: fetchFieldStatus ? false : true,
                  sequence: selectedConfig.sequence,
                })
              );
            dispatch(
              setEventsConfig({ selectedConfig, selectedValue: value, nodeId })
            );
            if (index !== totalSeq - 1) {
              dispatch(getAppEventConfigDetail({ nodeId, prevValue: value }));
            } else {
              if (fetchFieldStatus) {
                dispatch(
                  getAppEventConfigDetailFetch({ nodeId, selectedValue: value })
                );
              }
            }
            break;
          case "coupon":
            let list1 = JSON.parse(JSON.stringify(options));
            list1.forEach((obj) => {
              if (obj.id === value.id) {
                obj.selected = true;
              } else {
                obj.selected = false;
              }
            });
            dispatch(onLinkDelete({ nodeId: nodeId }));

            dispatch(updateDuration({ list1, nodeId: nodeId }));
            break;
          case "TextSplitter":
            let list = JSON.parse(JSON.stringify(options));
            list.forEach((obj) => {
              if (obj.id === value.id) {
                obj.selected = true;
              } else {
                obj.selected = false;
              }
            });
            dispatch(onLinkDelete({ nodeId: nodeId }));

            dispatch(modifySegmentIndex({ nodeId: nodeId, list }));
            break;
          case "payload_type":
            let list2 = JSON.parse(JSON.stringify(options));

            list2.forEach((obj) => {
              if (obj.id === value.id) {
                obj.selected = true;
              } else {
                obj.selected = false;
              }
            });
            if (name === "payload") {
              dispatch(updatePayloadType({ value: list2, nodeId: nodeId }));
            } else if (name === "wrap_request") {
              dispatch(
                updateWrapRequestInArray({ value: list2, nodeId: nodeId })
              );
            }
            break;
          case "conditionItem":
            if (isConditionDrop == "true" && name == "third") {
              let valueForCondition = "";

              if (!value.__isNew__) {
                let konnect_activity_id = nodes.find((node) =>
                  node.data.configResponses.find(
                    (x) => x.port.id === value.port.id
                  )
                ).data.konnect_activity_id;

                valueForCondition = {
                  type: "key-map",
                  value: value.config_key,
                  konnect_activity_id: konnect_activity_id,
                  name: value.label,
                  label: value.label,
                  id: value.label,
                };
                const conditionObj = {
                  ...condition,
                  value: valueForCondition,
                  thirdValue: valueForCondition,
                };
                dispatch(
                  updateCondition({
                    conditionObj,
                    nodeId: nodeId,
                    key: condition.key,
                  })
                );
              } else {
                valueForCondition = value.value;
                const conditionObj = {
                  ...condition,
                  value: valueForCondition,
                  thirdValue: {
                    value: value.value,
                    name: value.value,
                    id: value.value,
                    label: value.value,
                  },
                };
                dispatch(
                  updateCondition({
                    conditionObj,
                    nodeId: nodeId,
                    key: condition.key,
                  })
                );
              }
            }
            if (name == "first") {
              const conditionObj = {
                ...condition,
                field: {
                  key: value.linkId,
                  label: value.label,
                  value: value.config_key,
                  name: value.label,
                },
              };

              dispatch(
                updateCondition({
                  conditionObj,
                  nodeId: nodeId,
                  key: condition.key,
                })
              );
            }
            if (name == "second") {
              const conditionObj = {
                ...condition,
                operator: value.id,
                second: { ...value },
              };
              dispatch(
                updateCondition({
                  conditionObj,
                  nodeId: nodeId,
                  key: condition.key,
                })
              );
            }
          case "Delay":
            if(delayConfig==="First"){
              dispatch(setDelayConfigs({delay_type:"delay_type",value:value,nodeId:nodeId}))
            }
            if(delayConfig==="Second"){
              dispatch(setDelayConfigs({delay_type:"Second",value:value,nodeId:nodeId}))
            }
        }
      },
      customFetch: (targetValue) => {
        const value = {
          config_key: customFieldConfig.config_key.split(">>")[0],
          id: targetValue ? customFieldConfig.value : "",
          label: selectedConfig.selected.label,
          sequence: customFieldConfig.sequence,
          value: targetValue ? customFieldConfig.value : "",
        };
        dispatch(
          onLinkDelete({
            nodeId,
            onlyConfig: true,
            onlyCustom: fetchFieldStatus ? false : true,
            sequence: selectedConfig.sequence,
          })
        );
        dispatch(
          setEventsConfig({ selectedConfig, selectedValue: value, nodeId })
        );
        if (targetValue) {
          if (index !== totalSeq - 1) {
            dispatch(getAppEventConfigDetail({ nodeId, prevValue: value }));
          } else {
            if (fetchFieldStatus) {
              dispatch(
                getAppEventConfigDetailFetch({ nodeId, selectedValue: value })
              );
            }
          }
        }
      },
    };
    return (
      <Flex width="100%" sx={{ flexDirection: "column", gap: "10px" }}>
        <Flex width="100%" sx={{ alignItems: "center", gap: "10px" }}>
          <Flex
            onMouseDownCapture={() => (parentnode.current.id = "")}
            onMouseEnter={() => parentnode?.current.classList.add("nowheel")}
            onMouseLeave={() => parentnode?.current.classList.remove("nowheel")}
            width="100%"
          >
            {isConditionDrop == "true" ? (
              <CreatableSelect
                openMenuOnFocus
                onFocus={onFocus}
                blurInputOnSelect
                onChange={helper.selectEvent}
                placeholder={
                  placeholder?.includes("*") ? (
                    <span style={{ color: "#F74545" }}>
                      <span style={{ color: "black" }}>
                        {placeholder.split("*")[0]}
                      </span>
                      *
                    </span>
                  ) : (
                    placeholder || "Select..."
                  )
                }
                value={value}
                onBlur={() => (parentnode.current.id = "drag")}
                options={options}
                styles={selectStyle}
              />
            ) : (
              <Select
                openMenuOnFocus
                onFocus={onFocus}
                blurInputOnSelect
                onChange={helper.selectEvent}
                placeholder={
                  placeholder?.includes("*") ? (
                    <span style={{ color: "#F74545" }}>
                      <span style={{ color: "black" }}>
                        {placeholder.split("*")[0]}
                      </span>
                      *
                    </span>
                  ) : (
                    placeholder || "Select..."
                  )
                }
                value={value}
                onBlur={() => (parentnode.current.id = "drag")}
                options={options}
                styles={selectStyle}
              />
            )}
          </Flex>
          <Box
            height="100%"
            onClick={(e) => e.stopPropagation()}
            sx={{
              display: addAccount ? "flex" : "none",
            }}
          >
            <Tooltip label="Manage Accounts" hasArrow>
              <Flex
                onClick={() => {
                  if (auth_type) {
                    setExpand(true);
                    setExpandHeight(true);
                    dispatch(setModalData({ nodeId: nodeId, type: "Account" }));
                    setModalState({ display: true, type: "accounts" });
                  } else {
                    dispatch(searchApp({ search_query: name }))
                      .then((res) => {
                        const appType = res?.payload?.apps?.find(
                          (app) => app.name === name
                        ).auth_type;
                        dispatch(
                          uploadAppType({ nodeId: nodeId, auth_type: appType })
                        );
                      })
                      .then(() => {
                        setExpand(true);
                        setExpandHeight(true);
                        dispatch(
                          setModalData({ nodeId: nodeId, type: "Account" })
                        );
                        setModalState({ display: true, type: "accounts" });
                      });
                  }
                }}
                width="40px"
                sx={{
                  m: "auto",
                  cursor: "pointer",
                  fill: "rgb(22,44,115,0.75)",
                  stroke: "rgb(22,44,115,0.75)",
                  "&:hover": {
                    fill: "#162c73",
                    stroke: "#162c73",
                  },
                }}
              >
                {svg.addAccount}
              </Flex>
            </Tooltip>
          </Box>
          <Box
            height="100%"
            sx={{
              display: customField ? "flex" : "none",
              gap: "10px",
            }}
          >
            <Tooltip label="Configure by ID" hasArrow>
              <Flex
                onClick={() => {
                  if (!selectedConfig?.custom_field) {
                    dispatch(addCustom({ selectedConfig, nodeId }));
                  } else {
                    dispatch(
                      onLinkDelete({
                        nodeId,
                        selected: true,
                        customField: customFieldConfig,
                      })
                    );
                    dispatch(deleteCustom({ selectedConfig, nodeId }));
                  }
                }}
                width="40px"
                sx={{
                  m: "auto",
                  cursor: "pointer",
                  fill: selectedConfig?.custom_field
                    ? "rgb(247,197,68)"
                    : "rgb(22,44,115,0.75)",
                  stroke: selectedConfig?.custom_field
                    ? "rgb(247,197,68)"
                    : "rgb(22,44,115,0.75)",
                  "&:hover": {
                    fill: selectedConfig?.custom_field
                      ? "rgb(247,197,68)"
                      : "#162c73",
                    stroke: selectedConfig?.custom_field
                      ? "rgb(247,197,68)"
                      : "#162c73",
                  },
                  transition: "fill 0.15s, stroke 0.15s",
                }}
              >
                {svg.customField}
              </Flex>
            </Tooltip>
          </Box>
        </Flex>
        {selectedConfig?.custom_field && customFieldConfig && (
          <Box
            sx={{
              display: "flex",
              flexDir: "column",
              gap: "7px",
            }}
          >
            <Flex>
              <Box
                width="6%"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Handle
                  style={{
                    height: "10px",
                    background: "#152f73",
                    width: "10px",
                    borderRadius: "50%",
                    outline: "2px solid #152f73",
                  }}
                  type="target"
                  position="left"
                  isConnectable="true"
                  id={customFieldConfig.port.target}
                />
              </Box>
              <Flex sx={{ position: "relative", width: "88%" }}>
                <Input
                  name="config_value"
                  onFocus={() => (parentnode.current.id = "")}
                  onBlur={() => (parentnode.current.id = "drag")}
                  width="100%"
                  isDisabled={customFieldConfig.target_linked ? true : false}
                  sx={{
                    pr:
                      customFieldConfig?.value ===
                      selectedConfig?.selected?.value
                        ? ""
                        : (fetchFieldStatus && customFieldConfig?.value) ||
                          (customFieldConfig?.value &&
                            selectedConfig.sequence !== totalSeq)
                        ? "85px"
                        : "70px",
                    border: customFieldConfig?.dirty
                      ? "1px solid red"
                      : "1px solid #b7c9ff",
                    backgroundColor:
                      customFieldConfig?.value?.length &&
                      selectedConfig?.selected?.value ===
                        customFieldConfig?.value
                        ? "#F3F7FF"
                        : "#fff",
                    "&:disabled": {
                      opacity: 1,
                    },
                    "&:hover": {
                      border: "1px solid #b7c9ff",
                    },
                  }}
                  onChange={(e) => {
                    dispatch(
                      onLinkDelete({
                        nodeId,
                        additionalOnly: true,
                        selectedConfig: data,
                      })
                    );
                    dispatch(
                      onInputTextType({
                        nodeId: nodeId,
                        input: e.target.value,
                        portInfo: customFieldConfig,
                      })
                    );
                    if (!e.target.value) {
                      helper.customFetch(false, true);
                    }
                  }}
                  value={customFieldConfig.value ? customFieldConfig.value : ""}
                  placeholder={customFieldConfig.label}
                />
                {(fetchFieldStatus &&
                  customFieldConfig?.value &&
                  customFieldConfig?.value !==
                    selectedConfig?.selected?.value) ||
                (customFieldConfig?.value &&
                  selectedConfig.sequence !== totalSeq &&
                  customFieldConfig?.value !==
                    selectedConfig?.selected?.value) ? (
                  <Button
                    sx={{
                      m: "auto 0px",
                      position: "absolute",
                      zIndex: 3,
                      top: "0px",
                      right: "0px",
                      bgColor: "#162C73",
                      fontWeight: 400,
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      color: "#fff",
                      "&:hover": {
                        bgColor: "#162C73",
                        color: "#F7C544",
                      },
                    }}
                    onClick={() => helper.customFetch(true, true)}
                  >
                    Fetch
                  </Button>
                ) : (
                  customFieldConfig?.value &&
                  customFieldConfig?.value !==
                    selectedConfig?.selected?.value && (
                    <Button
                      sx={{
                        m: "auto 0px",
                        bgColor: "#162C73",
                        position: "absolute",
                        top: "0px",
                        zIndex: 3,
                        right: "0px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        fontWeight: 400,
                        color: "#fff",
                        "&:hover": {
                          bgColor: "#162C73",
                          color: "#F7C544",
                        },
                      }}
                      onClick={() => helper.customFetch(true, true)}
                    >
                      Set
                    </Button>
                  )
                )}
              </Flex>

              <Flex
                width="6%"
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Handle
                  style={{
                    height: "10px",
                    background: "#152f73",
                    width: "10px",
                    borderRadius: "50%",
                    outline: "2px solid #152f73",
                  }}
                  type="source"
                  position="right"
                  isConnectable="true"
                  id={customFieldConfig.port.source}
                />
              </Flex>
            </Flex>
          </Box>
        )}
      </Flex>
    );
  }
);
