import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Collapse,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { svg } from "../../assets/objectSVG";
import {
  toggleAddonCollapse,
  updateAddonCollapse,
} from "../../store/slice/canvasSlice";
import { getAddonApps, getApps, searchApp } from "../../store/thunk/appsThunk";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

export const SidebarContainer = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const sidebarState = useSelector((state) => state.canvas.sidebarState);
  const appContainer = useSelector((state) => state.canvas.appContainer);
  const addOnList = useSelector((state) => state.apps.addOnList);
  const appsList = useSelector((state) => state.apps.appsList);
  const [listedApp, setListedApp] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(60);
  const [searching, setSearching] = useState(false); //  Local state for loader
  useEffect(() => {
    dispatch(getApps({ page_size: pageSize }))
      .then((res) => {
        if(res.error){
          navigate("/logout");
        }
        setListedApp(res.payload.apps)})
    if (!addOnList?.length) {
      dispatch(getAddonApps()).catch((err) => {
        navigate("/logout");
      });
    }
  }, [pageSize]);

  const handleChange = (e) => {
    if (e.target.value?.length > 0 && !sidebarState.addOnCollapse) {
      dispatch(updateAddonCollapse(true));
    }
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setListedApp(appsList);
      setSearching(false);
    } else {
      dispatch(searchApp({ search_query: e.target.value })).then((res) => {
        if (res.payload.apps?.length > 0) {
          setListedApp(res.payload.apps);
          setSearching(true);
        } else {
          setListedApp([]);
          setSearching(true);
        }
      });
    }
  };

  return (
    <Flex
      sx={{
        width: appContainer === "Sidebar" ? "15%" : "0%",
        height: "100%",
        minW: appContainer === "Sidebar" ? "320px" : "0px",
        bgColor: "#fff",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
        transition: "width 0.25s, min-width 0.25s",
        p: appContainer === "Sidebar" ? "16px" : "0px",
        flexDir: "column",
        gap: "16px",
      }}
    >
      {appContainer === "Sidebar" && (
        <>
          <InputGroup size="md">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="rgb(21,45,115,0.6)" />
            </InputLeftElement>
            <Input
              placeholder={t("konnect.sidebar.search_apps")}
              value={searchValue}
              ref={inputRef}
              onChange={handleChange}
              sx={{
                borderTop: "1px solid #3182ce",
                borderRight: "1px solid #3182ce",
                borderLeft: "1px solid #3182ce",
                borderBottom: "3px solid #3182ce",
                "&:hover": {
                  borderTop: "1px solid #3182ce",
                  borderRight: "1px solid #3182ce",
                  borderLeft: "1px solid #3182ce",
                  borderBottom: "3px solid #3182ce",
                },
              }}
            />
            {searchValue?.length > 0 && (
              <InputRightElement
                onClick={() => {
                  setSearchValue("");
                  inputRef.current.focus();
                  setListedApp(appsList);
                  setSearching(false);
                }}
                sx={{
                  "&:hover": {
                    "& #closeicon": {
                      transform: "scale(1.2)",
                      color: "red",
                    },
                  },
                }}
                cursor="pointer"
              >
                <CloseIcon
                  id="closeicon"
                  color="#E57387"
                  sx={{
                    transition: "transform 0.15s, color 0.15s",
                  }}
                  boxSize="0.8em"
                  cursor="pointer"
                />
              </InputRightElement>
            )}
          </InputGroup>
          <Flex
            onClick={() => dispatch(toggleAddonCollapse())}
            sx={{
              cursor: "pointer",
              borderBottom: "2px solid #3182ce",
              "&:hover": {
                "& #down_icon": {
                  transform: sidebarState.addOnCollapse
                    ? "rotate(0deg) scale(1.2)"
                    : "rotate(180deg) scale(1.2)",
                },
              },
              justifyContent: "space-between",
              pb: "2px",
            }}
          >
            <Flex>{t("konnect.sidebar.add_on_apps")}</Flex>
            <ChevronDownIcon
              id="down_icon"
              boxSize="1.4em"
              sx={{
                m: "auto 0px",
                transform: sidebarState.addOnCollapse
                  ? "rotate(0deg)"
                  : "rotate(180deg)",
                transition: "transform 0.25s",
              }}
            />
          </Flex>
          <Wrap
            spacing={3}
            sx={{
              height: sidebarState.addOnCollapse ? "0px" : "",
              zIndex: sidebarState.addOnCollapse ? -1 : 1,
              opacity: sidebarState.addOnCollapse ? 0 : 1,
            }}
          >
            {addOnList &&
              addOnList?.length > 0 &&
              addOnList.map((app, idx) => {
                const image = app.image_url.startsWith("http")
                  ? `${app.image_url}`
                  : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg";
                return <AppView key={idx} app={app} idx={idx} image={image} />;
              })}
          </Wrap>
          <Flex
            sx={{
              borderBottom: "2px solid #3182ce",
              justifyContent: "space-between",
              pb: "2px",
              mt: sidebarState.addOnCollapse ? "-20px" : "0px",
            }}
          >
            <Flex>{t("konnect.sidebar.integrated_apps")}</Flex>
          </Flex>
          <Flex position="relative" sx={{ height: "100%", width: "100%" }}>
            <Flex
              id="scrollableDiv1"
              sx={{
                position: "absolute",
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px",
                overflowY: "auto",
              }}
            >
              <InfiniteScroll
                dataLength={listedApp?.length || 0} //This is important field to render the next data
                next={() => setPageSize((c) => c + 20)}
                hasMore={listedApp?.length > 1000 ? false : true}
                pullDownToRefresh={false}
                scrollableTarget="scrollableDiv1"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>{t("konnect.sidebar.search_apps_endList")}</b>
                  </p>
                }
                loader={
                  !searching && (
                    <Flex
                      width="60px"
                      sx={{ m: "20px auto", stroke: "#162c73" }}
                    >
                      {svg.loader}
                    </Flex>
                  )
                }
              >
                <Wrap justify="flex-start" spacing={3}>
                  {listedApp &&
                    listedApp?.length > 0 &&
                    listedApp.map((app, idx) => {
                      const image = app.image_url.startsWith("http")
                        ? `${app.image_url}`
                        : "https://d33wubrfki0l68.cloudfront.net/4e5626a0b7db6b84c0b51f1c0cfd56490fc19b74/7a5ad/images/logo-fancy.svg";
                      return (
                        <AppView key={idx} app={app} idx={idx} image={image} />
                      );
                    })}
                </Wrap>
              </InfiniteScroll>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const AppView = ({ app, idx, image }) => {
  return (
    <React.Fragment key={idx}>
      <Tooltip label={app.name}>
        <Flex
          onDragStart={(event) => {
            event.dataTransfer.setData(
              "appsData",
              JSON.stringify({
                id: app.id,
                name: app.name,
                image: image,
                auth_type: app.auth_type,
                background: app.background_color,
                associatedAccounts: app.account_count ? app.account_count : 1,
                webhook_enabled: app.webhook_enabled,
                webhook_instructions: app.webhook_instructions,
                webhook_url: app.webhook_url,
                provider: app.provider,
                type: app.app_type,
                auth_type: app.auth_type,
                side: app.side,
              })
            );
            event.dataTransfer.effectAllowed = "move";
          }}
          width="60px"
          flexDir="column"
          sx={{
            cursor: "pointer",
            "&:hover": {
              "& #image": {
                transform: "scale(1.2)",
              },
            },
          }}
          draggable
          alignItems="center"
          gap="5px"
        >
          <Image
            id="image"
            width="90%"
            src={app.image_url}
            sx={{ transition: "transform 0.15s" }}
          />
          <Flex fontSize={10} justifyContent="center">
            {" "}
            {app.name?.length > 9 ? app.name.slice(0, 8) + ".." : app.name}
          </Flex>
        </Flex>
      </Tooltip>
    </React.Fragment>
  );
};
