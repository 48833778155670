import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAgencyMemberData,
  addMemberData,
  deleteMember,
  featureList,
  getAgencyUserList,
  getProfileGet,
  getRedeemedCoupon,
  getTeamUserList,
  redeemCoupon,
  updateMemberData,
  updatePasswordPost,
  updateProfilePost,removeAgencyUsers
} from "../../api-client";

export const getProfile = createAsyncThunk("getProfile", async () => {
  try {
    const response = await getProfileGet();
    const data = response?.data;
    return data;
  } catch (err) {
    console.log(err);
  }
});

export const getCouponList = createAsyncThunk(
  "getCouponList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRedeemedCoupon();
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const redeemCouponPost = createAsyncThunk(
  "redeemCouponPost",
  async (code, { rejectWithValue }) => {
    try {
      const response = await redeemCoupon(code);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFeaturesList = createAsyncThunk(
  "getFeaturesList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await featureList();
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateProfilePost(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "addTeamMember",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addMemberData(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (data) => {
    const response = await updatePasswordPost(data);
    return response?.data;
  }
);

export const  fetchMemberList = createAsyncThunk(
  "fetchMemberList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTeamUserList();
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAgencyMemberList = createAsyncThunk(
  "fetchAgencyMemberList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAgencyUserList();
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMemberDataPost = createAsyncThunk(
  "updateMemberDataPost",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateMemberData(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMemberId = createAsyncThunk(
  "deleteMemberId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await deleteMember(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const removeAgencyUser = createAsyncThunk(
  "deleteMemberId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await removeAgencyUsers(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAgencyMember = createAsyncThunk(
  "addAgencyMember",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addAgencyMemberData(data);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
