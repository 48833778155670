import {
  createFolderPost,
  deleteKonnectFolderGet,
  getFoldersListGet,
  getKonnectsByFolderIdd,
  getKonnectsListGet,
  moveKonnectsToFolderPost,
  renameKonnectFolderPut,updateKonnectz
} from "../../api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFoldersList = createAsyncThunk(
  "getFoldersList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getFoldersListGet();
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createFolder = createAsyncThunk(
  "createFolder",
  async (folderName, { rejectWithValue }) => {
    try {
      const response = await createFolderPost(folderName);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const renameFolderCall = createAsyncThunk(
  "renameFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await renameKonnectFolderPut(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFolder = createAsyncThunk(
  "renameFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await deleteKonnectFolderGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getKonnectsList = createAsyncThunk(
  "getKonnectsList",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsListGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchKonnectz = createAsyncThunk(
  "searchKonnectz",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsListGet(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const moveKonnectsToFolder = createAsyncThunk(
  "moveKonnectsToFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await moveKonnectsToFolderPost(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getKonnectsByFolder = createAsyncThunk(
  "getKonnectsByFolder",
  async (props, { rejectWithValue }) => {
    try {
      const response = await getKonnectsByFolderIdd(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateKonnect=createAsyncThunk(
  "updateKonnect",
  async (props, { rejectWithValue }) => {
    try {
      const response = await updateKonnectz(props);
      const data = response?.data;
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
