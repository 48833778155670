import styled from '@emotion/styled';
import { Box, Button, Flex, Input } from '@chakra-ui/react';
export const BillOptionContainer = styled(Box)`
  display: flex;
  //   justify-content: space-between;
`;

//live
const proId = "pro_01hchtte5enqm964vh65mym8v7"
const teamId = "pro_01hchtvpbyqdp4hzd0m53599ec"
const topupId = "pro_01hchtwq8d5dh2w8rs356fgn6f"
const agencyId = "pro_01hchtw6vh9cg3xkx97gjnv7m0"
const ltdId = "pro_01hchtx92ev6f67ma9484zr9n3"
const chatGptId = 'pro_01hcygd42r0bzdjjv75rdvpj9y'

// //sandbox


// const proId = "pro_01ha6wq6j4txaew671frey4hzq"
// const teamId = "pro_01ha7s9chzmbzv0vzdkmmcne52"
// const topupId = "pro_01hacszt59fptremr74t1bys42"
// const agencyId = "pro_01ha9f4k1g2cns7f58q05n755w"
// const ltdId = "pro_01haht5ch09k8270h6ymeemafx"
// const chatGptId ="pro_01hcn3cbcr1fqky66hxjycdb20"
export const billOption = [
  {
    heading: 'Free',
    price: 0,
    time: 'Per Month',
    title: 'Free plan features:',
    detail: ['Unlimited Workflows', 'For Individual'],
    button: 'Try Free',
    plan_type:"Basic"
  },
  {
    heading: 'Pro',
    price: 12,
    title: 'Everything in Free, plus',
    time: 'Per Month ',
    detail: [
      'Unlimited Workflows',
      'Multi-Step',
      'Webhooks',
      'Folders',
      'Add on Applications',
      'Scheduler & Delay',
      'For Individual'
    ],
    button: 'Select Plan',
	id:proId,
  plan_type:"Basic"
  },
  {
    heading: 'Team',
    price: 15,
    title: 'Everything in Pro, plus',
    time: 'Per Month ',
    detail: [
     ' Unlimited - Team Members'
    ],
    button: 'Select Plan',
	id:teamId,
  plan_type:"Basic"
  },

  {
    heading: 'Agency',
    price: 50,
    title: 'Everything in Pro, plus',
    time: 'Per Month ',
    detail: [
     'Unlimited - Sub Accounts'
    ],
	button: 'Select Plan',
	id:agencyId,
  plan_type:"Basic"
  },
  
];
export const addOn = [
  {
    heading: 'Topup',
    price: 15,
    time: 'Per Month ',
    detail: ['Additional task on basic plan'],
	button: 'Select Plan',
  id:topupId,
  plan_type:"Addon"
  },
  {
    heading: 'ChatGPT Plugin',
    price: 5,
    time: 'Per Month ',
    detail: ['Access to KonnectzIT ChatGPT plugin'],
	button: 'Select Plan',
  id:chatGptId,
  plan_type:"Addon"
  },
];
export const LifeTimeDeal = [
  {
    heading: 'Life Time Deal',
    price: 15,
    time: 'Per Month ( saves 20% )',
    detail: ['Unlimited Workflows', 'Multi-Step','Webhooks','Folders','Add on Applications','Scheduler & Delay','For Individual'],
	button: 'Select Plan',
  plan_type:"LTD"
  },
];
export const selectStyle = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    '&:focus-visible': {
      outline: 'none',
    },
  }),
  control: (styles) => ({
    ...styles,
    '&:hover': {
      outline: 'none',
    },
    cursor:"pointer",
    margin: '3% 3%',
    transition: 'outline 0.15s',
    boxShadow: 'none',
    minHeight: '40px',
    backgroundColor: '#f3f7ff',
    border: '1px solid #b7caff',
    fontSize: 16,
  }),
  valueContainer: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles, color: '#000' }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
};