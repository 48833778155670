// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the date picker wrapper */
.custom-datepicker .react-date-picker__wrapper {

	padding:6px 4px;
	background-color: #f3f7ff;
    border: 1px solid #b7caff;
  }
  .react-date-picker__calendar .react-calendar{
	position:absolute;
  }
  .react-time-picker__wrapper{
	padding:6px 4px;
	background-color: #f3f7ff;
    border: 1px solid #b7caff;
  }
  /* .multiSelectContainer  li:hover{
	background:none !important;
	background:rgb(21, 47, 115) !important;
  } */
  /* Add any other custom styles for the date picker here */
  .dropdown {
    background-color: white;
    display: flex;
    padding: 10px;
    width: 500px;
    border-radius: 10px;
    margin-right: 85px;
flex-wrap: wrap;
gap:4%;
    border: 1px solid lightgray;
  }
  .chat {
    border: 0px solid red;
    font-size: 16px;
  
    margin: 8px;
    width: 100%;
    padding: 10px;
    background-color: #dbe5ff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .dropdown:empty {
    border: none;
  }
  
  .dropdown-row {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/node/type/style.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;;CAEC,eAAe;CACf,yBAAyB;IACtB,yBAAyB;EAC3B;EACA;CACD,iBAAiB;EAChB;EACA;CACD,eAAe;CACf,yBAAyB;IACtB,yBAAyB;EAC3B;EACA;;;KAGG;EACH,yDAAyD;EACzD;IACE,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB,eAAe;AACf,MAAM;IACF,2BAA2B;EAC7B;EACA;IACE,qBAAqB;IACrB,eAAe;;IAEf,WAAW;IACX,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,8BAA8B;EAChC;EACA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,aAAa;EACf","sourcesContent":["/* Custom styles for the date picker wrapper */\n.custom-datepicker .react-date-picker__wrapper {\n\n\tpadding:6px 4px;\n\tbackground-color: #f3f7ff;\n    border: 1px solid #b7caff;\n  }\n  .react-date-picker__calendar .react-calendar{\n\tposition:absolute;\n  }\n  .react-time-picker__wrapper{\n\tpadding:6px 4px;\n\tbackground-color: #f3f7ff;\n    border: 1px solid #b7caff;\n  }\n  /* .multiSelectContainer  li:hover{\n\tbackground:none !important;\n\tbackground:rgb(21, 47, 115) !important;\n  } */\n  /* Add any other custom styles for the date picker here */\n  .dropdown {\n    background-color: white;\n    display: flex;\n    padding: 10px;\n    width: 500px;\n    border-radius: 10px;\n    margin-right: 85px;\nflex-wrap: wrap;\ngap:4%;\n    border: 1px solid lightgray;\n  }\n  .chat {\n    border: 0px solid red;\n    font-size: 16px;\n  \n    margin: 8px;\n    width: 100%;\n    padding: 10px;\n    background-color: #dbe5ff;\n    border-radius: 10px;\n    display: flex;\n    justify-content: space-between;\n  }\n  .dropdown:empty {\n    border: none;\n  }\n  \n  .dropdown-row {\n    cursor: pointer;\n    text-align: start;\n    margin: 2px 0;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
