import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const es = {
  translation: {
    common: {
      app: 'Konnectz',
    },
    header: {
      topNavigations: {
        tipsAndTricks: 'Consejos y Trucos',
        pricing: 'Precios',
        blogs: 'Blogs',
        helpCenter: 'Centro de Ayuda',
      },
      searchAppTextPlaceholder: 'Buscar aquí...',
      createKonnectzBtnLabel: 'Crear Konnectz',
      taskhistory: 'Historial de Tareas',
      success: 'Éxito',
      error: 'Error',
      notask: 'No se encontraron Tareas',
      tryagain: 'Ajusta tus filtros y vuelve a intentarlo',
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        'Se han enviado las instrucciones para restablecer la contraseña por correo electrónico, por favor verifica tu correo.',
      SENT_RESET_INSTRUCTIONS_FAIL: '¡Solicitud no autorizada!',
      VERIFY_EMAIL_FAIL:
        'Lo siento, no es posible confirmar tu correo electrónico.',
      PASSWORD_NOT_UPDATED: 'Lo siento, no se pudo actualizar tu cuenta',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL:
        'No se pudieron enviar las instrucciones para restablecer la contraseña. Por favor, intenta nuevamente más tarde.',
      AUTH_SIGN_UP_FAIL:
        'Algo salió mal. Por favor, inténtalo de nuevo más tarde.',
      somethingWentWrong:
        'Algo salió mal. Por favor, inténtalo de nuevo más tarde.',
    },
    successMessages: {
      PASSWORD_UPDATED:
        'La contraseña ha sido actualizada. Por favor, inicia sesión con tu nueva contraseña.',
    },
    history: {
      task: {
        task: 'tarea',
        tasks: 'tareas',
        taskdate: 'fecha de tarea : ',
        user: 'Usuario : ',
        search_history: 'Buscar en el historial',
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: 'Crear Contraseña',
          subtitle: 'Escríbela DOS VECES para confirmar',
          passwordErrorMsg: 'Contraseña inválida',
          confirmPasswordErrorMsg: 'Contraseña inválida',
          passwordMatchingErrorMsg: '¡Las contraseñas no coinciden!',
          continueBtnLabel: 'CONTINUAR',
          createPasswordPlaceholder: 'Crear contraseña',
          confirmPasswordPlaceholder: 'Confirmar contraseña',
          passwordRule:
            'Sugerencia: Tu contraseña debe tener entre 8 y 15 caracteres, y debe contener al menos una mayúscula, una minúscula, un dígito y un carácter especial.',
          passwordRule1: 'Tu contraseña debe tener entre 8 y 15 caracteres.',
          passwordRule2: 'Tu contraseña debe contener al menos una mayúscula.',
          passwordRule3:
            'Tu contraseña debe contener al menos un dígito numérico.',
          passwordRule4:
            'Tu contraseña debe contener al menos un carácter especial.',
        },
        verificationSent: {
          title: 'VERIFICACIÓN',
          subtitle: 'Verificar Dirección de Correo Electrónico',
          verificationText1: 'Hemos enviado un enlace de verificación a ',
          verificationText2:
            ', abre el correo electrónico y verifica tu dirección de correo.',
          didntReceiveEmailText: '¿No recibiste ningún correo? ',
          resendText: 'Reenviar',
        },
        verifySuccess: {
          title: 'Verificación Completada',
          verifyFailedTitle: 'Verificación Fallida',
        },
        signupSuccess: {
          firstName: 'Nombre',
          firstNameErrorMsg: '¡Se requiere el Nombre!',
          lastName: 'Apellido',
          lastNameErrorMsg: '¡Se requiere el Apellido!',
          verifyErrorMsg:
            'Algo salió mal. Por favor, intenta iniciar sesión si tu cuenta ya ha sido verificada.',
        },
        title: 'Crea Tu Cuenta',
        subtitle: '¡Hola, Bienvenido!',
        verifyingMessage: 'Verificando',
        emailInvalid: 'El correo electrónico no es válido',
        emailExists:
          'La dirección de correo electrónico que has ingresado ya está registrada',
        teamSignup: '¿Registrándote como un equipo?',
        continueBtnLabel: 'Continuar',
        alreadyHaveAccountText: '¿Ya tienes una cuenta? ',
        loginBtnLabel: 'Iniciar Sesión',
        emailPlaceholder: 'Ingresa Correo Electrónico',
        features: {
          feature1: {
            title: 'Automatización Sencilla',
            image: SimpleAutomation,
            description:
              'Construye Flujos de Trabajo Complejos de manera simple y fácil en minutos usando nuestro constructor visual de conexiones',
          },
          feature2: {
            title: 'Plataforma para Todos',
            image: PlatformForEveryone,
            description:
              'No es una herramienta de automatización regular solo para programadores, cualquiera puede construir automatizaciones con KonnectzIT',
          },
          feature3: {
            title: 'Integración de Aplicaciones Fluida',
            image: SeamlessAppsIntegration,
            description:
              'Conecta aplicaciones ilimitadas de nuestra biblioteca de integraciones y automatiza tareas diarias',
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          'Algo salió mal. Por favor, intenta iniciar sesión si tu cuenta ya ha sido verificada',
      },
      login: {
        title: 'Iniciar Sesión en Tu Cuenta',
        subtitle: '¡Hola, Bienvenido de nuevo!',
        emailPlaceholder: 'Ingresa correo electrónico',
        emailInvalid: '¡Correo electrónico inválido!',
        passwordRequired: '¡Se requiere la contraseña!',
        passwordPlaceholder: 'Ingresa contraseña',
        rememberMe: 'Recuérdame',
        loginBtnLabel: 'INICIAR SESIÓN AHORA',
        forFirstTimeSignup: '¿Por primera vez?',
        forgotPasswordLbl: '¿Olvidaste la Contraseña?',
        alreadyHaveAccountText: '¿No tienes una cuenta? ',
        clickingContinueText: 'Al hacer clic en Continuar, aceptas nuestros ',
        termsAndConditionsText: 'Términos y Condiciones.',
        signupNowText: 'Regístrate',
        features: {
          feature1: {
            title: 'Pasos Ilimitados',
            image: UnlimitedSteps,
            description:
              'Conecta múltiples aplicaciones y crea pasos ilimitados para una automatización fluida.',
          },
          feature2: {
            title: 'Visualiza Todo',
            image: VisualizeEverything,
            description:
              'Crea tus flujos de trabajo y visualiza la automatización utilizando un constructor visual de conexiones fácilmente.',
          },
          feature3: {
            title: 'Biblioteca de Integración',
            image: IntegrationLibrary,
            description:
              'Colección de aplicaciones en la nube para fusionar y enviar datos entre ellas y ahorrar tiempo.',
          },
        },
        loginFailMsg: '¡Nombre de usuario o contraseña inválidos!',
        captchaErrorMsg: 'Error de Captcha',
      },
      forgot: {
        title: '¿Olvidaste la Contraseña?',
        forgtoBtnLabel: 'Obtener enlace de olvido de contraseña',
        passwordLink: 'Hemos enviado un enlace de olvido de contraseña a',
        successSubTitle: 'Correo Electrónico Enviado',
        features: {
          feature1: {
            title: 'Automatización Sencilla',
            image: SimpleAutomation,
            description:
              'Construye Flujos de Trabajo Complejos de manera simple y fácil en minutos usando nuestro constructor visual de conexiones',
          },
          feature2: {
            title: 'Plataforma para Todos',
            image: PlatformForEveryone,
            description:
              'No es una herramienta de automatización regular solo para programadores, cualquiera puede construir automatizaciones con KonnectzIT',
          },
          feature3: {
            title: 'Integración de Aplicaciones Fluida',
            image: SeamlessAppsIntegration,
            description:
              'Conecta aplicaciones ilimitadas de nuestra biblioteca de integraciones y automatiza tareas diarias',
          },
        },
        emailInvalid: '¡Correo electrónico inválido!',
      },
      reset: {
        title: 'Restablecer Contraseña',
        subTitle: 'Crear nueva contraseña',
      },
    },
    konnect: {
      sidebar: {
        search_apps: 'Buscar en Aplicaciones',
        search_apps_endList: '¡Genial! ¡Has visto todo!',
        add_on_apps: 'Aplicaciones Adicionales',

        integrated_apps: 'Aplicaciones Integradas',
        choose_trigger: 'Elegir Desencadenante',
        choose_action: 'Seleccionar acción',
        textFormater: {
          formaterText: "Ingresa '@' y selecciona los valores",
        },
        scheduler: {
          userTimeInput: 'Ingresa el valor en minutos u horas o días',
          selectDate: 'Seleccionar Fecha',
          select: 'Seleccionar',
          test_review: 'Probar y Revisar',
          time: 'Tiempo',
          dates: 'Fechas',
          date: 'Fecha',
          days: 'Días',
          everyday_time: 'Hora Diaria',
        },
        responseModal: {
          searchByLable: 'Buscar por Etiqueta',
          no_labelFound: 'No se encontraron etiquetas',
          nodeInner_title: 'Seleccionar Más Valores',
          labelText: 'Etiqueta:',
        },
        coupon_generator: {
          numberPlaceholder: 'Número',
        },
        choose_trigger_header: 'Elegir Evento de Aplicación',
        no_triggers_available: 'No hay desencadenantes disponibles',
        choose_format_header: 'Opción de Formato',
        choose_config_header: 'Elegir Configuración',
        select_trigger_action_dropdown_header: 'Seleccionar',
        choose_option_header: 'Definir Opción',
        no_options_available: 'No hay opciones disponibles',
        choose_linked_account: 'Seleccionar Cuenta',
        customize_form_header: 'Configurar Formulario',
        customize_form_partial: 'Elegir ',
        configure_response: 'Personalizar Respuesta',
        basic_auth: 'Autenticación Básica',
        api_key: 'Clave API',
        api_token: 'Token API',
        webhook_url: 'URL de Webhook',
        captureWebhookResponse: 'Capturar Respuesta del Webhook',
        hereText: 'Aquí',
        webhook_instructions: 'Instrucciones de Webhook - Haz clic',
        webhook_url_toast: 'URL de Webhook copiada al portapapeles',
        webhook_response: 'Respuesta del Webhook',
        choose_action_event: 'Elegir Acción',
        endpoint_url: 'URL de Punto Final',
        payload_type: 'Tipo de Carga',
        wrap_request_array: 'Envolver Solicitud en Matriz',
        set_params: 'Establecer Parámetros',
        add_header: 'Agregar Encabezado',
        delayLabel: 'Retraso',
        delayText: 'Cuánto retraso deseas',
        set_paramter: 'Establecer Parámetro',
        expiry_time: 'Cuenta de Línea de Tiempo',
        expiry_val: 'Línea de Tiempo',
        segmentIndex: 'Índice de Segmento',
        validate: 'Validar',
        generate: 'Generar',
      },
      tobar: {
        cancelBtnLabel: 'Cancelar',
        inputLeftElm_placeholder:
          'Buscar en 1000+ Aplicaciones Integradas o Aplicaciones Adicionales',
        redoBtnLabel: 'REHACER',
        undoBtnLabel: 'DESHACER',
      },
      UI: {
        betaText: 'Beta',
        webhookDuplicateToastMsg: '¡La aplicación de Webhook ya existe!',
      },
      header: {
        backBtnLabel: 'Atrás',
        saveBtnLabel: 'Guardar',
        publishBtnLabel: 'Publicar',
        nameOfKonnectz: 'Nombre del',
      },
      testAndReview: {
        btnLabel: 'Probar y Revisar',
        taskAvailableLabel: 'Tarea Disponible',
        taskConsumbedLabel: 'Tarea Consumida',
        taskCnsumptionLabel: 'Consumo de Tarea',
      },
      proceed: {
        btnLabel: 'CONTINUAR',
      },
      additionalConfigsModal: {
        cancelBtnLabel: 'Cancelar',
        okBtnLabel: 'Aceptar',
      },
      conditions: {
        conditionText: 'Condición',
        addNewConditionBtnLabel: 'Agregar Nueva Condición +',
        cancelBtnLabel: 'Cancelar',
        okBtnLabel: 'Aceptar',
        addBtnLabel: 'Agregar',
        operatorAndLabel: 'Y',
        operatorOrLabel: 'O',
        headerConditionText: 'Condición',
        selectFieldHeaderTitle: 'Seleccionar Campo',
        selectConditionHeaderTitle: 'Seleccionar Condición',
        validateBtnLabel: 'Validar',
        conditionsHeaderText: 'Condiciones',
      },
      publishSuccessMsg: '¡Konnect se publicó exitosamente!',
      saveSuccessMsg: '¡Konnect se guardó exitosamente!',
    },
    apps: {
      authenticatedAppsTitle: 'Aplicaciones Autenticadas',
      aunthenticateNowTitle: 'Autenticar Ahora',
      noAppsToAunthenticate:
        'No hay aplicaciones para autenticar en este momento.',
      authenticateNow:
        'No tienes ninguna aplicación autenticada. Autentica ahora.',
      addAccountText: 'Agregar Cuenta',
      betaText: 'Beta',
      integratedApps: 'APLICACIONES INTEGRADAS',
      followInstrusctionText:
        'Por favor, sigue las instrucciones para más referencia',
      oAuthHandlerText: 'Controlador OAuth de KonnectzIT, Por favor espera...',
      cancelBtnText: 'Cancelar',
      newsLable: 'Noticias',

      yesContinueText: 'Sí, continuar',
      requiredText: '(Requerido)',
      accessAPIModalHeaderText1:
        '¿Permitir que KonnectzIT acceda a la cuenta de ',
      accessAPIModalHeaderText2: '?',
      accessAPIModalMainText1: ' ',

      accessAPIModalMainText2: ' está disponible en',
      accessAPIModalMainText3: ' página de integración',
      authorized: 'Aplicaciones Autorizadas',
    },
    konnectzList: {
      homeText: 'Inicio',

      allText: 'Todo',
      searchText: 'Buscar',
      createkonnectzLabel: 'Crear',

      noKonnectzInfo: 'No hay konnectz en este momento.',
      deleteModalBodyText: '¿Estás seguro de que deseas eliminar?',
      deleteModalNoText: 'No',
      deleteModalDeleteText: 'Eliminar',
      folders: {
        createFolderTitleText: 'Nombre de la Carpeta',
        enterFolderName: 'Ingresa el nombre de la carpeta',
        fodersLeftTitleTextL: 'Carpetas',
        createFolderInputPlaceholder: 'Ingresa el nombre de la carpeta',
        createFolderBtn: 'Crear',
        nameTitle: 'Nombre',
        noKonnectz: 'Parece que no tienes',
        deleteFolderConfirmationText:
          '¿Estás seguro de que deseas eliminar esta carpeta?',
        cancelBtn: 'Cancelar',
        newFolder: 'Nueva Carpeta',
        deleteBtn: 'Eliminar',
        selectFolderInfo:
          'Selecciona la carpeta a la que deseas mover tu archivo',
        moveFolderCancelBtn: 'Cancelar',
        moveBtn: 'Mover Carpeta',
        renameSaveBtn: 'Renombrar Carpeta',
        renameFolder: 'Renombrar',
        renameYourFolderText: 'Renombra tu carpeta',
        createFolderBtn: 'Crear Carpeta',
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: 'Aplicaciones Más Recientes',
        },
        totalKonnectz: {
          headerTitle: 'Total de Konnectz',
          activeKonnectz: 'Konnectz Activos',
          inactiveKonnectz: 'Konnectz Inactivos',
        },
        latestKonnectz: {
          headerTitle: 'Konnectz Más Recientes',
          vieawAll: 'Ver Todos',
        },
        subscription: {
          subscriptionTitle: 'Suscripción',
          contentText: 'Desbloquea más potencia al actualizar a Pro',
          applyNewCoupon: 'Aplicar Nuevo Cupón',
          applyBtnLabel: 'Aplicar',
          featuresIncludedLabel: 'Funciones Incluidas',
          upgradeBtnLabel: 'ACTUALIZAR AHORA',
          unlockMoreUpgradingText: 'Desbloquea más potencia al actualizar',
        },
        taskConsumption: {
          headerTitle: 'Consumo de Tareas',
        },
      },
    },

    sideNav: {
      homeLabel: 'Inicio',
      appsLabel: 'Aplicaciones',
      konnectzLabel: 'Konnectz',
      historyLabel: 'Historial',
      historyCapsLabel: 'Historial',
      accountLabel: 'Cuenta',
      notificationsLabel: 'Notificaciones',
      logoutLabel: 'Cerrar sesión',
      getHelpLabel: '¿Necesitas ayuda?',
      helpModalText: 'Mantente al tanto. ¡Próximamente!',
      konnectzITLogoText: 'KonnectzIT',
    },
    account: {
      myProfileText: 'Mi Perfil',
      billingtext: 'Dirección de Facturación',
      emailAddressLabel: 'Dirección de Correo Electrónico',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      subAccountsLabel: 'Subcuentas',
      firstNameRequiredText: '¡Se requiere el nombre!',
      lastNameRequiredText: '¡Se requiere el apellido!',
      countryRequiredText: '¡Se requiere el país!',
      addressLine1RequiredText: '¡Se requiere la dirección (línea 1)!',
      cityRequiredText: '¡Se requiere la ciudad!',
      stateRequiredText: '¡Se requiere el estado!',
      zipCodeRequiredText: '¡Se requiere el código postal!',
      taxIdRequiredText: '¡Se requiere el NIF!',
      companyNameLabel: 'Nombre de la Empresa',
      currentPositionLabel: 'Cargo Actual',
      countryLabel: 'País',
      addressLabel: 'Dirección',
      cityLabel: 'Ciudad',
      stateLabel: 'Estado',
      zipCodeLabel: 'Código Postal',
      taxIdLabel: 'NIF',
      timeZoneLabel: 'Zona Horaria',
      changeLanguageLabel: 'Seleccionar Idioma',
      changePasswordHeaderText: 'Cambiar Contraseña',
      currentPasswordLabel: 'Contraseña Actual',
      currentPasswordRequiredText: '¡Se requiere la contraseña actual!',
      newPasswordLabel: 'Nueva Contraseña',
      newPasswordRequiredText: '¡Se requiere la nueva contraseña!',
      confirmPasswordLabel: 'Confirmar Contraseña',
      confirmPasswordRequiredText: '¡Se requiere confirmar la contraseña!',
      updateBtnLabel: 'Actualizar',
      editUpdateAccountSideBarText: 'Editar/Actualizar Cuenta',
      changePasswordSideBarText: 'Cambiar Contraseña',
      billingText: 'Facturación',
      teamText: 'Equipo',
      agencyText: 'Agencia',
      sideBarHeaderAccountText: 'Cuenta',
      profileLable: 'Perfil',
      profileUpdatedSuccessfullyText: '¡Perfil actualizado con éxito!',
      addTeamMemberText: 'Agregar Miembro al Equipo',
      addSubAccountsText: 'Agregar Subcuentas',

      billing: {
        couponCodeText: 'Código',
        applyCodeBtnLbl: 'Aplicar código',
        tasksText: 'Tareas',
        promotionalTasksText: 'Tareas Promocionales',
        redeemedOnText: 'Canjeado el',
        expiresOnText: 'Expira el',
        noCouponsAppliedText: 'Sin licencia aplicada',
        plansAndFeaturesText: 'Planes y Características',
        currentPlanText: 'Plan Actual',
        upgradeText: 'Actualizar',
        billingText: 'Facturación',
        licenseerror: '¡Licencia inválida!',
        completionofprofile:
          'Por favor, completa tu perfil antes de canjear el código. Esto es necesario para GPDR/Procesamiento de Datos y Facturación',
      },
      team: {
        teamLabel: 'Equipo',
        name: 'Nombre',
        firstName: 'Nombre',
        lastName: 'Apellido',
        access: 'Acceso',
        email: 'Correo Electrónico',
        addBtn: 'Agregar',
        cancelBtn: 'Cancelar',
        assignAccessLbl: 'Asignar Acceso',
        noUsersInfo: 'No hay usuarios disponibles',
        collapseTeamLabel: 'Colapsar Equipo',
        expandTeamLabel: 'Expandir Equipo',
        teamMembersHeaderText: 'Miembros del Equipo',
      },
      agency: {
        agencyLabel: 'Agencia',
        name: 'Nombre del Cliente:',
        firstName: 'Nombre',
        lastName: 'Apellido',
        tasks: 'Tareas Asignadas:',
        consumed: 'Tareas Consumidas',
        agencyConsumed: 'Consumidas',
        remainingLabel: 'Restantes',
        totalSubaccounts: 'Total de Subcuentas:',
        email: 'Correo Electrónico:',
        active: 'Activo:',
        inActive: 'Inactivo:',
        addBtn: 'Agregar',
        addMember: 'Agregar Miembro',
        deleteMember: 'Eliminar Miembro',
        cancelBtn: 'Cancelar',
        editMemberLabel: 'Editar Miembro',
        noAgenciesInfo: 'No hay agencias disponibles',
        agencyMembersHeaderText: 'Subcuentas',
        total: 'Total de Tareas:',
        update: 'Actualizar',
        saveChangeLabel: 'Guardar Cambios',
        updateTask: 'Actualizar Tarea',
        delete: 'Eliminar',
        available: 'Tareas Disponibles',
        deactivate: 'Activar',
        totalAssigned: 'Total Asignado',
        activate: 'Desactivar',
        detailsLabel: 'Detalles',
        subaccounterror: '¡Error al actualizar los detalles de la subcuenta!',
      },
      reseller: {
        resellerLabel: 'Revendedor',
      },
    },
  },
};
