import React from "react";
import { DashboardContainer } from "../features/dashboard";
import { Route, Routes } from "react-router-dom";
import { AuthShell, NonAuthShell } from "../components/shell";
import { LoginContainer } from "../features/auth/login";
import { VisualStudio } from "../features/visualstudio";
import { SignUpContainer } from "../features/auth/signup";
import { ForgotPasswordContainer } from "../features/auth/forgot-password";
import { VerifyEmailContainer } from "../features/auth/verify-email";
import { ResetPasswordContainer } from "../features/auth/reset-password";
import { AppsContainer } from "../features/apps";
import { KonnectzContainer } from "../features/konnectz";
import { HistoryContainer } from "../features/history";

import { Account, AccountContainer } from "../features/account";
import { ChatGPT } from "../features/chatGpt";
import { AppsOauthContainer } from "../features/apps/AppsOauthContainer";
import { ManageSubscription } from "../features/manage-subscription";

const AUTH_SHELL_ROUTES = [
  {
    path: "/dashboard",
    key: "DASHBOARD",
    component: DashboardContainer,
  },
  {
    path: "/apps",
    key: "APPS",
    component: AppsContainer,
  },
  {
    path: "/history",
    key: "HISTORY",
    component: HistoryContainer,
  },
  {
    path: "/notifications",
    key: "NOTIFICATIONS",
    component: DashboardContainer,
  },
  {
    path: "/account",
    key: "ACCOUNT",
    component: AccountContainer,
  },
  {
    path: "/konnectz",
    key: "Canvas",
    component: KonnectzContainer,
  },
  {
    path: "/manage-subscription",
    key: "Manage-subscription",
    component: ManageSubscription,
  },
  {
    path: "/chatgpt",
    key: "Chatgpt",
    component: ChatGPT,
  },
];

const NON_AUTH_ROUTES = [
  { path: "/", key: "ROOT", component: LoginContainer },
  {
    path: "/login",
    key: "APP_LOGIN",
    component: LoginContainer,
  },
  {
    path: "/logout",
    key: "APP_LOGOUT",
    component: LoginContainer,
  },
  {
    path: "/signup",
    key: "APP_SIGNUP",
    component: SignUpContainer,
  },
  {
    path: "/verify-email/:token",
    key: "APP_VERIFY_EMAIL",
    component: VerifyEmailContainer,
  },
  // {
  //   path: "/signup-success",
  //   key: "APP_SIGN_UP_SUCCESS",
  //   // component: DashboardContainer,
  // },
  {
    path: "/forgot-password",
    key: "APP_FORGOT_PASSWORD",
    component: ForgotPasswordContainer,
  },
  {
    path: "/reset-password/:token",
    key: "APP_RESET_PASSWORD",
    component: ResetPasswordContainer,
  },
];
const AUTH_NON_SHELL_ROUTES = [
  { path: "/konnect", key: "Reactflow", exact: true, component: VisualStudio }, //to canvas
  {
    path: "/konnect/:id",
    key: "Reactflow",
    exact: true,
    component: VisualStudio,
  }, //to particular konnect
];

export const RenderRoutes = () => {
  return (
    <Routes>
      <Route element={<NonAuthShell />}>
        {NON_AUTH_ROUTES.map((route, i) => {
          return (
            <Route key={i} path={route.path} element={<route.component />} />
          );
        })}
      </Route>
      <Route element={<AuthShell />}>
        {AUTH_SHELL_ROUTES.map((route, i) => {
          return (
            <Route key={i} path={route.path} element={<route.component />} />
          );
        })}
      </Route>
      <Route path="/konnect" element={<VisualStudio />} />
      <Route path="/konnect/:id" element={<VisualStudio key="Edit"/>} />
      <Route path="/oauth/response/:appId/:token" element={<AppsOauthContainer  />} />
      <Route path="/oauth/response" element={<AppsOauthContainer  />} />

    </Routes>
  );
};
