import React, { useState } from "react";
import { bigSVG, svg } from "../../../assets/objectSVG";
import { useFormik } from "formik";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  Flex,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Text,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NodeLoader } from "../../../components/loader";
import forgotFeatures from "../../../components/carousel/features/forgot-features.json";
import { Carousel } from "../../../components/carousel";
import { userForgotPassword } from "../../../store/thunk/authThunk";

export const ForgotPasswordContainer = () => {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.auth);
  const [state, setState] = useState("forgot-password");
  const [email, setEmail] = useState("");

  return (
    <>
      <Flex
        width="65%"
        sx={{
          p: "3% 0px 3% 3%",
          position: "relative",
          zIndex: 0,
        }}
      >
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          loop={true}
          style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {forgotFeatures?.features.map((feature, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-start",
                }}
              >
                <Carousel t={t} feature={feature} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
      <Flex
        width="35%"
        sx={{
          m: "3%",
          position: "relative",
          zIndex: 2,
          borderRadius: "8px",
          flexDirection: "column",
          gap: "10px",
          p: "3%",
          backgroundColor: "rgb(21,46,115)",
          boxShadow: "0px 0px 15px -1px rgb(21,46,115,0.3)",
        }}
      >
        <NodeLoader loading={loading} stroke="#fff" />
        <Flex sx={{ flexDir: "column", gap: "5px" }}>
          <Flex sx={{ m: "0px auto", gap: "5px" }}>
            <Flex
              sx={{
                m: "0px auto",
                width: ["20px", "22px", "24px", "26px", "26px"],
              }}
            >
              {svg.invertedLogo}
            </Flex>
            <Flex
              sx={{
                color: "#fff",
                fontSize: [18, 20, 22, 24, 26],
                fontWeight: 500,
              }}
            >
              KonnectzIT
            </Flex>
          </Flex>
          <Flex
            height="2px"
            width="100%"
            sx={{ bgColor: "#fff", borderRadius: "50%" }}
          />{" "}
        </Flex>
        <Flex
          sx={{
            color: "#fff",
            fontSize: [24, 26, 26, 28, 30],
            fontWeight: 500,
            justifyContent: "center",
          }}
        >
          {t("auth.forgot.title")}
        </Flex>
        {state === "forgot-password" ? (
          <>
            <Flex sx={{ m: "20px auto 40px auto" }}>
              {bigSVG.forgotPassword}
            </Flex>
            <ForgotPasswordForm
              setState={setState}
              loading={loading}
              setEmail={setEmail}
            />
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.alreadyHaveAccountText")}
                <NavLink
                  to={"/signup"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  {t("auth.login.signupNowText")}
                </NavLink>
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}
                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </>
        ) : (
          <>
            <Flex sx={{ m: "20px auto 20px auto" }}>
              {bigSVG.verificationSent}
            </Flex>
            <Text sx={{ color: "#fff", textAlign: "center" }}>
              {t("auth.forgot.passwordLink")}
              <span
                style={{
                  paddingLeft: "4px",
                  fontWeight: 600,
                  textDecoration: "underline",
                }}
              >
                {email}
              </span>
            </Text>
            <Flex
              sx={{
                m: "10% auto 0px auto",
                flexDirection: "column",
                gap: "20px",
                textAlign: "center",
                fontSize: [14, 15, 15, 16, 18],
              }}
            >
              <Flex
                sx={{
                  color: "#fff",
                  m: "0px auto",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.signup.alreadyHaveAccountText")}
                <NavLink
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  Sign In
                </NavLink>
              </Flex>
              <Flex
                sx={{
                  color: "#fff",
                  //   m: "0px auto",
                  flexDirection: "column",
                  fontSize: [14, 15, 15, 16, 18],
                }}
              >
                {t("auth.login.clickingContinueText")}

                <NavLink
                  target="_blank"
                  to={"https://konnectzit.com/terms-and-conditions/"}
                  style={{
                    textDecoration: "none",
                    color: "#f7c544",
                    paddingLeft: "5px",
                  }}
                >
                  {t("auth.login.termsAndConditionsText")}
                </NavLink>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

const ForgotPasswordForm = ({ loading, setState, setEmail }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    const payload = {
      email: formik.values.email,
    };
    dispatch(userForgotPassword(payload)).then((res) => {
      if (!res.error) {
        if (res.payload.key === "SENT_RESET_INSTRUCTIONS_FAIL") {
          toast({
            position: "top",
            status: "error",
            variant: "solid",
            title: "User doesn't exist!",
            duration: 2500,
            containerStyle: {
              fontWeight: 400,
            },
          });
        } else {
          setEmail(payload.email);
          setState("mail-sent");
        }
      } else {
        toast({
          position: "top",
          status: "error",
          variant: "solid",
          title: res.payload.message,
          duration: 2500,
          containerStyle: {
            fontWeight: 400,
          },
        });
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <form
        onSubmit={!loading ? formik.handleSubmit : console.log("Resting")}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <FormControl>
          <InputGroup
            size={{
              base: "md",
              sm: "md",
              md: "md",
              lg: "md",
              xl: "lg",
              "2xl": "lg",
            }}
          >
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="rgb(21,45,115)" boxSize="1em" />
            </InputLeftElement>
            <Input
              id="email"
              name="email"
              type="email"
              required
              autoComplete="off"
              placeholder={t("auth.signup.emailPlaceholder")}
              variant="outlined"
              sx={{ borderRadius: "4px", fontSize: [14, 15, 15, 16, 18] }}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </InputGroup>
        </FormControl>
        <Button
          type="submit"
          width="full"
          variant="outline"
          size={{
            base: "md",
            sm: "md",
            md: "md",
            lg: "md",
            xl: "lg",
            "2xl": "lg",
          }}
          sx={{
            borderTop: "1px solid #f7c544",
            borderRight: "1px solid #f7c544",
            borderLeft: "1px solid #f7c544",
            borderBottom: "4px solid #f7c544",
            fontSize: [14, 15, 15, 16, 18],
            borderRadius: "4px",
            color: "#f7c544",
            "&:hover": {
              color: "rgb(21,46,115)",
              borderTop: "1px solid #fff",
              borderRight: "1px solid #fff",
              borderLeft: "1px solid #fff",
              borderBottom: "4px solid #fff",
            },
          }}
        >
          {t("auth.forgot.forgtoBtnLabel")}
        </Button>
      </form>
    </div>
  );
};
