import { Flex, ScaleFade } from "@chakra-ui/react";
import React from "react";
import { InstructionModal } from "./InstructionModal";
import { ResponseModal } from "./ResponseModal";
import { HelpModal } from "./HelpModal";
import { GSheetModal } from "./GSheetModal";
import { AccountModal } from "./AccountModal";
import { useSelector } from "react-redux";
import { ApiModal } from "./ApiModal";
import { m } from "framer-motion";
import { ConditionsModal } from "./ConditionsModal";
import { DelayModal } from "./delayModal";
import { useTranslation } from "react-i18next";
import { ForwarderModal } from "./ForwarderModal";
import { ParserModal } from "./ParserModal";

export const ModalContainer = React.memo(
  ({
    data,
    modalState,
    setModalState,
    nodeOuter,
    setExpand,
    setExpandHeight,
    operatorList,
  }) => {
    const { t } = useTranslation();
    return (
      <ScaleFade
        initialScale={0.5}
        in={modalState.display}
        style={{
          position: "absolute",
          top: "46px",
          zIndex: modalState.display ? 10 : -2,
          bgColor: "#fff",
          bottom: "0px",
          left: "0px",
          right: "0px",
        }}
      >
        {modalState.type === "instructions" ? (
          <InstructionModal
            data={data.webhook_instructions}
            setModalState={setModalState}
          />
        ) : modalState.type === "Additional" ? (
          <ResponseModal
            responses={data.configResponses}
            setModalState={setModalState}
            parentnode={nodeOuter}
            nodeId={data.nodeId}
            type="Additional"
          />
        ) : modalState.type === "Normal" ? (
          <ResponseModal
            responses={data.configResponses}
            setModalState={setModalState}
            parentnode={nodeOuter}
            nodeId={data.nodeId}
            type="Normal"
          />
        ) : modalState.type === "accounts" ? (
          <AccountModal
            setExpand={setExpand}
            setModalState={setModalState}
            setExpandHeight={setExpandHeight}
            parentnode={nodeOuter}
            data={data}
            nodeId={data.nodeId}
            type="inModal"
          />
        ) : modalState.type === "GSheetModal" ? (
          <GSheetModal
            data={data}
            setModalState={setModalState}
            parentnode={nodeOuter}
            nodeId={data.nodeId}
          />
        ) : modalState.type === "ApiModal" ? (
          <ApiModal
            data={data}
            setModalState={setModalState}
            setExpand={setExpand}
            modalState={modalState}
            setExpandHeight={setExpandHeight}
          />
        ) : modalState.type === "ConditionModal" ? (
          <ConditionsModal
            data={data}
            setModalState={setModalState}
            setExpand={setExpand}
            modalState={modalState}
            setExpandHeight={setExpandHeight}
            parentnode={nodeOuter}
          />
        ) : modalState.type === "DelayModal" ? (
          <DelayModal
            setModalState={setModalState}
            setExpand={setExpand}
            modalState={modalState}
            parentnode={nodeOuter}
            setExpandHeight={setExpandHeight}
            data={data}
          />
        ) :modalState.type === "ForwarderModal"?(
          <ForwarderModal
          setModalState={setModalState}
          setExpand={setExpand}
          modalState={modalState}
          nodeOuter={nodeOuter}
          setExpandHeight={setExpandHeight}
          data={data}
        />

        ):modalState.type === "ParserModal"?(
          <ParserModal
          setModalState={setModalState}
          setExpand={setExpand}
          modalState={modalState}
          nodeOuter={nodeOuter}
          setExpandHeight={setExpandHeight}
          data={data}
        />

        ): (
          <HelpModal
            data={t("sideNav.helpModalText")}
            setModalState={setModalState}
          />
        )}
      </ScaleFade>
    );
  }
);
