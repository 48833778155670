import React from "react";
import { Flex } from "@chakra-ui/react";
import { EventWrapper } from "../../styled";

export const Divider = React.memo(({ visibility, bgColor, tested }) => {
  return (
    <EventWrapper visibility={visibility}>
      <Flex
        height="3px"
        width="100%"
        sx={{
          bgColor: bgColor ? bgColor : "#162c73",
          borderRadius: "50%",
        }}
      />
    </EventWrapper>
  );
});
