import React from "react";
import { Button, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ConditionItem } from "./Components/ConditionItem";
import {  addNewCondition,updateConditions,updateConditionsOnCancel} from "../../store/slice/canvasSlice";
import { useDispatch } from "react-redux";
export const ConditionsModal = ({
  parentnode,
  setModalState,
  onCancel = () => {
    setModalState({ display: false, data: null });
  },
  data,
  setExpand,
  setExpandHeight,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        p: "16px",
        flexDirection: "column",
        bgColor: "#fff",
        borderBottomRightRadius: "4px",
        height: "100%",
        borderBottomLeftRadius: "4px",
        justifyContent: "space-between",
        overflowY: "auto",
      }}
    >
      <Flex sx={{ flexDirection: "column" }}>
        {data.conditions &&
          data.conditions.map((condition, i) => (
            <ConditionItem
              condition={condition}
              itemIndex={i}
              parentNode={parentnode}
              fromFields={data.fromFields}
              operatorList={data.operatorList}
              showQueryOperators={
                data.conditions.length > 0 && i !== data.conditions.length-1
              }
              conditionsList={data.conditionsList}
              nodeId={data.nodeId}
              data={data}
            />
          ))}
        <Box
          onClick={()=>{dispatch(addNewCondition({nodeId:data.nodeId}))}}
          sx={{ fontSize: "12px", p: "10px", cursor: "pointer" }}
        >
          {t("konnect.conditions.addNewConditionBtnLabel")}
        </Box>
      </Flex>

      <Flex
        height="8%"
        minHeight="30px"
        maxHeight="30px"
        sx={{ justifyContent: "center", gap: "10px" }}
      >
        <Button
          onClick={() => {
            dispatch(updateConditions({nodeId:data.nodeId}));
            setExpand(false);
            setExpandHeight(false)
            onCancel();
          }}
          sx={{
            bg: "#152d73",
            color: "#fff",
            "&:hover": {
              color: "rgb(247,197,68)",
              bg: "#152d73",
            },
          }}
        >
          {t("konnect.conditions.okBtnLabel")}
        </Button>
        <Button
          onClick={() => {
            dispatch(updateConditionsOnCancel({nodeId:data.nodeId}));

            setExpand(false);
            setExpandHeight(false)
            onCancel();
          }}
          sx={{
            bg: "#152d73",
            color: "#fff",
            "&:hover": {
              color: "red",
              bg: "#152d73",
            },
          }}
        >
          {t("konnect.conditions.cancelBtnLabel")}
        </Button>
      </Flex>
    </Flex>
  );
};
