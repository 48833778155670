import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cancelPlans, paddleAuthh, planPrices, productDetails, subscriptionDetail, updatePlans } from '../api-client';


export const subscriptionDetails = createAsyncThunk(
	'subscription',
	async (id) => {
	  try {
		const response = await subscriptionDetail(id);
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  export const paddleAuth = createAsyncThunk(
	'paddleAuth',
	async () => {
	  try {
		const response = await paddleAuthh();
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  export const planPrice = createAsyncThunk(
	'plan',
	async (props) => {
	  try {
		const response = await planPrices(props);
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  export const updatePlan = createAsyncThunk(
	'update_plan',
	async (props) => {
	  try {
		const response = await updatePlans(props);
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  export const cancelPlan = createAsyncThunk(
	'cancel_plan',
	async (props) => {
	  try {
		const response = await cancelPlans(props);
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  export const productDetail = createAsyncThunk(
	'product_details',
	async (props) => {
	  try {
		const response = await productDetails(props);
		const data = response?.data;
		return data;
	  } catch (err) {
		console.log(err);
	  }
	}
  );
  
  const initialState = {
	user_details: [],
	loading: false,
    plan:[],
	token:[],
	cancel_plan:"",
	product_details:"",
	updateLoading:false
  };
  
  const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
	  builder
		.addCase(subscriptionDetails.pending, (state, action) => {
		  state.loading = true;
		})
		.addCase(subscriptionDetails.fulfilled, (state, action) => {
		  state.loading = false;
		  state.user_details = action.payload;
		})
		.addCase(subscriptionDetails.rejected, (state, action) => {
			state.loading = false;
		  })
		  .addCase(paddleAuth.pending, (state, action) => {
			state.loading = true;
		  })
		  .addCase(paddleAuth.fulfilled, (state, action) => {
			state.loading = false;
			state.token = action.payload;
		  })
		  .addCase(paddleAuth.rejected, (state, action) => {
			  state.loading = false;
			})
		  .addCase(planPrice.pending, (state, action) => {
			state.loading = true;
		  })
		  .addCase(planPrice.fulfilled, (state, action) => {
			state.loading = false;
			state.plan = action.payload;
		  })
		  .addCase(planPrice.rejected, (state, action) => {
			  state.loading = false;
			})
			.addCase(cancelPlan.pending, (state, action) => {
				state.loading = true;
			  })
			  .addCase(cancelPlan.fulfilled, (state, action) => {
				state.loading = false;
			
				state.cancel_plan = action.payload;
			  })
			  .addCase(cancelPlan.rejected, (state, action) => {
				  state.loading = false;
				})
				.addCase(productDetail.pending, (state, action) => {
					state.loading = true;
				  })
				  .addCase(productDetail.fulfilled, (state, action) => {
					state.loading = false;
					state.product_details = action.payload;
					
				  })
				  .addCase(productDetail.rejected, (state, action) => {
					  state.loading = false;
					})
					.addCase(updatePlan.pending, (state, action) => {
						state.updateLoading = true;
					  })
					  .addCase(updatePlan.fulfilled, (state, action) => {
						state.updateLoading = false;
						// state.product_details = action.payload;
						
					  })
					  .addCase(updatePlan.rejected, (state, action) => {
						  state.updateLoading = false;
						})
	},
  });
  
  export const subscriptionReducer = subscriptionSlice.reducer;
  