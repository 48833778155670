import React, { useCallback, useRef, useState, memo } from "react";
import { EventWrapper, NodeOuter, CaptureButton } from "../../styled";
import { NodeLoader } from "../../loader";
import { AppInfo } from "../ui/AppInfo";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "../ui/Selector";
import { Flex, Tooltip, useToast } from "@chakra-ui/react";
import { ModalContainer } from "../../modal/index";
import ChildWindow_ from "./GHelper";
import { ResponseContainer } from "../ui/ResponseContainer.js";
import { AdditionalResponse } from "../ui/AdditionalResponse";
import { Divider } from "../ui/Divider";
import { ResultContainer } from "../ui/ResultContainer";
import { BasicButton } from "../ui/BasicButton";
import {
  OnTestandReview,
  getAppEventConfigDetail,
  onDeleteNodeV2,
  saveKonnect,
} from "../../../store/thunk/rightHandAppsThunk.js";
import { useTranslation } from "react-i18next";
import { onLinkDelete, setModalData } from "../../../store/slice/canvasSlice";
import { searchApp } from "../../../store/thunk/appsThunk";
import { getProfile } from "../../../store/thunk/accountsThunk.js";

export const NodeInner = memo(({ data, selected }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const nodeOuter = useRef();
  const dispatch = useDispatch();
  const gSheetPopUp = useSelector((state) => state.canvas.gSheetPopUp);
  const [expand, setExpand] = useState(false);
  const [expandHeight, setExpandHeight] = useState(false);
  const [captureTooltip, setCaptureTooltip] = useState(false);
  const [modalState, setModalState] = useState({
    display: false,
    type: "",
  });

  let hasSequence =
    data.appEventConfigurations &&
    data.appEventConfigurations.filter((x) => x.sequence !== 0).length
      ? true
      : false;
  
  const helper = {
    onHelp: useCallback(() => {
      !data.loading && setModalState({ display: true, type: "help" });
    }, [data.loading]),
    onExpand: useCallback(() => {
      if (expand && modalState.type === "accounts") {
      } else !data.loading && setExpand((c) => !c);
    }, [data.loading, modalState]),
    additionalResult: useCallback(() => {
      setModalState({ display: true, type: "Normal" });
    }, [data.configResponses]),
    additionalResponse: useCallback(() => {
      setModalState({ display: true, type: "Additional" });
    }, [data.configResponses]),
    addAccount: useCallback(() => {
      dispatch(
        setModalData({
          nodeId: data.nodeId,
          type: "Account",
          auto: true,
        })
      );
      setExpand(true);
      setExpandHeight(true);
      setModalState({ display: true, type: "accounts" });
    }, []),
    onTestandReview: useCallback(() => {
      dispatch(
        onLinkDelete({
          nodeId: data.nodeId,
          additionalOnly: true,
        })
      );
      if (
        data?.delayReady &&
        data?.delayConfigsForTest?.delay_type === "Delay Until"
      ) {
        dispatch(getProfile()).then((res) => {
          if (res?.payload?.timezone === null) {
            toast({
              position: "top",
              status: "warning",
              variant: "solid",
              title:
                "Update the time zone in the profile section to proceed further.",
              duration: 2500,
              containerStyle: {
                fontWeight: 400,
              },
            });
          } else {
            if (
              !data.delay_activity_id &&
              data?.delayReady &&
              data?.konnect_activity_id
            ) {
              dispatch(onDeleteNodeV2(data.nodeId)).then((res) => {
                dispatch(OnTestandReview(data.nodeId)).then((res) => {
                  dispatch(saveKonnect({ publish: false }));
                });
              });
            } else {
              dispatch(OnTestandReview(data.nodeId)).then((res) => {
                dispatch(saveKonnect({ publish: false }));
              });
            }
          }
        });
      } else {
        if (
          !data.delay_activity_id &&
          data?.delayReady &&
          data?.konnect_activity_id
        ) {
          dispatch(onDeleteNodeV2(data.nodeId)).then((res) => {
            dispatch(OnTestandReview(data.nodeId)).then((res) => {
              dispatch(saveKonnect({ publish: false }));
            });
          });
        } else {
          dispatch(OnTestandReview(data.nodeId)).then((res) => {
            dispatch(saveKonnect({ publish: false }));
          });
        }
      }
    }, [data]),
  };
  return (
    <NodeOuter
      selected={selected}
      ref={nodeOuter}
      expand={expand ? "true" : "false"}
      expandheight={expandHeight ? "true" : "false"}
      id="drag"
    >
      {data &&
        data.konnect_custom &&
        data.konnect_custom.script_id &&
        gSheetPopUp === true && (
          <ChildWindow_ scriptId={data.konnect_custom.script_id} />
        )}
      <NodeLoader loading={data.loading} top="46px" />
      <AppInfo
        appImage={data.image}
        appName={data.name}
        status={data.status}
        message={data.captureMessage}
        nodeId={data.nodeId}
        tested={data?.tested || data?.konnect_activity_id ? true : false}
        expand={expand}
        onHelp={helper.onHelp}
        setExpand={setExpand}
        onExpand={helper.onExpand}
        setExpandHeight={setExpandHeight}
        setModalData={setModalData}
        setModalState={setModalState}
        nodeIdx={data.nodeIdx}
        hasDelay={data.hasDelay}
        modalState={modalState}
        delayActive={data?.delayConfigs ? true : false}
        delay_activity_id={
          data?.delay_activity_id ? data?.delay_activity_id : null
        }
      />
      <EventWrapper visibility={true}>
        <Flex sx={{ fontWeight: 500 }}>
          {data.nodeIdx === 0
            ? t("konnect.sidebar.choose_trigger")
            : t("konnect.sidebar.choose_action")}
        </Flex>
        <Selector
          parentnode={nodeOuter}
          options={data.appEvents}
          nodeId={data.nodeId}
          selectType={"Action"}
          value={data.selectedEvent ? data.selectedEvent : null}
        />
      </EventWrapper>
      <EventWrapper visibility={data?.appEvent ? true : false}>
        {data?.associatedAccounts.length ? (
          <>
            <Flex sx={{ fontWeight: 500 }}>Select Account</Flex>
            <Selector
              parentnode={nodeOuter}
              addAccount={true}
              selectType="Account"
              auth_type={data.auth_type ? data.auth_type : null}
              appId={data.id}
              nodeId={data.nodeId}
              name={data.name}
              setExpand={setExpand}
              setModalState={setModalState}
              setExpandHeight={setExpandHeight}
              options={data.associatedAccounts}
              value={data.selectedAccount ? data.selectedAccount : null}
            />
          </>
        ) : (
          <BasicButton
            title="Add Account"
            visibility="true"
            onClick={helper.addAccount}
          />
        )}
      </EventWrapper>
      <Divider
        bgColor="#b8d0fd"
        visibility={data.appEventConfigurations?.length}
      />
      {hasSequence && data.appEvent && data.selectedAccount && (
        <EventWrapper visibility={true}>
          {data.appEventConfigurations?.map((x, i) => {
            let title = x?.title;
            return (
              <Selector
                key={i}
                index={i}
                parentnode={nodeOuter}
                selectType="Sequence"
                totalSeq={data.appEventConfigurations.length}
                fetchFieldStatus={data.fetchFieldStatus}
                configResponses={data.configResponses}
                nodeId={data.nodeId}
                selectedConfig={x}
                value={x.selected || null}
                placeholder={title || "Select..."}
                options={x.config_details}
                customField={true}
                onFocus={() => {
                  if (x.config_details?.length == 0 && i === 0) {
                    dispatch(
                      getAppEventConfigDetail({
                        nodeId: data.nodeId,
                        config_key: data.appEventConfigurations.find(
                          (y) => y.sequence === x.sequence
                        ).config_key,
                      })
                    );
                  }
                }}
              />
            );
          })}
        </EventWrapper>
      )}

      <Divider bgColor="#b8d0fd" visibility={data.configResponses?.length} />
      <EventWrapper visibility={data.configResponses?.length}>
        <ResponseContainer
          result={data.configResponses}
          parentnode={nodeOuter}
          nodeId={data.nodeId}
        />
      </EventWrapper>
      <EventWrapper
        visibility={data.configResponses?.length > 5 ? true : false}
      >
        <BasicButton
          title={t("konnect.sidebar.responseModal.nodeInner_title")}
          visibility={data.configResponses?.length > 5 ? true : false}
          onClick={helper.additionalResult}
        />
      </EventWrapper>
      <EventWrapper visibility={data.configResponses?.length}>
        {(data.provider === "google_sheet" ||
          data.provider === "google-form") &&
        data.nodeIdx === 0 ? (
          <Tooltip
            isOpen={captureTooltip}
            onOpen={() => data.nodeIdx === 0 && setCaptureTooltip(true)}
            onClose={() => data.nodeIdx === 0 && setCaptureTooltip(false)}
            hasArrow
            label={"Only Mandatory fields must be filled to Trigger."}
          >
            <CaptureButton
              onClick={() => {
                setModalState({ display: true, type: "GSheetModal" });
              }}
            >
              {t("konnect.sidebar.scheduler.test_review")}
            </CaptureButton>
          </Tooltip>
        ) : (
          <Tooltip
            isOpen={captureTooltip}
            onOpen={() => !data.trigger_konnect_id && setCaptureTooltip(true)}
            onClose={() => !data.trigger_konnect_id && setCaptureTooltip(false)}
            hasArrow
            label={
              data.nodeIdx === 0
                ? "Only Mandatory fields must be filled to Trigger"
                : "Use Trigger first to create a Konnectz"
            }
          >
            <CaptureButton
              isDisabled={
                data.nodeIdx === 0
                  ? false
                  : data.trigger_konnect_id
                  ? false
                  : true
              }
              onClick={helper.onTestandReview}
            >
              {t("konnect.sidebar.scheduler.test_review")}
            </CaptureButton>
          </Tooltip>
        )}
      </EventWrapper>
      <Divider visibility={data?.additionalResponses} />
      <EventWrapper visibility={data?.additionalResponses} last="true">
        <ResultContainer response={data.configResponses} additional="true" />
      </EventWrapper>
      {data?.additionalResponses > 3 && !modalState.display ? (
        <AdditionalResponse
          visibility={data?.additionalResponses > 3 ? true : false}
          display={modalState.display}
          onClick={helper.additionalResponse}
        />
      ) : null}

      {modalState.display && (
        <ModalContainer
          nodeOuter={nodeOuter}
          modalState={modalState}
          setModalState={setModalState}
          data={data}
          setExpand={setExpand}
          setExpandHeight={setExpandHeight}
        />
      )}
    </NodeOuter>
  );
});
