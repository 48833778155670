import { Flex, Skeleton } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as Tool,
  Legend,
} from "chart.js";
ChartJS.register(ArcElement, Tool, Legend);
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next"	

import { useSelector, useDispatch } from "react-redux";
import { getWidgetDetails } from "../../../store/thunk/dashThunk";
import { setRoute } from "../../../store/dashSlice";
import AuthTokenService from "../../../utils/AuthTokenService";
import { useNavigate } from "react-router-dom";
export const TaskConsumption = () => {
  const { loading, taskConsumptionData } = useSelector((state) => state.dash);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getWidgetDetails("task_consumption")).then((res) => {
      if (res.error) {
        // dispatch(setRoute("Dashboard"));
        // AuthTokenService.clear();
        // navigate("/login");
      }
    });
  }, []);
  
  return (
    <Flex
      sx={{
        bgColor: "#fff",
        width: "30%",
        borderRadius: "4px",
        p: "20px",
        boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
      }}
    >
      <Flex
        width="100%"
        sx={{
          flexDir: "column",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Skeleton isLoaded={!loading}>
          <Flex
            sx={{
              fontSize: [16, 18, 18, 20, 22, 24],
              fontWeight: 500,
              height: "20%",
            }}
          >
          {t("konnect.testAndReview.taskCnsumptionLabel")}
          </Flex>
        </Skeleton>
        <Flex height="100%">
          {" "}
          <Skeleton
            isLoaded={!loading}
            width="100%"
            height="100%"
            display="flex"
          >
            <Flex flexWrap="wrap" width="100%">
              <Flex
                sx={{
                  gap: "5px",
                  fontSize: [12, 12, 14, 15, 15, 16],
                  m: "auto 0px",
                }}
              >
                <Flex
                  sx={{
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    bgColor: "#518EF8",
                    m: "auto 0px",
                  }}
                />
                {t("konnect.testAndReview.taskConsumbedLabel")} : {taskConsumptionData.datasets[0].data[0]}
              </Flex>{" "}
              <Flex
                sx={{
                  gap: "5px",
                  fontSize: [12, 12, 14, 15, 15, 16],
                  m: "auto 0px",
                }}
              >
                <Flex
                  sx={{
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    bgColor: "#F7C544",
                    m: "auto 0px",
                  }}
                />
                {t("konnect.testAndReview.taskAvailableLabel")} : {taskConsumptionData.datasets[0].data[1]}
              </Flex>{" "}
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
      <Skeleton isLoaded={!loading}>
        <Doughnut data={taskConsumptionData} />
      </Skeleton>
    </Flex>
  );
};
