import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Toast,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { svg } from "../../assets/objectSVG";
import axios from "axios";
import { NodeLoader } from "../../components/loader";
import { CheckIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturesList, getProfile } from "../../store/thunk/accountsThunk";
import Select from "react-select";
import { SlOptionsVertical } from "react-icons/sl";
import {
  BillOptionContainer,
  billOption,
  LifeTimeDeal,
  selectStyle,
  addOn,
} from "./items";
import {
  paddleAuth,
  planPrice,
  subscriptionDetails,
  updatePlan,
} from "../../store/paddleSlice";
import AuthTokenService from "../../utils/AuthTokenService";
import { truncate } from "lodash";
import { current } from "@reduxjs/toolkit";

export const ManageSubscription = () => {
  const [activeBillOption, setActiveBillOption] = useState(false);
  const [activeBillCard, setActiveBillCard] = useState(null);

  const [products, setProducts] = useState({
    productPro: [],
    productTeam: [],
    productAgency: [],
    productTopup: [],
    productLtd: [],
  });
  const [loading, setLoading] = useState(false);
  const [checkoutIds, setCheckoutIds] = useState({
    ////sandbox
    // Pro: 'pri_01ha77g1f7q30ce98zwpt11n6p',
    // Team: 'pri_01ha7sbvt7j8apfevxwws182qd',
    // Agency: 'pri_01ha9f7c911rcwf7k3btzsk4pz',
    // Topup: 'pri_01hafgzbkrcx7ee2cpr1daksfb',
    // Ltd: 'pri_01haht92r0anqg1pd03nb14vg6',
    // ChatGPT: 'pri_01hcn3dbmq7ndmzw0x57rv39nh',
    //    Free: 'pri_01hczffshg3wfj5zmx75cyqdsw',
    // live
    Pro: "pri_01hchxvs056fwck1hh0f89nx9b",
    Team: "pri_01hchxcjeybvjkssawsrd59cws",
    Agency: "pri_01hchwx87n0v37rgbavrav5bf3",
    Topup: "pri_01hchw864xnzayset55pdehsj6",
    Ltd: "pri_01hchw34s6hdsj0t835gxcppbv",
    ChatGPT: "pri_01hcyghmp9k6kfy4xsaqkq4c1y",
    Free: "pri_01hd43330rqbyffr921n5k1hxp",
  });
  const dispatch = useDispatch();
  const task_limit = useSelector(
    (state) => state.dash.taskConsumptionData.task_limit
  );
  const { couponList, featureData } = useSelector((state) => state.profile);
  const profileData = useSelector((state) => state.profile.profileData);
  const [cart, setCart] = useState([]);
  const [x, setX] = useState(null);
  const [basicPlan, setBasicPlan] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [latestSubscriptionId, setLatestSubscriptionId] = useState("");
  const [activePlan, setActivePlan] = useState({});
  const [spin, setSpin] = useState(false);
  const [spinStates, setSpinStates] = useState(
    Array(billOption.length).fill(false)
  );
  const [addOnIdx, setAddOnIdx] = useState(null);
  const updateLoading = useSelector(
    (state) => state.subscriptions.updateLoading
  );
  const [showConfirmation, setShowConfirmation] = useState({
    Basic: false,
    Topup: false,
    LTD: false,
  });
  const [currentPlan, setCurrentPlan] = useState({
    Pro: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
    Team: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
    Agency: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
    LTD: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
    Topup: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
    ChatGPTPlugin: {
      price: 0,
      description: "",
      id: "",
      plan_type: "",
    },
  });
  const [selectedPrices, setSelectedPrices] = useState({
    Pro: 13.49,
    Agency: 102.99,
    Team: 28.49,
    Topup: 58.49,
    Ltd: 99,
  });
  const [selectedBill, setSelectedBill] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(null);
  useEffect(() => {
    setSelectedPrices((prevSelectedPrices) => {
      return {
        Pro: currentPlan?.Pro?.price || prevSelectedPrices.Pro,
        Agency: currentPlan?.Agency?.price || prevSelectedPrices.Agency,
        Team: currentPlan?.Team?.price || prevSelectedPrices.Team,
        Topup: currentPlan?.Topup?.price || prevSelectedPrices.Topup,
        Ltd: currentPlan?.LTD?.price || prevSelectedPrices.Ltd,
      };
    });
  }, [currentPlan, LtdTasks, topupTasks]);
  const selectPlanUpdate = (plan) => {
    const newShowConfirmation = { ...showConfirmation };

    Object.keys(newShowConfirmation).forEach((key) => {
      newShowConfirmation[key] = false;
    });

    if (plan === "Topup") {
      newShowConfirmation["Topup"] = true;
    } else if (plan === "Life Time Deal") {
      newShowConfirmation["LTD"] = true;
    } else {
      newShowConfirmation["Basic"] = true;
    }

    setShowConfirmation(newShowConfirmation);
  };
  const handleCancel = () => {
    // Create a new state object by spreading the current state
    const newShowConfirmation = { ...showConfirmation };

    // Set all plan flags to false
    Object.keys(newShowConfirmation).forEach((key) => {
      newShowConfirmation[key] = false;
    });

    // Update the state
    setShowConfirmation(newShowConfirmation);
  };

  const handleButtonClick = (index) => {
    const newSpinStates = [...spinStates];
    newSpinStates[index] = !newSpinStates[index];
    setSpinStates(newSpinStates);
  };

  const checkPlansForIds = (ids) => {
    // //////sandbox
    // const proTeamAgencyIds = [
    //   'pro_01ha6wq6j4txaew671frey4hzq',
    //   'pro_01ha7s9chzmbzv0vzdkmmcne52',
    //   'pro_01ha9f4k1g2cns7f58q05n755w',
    // ];
    // const Ltd = ['pro_01haht5ch09k8270h6ymeemafx'];
    // const Addon = ['pro_01hacszt59fptremr74t1bys42','pro_01hcn3cbcr1fqky66hxjycdb20'];

    //live
    const proTeamAgencyIds = [
      "pro_01hchtte5enqm964vh65mym8v7",
      "pro_01hchtvpbyqdp4hzd0m53599ec",
      "pro_01hchtw6vh9cg3xkx97gjnv7m0",
    ];
    const Ltd = ["pro_01hchtx92ev6f67ma9484zr9n3"];
    const Addon = [
      "pro_01hchtwq8d5dh2w8rs356fgn6f",
      "pro_01hcygd42r0bzdjjv75rdvpj9y",
    ];

    const activePlans = {};

    for (const id of ids) {
      if (proTeamAgencyIds.includes(id)) {
        if (!activePlans["Basic"]) {
          activePlans["Basic"] = [];
        }
        activePlans["Basic"].push(id);
      }
      if (Ltd.includes(id)) {
        if (!activePlans["LTD"]) {
          activePlans["LTD"] = [];
        }
        activePlans["LTD"].push(id);
      }
      if (Addon.includes(id)) {
        if (!activePlans["Addon"]) {
          activePlans["Addon"] = [];
        }
        activePlans["Addon"].push(id);
      }
    }

    return activePlans;
  };

  const toast = useToast();
  useEffect(() => {
    setLoading(false);
    dispatch(paddleAuth()).then((res) => {
      setAuthToken(res.payload);
      dispatch(planPrice({ plan_type: "pro", authToken: res.payload })).then(
        (res) => {
          setProducts((products) => ({
            ...products,
            productPro: res?.payload?.data?.prices,
          }));
          setLoading(true);
        }
      );
      dispatch(planPrice({ plan_type: "team", authToken: res.payload })).then(
        (res) => {
          setProducts((products) => ({
            ...products,
            productTeam: res?.payload?.data?.prices,
          }));
          setLoading(true);
        }
      );
      dispatch(planPrice({ plan_type: "agency", authToken: res.payload })).then(
        (res) => {
          setProducts((products) => ({
            ...products,
            productAgency: res?.payload?.data?.prices,
          }));
          setLoading(true);
        }
      );
      dispatch(planPrice({ plan_type: "topup", authToken: res.payload })).then(
        (res) => {
          setProducts((products) => ({
            ...products,
            productTopup: res?.payload?.data?.prices,
          }));
          setLoading(true);
        }
      );
      dispatch(
        planPrice({ plan_type: "lifetime", authToken: res.payload })
      ).then((res) => {
        setProducts((products) => ({
          ...products,
          productLtd: res?.payload?.data?.prices,
        }));
        setLoading(true);
      });

      fetchData();
    });
  }, [updateLoading]);
  const fetchData = async () => {
    try {
      const profileResponse = await dispatch(getProfile());
      const id = profileResponse?.payload.paddle_customer_id;

      const subscriptionResponse = await dispatch(subscriptionDetails(id));

      const res = subscriptionResponse?.payload?.data;
      const res1 = res.filter((item) => item.status === "active");
      const p1 = res1[0]?.items[0]?.price?.product_id;
      const p2 = res1[1]?.items[0]?.price?.product_id;
      const p3 = res1[2]?.items[0]?.price?.product_id;
      const p4 = res1[3]?.items[0]?.price?.product_id;
      // const price1 =

      const active = checkPlansForIds([p1, p2, p3, p4]);
      setActivePlan(active);

      const sub_id = res1[0]?.id;
      setLatestSubscriptionId(sub_id);

      res1.forEach((subscription) => {
        const productName = subscription.productInfo?.productName;
        const plan_type = subscription.productInfo?.plan_type;
        const price = subscription.items[0]?.price?.unit_price?.amount;
        const price_id = subscription.items[0]?.price?.id;

        if (productName && currentPlan[productName]) {
          setCurrentPlan((prevPrice) => ({
            ...prevPrice,
            [productName]: {
              price: price / 100,
              description: subscription.items[0]?.price?.description,
              id: price_id,
              plan_type: plan_type,
            },
          }));
        }
      });
    } catch (error) {
      // Handle any errors here
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(getFeaturesList());
    dispatch(getProfile());
    //  handlePostRequest();
  }, [x, authToken]);
  const hasCurrentPlan = featureData?.products?.find(
    (plan) => plan.current_plan && plan.current_plan === true
  );

  const filterPricesByTimeLine = (prices, timeline) => {
    let annual = prices?.filter(
      (x) => x.billing_cycle.interval === timeline && x.status === "active"
    );
    return annual;
  };
  const AnnualPricesPro = filterPricesByTimeLine(products.productPro, "year");
  const MonthlyPricesPro = filterPricesByTimeLine(products.productPro, "month");
  const AnnualPricesTeam = filterPricesByTimeLine(products.productTeam, "year");
  const MonthlyPricesTeam = filterPricesByTimeLine(
    products.productTeam,
    "month"
  );
  const AnnualPricesAgency = filterPricesByTimeLine(
    products.productAgency,
    "year"
  );
  const MonthlyPricesAgency = filterPricesByTimeLine(
    products.productAgency,
    "month"
  );
  const AnnualPricesTopup = filterPricesByTimeLine(
    products.productTopup,
    "year"
  );
  const MonthlyPricesTopup = filterPricesByTimeLine(
    products.productTopup,
    "month"
  );
  const LtdPlanPrices = filterPricesByTimeLine(products.productLtd, "year");

  function sliceDescription(description) {
    const annualSubscriptionIndex = description.indexOf("Subscription");
    if (annualSubscriptionIndex !== -1) {
      return description
        .slice(annualSubscriptionIndex + "Subscription".length)
        .trim();
    }
    return description;
  }

  const proTasks = MonthlyPricesPro.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const teamTasks = MonthlyPricesTeam.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const agencyTasks = MonthlyPricesAgency.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const topupTasks = MonthlyPricesTopup.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));

  const proTasksAnnual = AnnualPricesPro.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const teamTasksAnnual = AnnualPricesTeam.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const agencyTasksAnnual = AnnualPricesAgency.reverse().map(
    (price, index) => ({
      value: price.id,
      label: `${sliceDescription(price.description)} `,
    })
  );
  const topupTasksAnnual = AnnualPricesAgency.reverse().map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));
  const LtdTasks = LtdPlanPrices.map((price, index) => ({
    value: price.id,
    label: `${sliceDescription(price.description)} `,
  }));

  const onChangePro = (event) => {
    let x = MonthlyPricesPro.find((x) => x.id === event.value);
    let y = AnnualPricesPro.find((x) => x.id === event.value);
    setSelectedPrices((prices) => ({
      ...prices,
      Pro: !activeBillOption
        ? x?.unit_price.amount / 100
        : y?.unit_price.amount / 100,
    }));
    setCheckoutIds((ids) => ({
      ...ids,
      Pro: !activeBillOption ? x.id : y?.id,
    }));
  };
  const onChangeTeam = (event) => {
    let x = MonthlyPricesTeam.find((x) => x.id === event.value);
    let y = AnnualPricesTeam.find((x) => x.id === event.value);
    setSelectedPrices((prices) => ({
      ...prices,
      Team: !activeBillOption
        ? x?.unit_price.amount / 100
        : y?.unit_price.amount / 100,
    }));

    // setCheckoutIdTeam(null)
    setCheckoutIds((ids) => ({
      ...ids,
      Team: !activeBillOption ? x.id : y?.id,
    }));
    // setCheckoutIdTeam(!activeBillOption?x.id:y?.id)
  };
  const onChangeAgency = (event) => {
    let x = MonthlyPricesAgency.find((x) => x.id === event.value);
    let y = AnnualPricesAgency.find((x) => x.id === event.value);
    setSelectedPrices((prices) => ({
      ...prices,
      Agency: !activeBillOption
        ? x?.unit_price.amount / 100
        : y?.unit_price.amount / 100,
    }));
    setCheckoutIds((ids) => ({
      ...ids,
      Agency: !activeBillOption ? x.id : y?.id,
    }));
  };
  const onChangeTopup = (event) => {
    let x = MonthlyPricesTopup.find((x) => x.id === event.value);
    // let y = AnnualPricesTopup.find((x) => x.id === event.value)
    setSelectedPrices((prices) => ({
      ...prices,
      Topup: !activeBillOption
        ? x?.unit_price.amount / 100
        : x?.unit_price.amount / 100,
    }));
    setCheckoutIds((ids) => ({ ...ids, Topup: x?.id }));
  };

  const onChangeLtd = (event) => {
    let x = LtdPlanPrices.find((x) => x.id === event.value);
    // let y = AnnualPricesTopup.find((x) => x.id === event.value)
    setSelectedPrices((prices) => ({
      ...prices,
      Ltd: !activeBillOption
        ? x?.unit_price.amount / 100
        : x?.unit_price.amount / 100,
    }));
    setCheckoutIds((ids) => ({
      ...ids,
      Ltd: !activeBillOption ? x.id : x?.id,
    }));
  };

  const Paddle = window.Paddle;
  const handleCart = (i) => {
    const basicPlans = ["Pro", "Agency", "Team"];

    // Check if the selected plan is a basic plan
    const isBasicPlan = basicPlans.includes(i);

    // Check if there's already a basic plan in the cart
    const hasBasicPlanInCart = cart.some((item) =>
      basicPlans.includes(item.plan)
    );

    if (isBasicPlan && hasBasicPlanInCart) {
      toast({
        position: "top",
        status: "error",
        variant: "solid",
        title:
          "You can add one basic plan (Pro, Agency, or Team) in your cart.",
        duration: 10000,

        containerStyle: {
          fontWeight: 400,
        },
      });
    } else {
      const priceId =
        checkoutIds.Pro ||
        checkoutIds.Team ||
        checkoutIds.Agency ||
        checkoutIds.Topup ||
        checkoutIds.Ltd
          ? i === "Pro"
            ? checkoutIds.Pro
            : i === "Team"
            ? checkoutIds.Team
            : i === "Agency"
            ? checkoutIds.Agency
            : i === "Topup"
            ? checkoutIds.Topup
            : i === "Life Time Deal"
            ? checkoutIds.Ltd
            : i === "ChatGPT Plugin"
            ? checkoutIds.ChatGPT
            : null
          : null;

      let x =
        i === "Pro" || i === "Team" || i === "Agency"
          ? "Basic"
          : i === "Topup" || i === "ChatGPT Plugin"
          ? "Addon"
          : i === "Life Time Deal"
          ? "LTD"
          : null;

      if (priceId) {
        if (!activePlan[x]) {
          setCart([...cart, { priceId, plan: i }]);
        } else {
          localStorage.setItem("cart", JSON.stringify(priceId));
        }
      }
    }
  };

  const handleUpdatePlan = (plan) => {
    dispatch(getProfile());
    let id = profileData.paddle_customer_id;
    dispatch(subscriptionDetails(id)).then((response) => {
      let subscriptions = response?.payload.data;
      let price_id = JSON.parse(localStorage.getItem("cart"));
      const matchingSubscription = subscriptions.find(
        (subscription) => subscription.productInfo.productName === plan
      );
      const subscriptionTypesToFind = ["Pro", "Team", "Agency"];
      const BasicSubscription = subscriptions.find((subscription) =>
        subscriptionTypesToFind.includes(subscription.productInfo.productName)
      );
      let sub_id = null;

      if (BasicSubscription && subscriptionTypesToFind.includes(plan)) {
        sub_id = BasicSubscription.id;
      }

      if (
        matchingSubscription &&
        matchingSubscription.productInfo.productName === "Topup" &&
        plan === "Topup"
      ) {
        sub_id = matchingSubscription.id;
      }
      if (
        matchingSubscription &&
        matchingSubscription.productInfo.productName === "LTD" &&
        plan === "LTD"
      ) {
        sub_id = matchingSubscription.id;
      }

      if (price_id) {
        dispatch(
          updatePlan({ subscription_id: sub_id, price_id: price_id })
        ).then((res) => {
          if (res.type === "update_plan/fulfilled") {
            if (res?.payload?.data?.status === "active") {
              const newSpinStates = Array(billOption.length).fill(false);
              setSpinStates(newSpinStates);

              // setLoading(true)
              toast({
                position: "top",
                status: "success",
                variant: "solid",
                title:
                  "Plan Updated Successfully and you well get the notification from your Bank and amount is debited on prorata basis of previous plan ",
                duration: 10000,

                containerStyle: {
                  fontWeight: 400,
                },
              });
            } else {
              const newSpinStates = Array(billOption.length).fill(false);
              setSpinStates(newSpinStates);
              toast({
                position: "top",
                status: "error",
                variant: "solid",
                title:
                  "Plan could not be Updated due to technical error , Contact Support",
                duration: 10000,
                containerStyle: {
                  fontWeight: 400,
                },
              });
            }
          } else {
            const newSpinStates = Array(billOption.length).fill(false);
            setSpinStates(newSpinStates);
            toast({
              position: "top",
              status: "error",
              variant: "solid",
              title:
                "Plan could not be Updated due to technical error , Contact Support",
              duration: 10000,
              containerStyle: {
                fontWeight: 400,
              },
            });
          }
        });
      }
    });
  };

  const openCheckout = async (i) => {
    const cartWithoutPlan = cart.map((item) => {
      const { plan, ...rest } = item;
      return rest;
    });
    if (loading) {
      await Paddle.Checkout.open({
        settings: {
          displayMode: "overlay",
          theme: "light",
          locale: "en",
          frameTarget: "checkout-container",
          frameInitialHeight: "450",

          frameStyle:
            "width: 100%; min-width: 312px; background-color: transparent; border: none;",
        },
        items:
          i === "Life Time Deal"
            ? [{ price_id: checkoutIds.Ltd }]
            : i === "Free"
            ? [{ price_id: checkoutIds.Free }]
            : JSON.parse(JSON.stringify(cartWithoutPlan)),
      });
    }
  };
  const clearCart = () => {
    // localStorage.removeItem('cart');
    //  setX(x=>x+1)
    setCart([]);
  };
  return (
    <>
      <Flex
        width="100%"
        className="checkout-container"
        sx={{
          bgColor: "#fff",
          boxShadow: "0px 0px 3px 0px rgb(81,142,248, 0.35)",
          borderRadius: "4px",
          p: "20px",
          gap: "20px",
          flexDir: "column",
          height: "80%",
          //   position: 'relative',
          overflow: "scroll",
          m: "2% 2%",
        }}
      >
        <Flex
          width="100%"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Flex
            sx={{
              fontWeight: 600,
              width: "100%",
              fontSize: [16, 16, 18, 18, 18, 20],
              m: "auto 0px",
              gap: "20px",
              flexDirection: "column",
              position: "relative",
            }}
          >
            {/* <Flex onClick={openCheckout}>{cart.length}Cart</Flex> */}
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                fontSize: "21",
              }}
            >
              {" "}
              Basic Plans
            </Flex>
            <Flex
              sx={{
                height: "2px",
                width: "100%",
                backgroundImage:
                  "Linear-gradient(90deg,rgba(56,189,248,0) 0%,#0EA5E9 32.29%,rgba(236,72,153,.3) 67.19%,rgba(236,72,153,0) 100%);",
              }}
            ></Flex>

            {loading &&
            products &&
            products.productPro.length > 0 &&
            agencyTasks[0]?.label &&
            proTasks[0]?.label &&
            teamTasks[0]?.label ? (
              <>
                <Flex
                  sx={{
                    justifyContent: "center",
                    // ml:"100px"
                  }}
                >
                  <Button
                    onClick={() => {
                      setActiveBillOption(false);
                      setCheckoutIds((ids) => ({
                        ...ids,

                        // //  sandbox
                        //   Pro: 'pri_01ha77g1f7q30ce98zwpt11n6p',
                        //   Team: 'pri_01ha7sbvt7j8apfevxwws182qd',
                        //   Agency: 'pri_01ha9f7c911rcwf7k3btzsk4pz',
                        //   Topup: 'pri_01hafgzbkrcx7ee2cpr1daksfb',
                        //live
                        Pro: "pri_01hchxvs056fwck1hh0f89nx9b",
                        Team: "pri_01hchxcjeybvjkssawsrd59cws",
                        Agency: "pri_01hchwx87n0v37rgbavrav5bf3",
                        Topup: "pri_01hchw864xnzayset55pdehsj6",
                      }));

                      setSelectedPrices((prices) => ({
                        ...prices,
                        Agency: 102.99,
                        Team: 28.49,
                        Pro: 13.49,
                        Topup: 58.49,
                      }));
                    }}
                    sx={{
                      fontSize: "18px",
                      px: "25px",
                      py: "10px",
                      borderRadius: "0px",
                      border: "1px solid #eae5e5",
                      width: "300px",
                      //   fontSize: Scale([12, 14, 16, 18, 20, 22]),

                      backgroundColor:
                        activeBillOption === false ? "#152d73" : "#fff",

                      color: activeBillOption === false ? "#fff" : "#999999",

                      "&:hover": {
                        transition: "width 1s",
                        backgroundColor:
                          activeBillOption === false ? "#152d73" : "#eae5e5",
                      },
                    }}
                  >
                    Billed Monthly
                  </Button>
                  <Button
                    onClick={() => {
                      setActiveBillOption(true);
                      setCheckoutIds((ids) => ({
                        ...ids,
                        // //sandbox
                        // Pro: 'pri_01ha77hefdfprmjyn7rdh89g2h',
                        // Team: 'pri_01haa5bzy7v4pdsv1r32yvjgcc',
                        // Agency: 'pri_01haa72grqqg7e861qjr4mvfzy',
                        // Topup: 'pri_01hafgzbkrcx7ee2cpr1daksfb',
                        //live
                        Pro: "pri_01hcyj5wxz8w4ft6zy9b3ah099",
                        Team: "pri_01hcyjy53n467mpesq9m4h6ts3",
                        Agency: "pri_01hcykbnnw7aat4m6y0d92chft",
                        Topup: "pri_01hafgzbkrcx7ee2cpr1daksfb",
                      }));

                      setSelectedPrices((prices) => ({
                        ...prices,
                        Agency: 79,
                        Team: 19,
                        Pro: 9,
                        Topup: 58.49,
                      }));
                    }}
                    sx={{
                      fontSize: "18px",
                      width: "300px",
                      px: "25px",
                      py: "10px",
                      borderRadius: "0px",
                      border: "1px solid #eae5e5",
                      //   fontSize: Scale([12, 14, 16, 18, 20, 22]),
                      backgroundColor:
                        activeBillOption === true ? "#152d73" : "#fff",

                      color: activeBillOption === true ? "#fff" : "#999999",

                      "&:hover": {
                        transition: "width 1s",
                        backgroundColor:
                          activeBillOption === true ? "#152d73" : "#eae5e5",
                      },
                    }}
                  >
                    Billed Annually (Save 33%)
                  </Button>
                </Flex>

                <BillOptionContainer
                  sx={{
                    px: "5%",
                    gap: "40px",
                    width: "100%",
                    mb: "10%",
                    flexWrap: "wrap",
                  }}
                >
                  {billOption.map((bill, i) => {
                    const isActiveBill = activePlan?.Basic?.includes(bill.id);
                    const isFreePlanActive = isActiveBill
                      ? profileData?.free_task > 100 && bill.heading === "Free"
                      : null;
                    return (
                      <>
                        <Flex
                          //   onClick={() => {
                          //     setActiveBillCard(bill.heading);
                          //   }}
                          width="22%"
                          className=" shadow-lg hover:shadow-lg shadow-[#D1FDD0]"
                          sx={{
                            cursor: "pointer",
                            fontSize: "15px",
                            pt: "12px",
                            pb: "40px",
                            outline:
                              activeBillCard === i
                                ? "2px solid #D1FDD0"
                                : "2px solid #E8EAFA",
                            alignItems: "center",
                            "&:hover": {
                              transition: "box-shadow 0.2s",
                              boxShadow:
                                activeBillCard === i
                                  ? "5px 8px 5px #E8EAFA"
                                  : "8px 8px 5px  #E8EAFA",
                            },
                            backgroundImage: isActiveBill
                              ? "linear-gradient(325deg,#9CF98A,#D1FDD0)"
                              : isFreePlanActive
                              ? "linear-gradient(325deg,#9CF98A,#D1FDD0)"
                              : null,

                            flexDirection: "column",
                          }}
                        >
                          <Flex
                            sx={{ justifyContent: "end", marginLeft: "auto" }}
                          >
                            <SlOptionsVertical />
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto 27px",
                              fontSize: "15px",
                              color: "#152d73",
                              alignSelf: "flex-start",
                            }}
                          >
                            {bill.heading}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              fontSize: "30px",
                              color: activeBillCard === i ? "#152d73" : "#000",
                            }}
                            //   key = {defaultTeamTask}
                          >
                            {i === 0 ? (
                              <>$0</>
                            ) : i === 1 ? (
                              <>${selectedPrices.Pro}</>
                            ) : i === 2 ? (
                              <>${selectedPrices.Team}</>
                            ) : i === 3 ? (
                              <>${selectedPrices.Agency}</>
                            ) : null}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              color: "#999999",
                              fontSize: "15px",
                            }}
                          >
                            {bill.time}
                          </Flex>
                          <Box sx={{ m: "20px auto", width: "100%" }}>
                            {/* <ReactSelect /> */}
                          </Box>
                          {i === 0 ? (
                            <Flex
                              sx={{
                                width: "95%",
                                margin: "3% 3%",
                                border: "1px solid #B7C9FF",
                                p: "7px",
                                borderRadius: "4px",
                                fontSize: "16px",
                                backgroundColor: "#F3F7FF",
                                fontWeight: "700",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              {task_limit + " " + "Tasks per Month"}
                            </Flex>
                          ) : i === 1 ? (
                            <Select
                              // key={proTasks[0]}
                              options={
                                activeBillOption ? proTasksAnnual : proTasks
                              }
                              key={
                                currentPlan?.Pro
                                  ? currentPlan?.Pro?.description
                                  : proTasks[0]
                              }
                              defaultValue={
                                !currentPlan.Pro.price
                                  ? {
                                      label: proTasks[0]?.label,
                                      value: proTasks[0]?.value,
                                    }
                                  : {
                                      label: sliceDescription(
                                        currentPlan.Pro.description
                                      ),
                                      value: currentPlan.Pro.id,
                                    }
                              }
                              onChange={onChangePro}
                              styles={selectStyle}
                            />
                          ) : i === 2 ? (
                            <Select
                              // key={teamTasks[0].label}
                              // defaultValue={{
                              //   label: teamTasks[0]?.label,
                              //   value: teamTasks[0]?.value,
                              // }}
                              key={
                                currentPlan?.Team.price
                                  ? currentPlan?.Team?.description
                                  : proTasks[0]
                              }
                              defaultValue={
                                !currentPlan.Team.price
                                  ? {
                                      label: teamTasks[0]?.label,
                                      value: teamTasks[0]?.value,
                                    }
                                  : {
                                      label: sliceDescription(
                                        currentPlan.Team.description
                                      ),
                                      value: currentPlan.Team.id,
                                    }
                              }
                              options={
                                activeBillOption ? teamTasksAnnual : teamTasks
                              }
                              onChange={onChangeTeam}
                              styles={selectStyle}
                            />
                          ) : i === 3 ? (
                            <Select
                              key={currentPlan.Agency || agencyTasks}
                              defaultValue={
                                currentPlan.Agency.price
                                  ? {
                                      label: sliceDescription(
                                        currentPlan.Agency.description
                                      ),
                                      value: currentPlan.Agency.id,
                                    }
                                  : {
                                      label: agencyTasks[0]?.label,
                                      value: agencyTasks[0]?.value,
                                    }
                              }
                              options={
                                activeBillOption
                                  ? agencyTasksAnnual
                                  : agencyTasks
                              }
                              onChange={onChangeAgency}
                              styles={selectStyle}
                            />
                          ) : null}

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: "20px",
                            }}
                          >
                            <Button
                              sx={{
                                fontSize: "15px",
                                py: "2px",
                                backgroundColor: "#152d73",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                px: "10px",
                                height: "40px",
                                borderRadius: "0px !important",
                                "&:hover": {
                                  transition: "width 1s",
                                  backgroundColor: "#3a48d6",
                                },
                                "&:disabled": {
                                  transition: "width 1s",
                                  backgroundColor: "",
                                  "&:hover": {
                                    transition: "width 1s",
                                    backgroundColor: "#152d73",
                                  },
                                },
                              }}
                              // isDisabled={
                              //   bill?.heading === hasCurrentPlan?.name
                              // }
                              // onMouseEnter={() => setHover(true)}
                              // onMouseLeave={() => setHover(false)}
                            >
                              <Box
                                onClick={() => {
                                  // setLoading(true);
                                  if (i > 0) {
                                    if (activePlan?.Basic?.length > 0) {
                                      selectPlanUpdate(bill.heading);
                                      setSelectedBill(bill.heading);
                                      setSelectedIdx(i);
                                    } else {
                                      handleCart(bill.heading);
                                    }
                                  }
                                  if (i === 0 && profileData.free_task <= 100) {
                                    openCheckout(bill.heading);
                                  }

                                  // openCheckout(bill.heading);
                                }}
                                isDisabled={true}
                                key={i}
                              >
                                {!spinStates[i] ? (
                                  isActiveBill ? (
                                    "Current Plan"
                                  ) : (
                                    bill.button
                                  )
                                ) : (
                                  <Flex
                                    sx={{ alignItems: "center", gap: "5px" }}
                                  >
                                    {bill.heading === hasCurrentPlan?.name
                                      ? "Current Plan"
                                      : bill.button}
                                    <Spinner
                                      thickness="3px"
                                      speed="1.5s"
                                      // emptyColor="gray.200"
                                      color="152E73"
                                      size="md"
                                    />
                                  </Flex>
                                )}
                              </Box>{" "}
                              <Flex
                                id="getStartedSvg"
                                sx={{
                                  width: "11px",
                                  height: "13px",
                                  // ml: hover ? '5px' : '5px',
                                }}
                              >
                                {svg.getStartedBlue}
                              </Flex>
                            </Button>
                          </Box>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Flex
                              sx={{
                                fontSize: "15px",
                                gap: "10px",
                                fontWeight: "700",
                              }}
                            >
                              {/* <Flex >
                            <CheckIcon
                              sx={{
                                m: 'auto 0px',
								color: 'green',
                                '&:hover': {
                                
                                  transform: 'scale(1.2)',
                                  transition: 'transform 0.15s',
                                },
                              }}
                           
                            />
                      
                          </Flex> */}

                              <Flex>{bill.title}</Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {bill.detail.map((text) => {
                              return (
                                <Flex sx={{ fontSize: "15px", gap: "10px" }}>
                                  <Flex>
                                    <CheckIcon
                                      sx={{
                                        m: "auto 0px",
                                        color: "green",
                                        "&:hover": {
                                          transform: "scale(1.2)",
                                          transition: "transform 0.15s",
                                        },
                                      }}
                                    />
                                  </Flex>

                                  <Flex>{text}</Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                        <Modal
                          isOpen={showConfirmation.Basic}
                          onClose={handleCancel}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalBody>
                              Are you sure you want to update Basic Plan?
                            </ModalBody>
                            <ModalFooter sx={{ gap: "10px" }}>
                              <Button
                                sx={{
                                  bg: "rgb(21,46,115)",
                                  color: "white",
                                  "&:hover": { color: "black" },
                                }}
                                onClick={() => {
                                  handleCart(selectedBill);
                                  handleUpdatePlan(bill.heading);
                                  handleButtonClick(selectedIdx);
                                  handleCancel();
                                }}
                              >
                                Yes
                              </Button>
                              <Button colorScheme="red" onClick={handleCancel}>
                                No
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    );
                  })}
                </BillOptionContainer>
                <Flex
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "21",
                  }}
                >
                  {" "}
                  Add On Plans
                </Flex>
                <Flex
                  sx={{
                    height: "2px",
                    width: "100%",
                    backgroundImage:
                      "Linear-gradient(90deg,rgba(56,189,248,0) 0%,#0EA5E9 32.29%,rgba(236,72,153,.3) 67.19%,rgba(236,72,153,0) 100%);",
                  }}
                ></Flex>
                <BillOptionContainer
                  sx={{
                    px: "5%",
                    gap: "40px",
                    width: "100%",
                    mb: "10%",
                    flexWrap: "wrap",
                  }}
                >
                  {addOn.map((bill, i) => {
                    const isActiveBill = activePlan?.Addon?.includes(bill.id);
                    return (
                      <>
                        <Flex
                          //   onClick={() => {
                          //     setActiveBillCard(bill.heading);
                          //   }}
                          width="22%"
                          className=" shadow-lg hover:shadow-lg shadow-[#D1FDD0]"
                          sx={{
                            cursor: "pointer",
                            fontSize: "15px",
                            pt: "12px",
                            pb: "40px",
                            outline:
                              activeBillCard === i
                                ? "2px solid #D1FDD0"
                                : "2px solid #E8EAFA",
                            alignItems: "center",
                            "&:hover": {
                              transition: "box-shadow 0.2s",
                              boxShadow:
                                activeBillCard === i
                                  ? "5px 8px 5px #E8EAFA"
                                  : "8px 8px 5px  #E8EAFA",
                            },
                            backgroundImage: isActiveBill
                              ? "linear-gradient(325deg,#9CF98A,#D1FDD0)"
                              : null,

                            flexDirection: "column",
                          }}
                        >
                          <Flex
                            sx={{ justifyContent: "end", marginLeft: "auto" }}
                          >
                            <SlOptionsVertical />
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto 27px",
                              fontSize: "15px",
                              color: "#152d73",
                              alignSelf: "flex-start",
                            }}
                          >
                            {bill.heading}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              fontSize: "30px",
                              color: activeBillCard === i ? "#152d73" : "#000",
                            }}
                            //   key = {defaultTeamTask}
                          >
                            {i === 0 ? <>${selectedPrices.Topup}</> : "$5"}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              color: "#999999",
                              fontSize: "15px",
                            }}
                          >
                            {bill.time}
                          </Flex>
                          <Box sx={{ m: "20px auto", width: "100%" }}>
                            {/* <ReactSelect /> */}
                          </Box>
                          {i === 0 ? (
                            <Select
                              key={
                                currentPlan?.Topup.description
                                  ? currentPlan?.Topup?.description
                                  : topupTasks[0]
                              }
                              defaultValue={
                                !currentPlan.Topup.price
                                  ? {
                                      label: topupTasks[0]?.label,
                                      value: topupTasks[0]?.value,
                                    }
                                  : {
                                      label: sliceDescription(
                                        currentPlan.Topup.description
                                      ),
                                      value: currentPlan.Topup.id,
                                    }
                              }
                              onChange={onChangeTopup}
                              styles={selectStyle}
                              options={
                                activeBillOption ? topupTasks : topupTasks
                              }
                            />
                          ) : (
                            <Select
                              styles={selectStyle}
                              defaultValue={{
                                label: "Konnectzit-Plugin",
                                value: "33",
                              }}
                              options={[
                                {
                                  label: "Konnectzit-Plugin",
                                  value: "23",
                                },
                              ]}
                            />
                          )}

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: "20px",
                            }}
                          >
                            <Button
                              sx={{
                                fontSize: "15px",
                                py: "2px",
                                backgroundColor: "#152d73",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                px: "10px",
                                height: "40px",
                                borderRadius: "0px !important",
                                "&:hover": {
                                  transition: "width 1s",
                                  backgroundColor: "#3a48d6",
                                },
                                "&:disabled": {
                                  transition: "width 1s",
                                  backgroundColor: "",
                                  "&:hover": {
                                    transition: "width 1s",
                                    backgroundColor: "#152d73",
                                  },
                                },
                              }}
                              isDisabled={
                                bill?.heading === hasCurrentPlan?.name
                              }
                            >
                              <Box
                                onClick={() => {
                                  if (
                                    currentPlan.Topup.plan_type === "AddOn" &&
                                    currentPlan.Topup.price > 0 &&
                                    currentPlan.ChatGPTPlugin.price > 0
                                  ) {
                                    if (i === 0) {
                                      selectPlanUpdate(bill.heading);
                                    }
                                  }
                                  if (
                                    !currentPlan?.ChatGPTPlugin?.price &&
                                    i === 1
                                  ) {
                                    handleCart(bill.heading);
                                  }
                                  if (!currentPlan?.Topup?.price && i === 0) {
                                    handleCart(bill.heading);
                                  }

                                  // handleCart(bill.heading);

                                  //   openCheckout(bill.heading);
                                }}
                                // isDisabled={true}
                              >
                                {i === 0
                                  ? currentPlan.Topup.price > 0
                                    ? "Current Plan"
                                    : bill.button
                                  : i === 1
                                  ? currentPlan.ChatGPTPlugin.price > 0
                                    ? "Current Plan"
                                    : bill.button
                                  : null}
                              </Box>{" "}
                              <Flex
                                id="getStartedSvg"
                                sx={{
                                  width: "11px",
                                  height: "13px",
                                  // ml: hover ? '5px' : '5px',
                                }}
                              >
                                {svg.getStartedBlue}
                              </Flex>
                            </Button>
                          </Box>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Flex
                              sx={{
                                fontSize: "15px",
                                gap: "10px",
                                fontWeight: "700",
                              }}
                            >
                              <Flex>{bill.title}</Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {bill.detail.map((text) => {
                              return (
                                <Flex sx={{ fontSize: "15px", gap: "10px" }}>
                                  <Flex>
                                    <CheckIcon
                                      sx={{
                                        m: "20px",
                                        color: "green",
                                        "&:hover": {
                                          transform: "scale(1.2)",
                                          transition: "transform 0.15s",
                                        },
                                      }}
                                    />
                                  </Flex>

                                  <Flex>{text}</Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                        <Modal
                          isOpen={showConfirmation.Topup}
                          onClose={handleCancel}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalBody>
                              Are you sure you want to update Topup Plan?
                            </ModalBody>
                            <ModalFooter sx={{ gap: "10px" }}>
                              <Button
                                sx={{
                                  bg: "rgb(21,46,115)",
                                  color: "white",
                                  "&:hover": { color: "black" },
                                }}
                                onClick={() => {
                                  if (
                                    currentPlan.Topup.plan_type === "AddOn" &&
                                    currentPlan.Topup.price > 0
                                  ) {
                                    handleCart("Topup");
                                    handleUpdatePlan("Topup");
                                    handleCancel();
                                  }
                                }}
                              >
                                Yes
                              </Button>
                              <Button colorScheme="red" onClick={handleCancel}>
                                No
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    );
                  })}
                </BillOptionContainer>
                <Flex
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "21",
                  }}
                >
                  {" "}
                  Life Time Deal
                </Flex>
                <Flex
                  sx={{
                    height: "2px",
                    width: "100%",
                    backgroundImage:
                      "Linear-gradient(90deg,rgba(56,189,248,0) 0%,#0EA5E9 32.29%,rgba(236,72,153,.3) 67.19%,rgba(236,72,153,0) 100%);",
                  }}
                ></Flex>
                <BillOptionContainer
                  sx={{
                    px: "5%",
                    gap: "40px",
                    width: "100%",
                    mb: "10%",
                    flexWrap: "wrap",
                  }}
                >
                  {LifeTimeDeal.map((bill, i) => {
                    const index = LtdTasks.findIndex(
                      (item) => item.value === currentPlan?.LTD?.id
                    );
                    return (
                      <>
                        <Flex
                          //   onClick={() => {
                          //     setActiveBillCard(bill.heading);
                          //   }}
                          width="22%"
                          className=" shadow-lg hover:shadow-lg shadow-[#D1FDD0]"
                          sx={{
                            cursor: "pointer",
                            fontSize: "15px",
                            pt: "12px",
                            pb: "40px",
                            outline:
                              activeBillCard === i
                                ? "2px solid #D1FDD0"
                                : "2px solid #E8EAFA",
                            alignItems: "center",
                            "&:hover": {
                              transition: "box-shadow 0.2s",
                              boxShadow:
                                activeBillCard === i
                                  ? "5px 8px 5px #E8EAFA"
                                  : "8px 8px 5px  #E8EAFA",
                            },
                            backgroundImage:
                              activePlan?.LTD?.length > 0
                                ? "linear-gradient(325deg,#9CF98A,#D1FDD0)"
                                : null,

                            flexDirection: "column",
                          }}
                        >
                          <Flex
                            sx={{ justifyContent: "end", marginLeft: "auto" }}
                          >
                            <SlOptionsVertical />
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto 27px",
                              fontSize: "15px",
                              color: "#152d73",
                              alignSelf: "flex-start",
                            }}
                          >
                            {bill.heading}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              fontSize: "30px",
                              color:
                                activeBillCard === "LTD" ? "#152d73" : "#000",
                            }}
                            //   key = {defaultTeamTask}
                          >
                            {i === 0 ? <>${selectedPrices.Ltd}</> : null}
                            {/* {'$' + selectedPrices?.Ltd} */}
                          </Flex>
                          <Flex
                            sx={{
                              m: "0px auto",
                              color: "#999999",
                              fontSize: "15px",
                            }}
                          >
                            {/* {bill.time} */}
                          </Flex>
                          <Box sx={{ m: "20px auto", width: "100%" }}>
                            {/* <ReactSelect /> */}
                          </Box>
                          {i === 0 ? (
                            <Select
                              key={
                                currentPlan?.LTD.description
                                  ? currentPlan?.LTD?.description
                                  : LtdTasks[0]
                              }
                              defaultValue={
                                !currentPlan.LTD.price
                                  ? {
                                      label: LtdTasks[0]?.label,
                                      value: LtdTasks[0]?.value,
                                    }
                                  : {
                                      label: sliceDescription(
                                        currentPlan.LTD.description
                                      ),
                                      value: currentPlan.LTD.id,
                                    }
                              }
                              onChange={onChangeLtd}
                              styles={selectStyle}
                              options={activeBillOption ? LtdTasks : LtdTasks}
                            />
                          ) : null}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: "20px",
                            }}
                          >
                            <Button
                              sx={{
                                fontSize: "15px",
                                py: "2px",
                                backgroundColor: "#152d73",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                px: "10px",
                                height: "40px",
                                borderRadius: "0px !important",
                                "&:hover": {
                                  transition: "width 1s",
                                  backgroundColor: "#3a48d6",
                                },
                                "&:disabled": {
                                  transition: "width 1s",
                                  backgroundColor: "",
                                  "&:hover": {
                                    transition: "width 1s",
                                    backgroundColor: "#152d73",
                                  },
                                },
                              }}
                              isDisabled={
                                bill?.heading === hasCurrentPlan?.name
                              }

                              // onMouseEnter={() => setHover(true)}
                              // onMouseLeave={() => setHover(false)}
                            >
                              <Box
                                onClick={() => {
                                  if (activePlan?.LTD?.length > 0) {
                                    // handleCart(bill.heading);
                                    // handleUpdatePlan();
                                    selectPlanUpdate(bill.heading);
                                  } else {
                                    openCheckout(bill.heading);
                                  }

                                  //   openCheckout(bill.heading);
                                }}
                                isDisabled={true}
                              >
                                {activePlan?.LTD?.length > 0
                                  ? "Current Plan"
                                  : bill.button}
                              </Box>{" "}
                              <Flex
                                id="getStartedSvg"
                                sx={{
                                  width: "11px",
                                  height: "13px",
                                  // ml: hover ? '5px' : '5px',
                                }}
                              >
                                {svg.getStartedBlue}
                              </Flex>
                            </Button>
                          </Box>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <Flex
                              sx={{
                                fontSize: "15px",
                                gap: "10px",
                                fontWeight: "700",
                              }}
                            >
                              <Flex>{bill.title}</Flex>
                            </Flex>
                          </Flex>
                          <Flex
                            sx={{
                              m: "15px 0px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {bill.detail.map((text) => {
                              return (
                                <Flex sx={{ fontSize: "15px", gap: "10px" }}>
                                  <Flex>
                                    <CheckIcon
                                      sx={{
                                        m: "auto 0px",
                                        color: "green",
                                        "&:hover": {
                                          transform: "scale(1.2)",
                                          transition: "transform 0.15s",
                                        },
                                      }}
                                    />
                                  </Flex>

                                  <Flex>{text}</Flex>
                                </Flex>
                              );
                            })}
                          </Flex>
                        </Flex>
                        <Modal
                          isOpen={showConfirmation.LTD}
                          onClose={handleCancel}
                        >
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirmation</ModalHeader>
                            <ModalBody>
                              Are you sure you want to update Life Time Deal
                              Plan?
                            </ModalBody>
                            <ModalFooter sx={{ gap: "10px" }}>
                              <Button
                                sx={{
                                  bg: "rgb(21,46,115)",
                                  color: "white",
                                  "&:hover": { color: "black" },
                                }}
                                onClick={() => {
                                  handleCart(bill.heading);
                                  handleUpdatePlan(bill.heading);
                                  handleCancel();
                                }}
                              >
                                Yes
                              </Button>
                              <Button colorScheme="red" onClick={handleCancel}>
                                No
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    );
                  })}
                </BillOptionContainer>
                {(!activePlan ||
                  activePlan?.Basic?.length === undefined ||
                  activePlan?.LTD?.length === undefined ||
                  activePlan.Topup === undefined) &&
                cart &&
                cart.length > 0 ? (
                  <Flex
                    key={cart}
                    sx={{
                      cursor: "pointer",
                      position: "fixed",
                      bottom: "6%",
                      right: "8%",
                      width: "84%",
                      backgroundColor: "#f3f7ff",
                      height: "50px",
                      justifyContent: "end",
                      alignContent: "center",
                    }}
                  >
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Button sx={{ bg: "#fff" }} onClick={clearCart}>
                        Clear Cart
                      </Button>
                      <Button
                        onClick={openCheckout}
                        sx={{
                          backgroundColor: "#152d73",
                          color: "white",
                          "&:hover": {
                            transition: "width 1s",
                            backgroundColor: "#3a48d6",
                          },
                        }}
                      >
                        Checkout( {cart.length})
                      </Button>
                    </Flex>
                  </Flex>
                ) : null}
              </>
            ) : (
              <>
                {" "}
                <NodeLoader
                  loading={loading || !updateLoading}
                  bgcolor="#fff"
                />
              </>
            )}
          </Flex>{" "}
        </Flex>{" "}
      </Flex>
    </>
  );
};
