import styled from "@emotion/styled";
import { Box, Button, Flex, Input } from "@chakra-ui/react";
import { MentionInput } from "../MentionInput";

export const AppWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100vh;
  margin: 0px;
  overflow: hidden;
`;

export const VisualBuilderWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: rgb(243, 247, 255);
  margin: 0px;
`;

export const EventWrapper = styled(Box)`
  display: ${(props) => (props.visibility ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;
  margin-bottom: ${(props) => (props.last === "true" ? "10px" : "0px")};
  padding-right: ${(props) => (props.child === "true" ? "0px" : "16px")};
  padding-left: ${(props) => (props.child === "true" ? "0px" : "16px")};
`;

export const NodeOuter = styled(Box)`
  display: flex;
  cursor: default;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: ${(props) =>
    props.selected
      ? "0px 0px 14px 0px rgb(81, 142, 248, 0.55)"
      : "0px 0px 3px 0px rgb(81, 142, 248, 0.35)"};
  border: 1px solid #d9d9d9;
  position: relative;
  width: ${(props) => (props.expand === "true" ? "560px" : "360px")};
  flex-direction: column;
  gap: 20px;
  padding-bottom: 16px;
  transition: width 0.25s, height 0.25s;
  min-height: ${(props) => (props.expandheight === "true" ? "500px" : "70px")};
  height: 100%;
  &:hover {
    box-shadow: 0px 0px 14px 0px rgb(81, 142, 248, 0.55);
  }
`;

export const CaptureButton = styled(Button)`
  background-color: #f8bf08;
  color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 18;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  &:hover {
    background-color: #f8c520;
  }
`;

export const BasicInput = styled(Input)`
  border: 1px solid #b7c9ff;
  background-color: #f3f7ff;
`;

export const DashButton = styled(Button)`
  border-top: 1px solid #f7c544;
  border-right: 1px solid #f7c544;
  border-left: 1px solid #f7c544;
  border-bottom: 3px solid #f7c544;
  border-radius: 4px;
  background-color: inherit;
  color: #f7c544;
  padding-right: 30px;
  padding-left: 30px;
  &:hover {
    color: rgb(21, 46, 115);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 3px solid #fff;
  }
`;
export const MentionsInput = styled(MentionInput)`
  flex: 1;
  border: 0px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  padding: 14px;

  resize: none;
  background: ${(props) => (props.dirty ? "#FFF6F3" : "#f3f7ff")};
  border: ${(props) => (props.dirty ? "1px solid #F74545" : "0px")};
  &::placeholder {
    color: ${(props) => (props.dirty ? "#F74545" : "#000000")};
  }
  &:disabled {
    background: #f3f7ff;
  }
  box-sizing: border-box;
`