import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { onLinkDelete } from "../../../store/slice/canvasSlice";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from "reactflow";
import { useSelector, useDispatch } from "react-redux";

export const ButtonEdge = ({
  data,
  selected,
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const dispatch = useDispatch();
  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    dispatch(onLinkDelete({ edge: id, selected: true }));
  };
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          stroke: selected ? "#f5574c" : "#f7c544",
          strokeWidth: 2,
          strokeDasharray: selected ? "5 5" : "none",
        }}
      />
      <EdgeLabelRenderer>
        <Tooltip arrow title="Firstname => Subject">
          <div
            style={{
              visibility: selected ? "visible" : "hidden",
              zIndex: selected ? 1 : -1000,
              opacity: selected ? 1 : 0,
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              transition: "opacity 0.25s, visibility 0.25s",
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <button
              className="edgebutton"
              style={{ color: "#fff", fontSize: 20 }}
              onClick={(event) => onEdgeClick(event, id)}
            >
              ×
            </button>
          </div>
        </Tooltip>
      </EdgeLabelRenderer>
    </>
  );
};
