import {
    Flex,
    Input,
    Box,
    Tooltip,
    Text,
    InputGroup,
    InputLeftAddon,
    Spinner,
    Progress,
    useToast,
    Icon,
  } from "@chakra-ui/react";
  import React, { memo, useEffect, useState } from "react";
  import { svg, svg_1 } from "../../assets/objectSVG";
  import { DeleteIcon, EditIcon, RepeatIcon } from "@chakra-ui/icons";
  import { FaRegSave } from "react-icons/fa";
  import {
    editAuthLabelPostIApps,
    editAuthLabelPost,
    getLinkedAccountsinModal,
    getOauthData,
    saveOauthData,
    saveTwitterCred,
    updateAppAccountStatusInApps,
  } from "../../store/thunk/appsThunk";
  import { useDispatch, useSelector } from "react-redux";
  import { useTranslation } from "react-i18next";
  import AuthTokenService from "../../utils/AuthTokenService";
  import { NodeLoader } from "../loader";
  import { setModalData } from "../../store/appsSlice";
  let browser = window;
  let popup = null;
  let Oauthtimer = null;
  export const AccountModalInapps = memo(
    ({ setModalState, setExpand, setExpandHeight, type, parentnode }) => {
      const dispatch = useDispatch();
      const modalData = useSelector((state) => state.apps.modalData);
      const twitterCredAvail = useSelector((state) => state.auth.twitter_cred);
      const [editAccount, setEditAccount] = useState();
      const [accountName, setAccountName] = useState();
      const [fieldsData, setFieldsData] = useState({});
      const [loading, setLoading] = useState(false);
      const [spinLoad, setSpinLoad] = useState(false);
      const [selectedAccount, setSelectedAccount] = useState();
      const [reAuthorise, setReAuthorise] = useState(false);
      const [appUserId, setAppUserId] = useState(null);
      const toast = useToast();
      const { t } = useTranslation();
      const [twitter, setTwitter] = useState(false);
      const staticTwitterFields = [
        {
          id: "apikey",
          name: "API KEY",
          instructions: [
            "Login to your Rebrandly account",
            "Goto API option in right corner below your profile pic",
            "Click on New API Key <a href='https://app.rebrandly.com/account/api' target='_blank'> refer this link </a>",
          ],
        },
      ];
      const watcher = () => {
        if (popup === null) {
          clearInterval(Oauthtimer);
          Oauthtimer = null;
          setLoading(false);
        } else if (popup !== null && !popup.closed) {
          popup.focus();
        } else if (popup !== null && popup.closed) {
          clearInterval(Oauthtimer);
          dispatch(getLinkedAccountsinModal(modalData)
            ).then((res) => {
                dispatch(
                    setModalData({
                      ...modalData,
                      accounts: res.payload.authorized_app_accounts,
                    })
                  );
          });
          setLoading(false);
          Oauthtimer = null;
          popup = null;
        }
      };
      useEffect(() => {
        if (modalData.auto) {
          handleAddAccount();
        }
      }, []);
  
      const handleAddAccount = () => {
        if (modalData.auth_type === "Token") {
          setLoading(true);
  
          dispatch(
            getOauthData({
              app_id: modalData.id,
              redirect_url: process.env.APP_BASE_URL,
            })
          ).then(() => setLoading(false));
        } else if (modalData.auth_type !== "Token" && modalData.provider === "twitter") {
          setTwitter(true);
        } else {
          setLoading(true);
          if (popup && !popup.closed) {
            popup.focus();
            return;
          }
          const name = "OAuth Permissions";
          const opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=800, height=600`;
          popup = browser.open(
            `${process.env.APP_BASE_URL + "oauth/response"}/${
              modalData.id
            }/${AuthTokenService.get()}`,
            name,
            opts
          );
          if (Oauthtimer === null) {
            Oauthtimer = setInterval(watcher, 2000);
          }
        }
      };
  
      const checkData = (data) => {
        let flag = true;
        for (let i = 0; i < modalData.addingAccount.fields.length; i++) {
          if (
            !fieldsData[modalData?.addingAccount?.fields[i].id] ||
            fieldsData[modalData?.addingAccount?.fields[i].id].length == 0
          ) {
            flag = false;
            break;
          }
        }
        return flag;
      };
  
      const saveTokenData = () => {
        if (!spinLoad && fieldsData && checkData(fieldsData)) {
          setSpinLoad(true);
          setLoading(true);
          dispatch(
            saveOauthData(
              reAuthorise
                ? {
                    app_id: modalData.id,
                    app_user_id: appUserId,
                    auth_response: fieldsData,
                  }
                : { app_id: modalData.id, auth_response: fieldsData }
            )
          ).then((res) => {
            if (modalData.nodeId) {
            }
  
           dispatch(getLinkedAccountsinModal(modalData)
            ).then((res) => {
             dispatch(
                    setModalData({
                      ...modalData,
                      accounts: res.payload.authorized_app_accounts,
                    })
                  );
  
              setLoading(false);
              setSpinLoad(false);
            });
            if (res.type === "saveOauthData/fulfilled") {
              toast({
                position: "top",
                status: "success",
                variant: "solid",
                title: reAuthorise
                  ? "Re-Authorise process Completed"
                  : res.payload.status,
                duration: 2500,
                containerStyle: {
                  fontWeight: 400,
                },
              });
            }
            if (res.type === "saveOauthData/rejected") {
              toast({
                position: "top",
                status: "error",
                variant: "solid",
                title: res.payload.status,
                duration: 2500,
                containerStyle: {
                  fontWeight: 400,
                },
              });
            }
          });
        }
      };
  
      if (modalData) {
        return loading ? (
          <NodeLoader loading={loading} bgcolor="#fff" />
        ) : !modalData?.addingAccount ? (
          twitter ? (
            <>
              <Flex
                sx={{
                  p: "15px",
                  flexDir: "column",
                  bgColor: "#fff",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  gap: ["10px", "10px", "15px", "15px", "15px", "17px"],
                  height: "100%",
                  overflowY: "auto",
                  backgroundColor: "white",
                }}
              >
                <Flex sx={{ justifyContent: "space-between", flexDir: "column" }}>
                  <Flex sx={{ padding: "15px", justifyContent: "space-between" }}>
                    <Flex
                      sx={{
                        m: "auto 0px",
                        alignItems: "center",
                        gap: "5px",
                        fontSize: [14, 14, 14, 15, 16, 18],
                      }}
                    >
                      <Box
                        sx={{
                          display: modalData.accounts.length ? "flex" : "none",
                          height: "1em",
                        }}
                      >
                        {svg_1.expand}
                      </Box>
                      {modalData.accounts.length
                        ? "API Integration"
                        : "No authorized account found."}
                    </Flex>
                    <Flex gap="10px">
                      <Flex
                        onClick={() => {
                          setLoading(true);
                          dispatch(getLinkedAccountsinModal(modalData)
                          ).then((res) => {
                            dispatch(
                              setModalData({
                                nodeId: modalData.nodeId,
                                type: "Account",
                              })
                            );
                            setLoading(false);
                            setSpinLoad(false);
                          });
                        }}
                        sx={{
                          px: "20px",
                          bgColor: "#152d73",
                          borderRadius: "4px",
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          "&:hover": {
                            bgColor: "#152d73",
                            color: "red",
                          },
                          display: type ? "flex" : "none",
                        }}
                      >
                        Back
                      </Flex>
                      <Flex
                        onClick={saveTokenData}
                        sx={{
                          bgColor: "#f6bd2c",
                          color: "#1e419d",
                          px: "15px",
                          py: "6px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          fontSize: [14, 14, 14, 15, 16, 18],
                          "&:hover": {
                            "& #icon": {
                              fill: "#fff",
                            },
                            bgColor: "#1e419d",
                            color: "#fff",
                          },
                          transition: "background 0.15s, color 0.15s",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {spinLoad ? (
                          <Spinner
                            thickness="3px"
                            speed="1.5s"
                            emptyColor="gray.200"
                            color="152E73"
                            boxSize="1.2em"
                          />
                        ) : (
                          <Flex
                            id="icon"
                            sx={{
                              fill: "#1e419d",
                              m: "auto 0px",
                              transition: "fill 0.15s",
                            }}
                          >
                            {svg.check}
                          </Flex>
                        )}
                        Add
                      </Flex>
                    </Flex>
                  </Flex>
                  <Text
                    sx={{
                      fontSize: "20px",
                      color: "#152e73",
                      fontWeight: "normal",
                      textAlign: "center",
                      margin: "10px auto",
                    }}
                  >
                    {t("apps.accessAPIModalHeaderText1")}
                    {modalData.name}
                    {t("apps.accessAPIModalHeaderText2")}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      padding: "15px",
                      gap: "10px",
                      overflow: "auto",
                    }}
                  >
                    {staticTwitterFields &&
                      staticTwitterFields.map((token, index) => {
                        if (index < 2 || index + 1) {
                          return (
                            <Flex
                              key={index}
                              style={{
                                width: "100%",
                                marginBottom: "10px",
                                textAlign: "center",
                                padding: "1%",
                                gap: "5px",
                                flexDirection: "column",
                              }}
                            >
                              <Text
                                sx={{
                                  fontSize: "md",
                                  color: "app.primary.30",
                                  marginBottom: "5px",
                                }}
                              >
                                {token.name}
                                <span style={{ color: "#E84539" }}>
                                  {t("apps.requiredText")}
                                </span>
                              </Text>
                              <Box
                                sx={{
                                  display: "flex",
                                  border: "0px solid red",
                                  height: "50px",
                                  gap: "10px",
                                }}
                              >
                                <InputGroup
                                  sx={{
                                    border: "1px solid #b7caff",
                                    height: "40px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <InputLeftAddon
                                    children={svg.key}
                                    sx={{
                                      backgroundColor: "#F3F7FF",
                                      border: "none",
                                      height: "100%",
                                    }}
                                  />
                                  <Input
                                    type={
                                      token.name === "Password"
                                        ? "password"
                                        : "text"
                                    }
                                    //   defaultValue={fieldsData[token.id]}
                                    sx={{
                                      // border:"1px solid #b7caff",
                                      color: "black",
                                      padding: "0px",
                                      // width: "80%",
                                      fontSize: "16px",
                                      border: "0px solid red",
                                      height: "100%",
                                      backgroundColor: "#F3F7FF",
                                      padding: "2%",
  
                                      outline: "none",
                                      "::placeholder": {
                                        textAlign: "left",
                                      },
                                      "&:focus": {
                                        border: "1px solid #b7caff",
                                        border: "none",
                                        outline: "none",
                                      },
                                    }}
                                    width="100%"
                                    className="map"
                                    placeholder={`Enter your ${token.name}`}
                                    onChange={(evt) =>
                                      setFieldsData({
                                        ...fieldsData,
                                        [token.id]: evt.currentTarget.value,
                                      })
                                    }
                                  />
                                </InputGroup>
                              </Box>
                              <Box>
                                {token.instructions ? (
                                  <div
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {t("apps.followInstrusctionText")}
                                  </div>
                                ) : (
                                  <Text className="text" fontSize={14}>
                                    {t("apps.accessAPIModalMainText1")}{" "}
                                    {token.name}
                                    {t("apps.accessAPIModalMainText2")}
                                    {t("apps.accessAPIModalMainText3")}
                                  </Text>
                                )}
                                {token.instructions ? (
                                  <Box
                                    sx={{
                                      p: "5px",
                                      textAlign: "left",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <ul>
                                      {eval(token.instructions).map((name, i) => (
                                        <div key={i} style={{ display: "flex" }}>
                                          {i + 1 + ". " + " "}&nbsp;
                                          <div
                                            key={i}
                                            style={{ margin: "0" }}
                                            dangerouslySetInnerHTML={{
                                              __html: name,
                                            }}
                                          ></div>
                                        </div>
                                      ))}
                                    </ul>
                                  </Box>
                                ) : null}
                              </Box>
                            </Flex>
                          );
                        }
                      })}
                  </div>
                </Flex>{" "}
              </Flex>
            </>
          ) : (
            <Flex
              sx={{
                p: "15px",
                flexDir: "column",
                bgColor: "#fff",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                gap: ["10px", "10px", "15px", "15px", "15px", "17px"],
                height: "100%",
                overflowY: "auto",
                backgroundColor: "white",
              }}
            >
              <Flex sx={{ justifyContent: "space-between" }}>
                <Flex
                  sx={{
                    m: "auto 0px",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: [14, 14, 14, 15, 16, 18],
                  }}
                >
                  <Box
                    sx={{
                      display: modalData?.accounts?.length ? "flex" : "none",
                      height: "1em",
                    }}
                  >
                    {svg_1.expand}
                  </Box>
                  {modalData?.accounts?.length
                    ? "Authorized Accounts"
                    : "No authorized account found."}
                </Flex>
                <Flex gap="10px">
                  <Flex
                    onClick={() => {
                      setExpandHeight(false);
                      setExpand(false);
                      setModalState({ display: false, type: null });
                      dispatch(getLinkedAccountsinModal(modalData)
            ).then((res) => {
                        setSpinLoad(false);
                      });
                    }}
                    sx={{
                      px: "20px",
                      bgColor: "#152d73",
                      borderRadius: "4px",
                      cursor: "pointer",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      "&:hover": {
                        bgColor: "#152d73",
                        color: "red",
                      },
                      display: type ? "flex" : "none",
                    }}
                  >
                    Back
                  </Flex>
                  <Flex
                    onClick={handleAddAccount}
                    sx={{
                      bgColor: "#f6bd2c",
                      color: "#1e419d",
                      px: "15px",
                      py: "6px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: [14, 14, 14, 15, 16, 18],
                      "&:hover": {
                        "& #icon": {
                          fill: "#fff",
                        },
                        bgColor: "#1e419d",
                        color: "#fff",
                      },
                      transition: "background 0.15s, color 0.15s",
                      gap: "5px",
                    }}
                  >
                    <Flex
                      id="icon"
                      sx={{
                        width: "20px",
                        fill: "#1e419d",
                        transition: "fill 0.15s",
                      }}
                    >
                      {svg.addAccount}
                    </Flex>
                    {t("apps.addAccountText")}
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                width="100%"
                pos={type ? "relative" : "unset"}
                height="100%"
              >
                <Flex
                  onMouseEnter={() =>
                    parentnode?.current.classList.add("nowheel")
                  }
                  onMouseLeave={() =>
                    parentnode?.current.classList.remove("nowheel")
                  }
                  flexDir="column"
                  width="100%"
                  sx={{
                    position: type ? "absolute" : "unset",
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    gap: ["10px", "10px", "15px", "15px", "15px", "17px"],
                  }}
                >
                  {modalData.accounts?.map((account, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Flex
                          key={i}
                          sx={{
                            border: "1px solid #b7caff",
                            bgColor: "#f3f7ff",
                            borderRadius: "4px",
                            position: "relative",
                            px: "10px",
                            py: ["6px", "6px", "8px", "8px", "8px", "8px"],
                            justifyContent: "space-between",
                            "&:hover": {
                              border: "1px solid #152d73",
                            },
                          }}
                        >
                          {editAccount === i ? (
                            <Input
                              size={{
                                base: "sm",
                                sm: "sm",
                                md: "sm",
                                lg: "md",
                                xl: "md",
                                "2xl": "md",
                              }}
                              width="70%"
                              value={accountName}
                              onChange={(e) => {
                                setAccountName(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if(e.key === "Enter"){
                                  
                                    setSelectedAccount(i);
                                    setAccountName(account.name);
                                    dispatch(
                                        editAuthLabelPostIApps({
                                        id: account.id,
                                        label: accountName,
                                      })
                                    ).then(() => {
                                        dispatch(getLinkedAccountsinModal(modalData)
                                        )
                                      setEditAccount(null);
                                      setSelectedAccount(null);
                                    });
                                  }
                                
                              }}
                              sx={{
                                bgColor: "#fff",
                                fontSize: [14, 14, 15, 16, 16, 18],
                              }}
                            />
                          ) : (
                            <Flex sx={{ fontSize: [14, 14, 15, 16, 16, 18] }}>
                              {account.name}
                            </Flex>
                          )}
                          <Flex sx={{ gap: "20px", alignItems: "center" }}>
                            <Tooltip label="Edit" hasArrow>
                              <EditIcon
                                onClick={() => {
                                  setEditAccount(i);
                                  setAccountName(account.name);
                                }}
                                color="#152d73"
                                sx={{
                                  display: editAccount === i ? "none" : "flex",
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.1)",
                                    color: "#f7c544",
                                  },
                                  transition: "transform 0.15s, color 0.15s",
                                }}
                              />
                            </Tooltip>
                            {editAccount === i ? (
                              <Tooltip label="Save" hasArrow>
                                <Icon
                                  as={FaRegSave}
                                  onClick={() => {
                                    setSelectedAccount(i);
                                    setAccountName(account.name);
                                    dispatch(
                                        editAuthLabelPostIApps({
                                        id: account.id,
                                        label: accountName,
                                      })
                                    ).then(() => {
                                        dispatch(getLinkedAccountsinModal(modalData)
                                        )
                                      setEditAccount(null);
                                      setSelectedAccount(null);
                                    });
                                  }}
                                  color="#152d73"
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      color: "#f7c544",
                                    },
                                    transition: "transform 0.15s, color 0.15s",
                                  }}
                                />
                              </Tooltip>
                            ) : null}
  
                            <Tooltip label="Re-authorize" hasArrow>
                              <RepeatIcon
                                color="#152d73"
                                onClick={() => {
                                  handleAddAccount();
                                  setReAuthorise(true);
                                  setAppUserId(account.id);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.1)",
                                  },
                                  transition: "transform 0.15s, color 0.15s",
                                }}
                              />
                            </Tooltip>
                            <Tooltip label="Delete" hasArrow>
                              <DeleteIcon
                                onClick={() => {
                                  setSelectedAccount(i);
                                  dispatch(
                                    updateAppAccountStatusInApps({
                                      appId: account.id,
                                      status: "REMOVE",
                                    })
                                  ).then((res) => {
                                    setSelectedAccount(null);
                                    if (
                                      res.type ===
                                        "updateAppAccountStatusInApps/fulfilled" &&
                                      res.payload.message === "SUCCESS"
                                    ) {
                                      toast({
                                        position: "top",
                                        status: "success",
                                        variant: "solid",
                                        title: res.payload.details,
                                        duration: 2500,
                                        containerStyle: {
                                          fontWeight: 400,
                                        },
                                      });
                                    }
                                    if (
                                      res.type ===
                                        "updateAppAccountStatusInApps/fulfilled" &&
                                      res.payload.message === "FAILURE"
                                    ) {
                                      toast({
                                        position: "top",
                                        status: "error",
                                        variant: "solid",
                                        title:
                                          "Cannot delete this account as it contains active Konnectz",
                                        duration: 2500,
                                        containerStyle: {
                                          fontWeight: 400,
                                        },
                                      });
                                    }
                                  });
                                }}
                                color="#152d73"
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    transform: "scale(1.1)",
                                    color: "#ff0000",
                                  },
                                  transition: "transform 0.15s, color 0.15s",
                                }}
                              />
                            </Tooltip>
                          </Flex>
                          <Progress
                            size="xs"
                            isIndeterminate
                            sx={{
                              display: selectedAccount === i ? "flex" : "none",
                              position: "absolute",
                              bottom: "0px",
                              left: "0px",
                              width: "100%",
                            }}
                          />
                        </Flex>
                      </React.Fragment>
                    );
                  })}
                </Flex>
              </Flex>
            </Flex>
          )
        ) : (
          <>
            <Flex
              sx={{
                backgroundColor: "white",
                flexDir: "column",
                overflowY: "auto",
                height: "100%",
              }}
            >
              {" "}
              <Flex sx={{ padding: "15px", justifyContent: "space-between" }}>
                <Flex
                  sx={{
                    m: "auto 0px",
                    alignItems: "center",
                    gap: "5px",
                    fontSize: [14, 14, 14, 15, 16, 18],
                  }}
                >
                  <Box
                    sx={{
                      display: modalData.accounts.length ? "flex" : "none",
                      height: "1em",
                    }}
                  >
                    {svg_1.expand}
                  </Box>
                  {modalData.accounts.length
                    ? "API Integration"
                    : "No authorized account found."}
                </Flex>
                <Flex gap="10px">
                  <Flex
                    onClick={() => {
                      setLoading(true);
                      dispatch(getLinkedAccountsinModal(modalData)
                      ).then((res) => {
                        dispatch(
                          setModalData({
                            nodeId: modalData.nodeId,
                            type: "Account",
                          })
                        );
                        setLoading(false);
                        setSpinLoad(false);
                      });
                    }}
                    sx={{
                      px: "20px",
                      bgColor: "#152d73",
                      borderRadius: "4px",
                      cursor: "pointer",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      "&:hover": {
                        bgColor: "#152d73",
                        color: "red",
                      },
                      display: type ? "flex" : "none",
                    }}
                  >
                    Back
                  </Flex>
                  <Flex
                    onClick={saveTokenData}
                    sx={{
                      bgColor: "#f6bd2c",
                      color: "#1e419d",
                      px: "15px",
                      py: "6px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: [14, 14, 14, 15, 16, 18],
                      "&:hover": {
                        "& #icon": {
                          fill: "#fff",
                        },
                        bgColor: "#1e419d",
                        color: "#fff",
                      },
                      transition: "background 0.15s, color 0.15s",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {spinLoad ? (
                      <Spinner
                        thickness="3px"
                        speed="1.5s"
                        emptyColor="gray.200"
                        color="152E73"
                        boxSize="1.2em"
                      />
                    ) : (
                      <Flex
                        id="icon"
                        sx={{
                          fill: "#1e419d",
                          m: "auto 0px",
                          transition: "fill 0.15s",
                        }}
                      >
                        {svg.check}
                      </Flex>
                    )}
                    Add
                  </Flex>
                </Flex>
              </Flex>
              <Text
                sx={{
                  fontSize: "20px",
                  color: "#152e73",
                  fontWeight: "normal",
                  textAlign: "center",
                  margin: "10px auto",
                }}
              >
                {t("apps.accessAPIModalHeaderText1")}
                {modalData.name}
                {t("apps.accessAPIModalHeaderText2")}
              </Text>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  padding: "15px",
                  gap: "10px",
                  overflow: "auto",
                }}
              >
                {modalData?.addingAccount?.fields &&
                  modalData?.addingAccount?.fields.map((token, index) => {
                    if (index < 2 || index + 1) {
                      return (
                        <Flex
                          key={index}
                          style={{
                            width: "100%",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "1%",
                            gap: "5px",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            sx={{
                              fontSize: "md",
                              color: "app.primary.30",
                              marginBottom: "5px",
                            }}
                          >
                            {token.name}
                            <span style={{ color: "#E84539" }}>
                              {t("apps.requiredText")}
                            </span>
                          </Text>
                          <Box
                            sx={{
                              display: "flex",
                              border: "0px solid red",
                              height: "50px",
                              gap: "10px",
                            }}
                          >
                            <InputGroup
                              sx={{
                                border: "1px solid #b7caff",
                                height: "40px",
                                borderRadius: "4px",
                              }}
                            >
                              <InputLeftAddon
                                children={svg.key}
                                sx={{
                                  backgroundColor: "#F3F7FF",
                                  border: "none",
                                  height: "100%",
                                }}
                              />
                              <Input
                                type={
                                  token.name === "Password" ? "password" : "text"
                                }
                                //   defaultValue={fieldsData[token.id]}
                                sx={{
                                  // border:"1px solid #b7caff",
                                  color: "black",
                                  padding: "0px",
                                  // width: "80%",
                                  fontSize: "16px",
                                  border: "0px solid red",
                                  height: "100%",
                                  backgroundColor: "#F3F7FF",
                                  padding: "2%",
  
                                  outline: "none",
                                  "::placeholder": {
                                    textAlign: "left",
                                  },
                                  "&:focus": {
                                    border: "1px solid #b7caff",
                                    border: "none",
                                    outline: "none",
                                  },
                                }}
                                width="100%"
                                className="map"
                                placeholder={`Enter your ${token.name}`}
                                onChange={(evt) =>
                                  setFieldsData({
                                    ...fieldsData,
                                    [token.id]: evt.currentTarget.value,
                                  })
                                }
                              />
                            </InputGroup>
                          </Box>
                          <Box>
                            {token.instructions ? (
                              <div
                                style={{
                                  marginTop: "10px",
                                  fontSize: "14px",
                                }}
                              >
                                {t("apps.followInstrusctionText")}
                              </div>
                            ) : (
                              <Text className="text" fontSize={14}>
                                {t("apps.accessAPIModalMainText1")} {token.name}
                                {t("apps.accessAPIModalMainText2")}
                                {t("apps.accessAPIModalMainText3")}
                              </Text>
                            )}
                            {token.instructions ? (
                              <Box
                                sx={{
                                  p: "5px",
                                  textAlign: "left",
                                  fontSize: "14px",
                                }}
                              >
                                <ul>
                                  {eval(token.instructions).map((name, i) => (
                                    <div key={i} style={{ display: "flex" }}>
                                      {i + 1 + ". " + " "}&nbsp;
                                      <div
                                        key={i}
                                        style={{ margin: "0" }}
                                        dangerouslySetInnerHTML={{
                                          __html: name,
                                        }}
                                      ></div>
                                    </div>
                                  ))}
                                </ul>
                              </Box>
                            ) : null}
                          </Box>
                        </Flex>
                      );
                    }
                  })}
              </div>
            </Flex>
          </>
        );
      }
    }
  );
  