// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the date picker wrapper */
.custom-datepicker .react-date-picker__wrapper {
display: flex;
	padding:6px 4px;
	background-color: #f3f7ff;
    border: 1px solid #b7caff;
  }
  .react-date-picker__calendar .react-calendar{
	position:absolute;
  }
  .react-time-picker__wrapper{
	padding:6px 4px;
	background-color: #f3f7ff;
    border: 1px solid #b7caff;
  }`, "",{"version":3,"sources":["webpack://./src/components/modal/style.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;AACA,aAAa;CACZ,eAAe;CACf,yBAAyB;IACtB,yBAAyB;EAC3B;EACA;CACD,iBAAiB;EAChB;EACA;CACD,eAAe;CACf,yBAAyB;IACtB,yBAAyB;EAC3B","sourcesContent":["/* Custom styles for the date picker wrapper */\n.custom-datepicker .react-date-picker__wrapper {\ndisplay: flex;\n\tpadding:6px 4px;\n\tbackground-color: #f3f7ff;\n    border: 1px solid #b7caff;\n  }\n  .react-date-picker__calendar .react-calendar{\n\tposition:absolute;\n  }\n  .react-time-picker__wrapper{\n\tpadding:6px 4px;\n\tbackground-color: #f3f7ff;\n    border: 1px solid #b7caff;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
