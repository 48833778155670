import { createSlice } from "@reduxjs/toolkit";
import {
  getLogsList,
  getTaskDetails,
  getTaskLogsList,
} from "./thunk/historyThunk";

const initialState = {
  logsLoading: true,
  taskLogsLoading: true,
  taskDetailsLoading: true,
  logsList: [],
  taskLogsList: [],
  taskDetails: [],
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetTask: (state, action) => {
      state.taskDetails = null;
      state.logsList = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLogsList.fulfilled, (state, action) => {
        state.logsLoading = false;
        state.logsList = action.payload.task_logs;
      })
      .addCase(getLogsList.rejected, (state, action) => {
        state.logsLoading = false;
      })
      .addCase(getTaskDetails.fulfilled, (state, action) => {
        state.taskDetailsLoading = false;
        state.taskDetails = action.payload.tasks;
      })
      .addCase(getTaskDetails.rejected, (state, action) => {
        state.taskDetailsLoading = false;
      })
      .addCase(getTaskLogsList.fulfilled, (state, action) => {
        state.taskLogsLoading = false;
        state.taskLogsList = action.payload.konnects;
      })
      .addCase(getTaskLogsList.rejected, (state, action) => {
        state.taskLogsLoading = false;
      });
  },
});

export const { resetTask } = historySlice.actions;

export default historySlice.reducer;
