import React, { useEffect, useRef, useState } from 'react';
import {
  Flex,
  Button,
  Collapse,
  Box,
  useDisclosure,
  Icon,
  Tooltip,
  Input,
  Spinner,
  Image,
  Progress,
  InputGroup,
  InputLeftElement,
  useToast,
} from '@chakra-ui/react';

import { BsFolderPlus, BsFolderMinus } from 'react-icons/bs';
import { GoSidebarExpand } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { AiOutlineRollback } from 'react-icons/ai';
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFolder,
  deleteFolder,
  getFoldersList,
  getKonnectsByFolder,
  getKonnectsList,
  moveKonnectsToFolder,
  renameFolderCall,
  updateKonnect,
  searchKonnectz,
} from '../../store/thunk/konnectzThunk';
import { dashSVG, svg_1, svg } from '../../assets/objectSVG';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  InfoIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import ReactSwitch from 'react-switch';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthTokenService from '../../utils/AuthTokenService';
import { setRoute } from '../../store/dashSlice';
import { NodeLoader } from '../../components/loader';
export const KonnectzContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { folderList, konnectzList, loading,loadingInPagination } = useSelector(
    (state) => state.konnectz
  );
  const toast = useToast();
  const [localList, setLocalList] = useState([]);
  const [localKonnectList, setLocalKonnectList] = useState([]);
  const [creating, setCreating] = useState(false);

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [folderName, setFolderName] = useState('');
  const [renameFolder, setRenameFolder] = useState(false);
  const [renameValue, setRenameValue] = useState('');
  const [id, setId] = useState(null);

  const [deleting, setDeleting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTooltip, setDeleteTooltip] = useState(false);

  const [openFolder, setOpenFolder] = useState();
  const newFolderInput = useRef();
  const newFolderContainer = useRef();
  const renameFolderInput = useRef();
  const folderBox = useRef([]);

  const [dragKonnect, setDragKonnect] = useState([]);
  const [dragging, setDragging] = useState();
  const [updateUI, setUpdateUI] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [deleteKonnect, setDeleteKonnect] = useState(false);
  const { t } = useTranslation();
  const [renderValue, setRerenderValue] = useState(0);
  useEffect(() => {
    dispatch(getFoldersList()).then((res) => {
      if (res.error) {
        dispatch(setRoute('Dashboard'));
        AuthTokenService.clear();
        navigate('/login');
      } else {
        setLocalList(res.payload.folders);
      }
    });
  }, [renameFolder, openFolder, updateUI]);

  useEffect(() => {
    dispatch(getKonnectsList({ page_size: pageSize })).then((res) => {
      if (res.error) {
        dispatch(setRoute('Dashboard'));
        AuthTokenService.clear();
        navigate('/login');
      } else {
        setLocalKonnectList(res.payload.konnects);
      }
    });
  }, [renderValue, pageSize]);

  useEffect(() => {
    if (localList.length > folderList.length && creating) {
      newFolderInput.current.focus();
    }
  }, [localList]);
  useEffect(() => {
    localStorage.setItem('editKonnectId', JSON.stringify(id));
    if (id > 0) {
      navigate('/konnect/' + id);
    }
  }, [id]);

  const handleChange = (e) => {
    if (!e.target.value) {
      setLocalKonnectList(konnectzList);
    } else {
      e.target.value.length > 1 &&
        dispatch(searchKonnectz({ search_query: e.target.value })).then(
          (res) => {
            if (res.payload.konnects.length > 0) {
              setLocalKonnectList(res.payload.konnects);
            } else setLocalKonnectList([]);
          }
        );
    }
  };
  return (
    <Flex
      id="folder_container"
      onDragOver={() => onOpen()}
      onMouseDown={(e) => {
        if (e.target.id !== newFolderContainer?.current?.id) {
          setCreating(false);
          setLocalList(localList.filter((c) => !c.creating));
        }
      }}
      width="100%"
      height="100%"
      sx={{ paddingY: '20px', gap: '20px', flexDirection: 'column' }}
    >
      <Flex
        width="100%"
        sx={{
          bgColor: '#fff',
          borderRadius: '4px',
          boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
          p: '20px',
          gap: '20px',
          flexDir: 'column',
        }}
      >
        {!openFolder ? (
          <>
            <Flex justifyContent="space-between">
              <Tooltip
                label={isOpen ? 'Collapse Folders' : 'Expand Folders'}
                hasArrow
              >
                <Flex
                  gap="10px"
                  alignItems="center"
                  onClick={onToggle}
                  cursor="pointer"
                >
                  <Icon
                    as={GoSidebarExpand}
                    boxSize="1.7em"
                    sx={{
                      cursor: 'pointer',
                      transform: isOpen ? 'rotate(90deg)' : 'rotate(270deg)',
                      transition: 'transform 0.15s',
                    }}
                  />
                  <Flex
                    sx={{
                      fontSize: [16, 16, 18, 20, 22, 24],
                      fontWeight: 500,
                      m: 'auto 0px',
                    }}
                  >
                    {t('konnectzList.folders.fodersLeftTitleTextL')}(
                    {folderList.length})
                  </Flex>
                </Flex>
              </Tooltip>
              <Button
                onClick={() => {
                  if (!creating) {
                    setLocalList((c) => [...c, { creating: true }]);
                    setFolderName('');
                    setCreating(true);
                    // setNewFolder(true);
                    onOpen();
                  }
                }}
                sx={{
                  borderTop: '1px solid #152D73',
                  borderRight: '1px solid #152D73',
                  borderLeft: '1px solid #152D73',
                  borderBottom: '3px solid #152D73',
                  fontSize: [14, 14, 14, 16, 16, 18],
                  borderRadius: '4px',
                  bgColor: 'inherit',
                  color: '#152D73',
                  px: '30px',
                  gap: '10px',
                  py: ['10px', '15px', '20px', '20px', '22px', '25px'],
                  '&:hover': {
                    '& #icon': {
                      fill: 'rgb(21,46,115)',
                    },
                    color: 'rgb(21,46,115)',
                    bgColor: '#F3F7FF',
                    borderTop: '1px solid #152D73',
                    borderRight: '1px solid #152D73',
                    borderLeft: '1px solid #152D73',
                    borderBottom: '3px solid #152D73',
                  },
                }}
              >
                <BsFolderPlus size="1.3em" />
                {t('konnectzList.folders.newFolder')}
              </Button>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
              <Flex
                sx={{
                  maxH: '350px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {localList.map((folder, index) => {
                  return !folder.creating ? (
                    <Box
                      id={`${folder.name + index}`}
                      px={2}
                      py={2}
                      width={1 / 6}
                      sx={{
                        position: 'relative',
                        '& #dragover': {
                          bgColor: '#F3F7FF',
                          border: '1px solid #152D73',
                        },
                      }}
                      key={'folder' + index}
                    >
                      <Button
                        ref={(ele) => (folderBox.current[index] = ele)}
                        onClick={() => {
                          setDragging(index);
                          if (folder.id) {
                            dispatch(getKonnectsByFolder(folder.id)).then(
                              (res) => {
                                setOpenFolder({
                                  ...folder,
                                  konnects: res.payload.konnects,
                                });
                                setRenameValue(folder.name);
                                setDragging(null);
                              }
                            );
                          } else {
                            setOpenFolder({
                              ...folder,
                              konnects: [],
                            });
                            setDragging(null);
                          }
                        }}
                        onDrop={() => {
                          setDragging(index);
                          dispatch(
                            moveKonnectsToFolder({
                              folderId: folder.id,
                              selectedKonnects: dragKonnect,
                            })
                          ).then(() => {
                            setDragging(null);
                            setDragKonnect([]);
                            setUpdateUI((c) => c + 1);
                          });
                          folderBox.current[index].id = '';
                        }}
                        className="folder-btn"
                        width="100%"
                        onDragOver={(e) => {
                          e.preventDefault();
                          folderBox.current[index].id = 'dragover';
                        }}
                        onDragLeave={(e) => (folderBox.current[index].id = '')}
                        sx={{
                          p: '30px 15px',
                          justifyContent: 'flex-start',
                          boxShadow: '0px 8px 6px -6px rgb(81,142,248, 0.35)',
                          border: '1px solid lightgray',
                          backgroundColor: '#FFFFFF',
                          color: '#152D73',
                          borderRadius: '4px',
                          fontSize: '14px',
                          fontWeight: 500,
                          '&:hover': {
                            bgColor: '#F3F7FF',
                            border: '1px solid #152D73',
                          },
                        }}
                        leftIcon={<Flex width="2.5em">{dashSVG.folder}</Flex>}
                      >
                        {' '}
                        <Tooltip label={folder.name} hasArrow>
                          <Flex
                            flexDir="column"
                            gap="3px"
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            <Flex
                              sx={{
                                fontSize: [14, 14, 14, 16, 18, 18],
                              }}
                            >
                              {folder.name}
                            </Flex>

                            <Flex
                              sx={{
                                color: '#E0D7D3',
                                fontSize: [10, 10, 10, 12, 12, 14],
                              }}
                            >
                              {folder.konnect_count} Konnectz
                            </Flex>
                          </Flex>
                        </Tooltip>
                        <Progress
                          size="xs"
                          isIndeterminate
                          sx={{
                            display: dragging === index ? 'flex' : 'none',
                            position: 'absolute',
                            bottom: '0px',
                            left: '0px',
                            width: '100%',
                          }}
                        />
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      ref={newFolderContainer}
                      onMouseDown={(e) => e.stopPropagation()}
                      id="new_folder_container"
                      px={2}
                      py={2}
                      width={1 / 6}
                      key={'folder' + index}
                    >
                      <Button
                        onClick={() => {
                          if (!creating) {
                            setOpenFolder({
                              konnect_count: 0,
                              name: folderName,
                              konnects: [],
                            });
                            setRenameValue(folderName);
                          }
                        }}
                        className="folder-btn"
                        width="100%"
                        sx={{
                          p: '30px 15px',
                          justifyContent: 'flex-start',
                          boxShadow: '0px 8px 6px -6px rgb(81,142,248, 0.35)',
                          display: creating ? 'flex' : 'none',
                          border: '1px solid lightgray',
                          backgroundColor: '#FFFFFF',
                          color: '#152D73',
                          borderRadius: '4px',
                          fontSize: '14px',
                          fontWeight: 500,
                          '&:hover': {
                            bgColor: '#F3F7FF',
                            border: '1px solid #152D73',
                          },
                        }}
                        leftIcon={<Flex width="2.5em">{dashSVG.folder}</Flex>}
                      >
                        <Flex flexDir="column" gap="3px">
                          {creating ? (
                            <Input
                              size="sm"
                              value={folderName}
                              onChange={(e) => setFolderName(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  if (!e.target.value) {
                                    // setNewFolder(false);
                                    setCreating(false);
                                    setLocalList(
                                      localList.filter((c) => !c.creating)
                                    );
                                  } else {
                                    dispatch(createFolder(folderName));
                                    setLocalList(
                                      localList
                                        .filter((c) => !c.creating)
                                        .concat({
                                          konnect_count: 0,
                                          name: folderName,
                                        })
                                    );
                                    setCreating(false);
                                    e.target.blur();
                                    // setNewFolder(false);
                                  }
                                }
                              }}
                              ref={newFolderInput}
                              placeholder={t('konnectzList.folders.nameTitle')}
                              sx={{ fontSize: [14, 14, 14, 16, 18, 18] }}
                            />
                          ) : (
                            <Flex sx={{ fontSize: [14, 14, 14, 16, 18, 18] }}>
                              {folderName}
                            </Flex>
                          )}
                          <Flex
                            sx={{
                              color: '#E0D7D3',
                              fontSize: [10, 10, 10, 12, 12, 14],
                            }}
                          >
                            0 Konnectz
                          </Flex>
                        </Flex>
                      </Button>
                    </Box>
                  );
                })}
              </Flex>
            </Collapse>
          </>
        ) : (
          <>
            <Flex justifyContent="space-between">
              <Flex gap="10px" alignItems="center" cursor="pointer">
                <Tooltip label="Go Back" hasArrow>
                  <Flex>
                    <Icon
                      onClick={() => {
                        setRenameFolder(false);
                        setOpenFolder(null);
                      }}
                      as={AiOutlineRollback}
                      boxSize="1.7em"
                      sx={{
                        cursor: 'pointer',
                        transition: 'transform 0.15s',
                      }}
                    />
                  </Flex>
                </Tooltip>
                <Box
                  sx={{
                    display: !renameFolder ? 'flex' : 'none',
                    fontSize: [16, 16, 18, 20, 22, 24],
                    fontWeight: 500,
                    m: 'auto 0px',
                  }}
                >
                  {renameValue ? renameValue : openFolder.name}
                </Box>
                <Input
                  ref={renameFolderInput}
                  width="500px"
                  autoFocus
                  sx={{ display: renameFolder ? 'flex' : 'none' }}
                  placeholder={t('konnectzList.folders.enterFolderName')}
                  value={renameValue}
                  onChange={(e) => setRenameValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && openFolder.id) {
                      dispatch(
                        renameFolderCall({
                          folderId: openFolder.id,
                          folderName: renameValue,
                        })
                      );
                      setRenameFolder(false);
                    } else if (e.key === 'Enter' && !openFolder.id) {
                      dispatch(getFoldersList()).then((res) => {
                        let folder = res.payload.folders.find(
                          (c) => c.name === openFolder.name
                        );
                        dispatch(
                          renameFolderCall({
                            folderId: folder.id,
                            folderName: renameValue,
                          })
                        );
                        setRenameFolder(false);
                      });
                    }
                  }}
                />
              </Flex>

              <Flex gap="20px">
                {deleteModal ? (
                  <Button
                    sx={{
                      borderTop: '1px solid #152D73',
                      borderRight: '1px solid #152D73',
                      borderLeft: '1px solid #152D73',
                      borderBottom: '3px solid #152D73',
                      fontSize: [14, 14, 14, 16, 16, 18],
                      borderRadius: '4px',
                      bgColor: renameFolder ? '#f3f7ff' : 'inherit',
                      color: '#152D73',
                      px: '30px',
                      gap: '10px',
                      py: ['10px', '15px', '20px', '20px', '22px', '25px'],

                      '&:hover': {
                        '& #icon': {
                          fill: 'rgb(21,46,115)',
                        },
                        color: 'rgb(21,46,115)',
                        bgColor: '#fff',
                        borderTop: '1px solid #152D73',
                        borderRight: '1px solid #152D73',
                        borderLeft: '1px solid #152D73',
                        borderBottom: '3px solid #152D73',
                      },
                    }}
                  >
                    <InfoIcon size="1.5em" />
                    Sure, you want to delete?
                    <Flex gap="15px">
                      <CheckIcon
                        onClick={() => {
                          if (openFolder.id) {
                            setDeleting(true);
                            dispatch(deleteFolder(openFolder.id)).then(
                              (res) => {
                                setOpenFolder(null);
                                setDeleting(false);
                                setDeleteModal(false);
                              }
                            );
                          } else {
                            dispatch(getFoldersList()).then((res) => {
                              setDeleting(true);
                              let folder = res.payload.folders.find(
                                (c) => c.name === openFolder.name
                              );
                              dispatch(deleteFolder(folder.id)).then((res) => {
                                setOpenFolder(null);
                                setDeleting(false);
                                setDeleteModal(false);
                              });
                            });
                          }
                        }}
                        boxSize="1.3em"
                        sx={{
                          '&:hover': {
                            transform: 'scale(1.2)',
                            transition: 'transform 0.15s',
                          },
                        }}
                      />
                      <CloseIcon
                        onClick={() => setDeleteModal(false)}
                        boxSize="1em"
                        sx={{
                          m: 'auto 0px',
                          '&:hover': {
                            color: 'red',
                            transform: 'scale(1.2)',
                            transition: 'transform 0.15s',
                          },
                        }}
                      />
                    </Flex>
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setRenameFolder(true);
                      setTimeout(() => {
                        renameFolderInput.current.focus();
                      }, 100);
                    }}
                    sx={{
                      borderTop: '1px solid #152D73',
                      borderRight: '1px solid #152D73',
                      borderLeft: '1px solid #152D73',
                      borderBottom: '3px solid #152D73',
                      fontSize: [14, 14, 14, 16, 16, 18],
                      borderRadius: '4px',
                      bgColor: renameFolder ? '#f3f7ff' : 'inherit',
                      color: '#152D73',
                      px: '30px',
                      gap: '10px',
                      py: ['10px', '15px', '20px', '20px', '22px', '25px'],

                      '&:hover': {
                        '& #icon': {
                          fill: 'rgb(21,46,115)',
                        },
                        color: 'rgb(21,46,115)',
                        bgColor: '#F3F7FF',
                        borderTop: '1px solid #152D73',
                        borderRight: '1px solid #152D73',
                        borderLeft: '1px solid #152D73',
                        borderBottom: '3px solid #152D73',
                      },
                    }}
                  >
                    <MdOutlineDriveFileRenameOutline size="1.5em" />
                    {t('konnectzList.folders.renameFolder')}
                  </Button>
                )}
                <Tooltip
                  hasArrow
                  isOpen={deleteTooltip}
                  onOpen={() =>
                    openFolder.konnect_count !== 0 && setDeleteTooltip(true)
                  }
                  onClose={() =>
                    openFolder.konnect_count !== 0 && setDeleteTooltip(false)
                  }
                  label="Cannot delete a folder with existing konnectz."
                  placement="left"
                >
                  <Button
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                    isDisabled={openFolder.konnect_count === 0 ? false : true}
                    sx={{
                      borderTop: '1px solid #152D73',
                      borderRight: '1px solid #152D73',
                      borderLeft: '1px solid #152D73',
                      borderBottom: '3px solid #152D73',
                      fontSize: [14, 14, 14, 16, 16, 18],
                      borderRadius: '4px',
                      bgColor: deleting || deleteModal ? '#f3f7ff' : 'inherit',
                      color: '#152D73',
                      px: '30px',
                      gap: '10px',
                      py: ['10px', '15px', '20px', '20px', '22px', '25px'],
                      '&:hover': {
                        '& #icon': {
                          fill: 'rgb(21,46,115)',
                        },
                        color: 'rgb(21,46,115)',
                        bgColor: '#F3F7FF',
                        borderTop: '1px solid #152D73',
                        borderRight: '1px solid #152D73',
                        borderLeft: '1px solid #152D73',
                        borderBottom: '3px solid #152D73',
                      },
                    }}
                  >
                    {deleting ? (
                      <Spinner
                        thickness="3px"
                        speed="1.5s"
                        emptyColor="gray.200"
                        color="152E73"
                        boxSize="1.3em"
                      />
                    ) : (
                      <BsFolderMinus size="1.3em" />
                    )}
                    {t('account.agency.delete')}
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      <Flex
        width="100%"
        height="100%"
        sx={{
          bgColor: '#fff',
          borderRadius: '4px',
          boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
          p: '20px',
          gap: '20px',
          flexDir: 'column',
          position: 'relative',
        }}
      >
        <NodeLoader loading={loading} bgcolor="#fff" />
        <Flex justifyContent="space-between">
          {!openFolder ? (
            <>
              <Flex gap="10px" alignItems="center">
                <Tooltip
                  label={isOpen ? 'Expand Konnectz' : 'Collapse Konnectz'}
                  hasArrow
                >
                  <Flex
                    gap="10px"
                    alignItems="center"
                    onClick={onToggle}
                    cursor="pointer"
                  >
                    <Icon
                      viewBox="160 0 10 512"
                      boxSize="1.4em"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <path d="M190.4 74.1c5.6-16.8-3.5-34.9-20.2-40.5s-34.9 3.5-40.5 20.2l-128 384c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2l128-384zm70.9-41.7c-17.4-2.9-33.9 8.9-36.8 26.3l-64 384c-2.9 17.4 8.9 33.9 26.3 36.8s33.9-8.9 36.8-26.3l64-384c2.9-17.4-8.9-33.9-26.3-36.8zM352 32c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32z" />
                    </Icon>
                    <Flex
                      sx={{
                        fontSize: [16, 16, 18, 20, 22, 24],
                        fontWeight: 500,
                        m: 'auto 0px',
                      }}
                    >
                      {t('konnectzList.allText')} Konnectz
                    </Flex>
                  </Flex>
                </Tooltip>
                <Tooltip
                  label="Drag & Drop using button to MOVE a Konnect to a particular folder."
                  placement="right"
                >
                  <Flex
                    sx={{
                      m: 'auto 0px',
                      fill: 'rgb(0,0,0,0.5)',
                      cursor: 'pointer',
                      '&:hover': {
                        fill: '#000',
                        transform: 'scale(1.1)',
                      },
                      transition: 'fill 0.15s, transform 0.15s',
                    }}
                  >
                    {dashSVG.info}
                  </Flex>
                </Tooltip>
              </Flex>
              <InputGroup
                size={{
                  base: 'sm',
                  sm: 'sm',
                  md: 'md',
                  lg: 'md',
                  xl: 'md',
                  '2xl': 'md',
                }}
                sx={{ justifySelf: 'flex-end', width: '30%' }}
              >
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="rgb(21,45,115,0.6)" />
                </InputLeftElement>
                <Input
                  onChange={handleChange}
                  variant="filled"
                  placeholder={`${t('konnectzList.searchText')} Konnectz`}
                  sx={{
                    borderTop: '1px solid #162c73',
                    borderBottom: '3px solid #162c73',
                    bgColor: '#fff',
                    '&:hover': {
                      bgColor: '#fff',
                    },
                    borderLeft: '1px solid #162c73',
                    borderRight: '1px solid #162c73',
                  }}
                />
              </InputGroup>
            </>
          ) : (
            <Flex gap="10px" alignItems="center">
              <Flex
                gap="10px"
                alignItems="center"
                onClick={onToggle}
                cursor="pointer"
              >
                <Icon
                  viewBox="160 0 10 512"
                  boxSize="1.4em"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <path d="M190.4 74.1c5.6-16.8-3.5-34.9-20.2-40.5s-34.9 3.5-40.5 20.2l-128 384c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2l128-384zm70.9-41.7c-17.4-2.9-33.9 8.9-36.8 26.3l-64 384c-2.9 17.4 8.9 33.9 26.3 36.8s33.9-8.9 36.8-26.3l64-384c2.9-17.4-8.9-33.9-26.3-36.8zM352 32c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32z" />
                </Icon>
                <Flex
                  sx={{
                    fontSize: [16, 16, 18, 20, 22, 24],
                    fontWeight: 500,
                    m: 'auto 0px',
                  }}
                >
                  <Flex
                    onClick={() => {
                      setRenameFolder(false);
                      setOpenFolder(null);
                    }}
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {t('sideNav.homeLabel')}
                  </Flex>
                  /{openFolder.name}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex sx={{ position: 'relative', height: '100%' }}>
          <Flex
            id="scrollableDiv1"
            sx={{
              position: 'absolute',
              flexDir: 'column',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
              height: '100%',
              overflowY: 'auto',
              p: '3px',
              pr: '8px',
              gap: '15px',
              overflowX: 'hidden',
            }}
          >
            {openFolder ? (
              openFolder.konnects.map((konnect, i) => {
                return (
                  <Flex
                    key={i}
                    width="100%"
                    sx={{
                      bgColor: '#f3f7ff',
                      borderRadius: '6px',
                      p: '10px',
                      boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
                      '&:hover': {
                        outline: '1px solid rgb(81,142,248, 1)',
                      },
                    }}
                  >
                    <Flex
                      flexDir="column"
                      gap="5px"
                      width="80%"
                      sx={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflowX: 'auto',
                      }}
                    >
                      <Flex sx={{ fontSize: [10, 10, 12, 14, 14, 15] }}>
                        {konnect.konnect_name ? (
                          konnect.konnect_name
                        ) : (
                          <Flex sx={{ flexDirection: 'row' }}>
                            {konnect.left_app.name}
                            {konnect.right_apps.map((x, i) => {
                              return (
                                <Flex key={i}>{' - ' + x.app_name + '  '}</Flex>
                              );
                            })}
                          </Flex>
                        )}
                      </Flex>
                      <Flex>
                        <Image width="4%" src={konnect.left_app.image_url} />
                        {konnect.right_apps.length > 0 && (
                          <Flex
                            sx={{
                              m: 'auto 0px',
                              fill: 'black',
                            }}
                          >
                            {svg_1.dashed_1}
                          </Flex>
                        )}
                        {konnect.right_apps.length > 0 &&
                          konnect.right_apps.map((rightApp, index) => {
                            return (
                              <React.Fragment key={i}>
                                {index !== 0 && (
                                  <Flex
                                    sx={{
                                      m: 'auto 0px',
                                      fill: 'black',
                                    }}
                                  >
                                    {svg_1.dashed_1}
                                  </Flex>
                                )}

                                <Image width="4%" src={rightApp.image_url} />
                              </React.Fragment>
                            );
                          })}
                      </Flex>
                    </Flex>

                    <Flex
                      width="20%"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Tooltip label="Turn Active" hasArrow>
                        <Flex>
                          <ReactSwitch
                            height={20}
                            width={40}
                            handleDiameter={14}
                            offColor="#162c73"
                            onColor="#162c73"
                            onHandleColor="#f7c544"
                          />{' '}
                        </Flex>
                      </Tooltip>
                      <Flex gap="20px">
                        {' '}
                        <Flex
                        // draggable
                        // onDragStart={() =>
                        //   setDragKonnect((c) => [...c, konnect.id])
                        // }
                        >
                          {/* <Tooltip label="Drag % Move" hasArrow>
                            <Icon
                              draggable
                              viewBox="0 0 512 512"
                              boxSize={{
                                base: "4",
                                sm: "4",
                                md: "5",
                                lg: "6",
                                xl: "6",
                                "2xl": "6",
                              }}
                              fill="#162c73"
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  color: "#ea1762",
                                },
                              }}
                            >
                              <path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32v96H128V192c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V288h96v96H192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H288V288h96v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6v32H288V128h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z" />
                            </Icon>
                          </Tooltip>{" "} */}
                        </Flex>
                        <Tooltip label="Edit" hasArrow>
                          <EditIcon
                            boxSize={{
                              base: '4',
                              sm: '4',
                              md: '5',
                              lg: '6',
                              xl: '6',
                              '2xl': '6',
                            }}
                            color="#162c73"
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                color: '#f7c544',
                              },
                            }}
                            onClick={() => setId(konnect.id)}
                          />
                        </Tooltip>
                        <Tooltip label="Delete" hasArrow>
                          <DeleteIcon
                            boxSize={{
                              base: '4',
                              sm: '4',
                              md: '5',
                              lg: '6',
                              xl: '6',
                              '2xl': '6',
                            }}
                            color="#162c73"
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                color: '#ea1762',
                              },
                            }}
                          />
                        </Tooltip>
                      </Flex>{' '}
                    </Flex>
                  </Flex>
                );
              })
            ) : localKonnectList.length ? (
              <Flex>
                <InfiniteScroll
                  dataLength={localKonnectList?.length || 0}
                  next={() => setPageSize((c) => c + 20)}
                  hasMore={localKonnectList?.length > 1000 ? false : true}
                  pullDownToRefresh={false}
                  scrollableTarget="scrollableDiv1"
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={
                    loadingInPagination && (
                      <Flex
                        width="80px"
                        sx={{ m: '20px auto', stroke: '#162c73' }}
                      >
                        {svg.loader}
                      </Flex>
                    )
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    padding: '3px',
                  }}
                >
                  {localKonnectList.map((konnect, i) => {
                    return (
                      <Flex
                        key={i}
                        width="100%"
                        sx={{
                          bgColor: '#f3f7ff',
                          borderRadius: '6px',
                          p: '10px',
                          alignItems: 'center',
                          boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
                          '&:hover': {
                            outline: '1px solid rgb(81,142,248, 1)',
                          },
                        }}
                      >
                        <Flex flexDir="column" gap="5px" width="80%">
                          <Flex sx={{ fontSize: [10, 10, 12, 14, 14, 15] }}>
                            {konnect.konnect_name ? (
                              konnect.konnect_name
                            ) : (
                              <Flex sx={{ flexDirection: 'row' }}>
                                {konnect.left_app.name}
                                {konnect.right_apps.length > 7
                                  ? konnect.right_apps
                                      .slice(0, 7)
                                      .map((x, i) => {
                                        return (
                                          <Flex key={i}>
                                            {' - ' + x.app_name + '  '}
                                          </Flex>
                                        );
                                      })
                                  : konnect.right_apps.map((x, i) => {
                                      return (
                                        <Flex key={i}>
                                          {' - ' + x.app_name + '  '}
                                        </Flex>
                                      );
                                    })}
                              </Flex>
                            )}
                          </Flex>
                          <Flex>
                            <Image
                              width="4%"
                              src={konnect.left_app.image_url}
                            />
                            {konnect.right_apps.length > 0 && (
                              <Flex
                                sx={{
                                  m: 'auto 0px',
                                  fill: 'black',
                                }}
                              >
                                {svg_1.dashed_1}
                              </Flex>
                            )}
                            {konnect.right_apps.length > 7 ? (
                              <>
                                {konnect.right_apps
                                  .slice(0, 7)
                                  .map((rightApp, index) => (
                                    <React.Fragment key={index}>
                                      {index !== 0 && (
                                        <Flex
                                          sx={{
                                            m: 'auto 0px',
                                            fill: 'black',
                                          }}
                                        >
                                          {svg_1.dashed_1}
                                        </Flex>
                                      )}
                                      <Image
                                        key={index}
                                        width="4%"
                                        src={rightApp.image_url}
                                      />
                                    </React.Fragment>
                                  ))}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: '10px',
                                  }}
                                >
                                  + {konnect.right_apps.length - 7 + ' '}Apps
                                </div>
                              </>
                            ) : (
                              konnect.right_apps.map((rightApp, index) => {
                                return (
                                  <React.Fragment key={i}>
                                    {index !== 0 && (
                                      <Flex
                                        key={i}
                                        sx={{
                                          m: 'auto 0px',
                                          fill: 'black',
                                        }}
                                      >
                                        {svg_1.dashed_1}
                                      </Flex>
                                    )}

                                    <Image
                                      key={i}
                                      width="4%"
                                      src={rightApp.image_url}
                                    />
                                  </React.Fragment>
                                );
                              })
                            )}
                          </Flex>
                        </Flex>
                        {deleteKonnect === i && deleteKonnect >= 0 ? (
                          <>
                            {' '}
                            <Button
                              sx={{
                                borderTop: '1px solid #152D73',
                                borderRight: '1px solid #152D73',
                                borderLeft: '1px solid #152D73',
                                borderBottom: '3px solid #152D73',
                                fontSize: [14, 14, 14, 16, 16, 18],
                                borderRadius: '4px',
                                bgColor: renameFolder ? '#f3f7ff' : 'inherit',
                                color: '#152D73',
                                px: '30px',
                                gap: '10px',
                                py: [
                                  '10px',
                                  '15px',
                                  '20px',
                                  '20px',
                                  '22px',
                                  '25px',
                                ],

                                '&:hover': {
                                  '& #icon': {
                                    fill: 'rgb(21,46,115)',
                                  },
                                  color: 'rgb(21,46,115)',
                                  bgColor: '#fff',
                                  borderTop: '1px solid #152D73',
                                  borderRight: '1px solid #152D73',
                                  borderLeft: '1px solid #152D73',
                                  borderBottom: '3px solid #152D73',
                                },
                              }}
                            >
                              <InfoIcon size="1.5em" />
                              Sure, you want to delete?
                              <Flex gap="15px">
                                <CheckIcon
                                  sx={{
                                    m: 'auto 0px',
                                    '&:hover': {
                                      color: 'green',
                                      transform: 'scale(1.2)',
                                      transition: 'transform 0.15s',
                                    },
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      updateKonnect({
                                        id: konnect.id,
                                        status: 'DELETED',
                                      })
                                    ).then((res) => {
                                      if (res.payload.message === 'SUCCESS') {
                                        toast({
                                          position: 'top',
                                          status: 'success',
                                          variant: 'solid',
                                          title: 'Deleted',
                                          duration: 2500,
                                          containerStyle: {
                                            fontWeight: 400,
                                            deleteKonnect,
                                          },
                                        });
                                        setRerenderValue((i) => i + 1);
                                        setDeleteKonnect(null);
                                      } else
                                        toast({
                                          position: 'top',
                                          status: 'error',
                                          variant: 'solid',
                                          title: 'Cannot Delete this Konnect',
                                          duration: 2500,
                                          containerStyle: {
                                            fontWeight: 400,
                                          },
                                        });
                                    });
                                  }}
                                />
                                <CloseIcon
                                  onClick={() => setDeleteKonnect(false)}
                                  boxSize="1em"
                                  sx={{
                                    m: 'auto 0px',
                                    '&:hover': {
                                      color: 'red',
                                      transform: 'scale(1.2)',
                                      transition: 'transform 0.15s',
                                    },
                                  }}
                                />
                              </Flex>
                            </Button>
                          </>
                        ) : (
                          <Flex
                            width="20%"
                            alignItems="center"
                            justifyContent="space-around"
                          >
                            <Tooltip
                              label={
                                konnect.status === 'ACTIVE'
                                  ? 'Turn InActive'
                                  : 'Turn Active'
                              }
                              hasArrow
                            >
                              <Flex>
                                <ReactSwitch
                                  checked={
                                    konnect.status === 'ACTIVE' ? true : false
                                  }
                                  onChange={() =>
                                    dispatch(
                                      updateKonnect({
                                        id: konnect.id,
                                        status:
                                          konnect.status === 'ACTIVE'
                                            ? 'INACTIVE'
                                            : 'ACTIVE',
                                      })
                                    ).then((res) => {
                                      if (res.payload.message === 'FAILED') {
                                        toast({
                                          position: 'top',
                                          status: 'error',
                                          variant: 'solid',
                                          title:
                                            res.payload.message +
                                            ', status is Draft',
                                          duration: 2500,
                                          containerStyle: {
                                            fontWeight: 400,
                                          },
                                        });
                                      }
                                      if (
                                        res.payload.message === 'SUCCESS' &&
                                        konnect.status === 'INACTIVE'
                                      ) {
                                        toast({
                                          position: 'top',
                                          status: 'success',
                                          variant: 'solid',
                                          title:
                                            res.payload.message +
                                            ', status changed to ACTIVE',
                                          duration: 2500,
                                          containerStyle: {
                                            fontWeight: 400,
                                          },
                                        });
                                      }
                                      if (
                                        res.payload.message === 'SUCCESS' &&
                                        konnect.status === 'ACTIVE'
                                      ) {
                                        toast({
                                          position: 'top',
                                          status: 'success',
                                          variant: 'solid',
                                          title:
                                            res.payload.message +
                                            ', status changed to INACTIVE',
                                          duration: 2500,
                                          containerStyle: {
                                            fontWeight: 400,
                                          },
                                        });
                                      }
                                      if (res.payload.message !== 'FAILED') {
                                        setRerenderValue((c) => c + 1);
                                      }
                                    })
                                  }
                                  height={20}
                                  width={40}
                                  handleDiameter={14}
                                  offColor="#162c73"
                                  onColor="#162c73"
                                  onHandleColor="#f7c544"
                                />{' '}
                              </Flex>
                            </Tooltip>
                            <Flex gap="20px">
                              {' '}
                              <Flex
                                draggable
                                onDragStart={() =>
                                  setDragKonnect((c) => [...c, konnect.id])
                                }
                              >
                                <Tooltip label="Drag & Move" hasArrow>
                                  <Icon
                                    draggable
                                    viewBox="0 0 512 512"
                                    boxSize={{
                                      base: '4',
                                      sm: '4',
                                      md: '5',
                                      lg: '6',
                                      xl: '6',
                                      '2xl': '6',
                                    }}
                                    fill="#162c73"
                                    sx={{
                                      cursor: 'pointer',
                                      '&:hover': {
                                        color: '#ea1762',
                                      },
                                    }}
                                  >
                                    <path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32v96H128V192c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V288h96v96H192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H288V288h96v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6v32H288V128h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z" />
                                  </Icon>
                                </Tooltip>{' '}
                              </Flex>
                              <Tooltip label="Edit" hasArrow>
                                <EditIcon
                                  onClick={() => setId(konnect.id)}
                                  boxSize={{
                                    base: '4',
                                    sm: '4',
                                    md: '5',
                                    lg: '6',
                                    xl: '6',
                                    '2xl': '6',
                                  }}
                                  color="#162c73"
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                      color: '#f7c544',
                                    },
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label="Delete" hasArrow>
                                <DeleteIcon
                                  onClick={() => setDeleteKonnect(i)}
                                  boxSize={{
                                    base: '4',
                                    sm: '4',
                                    md: '5',
                                    lg: '6',
                                    xl: '6',
                                    '2xl': '6',
                                  }}
                                  color="#162c73"
                                  sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                      color: '#ea1762',
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Flex>{' '}
                          </Flex>
                        )}
                      </Flex>
                    );
                  })}
                </InfiniteScroll>
              </Flex>
            ) : (
              <Flex
                width="100%"
                sx={{
                  bgColor: '#f3f7ff',
                  borderRadius: '6px',
                  px: '10px',
                  py: ['12px', '14px', '14px', '16px', '18px', '20px'],
                  boxShadow: '0px 0px 3px 0px rgb(81,142,248, 0.35)',
                  '&:hover': {
                    outline: '1px solid rgb(81,142,248, 1)',
                  },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Flex sx={{ fontSize: [14, 14, 16, 16, 16, 18] }}>
                  {t('konnectzList.noKonnectzInfo')}
                </Flex>
                <NavLink to={'/konnect'} style={{ textDecoration: 'none' }}>
                  <Button
                    sx={{
                      borderTop: '1px solid #f7c544',
                      borderRight: '1px solid #f7c544',
                      borderLeft: '1px solid #f7c544',
                      borderBottom: '3px solid #f7c544',
                      fontSize: [16, 16, 18, 18, 18, 20],
                      borderRadius: '4px',
                      bgColor: 'inherit',
                      color: '#f7c544',
                      px: '30px',
                      gap: '5px',
                      py: ['10px', '15px', '20px', '20px', '22px', '25px'],
                      '&:hover': {
                        '& #icon': {
                          fill: 'rgb(21,46,115)',
                        },
                        bgColor: '#fff',
                        color: 'rgb(21,46,115)',
                        borderTop: '1px solid #152D73',
                        borderRight: '1px solid #152D73',
                        borderLeft: '1px solid #152D73',
                        borderBottom: '3px solid #152D73',
                      },
                    }}
                  >
                    <Flex
                      id="icon"
                      sx={{ fill: '#f7c544', transition: 'fill 0.15s' }}
                    >
                      {svg_1.createKonnect}
                    </Flex>
                    {t('konnectzList.createkonnectzLabel')} Konnectz
                  </Button>
                </NavLink>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
