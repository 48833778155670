import UnlimitedSteps from '../assets/features/unlimited-steps.png';
import VisualizeEverything from '../assets/features/visualize-everything.png';
import IntegrationLibrary from '../assets/features/integration-library.png';
import SimpleAutomation from '../assets/features/simple-automation.png';
import PlatformForEveryone from '../assets/features/platform-for-everyone.png';
import SeamlessAppsIntegration from '../assets/features/seamless-apps-integration.png';

export const fr = {
  translation: {
    common: {
      app: 'Konnectz',
    },
    header: {
      topNavigations: {
        tipsAndTricks: 'Astuces et conseils',
        pricing: 'Tarification',
        blogs: 'Blogs',
        helpCenter: "Centre d'aide",
      },
      searchAppTextPlaceholder: 'Rechercher ici...',
      createKonnectzBtnLabel: 'Créer un Konnectz',
      taskhistory: 'Historique des tâches',
      success: 'Succès',
      error: 'Erreur',
      notask: 'Aucune tâche trouvée',
      tryagain: 'Ajustez votre filtre et réessayez',
    },
    errorMessages: {
      SENT_RESET_INSTRUCTIONS_SUCCESS:
        'Les instructions de réinitialisation du mot de passe ont été envoyées par e-mail. Veuillez vérifier votre e-mail.',
      SENT_RESET_INSTRUCTIONS_FAIL: 'Demande non autorisée !',
      VERIFY_EMAIL_FAIL:
        'Désolé, impossible de confirmer votre adresse e-mail.',
      PASSWORD_NOT_UPDATED: 'Désolé, la mise à jour de votre compte a échoué',
      GET_FORGOT_PASSWORD_LINK_SUCCESS: '',
      GET_FORGOT_PASSWORD_LINK_FAIL:
        "Échec de l'envoi des instructions de mot de passe. Veuillez réessayer ultérieurement !",
      AUTH_SIGN_UP_FAIL:
        "Quelque chose s'est mal passé. Veuillez réessayer ultérieurement !",
      somethingWentWrong:
        "Quelque chose s'est mal passé. Veuillez réessayer ultérieurement !",
    },
    successMessages: {
      PASSWORD_UPDATED:
        'Le mot de passe a été mis à jour. Veuillez vous connecter avec votre nouveau mot de passe !',
    },
    history: {
      task: {
        task: 'tâche',
        tasks: 'tâches',
        taskdate: 'date de la tâche : ',
        user: 'Utilisateur : ',
        search_history: "Rechercher dans l'historique",
      },
    },
    auth: {
      signup: {
        createPassword: {
          title: 'Créer un mot de passe',
          subtitle: 'Tapez-les DEUX FOIS pour confirmer',
          passwordErrorMsg: 'Mot de passe invalide !',
          confirmPasswordErrorMsg: 'Mot de passe invalide !',
          passwordMatchingErrorMsg:
            'Les deux mots de passe ne correspondent pas !',
          continueBtnLabel: 'CONTINUER',
          createPasswordPlaceholder: 'Créer un mot de passe',
          confirmPasswordPlaceholder: 'Confirmer le mot de passe',
          passwordRule:
            'Indication : Votre mot de passe doit comporter entre 8 et 15 caractères et doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
          passwordRule1:
            'Votre mot de passe doit comporter entre 8 et 15 caractères.',
          passwordRule2:
            'Votre mot de passe doit contenir au moins une lettre majuscule.',
          passwordRule3:
            'Votre mot de passe doit contenir au moins un chiffre.',
          passwordRule4:
            'Votre mot de passe doit contenir au moins un caractère spécial.',
        },
        verificationSent: {
          title: 'VÉRIFICATION',
          subtitle: "Vérifier l'adresse e-mail",
          verificationText1: 'Nous avons envoyé un lien de vérification à ',
          verificationText2:
            ", ouvrez l'e-mail et confirmez votre adresse e-mail.",
          didntReceiveEmailText: "Vous n'avez pas reçu d'e-mail ? ",
          resendText: 'Renvoyer',
        },
        verifySuccess: {
          title: 'Vérification terminée',
          verifyFailedTitle: 'Échec de la vérification',
        },
        signupSuccess: {
          firstName: 'Prénom',
          firstNameErrorMsg: 'Le prénom est requis !',
          lastName: 'Nom de famille',
          lastNameErrorMsg: 'Le nom de famille est requis !',
          verifyErrorMsg:
            "Quelque chose s'est mal passé. Veuillez vous connecter si votre compte a déjà été vérifié.",
        },
        title: 'Créez votre compte',
        subtitle: 'Hé, bienvenue',
        verifyingMessage: 'Vérification en cours',
        emailInvalid: "L'e-mail n'est pas valide !",
        emailExists:
          "L'adresse e-mail que vous avez saisie est déjà enregistrée",
        teamSignup: "Inscription en tant qu'équipe ?",
        continueBtnLabel: 'Continuer',
        alreadyHaveAccountText: 'Vous avez déjà un compte ? ',
        loginBtnLabel: 'Se connecter',
        emailPlaceholder: "Saisissez l'e-mail",
        features: {
          feature1: {
            title: 'Automatisation simple',
            image: SimpleAutomation,
            description:
              'Construisez des flux de travail complexes simplement et facilement en quelques minutes avec notre constructeur de connexion visuel.',
          },
          feature2: {
            title: 'Plateforme pour tous',
            image: PlatformForEveryone,
            description:
              "Pas un simple outil d'automatisation réservé aux programmeurs, tout le monde peut créer des automatisations avec KonnectzIT",
          },
          feature3: {
            title: 'Intégration transparente des applications',
            image: SeamlessAppsIntegration,
            description:
              "Connectez des applications illimitées de notre bibliothèque d'intégration et automatisez les tâches quotidiennes.",
          },
        },
      },
      emailVerification: {
        accountAlreadyVerified:
          "Quelque chose s'est mal passé. Veuillez vous connecter si votre compte a déjà été vérifié",
      },
      login: {
        title: 'Connectez-vous à votre compte',
        subtitle: 'Hé, bienvenue de retour !',
        emailPlaceholder: "Saisissez l'e-mail",
        emailInvalid: 'E-mail non valide !',
        passwordRequired: 'Le mot de passe est requis !',
        passwordPlaceholder: 'Saisissez le mot de passe',
        rememberMe: 'Se souvenir de moi',
        loginBtnLabel: 'SE CONNECTER MAINTENANT',
        forFirstTimeSignup: 'Pour la première fois ? ',
        forgotPasswordLbl: 'Mot de passe oublié',
        alreadyHaveAccountText: "Vous n'avez pas de compte ? ",
        clickingContinueText: 'En cliquant sur Continuer, vous acceptez nos ',
        termsAndConditionsText: 'Conditions générales.',
        signupNowText: 'Inscrivez-vous maintenant',
        features: {
          feature1: {
            title: 'Étapes illimitées',
            image: UnlimitedSteps,
            description:
              'Connectez plusieurs applications et créez des étapes illimitées pour une automatisation transparente.',
          },
          feature2: {
            title: 'Visualisez tout',
            image: VisualizeEverything,
            description:
              "Créez vos flux de travail et visualisez l'automatisation à l'aide d'un constructeur de connexion visuel facilement.",
          },
          feature3: {
            title: "Bibliothèque d'intégration",
            image: IntegrationLibrary,
            description:
              "Collection d'applications cloud pour fusionner et envoyer des données entre elles et gagner du temps.",
          },
        },
        loginFailMsg: "Nom d'utilisateur ou mot de passe incorrect !",
        captchaErrorMsg: 'Erreur de captcha',
      },
      forgot: {
        title: 'Mot de passe oublié',
        forgtoBtnLabel: 'Obtenir le lien de réinitialisation du mot de passe',
        passwordLink:
          'Nous avons envoyé le lien de réinitialisation du mot de passe à',
        successSubTitle: 'E-mail envoyé',
        features: {
          feature1: {
            title: 'Automatisation simple',
            image: SimpleAutomation,
            description:
              'Construisez des flux de travail complexes simplement et facilement en quelques minutes avec notre constructeur de connexion visuel.',
          },
          feature2: {
            title: 'Plateforme pour tous',
            image: PlatformForEveryone,
            description:
              "Pas un simple outil d'automatisation réservé aux programmeurs, tout le monde peut créer des automatisations avec KonnectzIT",
          },
          feature3: {
            title: 'Intégration transparente des applications',
            image: SeamlessAppsIntegration,
            description:
              "Connectez des applications illimitées de notre bibliothèque d'intégration et automatisez les tâches quotidiennes.",
          },
        },
        emailInvalid: 'E-mail non valide !',
      },
      reset: {
        title: 'Réinitialiser le mot de passe',
        subTitle: 'Créer un nouveau mot de passe',
      },
    },

    konnect: {
      sidebar: {
        search_apps: "Rechercher dans l'application",
        search_apps_endList: 'Yay ! Tu as tout vu',
        add_on_apps: 'Applications Complémentaires',
        integrated_apps: 'Applications Intégrées',
        choose_trigger: 'Choisir le Déclencheur',
        choose_action: 'Choisir làction',
        textFormater: {
          formaterText: "Saisir '@' et sélectionner les valeurs",
        },
        scheduler: {
          userTimeInput: 'Saisir la valeur en minutes, heures ou jours',
          selectDate: 'Sélectionner la Date',
          select: 'Sélectionner',
          test_review: 'Tester & Réviser',
          time: 'Temps',
          dates: 'Dates',
          date: 'Date',
          days: 'Jours',
          everyday_time: 'Heure Quotidienne',
        },
        responseModal: {
          searchByLable: 'Rechercher par Étiquette',
          no_labelFound: 'Aucune étiquette trouvée',
          nodeInner_title: 'Sélectionner Plus de Valeurs',
          labelText: 'Étiquette:',
        },
        coupon_generator: {
          numberPlaceholder: 'Numéro',
        },
        choose_trigger_header: "Choisir l'Événement de l'Application",
        no_triggers_available: 'Aucun déclencheur disponible',
        choose_format_header: 'Option de Format',
        choose_config_header: 'Choisir la Configuration',
        select_trigger_action_dropdown_header: 'Sélectionner',
        choose_option_header: "Définir l'Option",
        no_options_available: 'Aucune option disponible',
        choose_linked_account: 'Sélectionner le Compte',
        customize_form_header: 'Configurer le Formulaire',
        customize_form_partial: 'Choisir',
        configure_response: 'Personnaliser la Réponse',
        basic_auth: 'Authentification de Base',
        api_key: 'Clé API',
        api_token: 'Secret API',
        webhook_url: 'URL du Webhook',
        captureWebhookResponse: 'Capturer la Réponse du Webhook',
        hereText: 'Ici',
        webhook_instructions: 'Instructions du Webhook - Cliquer',
        webhook_url_toast: 'URL du Webhook copiée dans le presse-papiers !',
        webhook_response: 'Réponse du Webhook',
        choose_action_event: "Choisir l'Action",
        endpoint_url: "URL de l'Endpoint",
        payload_type: 'Type de Payload',
        wrap_request_array: 'Envelopper la Requête dans un Tableau',
        set_params: 'Définir les Paramètres',
        add_header: 'Ajouter un En-tête',
        delayLabel: 'Délai',
        delayText: 'Combien de délai voulez-vous ?',
        set_paramter: 'Définir le Paramètre',
        expiry_time: 'Compteur de la Période',
        expiry_val: 'Période',
        segmentIndex: 'Index du Segment',
        validate: 'Valider',
        generate: 'Générer',
      },
      tobar: {
        cancelBtnLabel: 'Annuler',
        inputLeftElm_placeholder:
          'Rechercher dans plus de 1000 Applications Intégrées ou Applications Complémentaires',
        redoBtnLabel: 'REFAIRE',
        undoBtnLabel: 'ANNULER',
      },
      UI: {
        betaText: 'Bêta',
        webhookDuplicateToastMsg: "L'application Webhook existe déjà !",
      },
      header: {
        backBtnLabel: 'Retour',
        saveBtnLabel: 'Enregistrer',
        publishBtnLabel: 'Publier',
        nameOfKonnectz: 'Nom du',
      },
      testAndReview: {
        btnLabel: 'Tester & Réviser',
        taskAvailableLabel: 'Tâche Disponible',
        taskConsumbedLabel: 'Tâche Consommée',
        taskCnsumptionLabel: 'Consommation de Tâche',
      },
      proceed: {
        btnLabel: 'PROCÉDER',
      },
      additionalConfigsModal: {
        cancelBtnLabel: 'Annuler',
        okBtnLabel: 'OK',
      },
      conditions: {
        conditionText: 'Condition',
        addNewConditionBtnLabel: 'Ajouter une Nouvelle Condition +',
        cancelBtnLabel: 'Annuler',
        okBtnLabel: 'OK',
        addBtnLabel: 'Ajouter',
        operatorAndLabel: 'ET',
        operatorOrLabel: 'OU',
        headerConditionText: 'Condition',
        selectFieldHeaderTitle: 'Sélectionner le Champ',
        selectConditionHeaderTitle: 'Sélectionner la Condition',
        validateBtnLabel: 'Valider',
        conditionsHeaderText: 'Conditions',
      },
      publishSuccessMsg: 'Konnect est publié avec succès !',
      saveSuccessMsg: 'Konnect est enregistré avec succès !',
    },
    apps: {
      authenticatedAppsTitle: 'Applications Authentifiées',
      aunthenticateNowTitle: "S'Authentifier Maintenant",
      noAppsToAunthenticate:
        'Aucune application à authentifier pour le moment.',
      authenticateNow:
        "Vous n'avez aucune application authentifiée. Authentifiez-vous maintenant.",
      addAccountText: 'Ajouter un Compte',
      betaText: 'Bêta',
      integratedApps: 'APPLICATIONS INTÉGRÉES',
      followInstrusctionText:
        'Veuillez suivre les instructions pour plus de référence',
      oAuthHandlerText:
        'Gestionnaire OAuth de KonnectzIT, Veuillez patienter...',
      cancelBtnText: 'Annuler',
      newsLable: 'Nouvelles',

      yesContinueText: 'Oui, continuer',
      requiredText: '(Obligatoire)',
      accessAPIModalHeaderText1: 'Autoriser KonnectzIT à accéder au compte ',
      accessAPIModalHeaderText2: ' ?',
      accessAPIModalMainText1: ' ',

      accessAPIModalMainText2: ' est disponible dans la',
      accessAPIModalMainText3: " page d'intégration",
      authorized: 'Applications Autorisées',
    },
    konnectzList: {
      homeText: 'Accueil',
      allText: 'Tout',
      searchText: 'Rechercher',
      createkonnectzLabel: 'Créer',
      noKonnectzInfo: 'Aucun konnectz pour le moment !',
      deleteModalBodyText: 'Êtes-vous sûr de vouloir supprimer ?',
      deleteModalNoText: 'Non',
      deleteModalDeleteText: 'Supprimer',
      folders: {
        createFolderTitleText: 'Nom du Dossier',
        enterFolderName: 'Entrer le nom du dossier',
        fodersLeftTitleTextL: 'Dossiers',
        createFolderInputPlaceholder: 'Entrer le nom du dossier',
        createFolderBtn: 'Créer',
        nameTitle: 'Nom',
        noKonnectz: "Il semble que vous n'ayez aucun",
        deleteFolderConfirmationText:
          'Êtes-vous sûr de vouloir supprimer ce dossier ?',
        cancelBtn: 'Annuler',
        newFolder: 'Nouveau Dossier',
        deleteBtn: 'Supprimer',
        selectFolderInfo:
          'Sélectionnez le dossier vers lequel vous souhaitez déplacer le fichier',
        moveFolderCancelBtn: 'Annuler',
        moveBtn: 'Déplacer le Dossier',
        renameSaveBtn: 'Renommer le Dossier',
        renameFolder: 'Renommer',
        renameYourFolderText: 'Renommez votre dossier',
        createFolderBtn: 'Créer un Dossier',
      },
    },
    dashboard: {
      widgets: {
        latestApps: {
          headerTitle: 'Dernières Applications',
        },
        totalKonnectz: {
          headerTitle: 'Total Konnectz',
          activeKonnectz: 'Konnectz Actifs',
          inactiveKonnectz: 'Konnectz Inactifs',
        },
        latestKonnectz: {
          headerTitle: 'Derniers Konnectz',
          vieawAll: 'Voir Tout',
        },
        subscription: {
          subscriptionTitle: 'Abonnement',
          contentText:
            'Débloquez plus de puissance en passant à la version Pro',
          applyNewCoupon: 'Appliquer un Nouveau Coupon',
          applyBtnLabel: 'Appliquer',
          featuresIncludedLabel: 'Fonctions Incluses',
          upgradeBtnLabel: 'MISE À NIVEAU MAINTENANT',
          unlockMoreUpgradingText:
            'Débloquez plus de puissance en passant à la version Pro',
        },
        taskConsumption: {
          headerTitle: 'Consommation de Tâches',
        },
      },
    },
    account: {
      myProfileText: 'Mon Profil',
      billingtext: 'Adresse de Facturation',
      emailAddressLabel: 'Adresse Email',
      firstNameLabel: 'Prénom',
      lastNameLabel: 'Nom',
      subAccountsLabel: 'Sous-Comptes',
      firstNameRequiredText: 'Le prénom est requis !',
      lastNameRequiredText: 'Le nom est requis !',
      countryRequiredText: 'Le pays est requis !',
      addressLine1RequiredText: "La ligne d'adresse 1 est requise !",
      cityRequiredText: 'La ville est requise !',
      stateRequiredText: "L'état est requis !",
      zipCodeRequiredText: 'Le code postal est requis !',
      taxIdRequiredText: "L'identifiant fiscal est requis !",
      companyNameLabel: "Nom de l'Entreprise",
      currentPositionLabel: 'Poste Actuel',
      countryLabel: 'Pays',
      addressLabel: 'Adresse',
      cityLabel: 'Ville',
      stateLabel: 'État',
      zipCodeLabel: 'Code Postal',
      taxIdLabel: 'Identifiant Fiscal',
      timeZoneLabel: 'Fuseau Horaire',
      changeLanguageLabel: 'Choisir la Langue',
      changePasswordHeaderText: 'Changer le Mot de Passe',
      currentPasswordLabel: 'Mot de Passe Actuel',
      currentPasswordRequiredText: 'Le mot de passe actuel est requis !',
      newPasswordLabel: 'Nouveau Mot de Passe',
      newPasswordRequiredText: 'Le nouveau mot de passe est requis !',
      confirmPasswordLabel: 'Confirmer le Mot de Passe',
      confirmPasswordRequiredText: 'Confirmez le mot de passe !',
      updateBtnLabel: 'Mettre à Jour',
      editUpdateAccountSideBarText: 'Modifier/Mettre à Jour le Compte',
      changePasswordSideBarText: 'Changer le Mot de Passe',
      billingText: 'Facturation',
      teamText: 'Équipe',
      agencyText: 'Agence',
      sideBarHeaderAccountText: 'Compte',
      profileLable: 'Profil',
      profileUpdatedSuccessfullyText: 'Profil mis à jour avec succès !',
      addTeamMemberText: "Ajouter un Membre de l'Équipe",
      addSubAccountsText: 'Ajouter des Sous-Comptes',

      billing: {
        couponCodeText: 'Code',
        applyCodeBtnLbl: 'Appliquer le Code',
        tasksText: 'Tâches',
        promotionalTasksText: 'Tâches Promotionnelles',
        redeemedOnText: 'Utilisé le',
        expiresOnText: 'Expire le',
        noCouponsAppliedText: 'Aucune licence appliquée',
        plansAndFeaturesText: 'Plans et Fonctionnalités',
        currentPlanText: 'Plan Actuel',
        upgradeText: 'Mettre à Niveau',
        billingText: 'Facturation',
        licenseerror: 'Licence Invalide !',
        completionofprofile:
          'Veuillez compléter votre profil avant de racheter le code. Ceci est requis pour le RGPD/le traitement des données & les fins de facturation',
      },
      team: {
        teamLabel: 'Équipe',
        name: 'Nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        access: 'Accès',
        email: 'Email',
        addBtn: 'Ajouter',
        cancelBtn: 'Annuler',
        assignAccessLbl: "Attribuer l'Accès",
        noUsersInfo: 'Aucun utilisateur disponible',
        collapseTeamLabel: "Réduire l'Équipe",
        expandTeamLabel: "Étendre l'Équipe",
        teamMembersHeaderText: "Membres de l'Équipe",
      },
      agency: {
        agencyLabel: 'Agence',
        name: 'Nom du Client :',
        firstName: 'Prénom',
        lastName: 'Nom',
        tasks: 'Tâche Assignée :',
        consumed: 'Tâche Consommée',
        agencyConsumed: 'Consommée',
        remainingLabel: 'Restant',
        totalSubaccounts: 'Total des Sous-Comptes :',
        email: 'Email :',
        active: 'Actif :',
        inActive: 'Inactif :',
        addBtn: 'Ajouter',
        addMember: 'Ajouter un Membre',
        deleteMember: 'Supprimer le Membre',
        cancelBtn: 'Annuler',
        editMemberLabel: 'Modifier le Membre',
        noAgenciesInfo: 'Aucune agence disponible',
        agencyMembersHeaderText: 'Sous-Comptes',
        total: 'Total des Tâches :',
        update: 'Mettre à Jour',
        saveChangeLabel: 'Enregistrer les Modifications',
        updateTask: 'Mettre à Jour la Tâche',
        delete: 'Supprimer',
        available: 'Tâche disponible',
        deactivate: 'Activer',
        totalAssigned: 'Total Assigné',
        activate: 'Désactiver',
        detailsLabel: 'Détails',
        subaccounterror: 'Échec de la mise à jour des détails du sous-compte !',
      },
      reseller: {
        resellerLabel: 'Revendeur',
      },
    },
    sideNav: {
      homeLabel: 'Accueil',
      appsLabel: 'Applications',
      konnectzLabel: 'Konnectz',
      historyLabel: 'Historique',
      historyCapsLabel: 'Historique',
      accountLabel: 'Compte',
      notificationsLabel: 'Notifications',
      logoutLabel: 'Se Déconnecter',
      getHelpLabel: "Besoin d'Aide ?",
      helpModalText: "Restez à l'écoute. Nous arrivons bientôt !",
      konnectzITLogoText: 'KonnectzIT',
    },
  },
};
