import { apiClient, apiClient1 } from "./axios";
import { routes } from "./routes";
import AuthTokenService from "../utils/AuthTokenService";
var tokenObj = AuthTokenService.get();
const baseUrl = "https://billing.konnectzit.com";
//   -----------------------login--------------------------
export const userLoginPost = (body) => {
  return apiClient({
    method: routes.USER_LOGIN.METHOD,
    url: routes.USER_LOGIN.URL,
    data: body,
  });
};

export const userSignupPost = (body) => {
  return apiClient({
    method: routes.USER_SIGNUP.METHOD,
    url: routes.USER_SIGNUP.URL,
    data: body,
  });
};
export const userExistPost = (body) => {
  return apiClient({
    method: routes.USER_EXISTS.METHOD,
    url: routes.USER_EXISTS.URL,
    data: body,
  });
};

export const userVerifyPost = (body) => {
  return apiClient({
    method: routes.VERIFY_EMAIL.METHOD,
    url: routes.VERIFY_EMAIL.URL,
    data: body,
  });
};

export const userUpdatePost = (body) => {
  return apiClient({
    method: routes.UPDATE_USER.METHOD,
    url: routes.UPDATE_USER.URL,
    data: body,
  });
};

export const userForgotPasswordPost = (body) => {
  return apiClient({
    method: routes.GET_FORGOT_PASSWORD_LINK.METHOD,
    url: routes.GET_FORGOT_PASSWORD_LINK.URL,
    data: body,
  });
};

export const userResetPasswordPost = (body) => {
  return apiClient({
    method: routes.RESET_PASSWORD.METHOD,
    url: routes.RESET_PASSWORD.URL,
    data: body,
  });
};

//   -----------------------dashboard--------------------------

export const widgetsGet = () => {
  return apiClient({
    method: routes.GET_WIDGETS_ACTIVE.METHOD,
    url: routes.GET_WIDGETS_ACTIVE.URL,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const widgetsDetailsGet = (widgetKey) => {
  return apiClient({
    method: routes.GET_WIDGETS_DETAILS.METHOD,
    url: `${routes.GET_WIDGETS_DETAILS.URL}${widgetKey}`,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updateKonnectStatusPost = (props) => {
  let url = routes.UPDATE_APP_STATUS.URL + props.id + "/update_status";
  return apiClient({
    method: routes.UPDATE_APP_STATUS.METHOD,
    url: url,
    data: { status: props.status },
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//   -----------------------konnects--------------------------

export const getFoldersListGet = () => {
  return apiClient({
    url: routes.GET_FOLDERS_LIST.URL,
    method: routes.GET_FOLDERS_LIST.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getKonnectsByFolderIdd = (id) => {
  let url = routes.GET_FOLDER_KONNECTS.URL + id + "/konnects";

  return apiClient({
    url: url,
    method: routes.GET_FOLDER_KONNECTS.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const createFolderPost = (folderName) => {
  return apiClient({
    url: routes.CREATE_FOLDER.URL,
    method: routes.CREATE_FOLDER.METHOD,

    data: {
      folder_name: folderName,
    },
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const moveKonnectsToFolderPost = (props) => {
  let url = routes.MOVE_KONNECTS_TO_FOLDER.URL + props.folderId + "/assign";

  return apiClient({
    url: url,
    data: {
      konnects: props.selectedKonnects.toString(),
    },
    method: routes.MOVE_KONNECTS_TO_FOLDER.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const renameKonnectFolderPut = (props) => {
  let url = routes.RENAME_FOLDER.URL + props.folderId + "/rename";

  return apiClient({
    url: url,
    data: {
      folder_name: props.folderName,
    },
    method: routes.RENAME_FOLDER.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const deleteKonnectFolderGet = (folderId) => {
  let url = routes.DELETE_FOLDER.URL + folderId + "/remove";

  return apiClient({
    url: url,
    method: routes.DELETE_FOLDER.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getKonnectsListGet = (searchQuery) => {
  let url = routes.GET_KONNECTS_LIST.URL;

  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map((key) => `${key}=${searchQuery[key]}`)
      .join("&");

    url = url + `?${qs}`;
  }

  return apiClient({
    url: url,
    method: "GET",
    searchQuery,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updateKonnectz = (props) => {
  let url = routes.UPDATE_KONNECTS_LIST.URL + `${props.id}/update_status`;
  return apiClient({
    url: url,
    method: routes.UPDATE_KONNECTS_LIST.METHOD,
    data: { status: props.status },
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//   -----------------------Apps--------------------------

export const getOauthDataPost = (payload) => {
  return apiClient({
    url: routes.GET_AUTH_URL.URL,
    data: payload,
    method: routes.GET_AUTH_URL.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};


export const saveTwitterCredPost = (payload) => {
  return apiClient({
    url: routes.GET_TWIT_URL.URL,
    data: payload,
    method: routes.GET_TWIT_URL.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const saveOauthDataPost = (payload) => {
  return apiClient({
    url: routes.SAVE_AUTH.URL,
    data: payload,
    method: routes.SAVE_AUTH.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppDetailsGet = (payload) => {
  let url = routes.GET_APP_DETAILS.URL + payload + "/authorized_app_accounts";

  return apiClient({
    url: url,
    method: routes.GET_APP_DETAILS.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updateAppStatusPost = (props) => {
  let url =
    routes.UPDATE_APP_STATUS_USER.URL + "/" + props.appId + "/update_status";
  return apiClient({
    url: url,
    data: { status: props.status },
    method: routes.UPDATE_APP_STATUS_USER.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const saveAuthLabelPost = (props) => {
  let url = routes.SAVE_LABEL.URL + "/" + props.id + "/update_label";
  return apiClient({
    url: url,
    data: { label: props.label },
    method: routes.SAVE_LABEL.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppsget = (searchQuery) => {
  let url = routes.GET_ALL_APPS.URL;
  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map((key) => `${key}=${searchQuery[key]}`)
      .join("&");

    url = url + `?${qs}`;
  }
  return apiClient({
    url: url,
    method: routes.GET_ALL_APPS.METHOD,
    searchQuery,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
//   -----------------------history--------------------------

export const getTaskLogsGet = (searchQuery) => {
  let url = routes.GET_TASK_LOGS.URL;

  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map((key) => `${key}=${searchQuery[key]}`)
      .join("&");

    url = url + `?${qs}`;
  }
  return apiClient({
    url: url,
    method: routes.GET_TASK_LOGS.METHOD,
    searchQuery,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getLogsGet = (searchQuery) => {
  let url = routes.GET_LOGS.URL;

  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map((key) => `${key}=${searchQuery[key]}`)
      .join("&");

    url = url + `?${qs}`;
  }
  return apiClient({
    url: url,
    method: routes.GET_LOGS.METHOD,
    searchQuery,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const getTaskDetailsGet = (taskId) => {
  let url = routes.GET_TASK_DETAILS.URL + taskId + "/tasks";
  return apiClient({
    url: url,
    method: routes.GET_TASK_DETAILS.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//   -----------------------sidebar--------------------------
export const getapps = (searchQuery) => {
  let url = routes.GET_APPS.URL;
  if (searchQuery) {
    const qs = Object.keys(searchQuery)
      .map((key) => `${key}=${searchQuery[key]}`)
      .join("&");
    url = url + `?${qs}`;
  }
  return apiClient({
    method: routes.GET_APPS.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const getaddonapps = () => {
  return apiClient({
    method: routes.GET_ADD_ON_APPS.METHOD,
    url: routes.GET_ADD_ON_APPS.URL,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getTeamUserList = () => {
  return apiClient({
    method: routes.GET_TEAM_USERS_LIST.METHOD,
    url: routes.GET_TEAM_USERS_LIST.URL,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAgencyUserList = () => {
  return apiClient({
    method: routes.GET_AGENCY_USERS_LIST.METHOD,
    url: routes.GET_AGENCY_USERS_LIST.URL,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updateMemberData = (data) => {
  return apiClient({
    method: routes.UPDATE_TEAM_MEMBER.METHOD,
    url: routes.UPDATE_TEAM_MEMBER.URL,
    data: data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const addMemberData = (data) => {
  return apiClient({
    method: routes.ADD_TEAM_MEMBER.METHOD,
    url: routes.ADD_TEAM_MEMBER.URL,
    data: data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const addAgencyMemberData = (data) => {
  return apiClient({
    method: routes.ADD_AGENCY_MEMBER.METHOD,
    url: routes.ADD_AGENCY_MEMBER.URL,
    data: data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const deleteMember = (data) => {
  return apiClient({
    method: routes.DELETE_TEAM_MEMBER.METHOD,
    url: routes.DELETE_TEAM_MEMBER.URL,
    data: data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppEvents = (id) => {
  let url = routes.GET_APP_EVENTS.URL + `/${id}/app_events`;
  return apiClient({
    method: routes.GET_APP_EVENTS.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//--------------------------Canvas------------------------------------------------
export const captureWebResponse = (node) => {
  const identifier = node.data.webhook_url.split("/").pop();
  let payload = {};
  for (const [key] of Object.entries(node.data)) {
    switch (key) {
      case "webhook_url":
        payload.webhookUrl = node.data.webhook_url;
        break;
      case "id":
        payload.left_app_id = node.data.id;
        break;
      case "appEvent":
        payload.left_app_event_id = node.data.appEvent;
        break;
      case "enabled":
        payload.auth_enabled = true;
        payload.api_key = node.data.apiKey;
        payload.api_secret = node.data.apiSecret;
        break;
      default:
        payload.canvas_json = {};
        if (node && node.data.konnect_id)
          payload.konnect_id = node.data.konnect_id;
    }
  }
  return apiClient({
    url: routes.CAPTURE_WEBHOOK_RESPONSE.URL + `${identifier}`,
    method: routes.CAPTURE_WEBHOOK_RESPONSE.METHOD,
    data: payload,
    extra: node.data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getLinkedAccount = (id) => {
  let url = routes.GET_LINKED_ACCOUNTS.URL + `${id}/authorized_app_accounts`;
  return apiClient({
    method: routes.GET_LINKED_ACCOUNTS.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

// export const getRedeemedCoupon = () => {
//   let url = routes.GET_COUPON.URL;
//   return apiClient({
//     method: routes.GET_COUPON.METHOD,
//     url: url,
//     headers: {
//       Authorization: AuthTokenService.get(),
//     },
//   });
// };

// export const redeemCoupon = (code) => {
//   let url = routes.MAKE_COUPON.URL;
//   return apiClient({
//     method: routes.MAKE_COUPON.METHOD,
//     url: url,
//     data: { code: code },
//     headers: {
//       Authorization: AuthTokenService.get(),
//     },
//   });
// };

// export const featureList = (code) => {
//   let url = routes.FEATURE_LIST.URL;
//   return apiClient({
//     method: routes.FEATURE_LIST.METHOD,
//     url: url,
//     headers: {
//       Authorization: AuthTokenService.get(),
//     },
//   });
// };

export const getAppEventConfig = (data) => {
  let url =
    routes.GET_APP_EVENT_CONFIGS.URL +
    `${data.id}/app_events/${data.selectedEvent.id}/event_config/${
      data.nodeIdx === 0 ? "left" : "right"
    }`;
  if (data.selectedAccount?.id)
    url += `?app_account_id=${data.selectedAccount.id}`;
  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const onNodeDeleteV2 = (props) => {
  return apiClient({
    method: routes.ON_NODE_DELETE.METHOD,
    url: routes.ON_NODE_DELETE.URL,
    data: props,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const testAndReview = (props) => {
  return apiClient({
    method: routes.TEST_AND_REVIEW.METHOD,
    url: routes.TEST_AND_REVIEW.URL,
    data: props.payload,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const getAppEventConfigDetails = (nodeData) => {
  const sequence = nodeData.prevSequence
    ? nodeData.prevSequence.sequence + 1
    : 1;
  const eventConfigId = nodeData.appEventConfigurations.find(
    (x) => x.sequence === sequence
  ).id;
  let url =
    routes.GET_APP_EVENT_CONFIGS_DETAILS.URL +
    `${nodeData.id}/app_events/${
      nodeData.selectedEvent.id
    }/event_config/${nodeData.selectedEvent.side.toLowerCase()}/${eventConfigId}/details?`;

  let params = "";
  if (nodeData.prevSequence) {
    nodeData.appEventConfigurations.map((x) => {
      if (x.config_key !== nodeData.prevSequence.config_key && x.selected) {
        params += `&${x.config_key}=${x.selected.id}`;
      }
    });
  }
  if (nodeData.prevSequence) {
    url = `${url}${nodeData.prevSequence.config_key}=${nodeData.prevSequence.id}${params}`;
  }

  if (nodeData.selectedAccount?.id)
    url += `&app_account_id=${nodeData.selectedAccount.id}`;
  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const getAppEventConfigDetailsFetchsimple = (nodeData) => {
  let params = "";
  nodeData.appEventConfigurations.map((x) => {
    if (x.config_key !== "sheet_id" && x.selected)
      params += `&${x.config_key}=${x.selected.id}`;
  });
  if (nodeData.selectedValue) {
    params = `?${nodeData.selectedValue.config_key}=${nodeData.selectedValue.id}${params}`;
  }
  let url =
    routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.URL +
    `${nodeData.id}/app_events/${
      nodeData.selectedEvent.id
    }/event_config/${nodeData.selectedEvent.side.toLowerCase()}/${
      nodeData.selectedValue.id
    }/fetch-fields`;

  url += `${params}`;

  if (nodeData.selectedAccount.id)
    url += `&app_account_id=${nodeData.selectedAccount.id}`;
  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updatePasswordPost = (data) => {
  return apiClient({
    url: routes.UPDATE_PASSWORD.URL,
    data: {
      current_password: data.current_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    },
    method: routes.UPDATE_PASSWORD.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};
export const getProfileGet = (payload) => {
  return apiClient({
    url: routes.GET_PROFILE.URL,
    data: payload,
    method: routes.GET_PROFILE.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const updateProfilePost = (data) => {
  return apiClient({
    url: routes.UPDATE_PROFILE.URL,
    data: {
      first_name: data.first_name,
      last_name: data.last_name,
      company_name: data.company_name,
      current_position: data.current_position,
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      country: data.country,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      tax_id: data.tax_id,
      timezone: data.timezone,
      preferred_language: data.preferred_language,
    },
    method: routes.UPDATE_PROFILE.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getKonnecttoEdit = (props) => {
  //url: `https://stagingapp.konnectzit.com/api/v1/konnects/${props}/edit`,
  let url = routes.ON_GET_KONNECT.URL + `${props}/edit`;
  return apiClient({
    method: routes.ON_GET_KONNECT.METHOD,
    url: url,
    data: props,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//-------------------------------Account-------------------------------

export const getAgencyUsersListGet = (payload) => {
  return apiClient({
    url: routes.GET_AGENCY_USERS_LIST.URL,
    data: payload,
    method: routes.GET_AGENCY_USERS_LIST.METHOD,
  });
};

export const addAgencyMemberPost = (data) => {
  return apiClient({
    url: routes.ADD_AGENCY_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_task: data.tasks,
    },
    method: routes.ADD_AGENCY_MEMBER.METHOD,
  });
};

export const updateAgencyMemberPost = (data) => {
  return apiClient({
    url: routes.UPDATE_AGENCY_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_id: data.sub_account_id,
      sub_account_task: data.tasks,
    },
    method: routes.UPDATE_AGENCY_MEMBER.METHOD,
  });
};

export const deleteAgencyMemberGet = (id) => {
  return apiClient({
    url: routes.DELETE_AGENCY_MEMBER.URL + id,
    data: payload,
    method: routes.DELETE_AGENCY_MEMBER.METHOD,
  });
};

export const getTeamUsersListGet = (payload) => {
  return apiClient({
    url: routes.GET_TEAM_USERS_LIST.URL,
    data: payload,
    method: routes.GET_TEAM_USERS_LIST.METHOD,
  });
};

export const addTeamMemberPost = (data) => {
  return apiClient({
    url: routes.ADD_TEAM_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_task: data.tasks,
    },
    method: routes.ADD_TEAM_MEMBER.METHOD,
  });
};

export const updateTeamMemberPost = (data) => {
  return apiClient({
    url: routes.UPDATE_TEAM_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_id: data.sub_account_id,
      sub_account_task: data.tasks,
    },
    method: routes.UPDATE_TEAM_MEMBER.METHOD,
  });
};

export const deleteTeamMemberGet = (id) => {
  return apiClient({
    url: routes.DELETE_TEAM_MEMBER.URL + id,
    data: payload,
    method: routes.DELETE_TEAM_MEMBER.METHOD,
  });
};

export const getResellerUsersListGet = (payload) => {
  return apiClient({
    url: routes.GET_RESELLER_USERS_LIST.URL,
    data: payload,
    method: routes.GET_RESELLER_USERS_LIST.METHOD,
  });
};

export const addResellerMemberPost = (data) => {
  return apiClient({
    url: routes.ADD_RESELLER_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_task: data.tasks,
    },
    method: routes.ADD_RESELLER_MEMBER.METHOD,
  });
};

export const updateResellerMemberPost = (data) => {
  return apiClient({
    url: routes.UPDATE_RESELLER_MEMBER.URL,
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      sub_account_id: data.sub_account_id,
      sub_account_task: data.tasks,
    },
    method: routes.UPDATE_RESELLER_MEMBER.METHOD,
  });
};

export const deleteResellerMemberGet = (id) => {
  return apiClient({
    url: routes.DELETE_RESELLER_MEMBER.URL + id,
    data: payload,
    method: routes.DELETE_RESELLER_MEMBER.METHOD,
  });
};

export const savekonnect = (props) => {
  let { editorState } = props;
  const newEditorState = {
    ...editorState,
    konnect_id: editorState.nodes[0]?.data?.konnect_id ?editorState.nodes[0]?.data?.konnect_id:null,
  };

  const payload = {
    canvas_name: true,
    konnect_id: editorState.nodes[0]?.data?.konnect_id ?editorState.nodes[0]?.data?.konnect_id:null,
    canvas_json: newEditorState,
    canvasname: "ReactFlow2.2",
  };

  const allReviewed = editorState.nodes.every((node) => node.data.reviewed);

  let url =
    props.publish && allReviewed
      ? routes.ON_SAVE_KONNECT.URL + `publish_konnect`
      : routes.ON_SAVE_KONNECT.URL + `save_konnect`;

  return apiClient({
    method: routes.ON_SAVE_KONNECT.METHOD,
    url: url,
    data: payload,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppEventConfigDetailCust = (body) => {
  const {
    data,
    config_key = "",
    input,
    port: eventConfigId = "",
    prevSequence = null,
    eventSequence,
  } = body;
  const { id, appEvent, appEventSequences, selectedAccount } = data;
  let source = false;
  let url =
    routes.GET_APP_EVENT_CONFIGS_DETAILS.URL +
    `${id}/app_events/${appEvent}/event_config/${source ? "left" : "right"}/${
      eventConfigId + 1
    }/details?`;

  let params = "";

  if (data.appEventSequences) {
    data.appEventSequences.map((x) => {
      let ind = x.config_key.indexOf(">");
      params += `&${x.config_key}=${x.id !== "" ? x.id : input}`;
    });
  }

  if (prevSequence) {
    url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`;
  }
  if (selectedAccount.id)
    url += `${params}&app_account_id=${selectedAccount.id}`;

  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS_DETAILS.METHOD,
    url: url,

    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const removeAgencyUsers = (data) => {
  let url = routes.DELETE_AGENCY_MEMBER.URL + `${data.sub_account_id}`;
  return apiClient({
    method: routes.DELETE_AGENCY_MEMBER.METHOD,
    url: url,
    data: data,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppEventConfigDetailfetchuserDefined = (props) => {
  const {
    data,
    nodeId,
    appId,
    eventId,

    selectedEventConfigId,
    selectedConfigKey,
    app_account_id,
    input,
    nodes,
  } = props;
  const nodeIdx = nodes.findIndex((x) => x.id === data.nodeId);
  let source = nodeIdx === 0;
  let params = "";
  if (
    data.appEventSequences //&& data.provider !=="crove_new"
  ) {
    data.appEventSequences.map((x) => {
      if (x.config_key !== "sheet_id" && x.id !== "")
        params += `&${x.config_key}=${x.id}`;
    });
  }

  if (selectedConfigKey) {
    params = `?${selectedConfigKey.slice(
      0,
      selectedConfigKey.indexOf(">")
    )}=${selectedEventConfigId}${params}`;
  }

  let url =
    routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.URL +
    `${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`;

  url += `${params}`;

  if (app_account_id) url += `&app_account_id=${app_account_id}`;
  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppEventConfigDetailCustomKmap = (payload) => {
  const {
    data,
    app,
    appEvent,
    node,
    eventConfigId,
    app_account_id,
    targetPortInfo,
    editorState,
  } = payload;

  const { nodes } = editorState;

  const { config_key, id, key_value_type, label, type } = targetPortInfo;
  const Node = nodes.find((n) => n.id === data.nodeId);
  const port = data.configResponses.find(
    (d) => d.config_key === config_key
  ).portId;
  let prevSequence = false;
  let source = false;

  let url =
    routes.GET_APP_EVENT_CONFIGS.URL +
    `${app}/app_events/${appEvent}/event_config/${
      source ? "left" : "right"
    }/${`${Number(port) + 1}`}/details?`;

  let params = "";

  if (
    data.configResponses.filter((d) => d.config_key === config_key)[0].value
  ) {
    data.appEventSequences.filter(
      (d) => d.config_key === config_key.slice(0, config_key.indexOf(">"))
    )[0].sequence.id = data.configResponses.filter(
      (d) => d.config_key === config_key
    )[0].value;
    data.appEventSequences.filter(
      (d) => d.config_key === config_key.slice(0, config_key.indexOf(">"))
    )[0].id = data.configResponses.filter(
      (d) => d.config_key === config_key
    )[0].value;
  }
  if (data.appEventSequences) {
    data.appEventSequences.map((x) => {
      let ind = x.config_key.indexOf(">");
      params += `&${x.config_key}=${x.id !== "" ? x.id : eventConfigId}`;
    });
  }
  if (prevSequence) {
    url = `${url}${prevSequence.config_key}=${prevSequence.id}${params}`;
  }

  // url+=params&params

  if (app_account_id) url += `${params}&app_account_id=${app_account_id}`;

  return apiClient({
    url: url,
    data: { ...payload },
    method: routes.GET_APP_EVENT_CONFIGS.METHOD,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getAppEventConfigDetailFetchKMap = (props) => {
  const {
    data,
    nodeId,
    appId,
    eventId,
    selectedEventConfigId,
    app_account_id,
    editorState,
  } = props;
  const { nodes, links } = editorState;
  const nodeIdx = nodes.findIndex((x) => x.id === data.nodeId);
  let source = nodeIdx === 0;
  let params = "";
  if (data.appEventSequences) {
    data.appEventSequences.map((x) => {
      if (x.config_key !== "sheet_id" && x.id !== "")
        params += `&${x.config_key}=${x.id}`;
    });
  }

  let selectedConfigKey =
    data.appEventSequences[data.eventSequence - 1].config_key;

  if (selectedConfigKey) {
    params = `?${selectedConfigKey}=${selectedEventConfigId}${params}`;
  }

  let url =
    routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.URL +
    `${appId}/app_events/${eventId}/event_config/${
      source ? "left" : "right"
    }/${selectedEventConfigId}/fetch-fields`;

  url += `${params}`;

  if (app_account_id) url += `&app_account_id=${app_account_id}`;

  return apiClient({
    method: routes.GET_APP_EVENT_CONFIGS_DETAILS_FETCH.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const getRedeemedCoupon = () => {
  let url = routes.GET_COUPON.URL;
  return apiClient({
    method: routes.GET_COUPON.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const redeemCoupon = (code) => {
  let url = routes.MAKE_COUPON.URL;
  return apiClient({
    method: routes.MAKE_COUPON.METHOD,
    url: url,
    data: { code: code },
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

export const featureList = (code) => {
  let url = routes.FEATURE_LIST.URL;
  return apiClient({
    method: routes.FEATURE_LIST.METHOD,
    url: url,
    headers: {
      Authorization: AuthTokenService.get(),
    },
  });
};

//   -----------------------Paddle--------------------------
export const subscriptionDetail = (id) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/subscription?customer_id=${id}`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
      // Add other headers as needed
    },
  });
};
export const paddleAuthh = (plan_type) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/paddle`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
    },
  });
};
export const planPrices = (props) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/${props.plan_type}`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
      Authorization: `Bearer ${props.authToken}`,
    },
  });
};
export const updatePlans = (props) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/update_subscription?subscription_id=${props.subscription_id}&price_id=${props.price_id}`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
      Authorization: `Bearer ${props.authToken}`,
    },
  });
};

export const cancelPlans = (props) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/cancel_subscription?subscription_id=${props.subscription_id}`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
      Authorization: `Bearer ${props.authToken}`,
    },
  });
};

export const productDetails = (props) => {
  return apiClient({
    method: "get",
    url: `${baseUrl}/product_details?product_id=${props.product_id}`,
    headers: {
      "Content-Type": "application/json", // Set the appropriate content type if sending JSON data
      Authorization: `Bearer ${props.authToken}`,
    },
  });
};
